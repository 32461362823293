<div fxLayout="row" fxLayoutAlign="start center" class="date-range-picker__date-section-container">
    <date-picker class="date-range-picker__picker"
                 [campaignType]="campaignType"
                 [placeholder]="startDatePlaceholder"
                 [isMobile]="isMobile"
                 [date]="start"
                 [disabled]="disabled"
                 [disabledDays]="disabledDays"
                 [disableSundays]="disableSundays"
                 [minDate]="minStart"
                 [maxDate]="maxStart"
                 [validationControl]="startDateFormControl"
                 (dateChanged)="startDateChanged($event)">
    </date-picker>
    <date-picker class="date-range-picker__picker"
                 [campaignType]="campaignType"
                 [placeholder]="endDatePlaceholder"
                 [isMobile]="isMobile"
                 [date]="endDate"
                 [disabled]="disabled"
                 [disabledDays]="disabledDays"
                 [disableSundays]="disableSundays"
                 [minDate]="minEnd"
                 [maxDate]="maxEnd"
                 [validationControl]="endDateFormControl"
                 (dateChanged)="endDateChanged($event)">
    </date-picker>
</div>
