import * as settings from '../actions/settings';
import * as user from '../actions/user';
import { clone, findIndex, propEq } from 'ramda';
import { SettingsConstants } from '../settings/settings.constants';

// Maintain the original future events from the api call in
// case the user leaves the settings page without saving
export interface State {
  activeAutomations: boolean;
  automations: any;
  futureEvents: any;
  locations: any[];
  successMessage: string;
  updatedFutureEvents: any;
}

export const initialState: State = {
  activeAutomations: false,
  automations: {
    automations: [],
    error: null,
    loading: true
  },
  futureEvents: {
    loading: true,
    error: null,
    events: []
  },
  locations: [],
  successMessage: null,
  updatedFutureEvents: {
    events: []
  }
};

export function reducer(state = initialState, action: settings.Actions | user.Actions): State {
  switch (action.type) {
    case settings.ActionTypes.DEFAULT_LOCATION_UPDATED: {
      return {
        ...state,
        successMessage: SettingsConstants.defaultLocationUpdateSuccess
      };
    }

    case settings.ActionTypes.EVENT_SAVE_ERROR: {
      const error = action.payload;
      return {
        ...state,
        futureEvents: { loading: false, events: [], error },
      };
    }

    case settings.ActionTypes.EVENT_SAVE_SUCCESSFUL: {
      const successMessage = action.payload;
      return {
        ...state,
        successMessage
      };
    }

    case settings.ActionTypes.FUTURE_EVENTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        futureEvents: { events: [], loading: false, error },
      };
    }

    case settings.ActionTypes.FUTURE_EVENTS_FOUND: {
      const events = action.payload;
      return {
        ...state,
        futureEvents: { events, loading: false, error: null },
        updatedFutureEvents: { events },
      };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS:
    case settings.ActionTypes.SAVE_EVENT_SETTINGS: {
      return {
        ...state,
        futureEvents: { ...state.futureEvents, loading: true },
      };
    }

    case settings.ActionTypes.RSVP_REMINDER_CHANGED: {
      const event = action.payload;
      const index = findIndex(propEq('eventId', event.eventId))(state.updatedFutureEvents.events);
      let updatedEventArray = clone(state.updatedFutureEvents.events);
      updatedEventArray[index] = event;

      return {
        ...state,
        updatedFutureEvents: {
          ...state.updatedFutureEvents,
          events: updatedEventArray
        },
      };
    }

    case settings.ActionTypes.SUCCESS_MESSAGE_DISMISSED: {
      return {
        ...state,
        successMessage: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getFutureEvents = (state: State) => state.futureEvents.events;

export const getFutureEventsError = (state: State) => state.futureEvents.error;

export const getFutureEventsLoading = (state: State) => state.futureEvents.loading;

export const getLocations = (state: State) => state.locations;

export const getSuccessMessage = (state: State) => state.successMessage;

export const getUpdatedFutureEvents = (state: State) => state.updatedFutureEvents.events;
