import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/location.model';
import { AnalyticsService } from '../core/analytics';
import { environment } from "../../environments/environment";

@Injectable()
export class ManageAttendeesService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService,
    private analytics: AnalyticsService
  ) {}

  public getAttendeeData(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/event/attendees/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public updateAttendeeData(payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/event/attendees`;
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map((res: any) => {
        this.analytics.sendMParticleEvent(
          'Saved Attendees',
          {}
        );
        return res.responseObject;
      }),
      catchError((err) => this.ehs.handleError(err))
    );
  }
}
