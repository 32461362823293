import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import * as fromRoot from '../../reducers';
import * as customer from '../../actions/customer';
import { CustomersSelectedForEngage } from '../../actions/customer';
import { AnalyticsService } from '../../core/analytics';
import { MeetConstants } from '../../meet/meet.constants';
import { SharedConstants } from '../shared.constants';
import { IconConfirmationDialogComponent } from '../icon-confirmation-dialog/icon-confirmation-dialog.component';

@Component({
  selector: 'actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
})
export class ActionsMenuComponent {
  @Input() public canMakeInactive: boolean = false;
  @Input() public selectedCustomerIds: string[] = [];
  @Input() public isMobile: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public disableActionMenu: boolean;
  @Input() public unsavedChangesTitle: string;
  @Input() public unsavedChangesText: string;
  @Input() public unsavedChangesIcon: string;
  @Input() public unsavedChangesCancel: string;
  @Input() public unsavedChangesConfirm: string;
  @Input() public shouldDisplayUnsavedChangesDialog: boolean = false;
  @Input() public keepAppliedFilterAnalytics: boolean = false;

  public isAllSendingDisabled$: Observable<boolean>;
  public isRewardsSendingDisabled$: Observable<boolean>;
  public menuOpen: boolean = false;

  constructor(private store: Store<fromRoot.State>,
              private router: Router,
              private analytics: AnalyticsService,
              private dialog: MatDialog) {
    this.isAllSendingDisabled$ = store.select(fromRoot.getFeatureEnabled(MeetConstants.disableSendingFlag));
    this.isRewardsSendingDisabled$ = store.select(fromRoot.getFeatureEnabled(SharedConstants.disableRewardSendFlag));
  }

  public engageWithCustomer = (engagementIndex) : void => {
    let payload = {};
    if (this.keepAppliedFilterAnalytics) {
      this.store.select(fromRoot.getMeetSelectedFilters).pipe(take(1)).subscribe(filters => {
        payload = { numberOfAppliedFilters: filters.length };
      })
    }

    this.analytics.sendMParticleEvent('Start New Engage Flow From Action Menu', payload);

    this.store.dispatch(new CustomersSelectedForEngage(this.selectedCustomerIds));
    this.router.navigate(
      ['/engage/template-selection'],
      { queryParams: { retainSelected: true, engagementIndex } }
    );
  }

  public makeInactive() {
    this.analytics.sendMParticleEvent(
      'Customers Moved to Inactive',
      { numberOfCustomers: this.selectedCustomerIds.length }
    );
    this.store.dispatch(new customer.MakeInactiveAction(this.selectedCustomerIds));
  }

  public makeActive() {
    this.analytics.sendMParticleEvent(
      'Customers Moved to Active',
      { numberOfCustomers: this.selectedCustomerIds.length }
    );
    this.store.dispatch(new customer.MakeActiveAction(this.selectedCustomerIds));
  }

  // If there is an open note with unsaved changes, open confirmation dialog
  // This will accept a function as an input that will be called based on confirmation dialog status
  public checkForUnsavedChanges(userAction: (param: any) => any, optionalParam?) : void {
    if (this.shouldDisplayUnsavedChangesDialog) {
      let config: MatDialogConfig = {
        data: {
          title: this.unsavedChangesTitle,
          displayText: this.unsavedChangesText,
          iconUrl: this.unsavedChangesIcon,
          cancelText: this.unsavedChangesCancel,
          confirmText: this.unsavedChangesConfirm
        },
        autoFocus: false
      }

      this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((shouldCancel) => {
        // Specifically check for false since clicking outside of dialog will return undefined
        let shouldDiscardChanges = shouldCancel === false;
        this.analytics.sendMParticleEvent(
          'Unsaved Changes Closed',
          { changesDiscarded: shouldDiscardChanges }
        );
        if (shouldDiscardChanges) {
          userAction(optionalParam);
        }
      });
    } else {
      userAction(optionalParam);
    }
  }

  isOpened() {
    this.menuOpen = true;
  }

  isClosed() {
    this.menuOpen = false;
  }
}
