import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { find, forEach, pluck, propEq, uniq } from 'ramda';
import { AnalyticsService } from '../../../core/analytics';
import { MeetConstants } from '../../../meet/meet.constants';

@Component({
  selector: 'mobile-data-table',
  templateUrl: './mobile-data-table.component.html',
  styleUrls: ['./mobile-data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDataTableComponent {
  @Input() public rowIdProp: string;
  @Input() public allCustomerIds: any[];
  @Input() public selectedItems: any[];
  @Input() public noResultsMessage: string;
  @Input() public numberOfPages: number;
  @Input() public currentPage: number;
  @Input() public totalElements: number;
  @Input() public pageSize: number;
  @Input() public showFooter: boolean = true;
  @Input() public loading: boolean;
  @Input() public actionableErrorText: string;
  @Input() public mobileDeleteEnabled: boolean = false;
  @Input() public useMembershipAsIcon: boolean = false;
  @Input() public disableCheckboxes: boolean = false;
  @Input() public enableMobileCheckbox: boolean = false;
  @Input() public disableRipple: boolean = false;
  @Input() public disableSelect: boolean = false;
  @Input() public disableIndividualRowAttribute: string;
  @Input() public mobileActionMenuMetaData: any;
  @Input() public hasOnClickListeners: boolean;
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public deepLinkMessage: string;
  @Input() public isVisibleDeepLinkMsg: string;
  @Input() public shouldDisplayIcon: boolean = true;
  @Input() public shouldDisplayLabel: boolean = true;
  @Input() public shouldDisplayMemberText: boolean = false;
  @Input() public isRowNotInteractable: boolean;
  @Input() public noteProp: string;

  public deleteIconUrl = MeetConstants.deleteIcon;

  @Input()
  set columns(columns: any[]) {
    if (columns) {
      this.mobileColumns = columns.filter((col) => {
        return col.mobile;
      });

      if (this.useMembershipAsIcon) {
        let membershipColumn = find(propEq('prop', 'memberStatus'))(columns);
        let memberColumn = find(propEq('prop', 'membership'))(columns);
        this.displayCfaOne = membershipColumn ? membershipColumn.displayCfaOne : memberColumn.displayCfaOne;
      }
    }
  }

  @Input()
  set rows(rows: any[]) {
    if (rows) {
      this.uniqueRowsOnPage = uniq(pluck(this.rowIdProp)(rows));
      this._rows = rows;
    } else {
      this.uniqueRowsOnPage = [];
      this._rows = [];
    }
  }

  get rows() {
    return this._rows;
  }

  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onErrorTextClick = new EventEmitter<any>();
  @Output() public onPageChange = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onCheckboxClick = new EventEmitter<any>();
  @Output() public onSelectAllCustomers = new EventEmitter<boolean>();
  @Output() public onSelectAllOnPageOnly = new EventEmitter<boolean>();
  @Output() public deepLinkMessageClick = new EventEmitter<any>();

  constructor(private analytics: AnalyticsService) { }

  public _rows: any[];
  public uniqueRowsOnPage: any[];
  public mobileColumns: any[];
  public displayCfaOne: boolean;

  public rowClicked(rowData: any) {
    if (!this.shouldDisableRow(rowData)) {
      this.onRowClick.emit(rowData);
    }
  }

  public shouldDisableRow(row: any) {
    return row[this.disableIndividualRowAttribute];
  }

  public isRowNonInteractive(row) {
    return this.shouldDisableRow(row) || (!this.hasOnClickListeners) || this.isRowNotInteractable;
  }

  public isDataAvailable() {
    return this.rows
      && this.rows.length
      && this.rows.length > 0;
  }

  public changeCurrentPage(page) {
    this.onPageChange.emit(page);
  }

  public getMembership(row) {
    let membershipObj = row['memberStatus'] ? row['memberStatus'] : row['membership'];
    return membershipObj ? membershipObj : '';
  }

  public viewSyncReport(row) {
    this.analytics.sendMParticleEvent('Sync Report Viewed on Mobile', {});
    window.open(row.reportUrl, '_blank');
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public checkboxClick(row) {
    let lastRow = {}; // Only use last row with matching id to send consistent data
    forEach((dr) => {
      if (dr[this.rowIdProp] === row[this.rowIdProp]) {
        dr['state'] = row.state; // Make all rows with same id be the same state
        lastRow = dr;
      }
    })(this.rows);
    this.onCheckboxClick.emit(row);
  }

  public selectAllClicked(checked) {
    this.onSelectAllCustomers.emit(checked);
  }

  public isAllCustomersSelected() {
    return this.selectedItems && this.allCustomerIds ? uniq(this.selectedItems).length === this.allCustomerIds.length : false;
  }

  public isOnePage() {
    return this.allCustomerIds && this.uniqueRowsOnPage ? this.allCustomerIds.length === this.uniqueRowsOnPage.length : false;
  }

  public shouldHideChevron(row: any) {
    return this.shouldDisableRow(row) || (!this.hasOnClickListeners) || this.isRowNotInteractable;
  }

  public getDisplayCfaOne() {
    return this.displayCfaOne ? this.displayCfaOne : this.isEnableSignatureMembership;
  }

  public onClickDeepLinkMsg() {
    this.deepLinkMessageClick.emit();
  }

  public noteClicked(row) {
    if (row) {
      row.isNoteOpen = !row.isNoteOpen;
    }
  }

  public selectAllOnPageOnly(state) {
    this.onSelectAllOnPageOnly.emit(state.checked);
  }

  public isCustomersSelectedOnPage() {
    if (this.rows !== undefined && this.rows.length > 0) {
      let countSelectedNumberOfRows = 0;
      this.rows.forEach(row => {
        if (row.state) {
          countSelectedNumberOfRows = countSelectedNumberOfRows + 1;
        }
      })
      return countSelectedNumberOfRows === this.uniqueRowsOnPage.length ? true : false;
    }
  }
}
