<mat-list-item (click)="onSummaryClick.emit()">
    <h4 matLine>
        View Overlapping Events
    </h4>
    <button mat-icon-button>
        <img alt="Chevron Icon"
             class="overlapping-summary__chevron-icon"
             src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
    </button>
</mat-list-item>
