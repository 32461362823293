import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromRoot from '../../reducers';
import { browserRefreshed } from '../../app.component';
import { DiscoverTile } from '../../reducers/discover';
import { DiscoverConstants } from '../discover.constants';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class DiscoverEditComponent implements OnInit {
  public title$: Observable<string>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public discoverTilesLoading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public engagementType$: Observable<string>;
  public templateHtml$: Observable<SafeHtml>;
  public tile$: Observable<DiscoverTile>;

  public emailType = DiscoverConstants.email;
  public promotionType = DiscoverConstants.promotion;
  public communityEventType = DiscoverConstants.categoryCommunityCare;
  public thirdPartyType = DiscoverConstants.thirdPartyPromotion;
  public freeItemInCart = DiscoverConstants.freeItemInCartPromotion
  public promotionTypes = [DiscoverConstants.promotion, DiscoverConstants.categoryCommunityCare, DiscoverConstants.thirdPartyPromotion, DiscoverConstants.freeItemInCartPromotion]


  constructor(private router: Router,
              private store: Store<fromRoot.State>,
              private sanitizer: DomSanitizer) {}

  public ngOnInit() {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.title$ = this.tile$.pipe(map((tile) => tile.descriptionText.summary));
      this.templateHtml$ = this.store.select(fromRoot.getReplacedOnePageFlowHtml).pipe(
        map((html) => this.sanitizer.bypassSecurityTrustHtml(html))
      );
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    }
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }
}
