import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'profile-section',
  templateUrl: './profile-section.component.html',
  styleUrls: ['./profile-section.component.scss'],
})
export class ProfileSectionComponent {
  @Input() public title: string;
  @Input() public displayDate: boolean = false;
  @Input() public exclude365Days: boolean = false;
  @Input() public defaultSelectedRange: number;

  @Output() public dateChanged = new EventEmitter<number>();

  public updateDateRange(event) {
    this.dateChanged.emit(event);
  }
}
