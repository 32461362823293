import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscoverTile } from '../../reducers/discover';

@Component({
  selector: 'banner-card',
  templateUrl: './banner-card.component.html',
  styleUrls: ['./banner-card.component.scss'],
})
export class BannerCardComponent {
  @Input() public tile: DiscoverTile;
  @Input() public isMobile: boolean;

  @Output() public dismissBanner = new EventEmitter();
  @Output() public bannerBtnClick = new EventEmitter();
}
