<spot-nav [title]="'Email Preview'"
          [isSmallNav]="true"
          [isMobile]="isMobile$ | async"
          (back)="goBack()">
</spot-nav>
<template-preview [templateHtml]="templateHtml$ | async"
                  [showAppPreview]="(engagementType$ | async) !== emailType && !promotionTypes.includes(engagementType$ | async)"
                  [showEmailPreview]="!promotionTypes.includes(engagementType$ | async)"
                  [showPromotionAppPreview]="(engagementType$ | async) === promotionType"
                  [showThirdPartyPromotion]="(engagementType$ | async) === thirdPartyPromotionType"
                  [showCommunityAppPreview]="(engagementType$ | async) === categoryCommunityCareType"
                  [showFreeItemInCartPreview] ="(engagementType$ |async) === freeItemInCartType"
                  [isMobile]="true">
</template-preview>
<div class="email-preview__mobile-footer" fxLayout="row" fxLayoutAlign="space-between">
  <div *ngIf="(engagementMode$ | async) !== editMode"
       fxLayout="row"
       fxLayoutAlign="end center"
       class="email-preview__button-container">
    <button *ngIf="(engagementType$ | async) === rewardType"
            mat-raised-button
            class="email-preview__send-button"
            fxFlex="60"
            fxFlexAlign="end"
            [disabled]="readOnlyUser$ | async"
            (click)="sendReward()">
      Send Reward
    </button>
    <button *ngIf="(engagementType$ | async) === automationType"
            mat-raised-button
            class="email-preview__send-button"
            fxFlex="60"
            fxFlexAlign="end"
            [disabled]="readOnlyUser$ | async"
            (click)="sendAutomation()">
      Automate Reward
    </button>
    <button *ngIf="(engagementType$ | async) === emailType"
            mat-raised-button
            class="email-preview__send-button"
            fxFlex="60"
            fxFlexAlign="end"
            [disabled]="readOnlyUser$ | async"
            (click)="sendEmail()">
      Send Email
    </button>
  </div>
  <div *ngIf="(engagementMode$ | async) === editMode"
       class="email-preview__button-container"
       fxLayout="row"
       fxLayoutAlign="space-between">
    <button mat-raised-button
            class="email-preview__send-button"
            fxFlexAlign="end"
            [disabled]="readOnlyUser$ | async"
            (click)="update()">
      Save Update
    </button>

    <button mat-raised-button
            class="email-preview__send-button"
            [disabled]="readOnlyUser$ | async"
            (click)="stopAutomation()">
      Stop Automation
    </button>
  </div>
</div>
<div *ngIf="(engagementType$ | async) === promotionType"
     class="email-preview__mobile-footer-promotion" >
  <button mat-button
          class="email-preview__display-button-bonus-points"
          [disabled]="readOnlyUser$ | async"
          (click)="sendPromotion()">
    Create Promotion
  </button>
  <button mat-button
          class="email-preview__display-button-previous"
          [disabled]="readOnlyUser$ | async"
          (click)="goBack()">
    Go Back to Previous Screen
  </button>
</div>
<div *ngIf="(engagementType$ | async) === categoryCommunityCareType"
     class="email-preview__mobile-footer-promotion" >
  <button mat-button
          class="email-preview__display-button-community-care"
          [disabled]="readOnlyUser$ | async"
          (click)="sendCommunityEvent()">
    COMPLETE SET UP
  </button>
  <button mat-button
          class="email-preview__display-button-previous"
          [disabled]="readOnlyUser$ | async"
          (click)="goBack()">
    Go Back to Previous Screen
  </button>
</div>

<div *ngIf="(engagementType$ | async) === thirdPartyPromotionType || (engagementType$ | async) === freeItemInCartType "
     class="email-preview__mobile-footer-promotion" >
  <button mat-button
          class="email-preview__display-button-bonus-points"
          [disabled]="readOnlyUser$ | async"
          (click)="sendPromotions()">
    Create Promotion
  </button>
  <button mat-button
          class="email-preview__display-button-previous"
          [disabled]="readOnlyUser$ | async"
          (click)="goBack()">
    Go Back to Previous Screen
  </button>
</div>


