import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromRoot from '../reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})

export class AlertDialogComponent implements OnInit {
  public isMobile$: Observable<boolean>;

  public alertImageUrl: string;
  public alertHeaderText: string;
  public alertBodyText: string;
  public alertCtaButtonText: string;
  public alertCtaLink: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<AlertDialogComponent>) { }

  public ngOnInit() {
    this.alertImageUrl = this.dialogData.alertImageUrl;
    this.alertHeaderText = this.dialogData.alertHeaderText;
    this.alertBodyText = this.dialogData.alertBodyText;
    this.alertCtaButtonText = this.dialogData.alertCtaButtonText;
    this.alertCtaLink = this.dialogData.alertCtaLink;

    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
  }

  public closeDialog(isCta: boolean) {
    this.dialogRef.close(isCta);
  }
}
