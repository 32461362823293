<div (mouseenter)="showHelpText()" (mouseleave)="hideHelpText()" (click)="$event.stopPropagation()">
    <mat-icon *ngIf="!hoverText" [ngClass]="{'help-text__small-icon': isSmallIcon, 'help-text__medium-icon': isMediumIcon, 'help-text__3p-size': is3pSize}">
        info_outline
    </mat-icon>
    <span *ngIf="hoverText">{{hoverText}}</span>
    <div [hidden]="!show"
         fxLayout="column"
         fxLayoutAlign="start start"
         class="help-text__body"
         [ngClass]="{'help-text__left-display': displayOnLeft,
                     'help-text__center-display':displayInCenter}">
        <div *ngIf="helpText"
          [ngClass]="{'help-text__block-display-text': blockDisplay}">{{helpText}}</div>
        <div *ngIf="filterText && filterText.length > 0"
             class="help-text__sub-section-margin">
            <div *ngFor="let filter of filterText; let i = index"
                 class="help-text__filter-text"
                 [ngClass]="{'help-text__filter-text-margin' : i != (filterText.length - 1)}">
                {{filter}}
            </div>
        </div>
        <div *ngIf="strategicGuidanceText"
             class="help-text__sub-section-margin">
            {{strategicGuidanceText}}
        </div>
    </div>
</div>