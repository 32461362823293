import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedConstants } from '../shared.constants';

@Component({
  selector: 'text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit {

  @Input() public text: string;

  @Output() public textChanged = new EventEmitter();
  public toolbarOptions = SharedConstants.toolbarOptions;
  public requiredReplacements = SharedConstants.textBoxReplacements;
  public localText: any;

  ngOnInit(): void {
    this.localText = this.text;
  }

  public textChange(changeObj) {
    // Only output if the change is due to a user and not when it is due to the ngModel
    if (changeObj.source === 'user') {
      let formattedHtml = changeObj.html;

      // ngx-quill uses the ql-align classes to align text which requires the quill css packages.
      // In order to bypass that, this will replace those classes with the corresponding text-align style.
      // Also it replaces <p> tags with <span> to avoid styling concerns
      if (formattedHtml) {
        this.requiredReplacements.forEach(replacementObj => {
          if (formattedHtml.includes(replacementObj.originalText)) {
            formattedHtml = formattedHtml.replace(replacementObj.originalText, replacementObj.replacementText);
          }
        });
      } else {
        formattedHtml = '';
      }
      this.textChanged.emit(formattedHtml);
    }
  }

}
