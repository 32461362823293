<mat-card class="collapsible-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="collapsible-card__header card-header" (click)="toggleOpen()">
        <div fxFlex="95" fxLayout="row" fxLayoutAlign="start center">
            <i class="material-icons collapsible-card__delete"
               *ngIf="displayDelete"
               (click)="$event.stopPropagation(); deleteAction()">delete</i>
            <img *ngIf="icon" alt="Icon" class="collapsible-card__avatar" [src]="icon"/>
            <div fxLayout="column" fxLayoutAlign="start center">
                <mat-card-title [ngClass]="{'collapsible-card__title-restyled': isRestyled, 'collapsible-card__title': !isRestyled}">
                    <span class="collapsible-card__pre-title" *ngIf="preTitle">{{preTitle}}</span>
                    <span>{{title}}</span>
                    <span class="collapsible-card__post-title">{{postTitle}}</span>
                </mat-card-title>
                <div *ngIf="subtitle" class="collapsible-card__subtitle">
                    {{subtitle}}
                </div>
            </div>
        </div>
        <div fxFlex="5">
            <img alt="Chevron Icon Facing Up"
                 class="collapsible-card__chevron-icon-up"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"
                 [hidden]="!open"/>
            <img alt="Chevron Icon Facing Down"
                 class="collapsible-card__chevron-icon-down"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"
                 [hidden]="open"/>
        </div>
    </mat-card-header>
    <ng-container *ngIf="!loading && !error">
        <mat-card-content class="collapsible-card__content" *ngIf="open" [ngClass]="{'collapsible-card__remove-padding': isRestyled && isMobile}">
            <ng-content></ng-content>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="loading">
        <mat-card-content class="collapsible-card__content" *ngIf="open">
            <content-placeholder></content-placeholder>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="!loading && !!error">
        <mat-card-content class="collapsible-card__content" *ngIf="open">
            {{error}}
        </mat-card-content>
    </ng-container>
</mat-card>
