<div class="activity-history-with-notes" fxLayout="column" fxLayoutAlign="center center">
  <error-message *ngIf="error"
                 [error]="error"
                 [page]="'Activity History'">
  </error-message>

  <div *ngIf="!error" class="activity-history-with-notes__body">
    <div *ngFor="let record of displayRecords; let i = index"
         class="activity-history-with-notes__row"
         [ngClass]="{'activity-history-with-notes__blue-background' : i % 2 !== 0}">
      <div class="activity-history-with-notes__name">{{getCreatedBy(record.updatedBy)}}</div>
      <div class="activity-history-with-notes__date">{{record.updatedAt | locTimeZoneDate:'ddd, MMM DD YYYY h:mm a'}}</div>
      <div class="activity-history-with-notes__status">{{record.status}}</div>
      <div class="activity-history-with-notes__button-container">
        <button *ngIf="record.hasEmailRecord" mat-button (click)="viewEmailDetails.emit(record)">
          <img class="activity-history-with-notes__button-icon" src="../../../assets/img/eye.png" alt="">
          View Email
        </button>
        <button *ngIf="record.activityId && !readonly" mat-button (click)="checkForUnsavedChanges(openNote, record.activityId)">
          <img class="activity-history-with-notes__button-icon" src="../../../assets/img/chat.png" alt="">
          Add a Note
        </button>
      </div>
      <div *ngFor="let note of record.recoveryNotes; let j = index"
           class="note-section__note-container"
           [ngClass]="{'note-section__blue-background': j % 2 !== 0, 'note-section__note-top-border': j === 0}">
        <div class="activity-history-with-notes__note">
          <note [note]="note"
                [deleteEnabled]="false"
                [readOnly]="readonly"
                (editNoteClicked)="checkForUnsavedChanges(editNote, record.activityId, j)"
                (updateNoteText)="updateNoteText($event, record.activityId, j)"
                (cancelEditClicked)="checkForUnsavedChanges(cancelEdit, note, record.activityId, j)"
                (saveNoteClicked)="saveNote(note, record.activityId, j)">
          </note>
        </div>
      </div>
    </div>
    <pagination *ngIf="allRecords.length > pageSize"
                [totalElements]="allRecords.length"
                [pageSize]="pageSize"
                [currentPage]="currentPage"
                (pageLeft)="pageLeft()"
                (pageRight)="pageRight()">
    </pagination>
  </div>
</div>
