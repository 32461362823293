import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'bottom-sheet',
  templateUrl: 'bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})

export class BottomSheetComponent implements OnInit {
  public content;

  constructor(@Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
              public bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>) {}

  public ngOnInit() {
    this.content = this.data.content;
  }

  public dismissSheet(listItem) {
    if (listItem && this.content && this.content.selectedItem !== listItem.value) {
      this.bottomSheetRef.dismiss(listItem);
    } else {
      this.bottomSheetRef.dismiss();
    }
  }
}
