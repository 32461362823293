<div fxLayout="row"
     fxLayoutAlign="center center"
     class="flex-container ngx-data-table__header"
     [ngClass]="{'ngx-data-table__small-header' : useSmallRowSize}">
  <div class="flex-item" [fxFlex]="getFlex()">
    <mat-checkbox class="ngx-data-table__checkbox checkbox-header"
                  *ngIf="showCheckboxes && rows && rows.length > 0"
                  [checked]="isCustomersSelectedOnPage()"
                  [hidden]="isAllCustomersNonSpotlight"
                  [disabled]="disableCheckbox"
                  (change)="selectAllOnPageOnly($event)">
    </mat-checkbox>
  </div>
  <ng-container *ngFor="let col of columns">
    <div class="flex-item"
         *ngIf="!col.hide"
         [fxFlex]="getColSize(col)"
         (click)="changeSort(col)">
      <span [ngClass]="{'ngx-data-table__header-title': !colIsNotSortable(col) && rows.length > 0,
                        'ngx-data-table__header-mobile-full': useFullMobileTable,
                        'ngx-data-table__center-header-title': col.centerContent}"
            fxLayout="row"
            fxLayoutAlign="start center">
          <span>{{getColTitle(col,isMobile)}}</span>
        <mat-icon [@arrowState]="getColState(col)"
                  fxFlex="5"
                  *ngIf="getColState(col) !== 'none' && rows.length > 0"
                  class="ngx-data-table__arrow">
          chevron_left
        </mat-icon>
      </span>
    </div>
  </ng-container>
  <!-- Account for space created from the chevron in the data table body-->
  <div fxFlex="32px"></div>
</div>
<div *ngIf="(selectedItems?.length !== allCustomerIds?.length) && showCheckboxes && canSelectAll &&
            isCustomersSelectedOnPage() && !isOnePage() && (uniqueRowsOnPage.length > 0) && !isAllCustomersNonSpotlight"
     class="flex-container ngx-data-table__select-all-message"
     fxLayout="row"
     fxLayoutAlign="center center">
  <span>Selected only {{uniqueRowsOnPage.length}} Customers on this page.</span>
  <span class="ngx-data-table__select-all-message-clickable" (click)="selectAllClicked(true)">
      Click here to select all {{allCustomerIds.length}} Customers in your Spotlight
  </span>
</div>

