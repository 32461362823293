<div class="community-care-multiple-event-dialog">

  <div fxLayout="column">
    <div mat-dialog-content fxLayout="column" fxFlexAlign="center">
      <div mat-dialog-title fxFlexFill fxLayout="row" fxFlexAlign="start">
        <h4 fxFlex class="community-care-multiple-event-dialog__title">You currently have active and scheduled Community Care Events.</h4>
        <button id = "community-care-multiple-event-dialog__close-dialog-x" mat-icon-button aria-label="close dialog" mat-dialog-close>
          <mat-icon [ngStyle]="{fontSize: size}">close</mat-icon>
        </button>
      </div>
      <table fxFlexFill fxLayout="row" fxFlexAlign="center" mat-table [dataSource]="events"  class="mat-elevation-z4">
        <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
          <td id = "community-care-multiple-event-dialog__existing-event-row"  mat-cell *matCellDef="let row" >
            <div *ngIf="col !='viewEvent'" class="community-care-multiple-event-dialog__table-data-content"
                  [ngClass] = "{'community-care-multiple-event-dialog__table-data-content-mobile': isMobile}">
              <span *ngIf="col !='viewEvent'">{{getDisplayData(row,col)}}</span>
            </div>
            <div *ngIf="col =='viewEvent'" class="community-care-multiple-event-dialog__table-data-content"
                 [ngClass] = "{'community-care-multiple-event-dialog__table-data-content-mobile': isMobile}">
              <button mat-button (click)="selectEvent(row)" class="community-care-multiple-event-dialog__button-small" >
                View Event
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
      </table>
      <div *ngIf="isMobile" fxLayout="column" fxFlexAlign="center" class= "community-care-multiple-event-dialog__footer-mobile">
          <button mat-button (click)="closeDialog(true)" class="community-care-multiple-event-dialog__action-button-mobile">
            Go To Calendar
          </button>
          <button mat-button (click)="closeDialog(false)" class = "community-care-multiple-event-dialog__button-mobile">
            Create New Event
          </button>
      </div>
    </div>
    <div *ngIf="!isMobile">
      <div mat-dialog-actions class="community-care-multiple-event-dialog__actions-container">
        <button mat-button (click)="closeDialog(true)" class="community-care-multiple-event-dialog__action-button">
          Go To Calendar
        </button>
        <button mat-button (click)="closeDialog(false)" class="community-care-multiple-event-dialog__button">
          Create New Event
        </button>
      </div>
    </div>
  </div>
</div>
