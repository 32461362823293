<ng-container *ngIf="!(loading$ | async) && !(error$ | async)">
  <div fxLayout="row" fxLayoutAlign="none center">
    <spot-nav fxFlex="100"
              [title]="pageTitle$ | async"
              [actionMenu]="false"
              [isSmallNav]="true"
              [isMobile]="isMobile$ | async"
              [hideBackArrow]="!(isMobile$ | async)"
              (back)="goToDiscover()">
    </spot-nav>
  </div>
  <app-community-care-details [tile]="tile$ | async"
                              [isMobile]="isMobile$ | async"
                              [optInButtons]="optInButtons$ |async"
                              [campaignName]="campaignName$ | async"
                              [organizations]="organizations$ | async"
                              [dateAndTimeObject]="datesAndTimes$ | async"
                              [disabledDays]="disabledDays$ | async"
                              [disabledTimes]="disabledTimes$ | async"
                              [selectedLocation]="selectedLocation$ | async"
                              [selectedOptIn]="(selectedOptIn$ | async)"
                              (onAnyDateChange)="onAnyDateChange($event)"
                              (onStartTimeChanged)="onStartTimeChange($event)"
                              (onAnyEngagementChange)="onAnyEngagementChange($event)"
                              (onGoToOverlappingEvents)="goToOverlappingEvents()"
                              (onGoBack)="goToDiscover()"
                              (onGoToPreview)="goToPreviewPage()"
                              (onSendEvent)="sendCommunityEvent()">
  </app-community-care-details>
</ng-container>
