import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorHandlerService } from './error-handler';
import { AnalyticsService } from './analytics';
import { WindowService } from './window/window.service';
import { NotificationsService } from './notifications/notifications.service';
import { CalendarService } from '../calendar/calendar.service';
import { CareService } from '../care/care.service';
import { DiscoverService } from '../discover/discover.service';
import { MeetService } from '../meet/meet.service';
import { EngageService } from '../engage/engage.service';
import { SettingsService } from '../settings/settings.service';
import { HelpService } from '../help/help.service';
import { EulaService } from '../eula/eula.service';
import { UnderstandService } from '../understand/understand.service';
import { CustomerProfileService } from '../customer-profile/customer-profile.service';
import { ManageAttendeesService } from '../manage-attendees/manage-attendees.service';
import { ConsoleErrorHandlerModule } from './console-error-handler/console-error-handler.module';

@NgModule({
  imports: [ CommonModule, ConsoleErrorHandlerModule ],
  providers: [
    AnalyticsService,
    WindowService,
    WindowService,
    NotificationsService,
    CareService,
    DiscoverService,
    MeetService,
    EngageService,
    SettingsService,
    HelpService,
    EulaService,
    ErrorHandlerService,
    UnderstandService,
    CustomerProfileService,
    ManageAttendeesService,
    CalendarService
  ]
})
export class CoreModule { }
