import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import * as moment from 'moment-timezone';
import { AnalyticsService } from '../core/analytics';
import { UserLocation } from '../models/location.model';
import { EngageConstants } from './engage.constants';
import Bugsnag from '@bugsnag/js';
import { environment } from '../../environments/environment';

@Injectable()
export class EngageService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService,
    private analytics: AnalyticsService
  ) { }

  public getCalendarRewardDetails(id, type) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/calendar/reward/${id}/${type}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCalendarAutomatedRewardDetails(treatGroupIds) {
    let groupIds = {
      'treatBatchIds' : treatGroupIds
    };
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/calendar/reward/automation`;
        return this.http.post(environment.spotlightApiUrl + url, groupIds);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCalendarEventDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/event/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCalendarEmailDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/emailStats/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCalendarBonusPointDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/promotion/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => { return res.responseObject }),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Bonus Point Details Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getCalendarBonusPointStats(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const statsUrl = `/api/location/${loc.locationNumber}/promotionStats/${id}`;
        return this.http.get(environment.spotlightApiUrl + statsUrl);
      }),
      map((res: any) => { return res.responseObject }),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Bonus Point Details Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getCalendarCommunityCareEvent(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const customerUrl = `/api/location/${loc.locationNumber}/cceStats?eventId=${id}`;
        const eventUrl = `/api/location/${loc.locationNumber}/communityCareEvent?eventId=${id}`;
        return forkJoin( [this.http.get(environment.spotlightApiUrl + customerUrl), this.http.get(environment.spotlightApiUrl + eventUrl)])
      }),
      map((res: any) => { return { ...res[0], ...res[1] } }),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Community Care Details Failed'));
        return this.ehs.handleError(error);
      })
    )
  }

  public getCalendarThirdPartyPromotion(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const promoUrl = `/api/location/${loc.locationNumber}/thirdPartyPromotion/${id}`;
        const metaDataUrl = `/api/${loc.locationNumber}/engagementDetails?automationDetails=${false}`;
        const metaDataRequest = {
          'campaignName': 'Grow Third Party Delivery',
          'engagementType': 'third-party-promotion',
          'flowId': 'third-party-promotion',
          'emailMessage': '',
          'selectedCustomers': [],
          'subject': ''
        }
        return forkJoin( [this.http.get(environment.spotlightApiUrl + promoUrl),this.http.post(environment.spotlightApiUrl + metaDataUrl, metaDataRequest)])
      }),
      map((responses: any) => responses.map(response => response.responseObject)),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Third Party Details Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

    public getCalendarFreeItemInCartPromotion(id) {
        return this.us.getSelectedLocation().pipe(
            switchMap((loc) => {
                const promoUrl = `/api/location/${loc.locationNumber}/promotion/${id}`;
                const metaDataUrl = `/api/${loc.locationNumber}/engagementDetails?automationDetails=${false}`;
                const metaDataRequest = {
                    'campaignName': 'Grow Third Party Delivery',
                    'engagementType': 'free-item-in-cart-promotion',
                    'flowId': 'free-item-in-cart-promotion',
                    'emailMessage': '',
                    'selectedCustomers': [],
                    'subject': ''
                }
                return forkJoin( [this.http.get(environment.spotlightApiUrl + promoUrl), this.http.post(environment.spotlightApiUrl + metaDataUrl, metaDataRequest)])
            }),
            map((responses: any) => responses.map(response => response.responseObject)),
            catchError((error: any) => {
                Bugsnag.notify(new Error('Fetch Free Item in Cart detailsFailed'));
                return this.ehs.handleError(error);
            })
        );
    }



  public getCalendarThirdPartyPromotionStats(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const statsUrl = `/api/location/${loc.locationNumber}/thirdPartyPromotion/stats/${id}`;
        return this.http.get(environment.spotlightApiUrl + statsUrl);
      }),
      map((response: any) => response.responseObject ),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Third Party Stats Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

    public getCalendarFreeInCartStats(id) {
        return this.us.getSelectedLocation().pipe(
            switchMap((loc) => {
                const statsUrl = `/api/location/${loc.locationNumber}/promotion/${id}/stats`;
                return this.http.get(environment.spotlightApiUrl + statsUrl);
            }),
            map((response: any) => response.responseObject ),
            catchError((error: any) => {
                Bugsnag.notify(new Error('Fetch FIC Stats Failed'));
                return this.ehs.handleError(error);
            })
        );
    }

  public getRewardTemplateDetails() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/reward/template/metadata`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Template Details Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getTreats() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/awardTemplates`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Rewards Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getEmailTemplates() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email/templates`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Emails Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getEventTemplates() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/events`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject.centerStageEvents),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Events Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getTemplateDetails(templateId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email/templates/${templateId}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Fetch Event / Email Details Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getDraftDetails(templateDraftObj, allFilters) {
    const { draftObj, id } = templateDraftObj;
    let hasSavedGroups = draftObj.selectedSavedGroups.length > 0;
    let hasSelectedFilters = draftObj.selectedFilters.length > 0;

    // Set initial array with the value of templateDetails
    let apiArr = [this.getTemplateDetails(id)];

    if (allFilters.length === 0 && (hasSelectedFilters || hasSavedGroups)) {
      // Need to load base filters to get meta data about them
      apiArr.push(this.loadFilters());
    }

    const templateCalls = forkJoin(apiArr);
    return templateCalls.pipe(map((draftApi) => {
      let { previewHtml, themes, displayThemes, description } = draftApi[0];
      let displayFilters = allFilters;

      if (allFilters.length === 0 && (hasSelectedFilters || hasSavedGroups)) {
        displayFilters = draftApi[1];
      }

      return {
        previewHtml,
        themes,
        displayThemes,
        description,
        displayFilters,
        selectedFilters: draftObj.selectedFilters,
        selectedSavedGroups: draftObj.selectedSavedGroups
      };
    }));
  }

  public loadFilters() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/${locationNumber}/default-filters`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res),
      catchError(this.ehs.handleError)
    );
  }

  public getEventTemplateDetails() {
    const eventTemplateHtml = this.getTemplateDetails(EngageConstants.eventHtmlId);
    const eventTemplateRSVP = this.getTemplateDetails(EngageConstants.eventRsvpHtmlId);
    const eventTemplateNoRSVP = this.getTemplateDetails(EngageConstants.eventNoRsvpHtmlId);

    const templateCalls = forkJoin([eventTemplateHtml, eventTemplateRSVP, eventTemplateNoRSVP]);
    return templateCalls.pipe(map((templateHtmls) => {
      return {
        previewHtml: templateHtmls[0].previewHtml,
        rsvpHtml: templateHtmls[1].previewHtml,
        noRsvpHtml: templateHtmls[2].previewHtml
      };
    }));
  }

  public sendReward(reward) {
    this.analytics.sendMParticleEvent(
      'Reward Sent',
      {
        rewardName: reward.offerName,
        rewardLength: reward.endDate ? reward.endDate.diff(reward.startDate, 'days') + 1 : null,
        numberOfCustomers: reward.numberOfCustomers,
        numberOfFilters: reward.selectedFilters.length,
        numberOfSavedGroups: reward.selectedSavedGroups.length,
        endDate: reward.endDate.format(),
        startDate: reward.startDate.format(),
        includedFilters:  reward.selectedFilters.map(filter=>filter.id).toString(),
        includedGroups: reward.selectedSavedGroups.map(group=>group.name).toString(),
        recruitmentModuleEnabled: reward.recruitmentModuleEnabled,
        recruitmentModuleLink: reward.recruitmentModuleLink,
        includeActive: reward.includeActive
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        let mappedReward = this.mapReward(reward, location.locationNumber);
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/sendOffer`;
        return this.http.post(environment.spotlightApiUrl + url, mappedReward);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Send Reward Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public resendReward(reward) {
    this.analytics.sendMParticleEvent(
      'Reward Sent',
      {
        rewardName: reward.offerName,
        rewardLength:
          reward.endDate ? reward.endDate.diff(reward.startDate, 'days') + 1 : null,
        numberOfIndividualRecipients: reward.cfaIds.length,
        numberOfDefaultGroups: reward.includedGroups.length,
        numberOfSavedFilters: reward.includedFilters.length,
        defaultGroups: reward.includedGroups.join(),
        endDate: reward.endDate.format(),
        startDate: reward.startDate.format(),
        recruitmentModuleEnabled: reward.recruitmentModuleEnabled,
        recruitmentModuleLink: reward.recruitmentModuleLink,
        includeActive: reward.includeActive
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        let mappedReward = this.mapResendReward(reward, location.locationNumber);
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/sendOffer`;
        return this.http.post(environment.spotlightApiUrl + url, mappedReward);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Send Reward Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public sendEvent(event) {
    this.analytics.sendMParticleEvent(
      'Event Sent',
      {
        templateName: event.name,
        numberOfCustomers: event.numberOfCustomers,
        numberOfFilters: event.selectedFilters.length,
        numberOfSavedGroups: event.selectedSavedGroups.length,
        recruitmentModuleEnabled: event.recruitmentModuleEnabled,
        includedFilters:  event.selectedFilters.map(filter=>filter.id).toString(),
        includedGroups: event.selectedSavedGroups.map(group=>group.name).toString(),
      }
    );

    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/calendarEvent`;
        return this.http.post(environment.spotlightApiUrl + url, event);
      }),
      map((res: any) => res.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Send Event Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public sendEmail(email) {
    this.analytics.sendMParticleEvent(
      'Email Sent',
      {
        templateName: email.templateName,
        numberOfCustomers: email.numberOfCustomers,
        numberOfFilters: email.selectedFilters.length,
        numberOfSavedGroups: email.selectedSavedGroups.length,
        recruitmentModuleEnabled: email.recruitmentModuleEnabled,
        includedFilters:  email.selectedFilters.map(filter=>filter.id).toString(),
        includedGroups: email.selectedSavedGroups.map(group=>group.name).toString(),
      }
    );

    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email`;
        return this.http.post(environment.spotlightApiUrl + url, email);
      }),
      map((body: any) => body.responseObject),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Send Email Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public cancelReward(reward) {
    let rewardRecipientCount = null;

    if (reward.showCustomers) {
      rewardRecipientCount = reward.recipients.length;
    }
    this.analytics.sendMParticleEvent(
      'Reward Canceled',
      {
        rewardName: reward.offerName,
        rewardRecipients: rewardRecipientCount
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/cancelReward/${reward.batchId}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((body) => body),
      catchError(this.ehs.handleError)
    );
  }

  public cancelEvent(event) {
    let eventRecipientCount = null;

    if (event.showCustomers) {
      eventRecipientCount = event.recipientIds.length;
    }
    this.analytics.sendMParticleEvent(
      'Event Canceled',
      {
        eventName: event.title,
        eventRecipients: eventRecipientCount
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/calendarEvent/${event.id}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public cancelEmail(email) {
    let emailCustomerCount = null;

    if (email.showCustomers) {
      emailCustomerCount = email.cfaIds.length;
    }
    this.analytics.sendMParticleEvent(
      'Email Canceled',
      {
        emailTemplate: email.templateTitle,
        emailRecipients: emailCustomerCount
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email/${email.id}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((body) => body),
      catchError(this.ehs.handleError)
    );
  }

  public editEvent(event) {
    this.analytics.sendMParticleEvent(
      'Event Edited',
      {
        emailTemplate: event.templateTitle,
        numberOfFilters: event.selectedFilters.length,
        numberOfSavedGroups: event.selectedSavedGroups.length
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/calendarEvent`;
        return this.http.put(environment.spotlightApiUrl + url, event);
      }),
      map((body) => body),
      catchError(this.ehs.handleError)
    );
  }

  public editEmail(email) {
    this.analytics.sendMParticleEvent(
      'Email Edited',
      {
        emailTemplate: email.templateTitle,
        numberOfFilters: email.selectedFilters.length,
        numberOfSavedGroups: email.selectedSavedGroups.length
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email`;
        return this.http.put(environment.spotlightApiUrl + url, email);
      }),
      map((body: any) => body.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public saveDraft(draft) {
    this.analytics.sendMParticleEvent(
      'Email Draft Saved',
      { draft }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email/draft`;
        return this.http.post(environment.spotlightApiUrl + url, draft);
      }),
      map((body) => body),
      catchError(this.ehs.handleError)
    );
  }

  public deleteDraft(draftId) {
    this.analytics.sendMParticleEvent(
      'Email Draft Delete',
      { draftId }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/email/draft/${draftId}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((body) => body),
      catchError(this.ehs.handleError)
    );
  }

  public getSavedFilters() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/savedFiltersForEngage`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getSavedFilterCustomers(filterId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/filter/${filterId}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getFilterDisplayCustomers(req) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/filters/customers`;
        return this.http.post(environment.spotlightApiUrl + url, req);
      }),
      map((res: any) => res.responseObject || {}),
      catchError(this.ehs.handleError)
    );
  }

  public getCustomersByIds(ids) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/customersById`;
        return this.http.post(environment.spotlightApiUrl + url, ids);
      }),
      map((res: any) => res.responseObject || {}),
      catchError(this.ehs.handleError)
    );
  }

  public clearCalendarEventsCache() {
    return this.us.getSelectedLocation().pipe(
      switchMap(() => {
        const url = '/api/cache/reset/apikeys/calendarEvents';
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject || { }),
      catchError(this.ehs.handleError)
    );
  }

  private mapReward(reward, locationNumber) {
    return {
      awardId: reward.id || reward.treatId,
      breakfastDisclaimerText: reward.breakfastDisclaimerText,
      startDateLiteral: moment(reward.startDate).format('YYYY-MM-DD'),
      endDateLiteral: moment(reward.endDate).format('YYYY-MM-DD'),
      endDateCountdownTimer: reward.endDateCountdownTimer,
      headerImage: reward.image || reward.headerImage,
      mobileHeaderImage: reward.mobileImage,
      headline: reward.subTitle || reward.headline,
      locationNumber,
      name: reward.name || reward.offerName,
      campaignName: reward.campaignName,
      offerCategory: reward.category || reward.treatCategory,
      offerImage: reward.imageURL || reward.offerImage,
      sendOperatorImage: reward.sendOperatorImage,
      subject: reward.subject,
      type: 'reward',
      recruitmentModuleEnabled: reward.recruitmentModuleEnabled,
      recruitmentModuleLink: reward.recruitmentModuleLink,
      treatCategory: reward.category,
      treatMessageForApp: reward.defaultMessageForApp,
      validDetails: reward.details || reward.validDetails,
      offerDetails: reward.emailCopy,
      resentNewTreat: reward.resentNewTreat,
      resentSameTreat: reward.resentSameTreat,
      earlierBatchId: reward.earlierBatchId,
      countdownTimer: reward.showCountdownTimer !== 'style="display: none;"',
      breakfast: reward.breakfast,
      limitedtimeoffer: reward.limitedtimeoffer,
      dynamicFlag: reward.dynamicFlag,
      selectedTheme: reward.selectedTheme,
      themeId: reward.themeId,
      includeActiveCustomers: reward.includeActive,
      selectedFilters: reward.selectedFilters,
      selectedSavedGroups: reward.selectedSavedGroups
    };
  }

  private mapResendReward(reward, locationNumber) {
    return {
      awardId: reward.id || reward.treatId,
      breakfastDisclaimerText: reward.breakfastDisclaimerText,
      startDateLiteral: moment(reward.startDate).format('YYYY-MM-DD'),
      endDateLiteral: moment(reward.endDate).format('YYYY-MM-DD'),
      endDateCountdownTimer: reward.endDateCountdownTimer,
      headerImage: reward.image || reward.headerImage,
      mobileHeaderImage: reward.mobileImage,
      headline: reward.subTitle || reward.headline,
      locationNumber,
      name: reward.name || reward.offerName,
      campaignName: reward.campaignName,
      offerCategory: reward.category || reward.treatCategory,
      offerImage: reward.imageURL || reward.offerImage,
      cfaIds: reward.cfaIds,
      includedGroups: reward.includedGroups,
      includedFilters: reward.includedFilters,
      sendOperatorImage: reward.sendOperatorImage,
      subject: reward.subject,
      type: 'reward',
      recruitmentModuleEnabled: reward.recruitmentModuleEnabled,
      recruitmentModuleLink: reward.recruitmentModuleLink,
      treatCategory: reward.category,
      treatMessageForApp: reward.defaultMessageForApp,
      validDetails: reward.details || reward.validDetails,
      offerDetails: reward.emailCopy,
      resentNewTreat: reward.resentNewTreat,
      resentSameTreat: reward.resentSameTreat,
      earlierBatchId: reward.earlierBatchId,
      countdownTimer: reward.showCountdownTimer !== 'style="display: none;"',
      breakfast: reward.breakfast,
      limitedtimeoffer: reward.limitedtimeoffer,
      dynamicFlag: reward.dynamicFlag,
      selectedTheme: reward.selectedTheme,
      themeId: reward.themeId,
      includeActiveCustomers: reward.includeActive
    };
  }
}
