import { Component, Input } from '@angular/core';

@Component({
  selector: 'navbar-footer',
  templateUrl: './navbar-footer.component.html',
  styleUrls: ['./navbar-footer.component.scss']
})
export class NavBarFooterComponent {
    @Input() public navLinks: any[];
}
