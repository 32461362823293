<div fxLayout="column" fxLayoutAlign="space-between center" class="alert-dialog__dialog">
  <img [src]="alertImageUrl" class="alert-dialog__image" alt="Alert Image"/>
  <div [ngClass]="(isMobile$ | async) ? 'alert-dialog__mobile-header-text' : 'alert-dialog__header-text'">
    {{alertHeaderText}}
  </div>
  <div [ngClass]="(isMobile$ | async) ? 'alert-dialog__mobile-body-text' : 'alert-dialog__body-text'">
    {{alertBodyText}}
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" class="alert-dialog__button-wrapper">
    <button *ngIf="alertCtaButtonText" mat-button class="alert-dialog__button" (click)="closeDialog(true)">{{alertCtaButtonText}}</button>
    <button mat-button class="alert-dialog__button" (click)="closeDialog(false)">OK</button>
  </div>
</div>
