import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserService } from '../user/user.service';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { environment } from "../../../environments/environment";

@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient,
              private us: UserService,
              private ehs: ErrorHandlerService
  ) { }

  public getNotifications() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/notifications`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public clearNotification(notificationId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/notification/${notificationId}`;
        return this.http.post(environment.spotlightApiUrl + url, 'C');
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public clearAllNotifications() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/notifications`;
        return this.http.post(environment.spotlightApiUrl + url, {});
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public markAllNotificationsAsRead() {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/notificationsRead`;
        return this.http.post(environment.spotlightApiUrl + url, {});
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }
}
