import { Actions, ActionTypes } from '../actions/notifications';
import { clone, findIndex, propEq } from 'ramda';

export interface State {
  error: string;
  loading: boolean;
  notifications: any[];
  notificationsReadLoading: boolean;
  unreadCount: number;
}

export const initialState: State = {
  error: null,
  loading: true,
  notifications: [],
  notificationsReadLoading: false,
  unreadCount: 0
};

export function reducer(state: State = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.CLEAR_ALL_NOTIFICATIONS: {
      return { ...state, loading: true };
    }

    case ActionTypes.CLEAR_ALL_NOTIFICATIONS_FAILURE:
    case ActionTypes.NOTIFICATIONS_FETCH_FAILURE:  {
      const error = action.payload;

      return {
        ...state,
        error,
        loading: false
      };
    }

    case ActionTypes.INITIAL_PAGE_VIEW_FETCH_SUCCESS: {
      const notificationsObj = action.payload;

      return {
        ...state,
        error: null,
        loading: false,
        notifications: notificationsObj.notifications,
        notificationsReadLoading: true,
        unreadCount: 0
      };
    }

    case ActionTypes.NOTIFICATIONS_CLEAR: {
      const id = action.payload;
      let notifications = clone(state.notifications);
      let index = findIndex(propEq('notificationId', id))(notifications);
      notifications[index] = { ...notifications[index], loading: true };

      return { ...state, notifications };
    }

    case ActionTypes.NOTIFICATIONS_CLEAR_FAILURE: {
      const { id, error } = action.payload;
      let notifications = clone(state.notifications);
      let index = findIndex(propEq('notificationId', id))(notifications);
      notifications[index] = { ...notifications[index], loading: false, error };

      return { ...state, notifications: clone(notifications) };
    }

    case ActionTypes.NOTIFICATIONS_CLEAR_SUCCESS: {
      const id = action.payload;
      let notifications = clone(state.notifications);
      let index = findIndex(propEq('notificationId', id))(notifications);
      notifications[index] = { ...notifications[index], loading: false };

      return { ...state, notifications };
    }

    case ActionTypes.NOTIFICATIONS_FETCH_SUCCESS: {
      const notificationsObj = action.payload;
      let unreadCount = state.notificationsReadLoading ? 0 : notificationsObj.unread;

      return {
        ...state,
        error: null,
        loading: false,
        notifications: notificationsObj.notifications,
        unreadCount
      };
    }

    case ActionTypes.NOTIFICATIONS_MARKED_AS_READ_SUCCESS: {
      return { ...state, notificationsReadLoading: false, unreadCount: 0 };
    }

    case ActionTypes.NOTIFICATIONS_PAGE_VIEWED: {
      return { ...state, error: null, loading: true };
    }

    default: {
      return state;
    }
  }
}

export const getNotifications = (state: State) => state.notifications;

export const getUnreadCount = (state: State) => state.unreadCount;

export const getLoading = (state: State) => state.loading;

export const getError = (state: State) => state.error;
