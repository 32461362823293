<div class="horizontal-bar-chart" fxLayout="column" fxLayoutAlign="space-between">
  <div class="horizontal-bar-chart__label-category">
    {{title}}
  </div>
  <div fxFlex *ngIf="showChart">
    <div *ngFor="let row of chartData">
      <div class="horizontal-bar-chart__base-bar-{{category}}">
        <div class="horizontal-bar-chart__text-container" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="horizontal-bar-chart__text"
                [ngClass]="{'horizontal-bar-chart__text-mobile': isMobile}">
            {{getData(row, listTitleProp)}}
          </span>
          <span [ngSwitch]="listValueFormat">
            <ng-container *ngSwitchCase="'currency'">
                {{getData(row, listValueProp) | currency:'USD':'symbol':'1.2-2'}}
            </ng-container>
            <ng-container *ngSwitchCase="'percentage'">
              <div>{{getData(row, listValueProp) | percent: '1.0-2'}}</div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                {{getData(row, listValueProp)}}
            </ng-container>
        </span>
        </div>
        <div class="horizontal-bar-chart__fill-bar-{{category}}"
             [ngStyle]="{'width': getBarPercentage(row, listValueProp) }">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showChart"
    class="horizontal-bar-chart__no-data-container"
    fxLayout="column"
    fxLayoutAlign="center center">
    <span class="horizontal-bar-chart__no-data-text">
      {{noDataText}}
    </span>
  </div>
</div>
