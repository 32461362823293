<span [ngSwitch]="col.icon" class="ngx-data-table__icon" *ngIf="getIcon()">
  <ng-container *ngSwitchCase="'inactive'">
    <mat-icon class="ngx-data-table__inactive" title="Inactive Customer">person_outline</mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'caresProspect'">
    <mat-icon title="CARES Prospect">face</mat-icon>
  </ng-container>
</span>
<span [ngSwitch]="col.type"
      [ngClass]="{'mobile-data-table__title': (isMobile && col.mobile === 'title'),
                  'mobile-data-table__subtext': (isMobile && col.mobile === 'subtext')}">

  <span *ngIf="isMobile && mobileTitle" [ngClass]="{'ngx-data-table__bold': mobileTitleBold}">{{mobileTitle}}</span>
  <ng-container *ngSwitchCase="'MonthDay'">{{getData() | locTimeZoneDate:'MMMM DD'}}</ng-container>
  <ng-container *ngSwitchCase="'UTCLongMonthDay'">{{getData() | locTimeZoneDate:'MMMM DD'}}</ng-container>
  <ng-container *ngSwitchCase="'UTCDate'">{{getData() | locTimeZoneDate:'MMM DD, YYYY'}}</ng-container>
  <ng-container *ngSwitchCase="'Date'">{{getData() | locTimeZoneDate:'MMM DD, YYYY'}}</ng-container>
  <ng-container *ngSwitchCase="'DayDate'">{{getData() | locTimeZoneDate:'MMM DD, YYYY'}}</ng-container>
  <ng-container *ngSwitchCase="'DateTime'">
    <ng-container *ngIf="getData()">
      {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </ng-container>
    <ng-container *ngIf="!getData()">
      N/A
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'DayDateTime'">
    {{getData() | locTimeZoneDate:'ddd, MMM DD YYYY hh:mm A':getLocTimezone()}}
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    {{getNumber() | number: '0.0-0'}}
  </ng-container>
  <ng-container *ngSwitchCase="'comma'">
    {{getNumber().toLocaleString()}}
  </ng-container>
  <ng-container *ngSwitchCase="'currency'">
    {{getNumber() | currency:'USD':'symbol':'1.2-2'}}
  </ng-container>

  <ng-container *ngSwitchCase="'percentage'">
    {{getNumber() | percent: '1.2-2'}}
  </ng-container>

  <ng-container *ngSwitchCase="'roundedPercentage'">
    {{getNumber() | percent: '1.0-0'}}
  </ng-container>

  <ng-container *ngSwitchCase="'truncate'">
    {{getData() | myTruncate: col.truncateLength}}
  </ng-container>

  <ng-container *ngSwitchCase="'html'">
    <div *ngIf="!useFullMobileTable" [innerHTML]="getData()"></div>
    <div *ngIf="useFullMobileTable" [innerHTML]="getShortenedData(17)"></div>
  </ng-container>

  <div *ngSwitchCase="'Status'" (mouseenter)="showHelpText()" (mouseleave)="hideHelpText()" (click)="$event.stopPropagation()" class="ngx-data-table__status-container">
    <img *ngIf="row[col.prop] === 'Completed' || row[col.prop] === 'Scheduled' || row[col.prop] === 'Failed'"
         alt="Status Icon"
         class="ngx-data-table__status-icon"
         [src]="getStatusIcon()"/>
    <div [hidden]="!show"
         class="ngx-data-table__status-icon-info">
         <span [innerHTML]="getStatusIconInfo()"></span>
    </div>
  </div>

  <ng-container *ngSwitchCase="'address'">
    <div *ngIf="getData()">
      {{getData().streetAddress}}
      <br>
      <span *ngIf="hasSecondStreetAddress(getData().streetAddress2)">
        {{getData().streetAddress2}}
        <br>
      </span>
      {{getData().locality}}, {{getData().region}}, {{getData().postalCode}}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'membership'">
    <member-status [membership]="getData()"
                   [displayCfaOne]="getDisplayCfaOne()"
                   [isEnableSignatureMembership]="isEnableSignatureMembership"
                   [shouldDisplayIcon]="shouldDisplayIcon"
                   [shouldDisplayLabel]="shouldDisplayLabel"
                   [shouldDisplayMemberText]="shouldDisplayMemberText"
                   [isMobile]="isMobile">
    </member-status>
  </ng-container>
   <ng-container *ngSwitchCase="'redFlag'">
    <div (mouseenter)="showHelpText()" (mouseleave)="hideHelpText()" (click)="$event.stopPropagation()">
    <img *ngIf="getData()"
         alt="Red Flag Icon"
         class="ngx-data-table__red-flag-icon"
         src="https://www.cfacdn.com/img/Spotlight/Icons/3P_Promotions_RedFlag.png" >
     <div [hidden]="!show"
          fxLayout="column"
          fxLayoutAlign="start start"
          class="ngx-data-table__hover-text">
       <div class="ngx-data-table__block-text">This Customer has requested more than the set threshold for digital refunds.</div>
     </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase= "'ArrayList'">
    <app-read-more [displayString]="getDataArrayToList()" [isMobile] = useFullMobileTable></app-read-more>
  </ng-container>

  <ng-container *ngSwitchCase="'offertype'">
    <offer-type [offerType]="getData()" [displaySnapIn]="getDisplayIcon()"></offer-type>
  </ng-container>

  <ng-container  *ngSwitchCase="'forgot2ScanIcon'">
    <span *ngIf="getDisplayForgot2Scan()" [matTooltip]="f2sToolTipText"
          [matTooltipClass]="'ngx-data-table__f2s-tooltip'" >
      <img alt="Receipt Icon" class="ngx-data-table__f2s-icon" src="../../../../assets/img/F2S_Receipt_Icon.png"/>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'indicateComments'">
    <ng-container *ngIf="!isMobile; else templateMobile">
      <mat-icon *ngIf="trimData()"
                title="Comment Available">
                mode_comment
      </mat-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'subtitleWithLabel'">
    <span *ngIf="isMobile; else templateDefault" class="mobile-data-table__subtitle">
      {{col.title}}: {{getData()}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'visitDate'">
    <span class="mobile-data-table__subtitle">
       {{getData() || 'N/A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'lastVisitDate'">
    <span *ngIf="isMobile" class="mobile-data-table__subtitle">
      {{col.title}} {{getData() || 'N/A'}}
    </span>
    <span *ngIf="!isMobile" class="mobile-data-table__subtitle">
      {{getData() || 'N/A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'birthDate'">
    <span *ngIf="isMobile; else templateDefault" class="mobile-data-table__subtitle">
      {{col.title}} {{getData() || 'N/A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'visitDateTime'">
    <span *ngIf="isMobile" class="mobile-data-table__subtitle">
      {{col.title}}: {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </span>
    <span *ngIf="!isMobile" class="mobile-data-table__subtitle">
      {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'reportReceivedDate'">
    <span *ngIf="isMobile" class="mobile-data-table__subtitle">
      {{getData() | locTimeZoneDate:'ddd, MMM DD YYYY hh:mm A'}}
    </span>
    <span *ngIf="!isMobile" class="mobile-data-table__subtitle">
      {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'recoveredDate'">
    <span *ngIf="isMobile" class="mobile-data-table__subtitle">
      {{col.title}}: {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </span>
    <span *ngIf="!isMobile" class="mobile-data-table__subtitle">
      {{getData() | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'delete'">
    <span *ngIf="!disableDeleteIcon">
      <img alt="" class="ngx-data-table__delete-icon"
           src="{{deleteIconUrl}}"
           (click)="$event.stopPropagation(); deleteClicked()">
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'remove'">
    <mat-icon *ngIf="!getPreventDeletion()"
              class="ngx-data-table__delete-icon"
              (click)="$event.stopPropagation(); deleteClicked()">
      clear
    </mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <button mat-raised-button
            class="ngx-data-table__button"
            [disabled]="shouldDisableButton"
            (click)="$event.stopPropagation(); buttonClicked()">
      {{getText()}}
    </button>
  </ng-container>

   <ng-container *ngSwitchCase="'editAttendees'">
    <button mat-raised-button
            class="ngx-data-table__edit-attendees"
            [disabled]="shouldDisableButton"
            (click)="$event.stopPropagation(); buttonClicked()">
      {{getText()}}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'syncReport'">
    <button mat-raised-button
            class="ngx-data-table__sync-report"
            (click)="$event.stopPropagation(); externalLinkClicked()">
      {{getText()}} {{getConcatData()}}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'memberProfile'">
    <button *ngIf="showMemberProfile()"
            mat-raised-button
            class="ngx-data-table__member-profile"
            (click)="$event.stopPropagation(); buttonClicked()">
      {{getText()}}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'conditionalExternalLink'">
    <a class="ngx-data-table__link"
       *ngIf="getData()"
       (click)="$event.stopPropagation(); externalLinkClicked()">
      {{getText()}}
    </a>
    <div *ngIf="!getData()">N/A</div>
  </ng-container>

  <ng-container *ngSwitchCase="'defaultNA'">
    <span *ngIf="getData()">{{getData()}}</span>
    <span *ngIf="!getData()">N/A</span>
  </ng-container>

  <ng-container *ngSwitchCase="'viewNotes'">
    <span *ngIf="getViewNotesData()"
          class="ngx-data-table__view-notes"
          (click)="$event.stopPropagation(); viewNotesClicked()">
      {{getViewNotesLabel()}}
    </span>
    <span *ngIf="!getViewNotesData()">N/A</span>
  </ng-container>

  <ng-container *ngSwitchCase="'dateOfReport'">
    {{getDate() | locTimeZoneDate:'MMM DD, YYYY hh:mm A':col.showTimezone}}
  </ng-container>

  <ng-container *ngSwitchCase="'refundReasons'">
    <span [innerHtml]="getRefundReasons()"></span>
  </ng-container>

  <ng-container *ngSwitchCase="'orderNumber'">
    <div *ngIf="getData()">
      <div *ngIf="getOrderNumberSelectable()"
           fxLayout="row"
           fxLayoutAlign="center center"
           class="ngx-data-table__order-link"
           (click)="$event.stopPropagation(); orderNumberClicked()">
        {{getData()}}
      </div>
      <div *ngIf="!getOrderNumberSelectable()"
           class="ngx-data-table__order-nonselectable">
        {{getData()}}
      </div>
    </div>
    <div *ngIf="!getData()"></div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="isMobile; then templateMobile; else templateDefault;"></ng-container>
  </ng-container>

  <ng-template #templateMobile>
    <span class="truncated">{{getData() | myTruncate: getTruncateLimit()}}</span>
  </ng-template>

  <ng-template #templateDefault>
    <span *ngIf="!useFullMobileTable">{{getData()}}</span>
    <span *ngIf="useFullMobileTable">{{getShortenedData(7)}}</span>
  </ng-template>
</span>
