<div [cfaLoading]="loading" class="filter-list">
  <error-message *ngIf="error"
                 [error]="error"
                 [page]="'Filters'">
  </error-message>
  <div *ngIf="!error && !loading">
    <div *ngFor="let category of filters; let i = index">
      <div fxLayout="row"
           fxLayoutAlign="space-between center"
           class="filter-list__category-container"
           [ngClass]="{'filter-list__category-separator': i > 0}"
           (click)="categoryToggled(category)">
        <div class="filter-list__category-name">
          {{category.name}} <span *ngIf="category.hasAFilterSelected">*</span>
        </div>
        <mat-icon [@arrowState]="getCategoryState(category)"
                  class="filter-list__arrow">
          chevron_right
        </mat-icon>
      </div>
      <div *ngIf="category.open && !category.savedGroupsCategory" class="filter-list__category-filters-container">
        <ng-container *ngFor="let filter of category.subFilters">
          <div *ngIf="filter.id !== filterOnBetween" class="filter-list__filter-container">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <div fxLayout="row" fxLayoutAlign="start start">
                <div class="filter-list__checkbox" *ngIf="!filter.hideCheckBox">
                  <mat-checkbox [checked]="filter.isSelected" (change)="filterToggled($event.checked, filter)">
                  </mat-checkbox>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="filter-list__name">
                      {{filter.name}}
                      <help-text *ngIf="filter.infoText"
                                 class="filter-list__help-text"
                                 [helpText]="filter.infoText"
                                 [isSmallIcon]="true">
                      </help-text>
                    </div>
                  </div>
                  <div class="filter-list__subtext">{{filter.subtext}}</div>
                  <div *ngIf="filter.autoCompleteEnabled"
                       class="filter-list__autocomplete">
                    <spot-autocomplete [items]="filteredCustomers"
                                       [loading]="autocompleteCustomersLoading"
                                       [placeholder]="customerSearchPlaceholder"
                                       [noResultsText]="noCustomerResultsText"
                                       [isBorderStyling]="true"
                                       (valueChange)="onCustomerSearch($event)"
                                       (selectionMade)="onCustomerSelection($event, filter)">
                    </spot-autocomplete>
                  </div>
                  <button-toggle *ngIf="filter.isSelected && filter.buttonToggles"
                                 class="filter-list__button-toggle"
                                 [buttons]="filter.buttonToggles"
                                 (buttonToggled)="buttonToggled(category.id, filter, $event)">
                  </button-toggle>
                  <div *ngIf="filter.isSelected && filter.buttonChipSections">
                    <button-chip-section *ngFor="let chipSection of filter.buttonChipSections"
                                         [name]="chipSection.name"
                                         [buttonChips]="chipSection.buttonChips"
                                         [isMobile]="mobile"
                                         (buttonChipToggled)="buttonChipToggled($event, filter, chipSection)">
                    </button-chip-section>
                  </div>
                  <div *ngIf="filter.isSelected && filter.subOptions" class="filter-list__sub-options">
                    <filter-sub-option *ngFor="let subOption of filter.subOptions"
                                       [name]="subOption.name"
                                       [image]="subOption.image"
                                       [selected]="subOption.selected"
                                       [subtext]="subOption.subtext"
                                       (subOptionToggled)="subOptionToggled(filter, subOption)">
                    </filter-sub-option>
                  </div>
                  <div *ngIf="filter.isSelected && filter.displaySlider">
                    <double-slider *ngIf="filter.sliderOptions.highValue !== null"
                                   [filter]="filter"
                                   (twoVariableSliderValueUpdated)="twoVariableSliderValueUpdated.emit($event)">
                    </double-slider>
                    <single-slider *ngIf="filter.sliderOptions.highValue === null"
                                    [filter]="filter"
                                    (sliderValueUpdated)="sliderValueUpdated.emit($event)">
                    </single-slider>
                  </div>
                  <date-range-picker *ngIf="filter.isSelected && filter.displayDateSelection"
                                     [isMobile]="mobile"
                                     [startDate]="filtersStartDate"
                                     [endDate]="filtersEndDate"
                                     [pastDatesOnly]="pastDatesOnly"
                                     (datesChanged)="updateDate($event)">
                  </date-range-picker>
                </div>
              </div>
              <div class="filter-list__icon-container" fxLayout="row" fxLayoutAlign="center center">
                <img alt="" *ngIf="filter.image" class="filter-list__icon" [src]="filter.image">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="category.open && category.savedGroupsCategory" class="filter-list__category-filters-container">
        <div *ngIf="savedGroupsLoading" [cfaLoading]="savedGroupsLoading" class="filter-list__loading"></div>
        <error-message *ngIf="savedGroupsError"
                       [error]="savedGroupsError"
                       [page]="'Filters Saved Groups'">
        </error-message>
        <div *ngIf="!savedGroupsLoading && !savedGroupsError">
          <div *ngIf="savedGroups.length === 0">
            <div class="filter-list__no-results-text">
              No results were found. Looks like you have no saved groups.
            </div>
          </div>
          <div *ngIf="savedGroups.length > 0">
            <div *ngFor="let savedGroup of savedGroups" class="filter-list__saved-group-container" fxLayout="row" fxLayoutAlign="start start">
              <div class="filter-list__checkbox" fxFlex="10">
                <mat-checkbox [checked]="savedGroup.isSelected"
                              [disabled]="savedGroupEditActive"
                              (change)="savedGroupToggled($event.checked, savedGroup)">
                </mat-checkbox>
              </div>
              <div fxLayout="column" fxFlex="90">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div class="filter-list__name" fxFlex="80"
                       [ngClass]="{'filter-list__name-disabled': savedGroupEditActive}">
                    {{savedGroup.name}}
                  </div>
                  <div *ngIf="(page === meetPage) && displaySavedGroupActions && !savedGroupEditActive && !savedGroupEditDisabled"
                       fxFlex="10">
                    <img class="filter-list__edit-icon"
                         alt=""
                         src="{{editIconUrl}}"
                         (click)="savedGroupEdit(savedGroup)">
                  </div>
                  <div fxFlex="10">
                    <img *ngIf="(page === meetPage) && !savedGroupEditActive && !savedGroupEditDisabled"
                         alt=""
                         class="filter-list__delete-icon"
                         src="{{deleteIconUrl}}"
                         (click)="savedGroupDelete(savedGroup)">
                  </div>
                </div>
                <div class="filter-list__subtext">
                  {{savedGroup.customerCount}} customers as of last update on {{savedGroup.updateAt | locTimeZoneDate:'M/D/YYYY'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="mobile && page === meetPage">
      <div *ngIf="!savedGroupEditActive" class="filter-list__button-container">
        <button *ngIf="!displayCustomerLoading"
                mat-raised-button
                class="filter-list__button"
                (click)="goBack.emit()">
          <span *ngIf="totalCustomers === 1">Show {{totalCustomers}} Customer</span>
          <span *ngIf="totalCustomers !== 1">Show {{totalCustomers}} Customers</span>
        </button>
        <button *ngIf="displayCustomerLoading" mat-raised-button
                class="filter-list__button">
          <mat-spinner strokeWidth="3" [diameter]="25"></mat-spinner> Loading...
        </button>
      </div>
      <div *ngIf="savedGroupEditActive" class="filter-list__button-container">
        <button mat-raised-button
                class="filter-list__button"
                [disabled]="(selectedFilters.length === 0) && (includedCustomers.length === 0) && (excludedCustomers.length === 0)"
                (click)="updateSavedGroup.emit()">
          Update {{savedGroupEditName}}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="mobile && page === engagePage">
      <div class="filter-list__button-container">
        <button mat-raised-button
                class="filter-list__select-all-button"
                (click)="selectAllCustomers.emit()">
          Select All Spotlight
        </button>
        <button *ngIf="!displayCustomerLoading"
                mat-raised-button
                class="filter-list__apply-button"
                [disabled]="isApplyDisabled()"
                (click)="apply.emit()">
          Apply
          <span *ngIf="!isApplyDisabled()">
            ({{selectedEngageCustomers | number:'1.0':'en-US'}})
          </span>
        </button>
        <button *ngIf="displayCustomerLoading"
                mat-raised-button
                class="filter-list__apply-button">
          <mat-spinner strokeWidth="3" [diameter]="25"></mat-spinner> Loading...
        </button>
      </div>
    </ng-container>
  </div>
</div>
