import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DayRangePickerConstants } from './day-range-picker.constants';
import { find, findIndex, propEq, remove } from 'ramda';

@Component({
  selector: 'day-range-picker',
  templateUrl: './day-range-picker.component.html',
  styleUrls: ['./day-range-picker.component.scss'],
})
export class DayRangePickerComponent implements OnInit {
  @Input() public exclude365Days: boolean = false;
  @Input() public defaultSelectedRange: number;

  @Output() public updateDate = new EventEmitter<number>();
  public ranges = DayRangePickerConstants.dayRange;
  public rangeStrings = DayRangePickerConstants.dayRangeStrings;
  public defaultRangeString = '';

  public ngOnInit() {
    if (this.exclude365Days) {
      let indexOf365Days = findIndex(propEq('value', 365))(DayRangePickerConstants.dayRange);
      this.ranges = remove(indexOf365Days, 1, DayRangePickerConstants.dayRange);
    }
    this.defaultRangeString
      = find(propEq('value', this.defaultSelectedRange))(DayRangePickerConstants.dayRange).display;
  }

  public dayRangeChanged(selectedRange) {
    this.updateDate.emit(
      find(propEq('display', selectedRange))(DayRangePickerConstants.dayRange).value);
  }
}
