import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GoToEngagePage, ViewOnCalendar } from 'spotlight-ui/app/actions/engage';
import { AnalyticsService } from 'spotlight-ui/app/core/analytics';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedConstants } from '../shared.constants';
import { GoToDiscoverPage, SendThirdPartyPromotionSuccess } from 'spotlight-ui/app/actions/engagement';
import { DiscoverConstants } from "../../discover/discover.constants";
import * as engagement from "../../actions/engagement";
import {Router} from "@angular/router";
import {EngageConstants} from "../../engage/engage.constants";
@Component({
  selector: 'send-confirmation-dialog',
  templateUrl: './send-confirmation-dialog.component.html',
  styleUrls: ['./send-confirmation-dialog.component.scss']
})
export class SendConfirmationDialogComponent {
  public type$: Observable<string>;
  public sendError$: Observable<any>;
  public isHoldouts$: Observable<boolean>;
  public areAllDuplicates$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public sendLoading$: Observable<boolean>;
  public sendConfirmationAction$: Observable<any>;
  public failedPlatforms$: Observable<string>;
  public successfulPlatforms$: Observable<string>;
  public thirdPartyPromoCreatedId$: Observable<string>;

  public discoverPage: string = SharedConstants.discoverPage;
  public engagePage: string = SharedConstants.engagePage;
  public thirdPartyPromotionType: string = DiscoverConstants.thirdPartyPromotion;
  public editMode = EngageConstants.editMode
  public page: string = '';
  public mode$: Observable<string>;

  constructor(private analytics: AnalyticsService,
              private store: Store<fromRoot.State>,
              private router: Router,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<SendConfirmationDialogComponent>
  ) {
    this.page = dialogData.page;
    this.isMobile$ = store.select(fromRoot.getIsMobile);

    if (this.page === SharedConstants.engagePage) {
      this.type$ = store.select(fromRoot.getEngageType);
      this.sendLoading$ = store.select(fromRoot.getEngageSendLoading);
      this.sendError$ = store.select(fromRoot.getEngageSendError);
      this.sendConfirmationAction$ = store.select(fromRoot.getSendConfirmationAction);
      this.areAllDuplicates$ = store.select(fromRoot.getAreAllDuplicates);
      this.isHoldouts$ = store.select(fromRoot.getIsHoldoutRecipients);
      this.mode$ = store.select(fromRoot.getEngageMode)
    } else if (this.page === SharedConstants.discoverPage) {
      this.sendLoading$ = store.select(fromRoot.getEngagementSendLoading);
      this.sendError$ = store.select(fromRoot.getEngagementSendError);
      this.sendConfirmationAction$ = store.select(fromRoot.getEngagementSendAction);
      this.type$ = store.select(fromRoot.getEngagementType);
      this.failedPlatforms$ = store.select(fromRoot.getEngagementFailedPlatforms)
      this.successfulPlatforms$ = store.select(fromRoot.getEngagementSuccessfulPlatforms)
      this.thirdPartyPromoCreatedId$ = store.select(fromRoot.getEngagementThirdPartyPromoCreatedId)
    }
  }

  public backToEngage() {
    this.type$.pipe(take(1)).subscribe(type => {
      this.store.dispatch(new GoToEngagePage());
      this.analytics.sendMParticleEvent(
        'Navigated to Engage After Successful send',
        {
          'Type': type
        }
      );
    });
  }

  public goToCalendar() {
    this.type$.pipe(take(1)).subscribe((type) => {
      if(type === this.thirdPartyPromotionType){
        this.thirdPartyPromoCreatedId$.pipe(take(1)).subscribe((id) =>{
          let queryParams = { type, mode: 'review', id };
          this.store.dispatch(new ViewOnCalendar(queryParams));
        })
      }else {
        this.store.select(fromRoot.getEngagementId).pipe(take(1)).subscribe((id) => {
          let queryParams = { type, mode: 'review', id };
          this.store.dispatch(new ViewOnCalendar(queryParams));
          this.analytics.sendMParticleEvent(
            'Navigated to Engage Details Page After Send',
            {
              'Type': type,
              'Mode': 'review',
              'Id': id
            }
          );
        });
      }
    });
  }

  public backToDiscover() {
    this.store.dispatch(new GoToDiscoverPage());
    this.analytics.sendMParticleEvent('Navigated to Discover After Successful send', {});
  }

  public backToFullCalendar() {
    this.dialogRef.close();
    this.router.navigate(['/calendar'])
  }

  createThirdPartyPromotion() {
    this.thirdPartyPromoCreatedId$.pipe(take(1)).subscribe(id => {
      this.store.dispatch(new SendThirdPartyPromotionSuccess({
        fieldId: id,
        automated: true,
        id: id,
        engagementType: DiscoverConstants.thirdPartyPromotion
      }))
    })

  }

  cancelThirdPartyPromotion() {
    this.thirdPartyPromoCreatedId$.pipe(take(1)).subscribe((thirdPartyPromoCreatedId) => {
      let tile = {
        id: DiscoverConstants.thirdPartyPromotion,
        tileObjectId: thirdPartyPromoCreatedId,
      }
      this.store.dispatch(new engagement.OpenCancellationDialog({
        tile,
        engagementType: DiscoverConstants.thirdPartyPromotion,
        engagementMode: DiscoverConstants.edit
      }));
    });
    this.dialogRef.close();
  }
}
