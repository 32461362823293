import * as eula from '../actions/eula';
import * as user from '../actions/user';
import { DiscoverConstants } from '../discover/discover.constants';

export interface State {
  agree: boolean;
  displayWelcomeModal: boolean;
  error: any;
  html: string;
  loading: boolean;
}

export const initialState: State = {
  agree: false,
  displayWelcomeModal: false,
  error: null,
  html: '',
  loading: true
};

export function reducer(state = initialState, action: eula.Actions| user.Actions): State {
  switch (action.type) {
    case user.ActionTypes.DISMISS_BANNER_SUCCESS: {
      let banner = action.payload;
      if (banner === DiscoverConstants.welcomeModalBanner) {
        return { ...state, displayWelcomeModal: false }
      }
      return state;
    }

    case eula.ActionTypes.EULA_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case eula.ActionTypes.EULA_FOUND: {
      let { eulaHtml, eulaAccept, displayWelcomeModal } = action.payload;
      return {
        ...state,
        agree: eulaAccept,
        displayWelcomeModal: displayWelcomeModal,
        html: eulaHtml,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getAgree = (state: State) => state.agree;

export const getDisplayWelcomeModal = (state: State) => state.displayWelcomeModal;

export const getError = (state: State) => state.error;

export const getHtml = (state: State) => state.html;

export const getLoading = (state: State) => state.loading;
