<div class="activity-history" fxLayout="column" fxLayoutAlign="center center">
  <error-message *ngIf="error"
                 [error]="error"
                 [page]="'Activity History'">
  </error-message>

  <div *ngIf="!error && !isMobile" class="activity-history__body">
    <data-table [totalElements]="allRecords.length"
                [frontEndPaging]="true"
                [columns]="columns"
                [rows]="allRecords"
                [isLoading]="loading"
                [noResultsMessage]="noResultsText"
                [numberOfPages]="getNumberOfPages(allRecords.length)"
                [pageSize]="pageSize"
                [noteProp]="noteProp"
                (onExternalLinkClick)="viewEmailDetails.emit($event)">
    </data-table>
  </div>

  <div class="activity-history__data-container" *ngIf="isMobile">
    <div *ngIf="loading"
         class="activity-history__loading"
         [cfaLoading]="true">
    </div>
    <div *ngIf="!loading">
      <div *ngIf="displayRecords.length === 0">{{noResultsText}}</div>
      <div *ngFor="let action of displayRecords; let i = index"
           class="activity-history__response-container"
           [ngClass]="{'activity-history__blue-row-background' : (i % 2  !== 0), 'activity-history__white-row-background' : (i % 2  === 0)}">
        <div [innerHTML]="action[statusVariable]" class="activity-history__status-text"></div>
        <div fxLayout="row" class="activity-history__row-container">
          <span fxFlex="40" class="activity-history__label">{{dateLabel}}:</span>
          <span fxFlex="60" class="activity-history__info">{{action.updatedAt | locTimeZoneDate:'ddd, MMM DD YYYY hh:mm A':true}}</span>
        </div>

        <div fxLayout="row" class="activity-history__row-container">
          <span fxFlex="40" class="activity-history__label">User:</span>
          <span *ngIf="action.updatedBy" fxFlex="60" class="activity-history__info">{{action.updatedBy}}</span>
          <span *ngIf="!action.updatedBy" fxFlex="60" class="activity-history__info">N/A</span>
        </div>

        <div fxLayout="row" class="activity-history__row-container">
          <span fxFlex="40" class="activity-history__label">Communication Sent:</span>
          <a *ngIf="action.hasEmailRecord"
             fxFlex="60"
             (click)="viewEmailDetails.emit(action)"
             class="activity-history__info activity-history__info-red">
            View Email
          </a>
          <span fxFlex="60" *ngIf="!action.hasEmailRecord" class="activity-history__info">N/A</span>
        </div>

        <div fxLayout="row" class="activity-history__row-container">
          <span fxFlex="40" class="activity-history__label">Notes:</span>
          <a *ngIf="action[noteProp] && action[noteProp].length > 0"
             fxFlex="60"
             (click)="action.isNoteOpen = !action.isNoteOpen"
             class="activity-history__info activity-history__info-red">
            {{getViewNotesLabel(action)}}
          </a>
          <span fxFlex="60" *ngIf="!action[noteProp] || action[noteProp].length == 0" class="activity-history__info">N/A</span>
        </div>

        <div *ngIf="action.isNoteOpen" fxLayout="row" class="activity-history__row-container">
          <div fxLayout="row"
               fxFlex="100"
               *ngFor="let note of action[noteProp]">
            <div class="activity-history__note">
              <div>
                <span class="activity-history__note-name">{{note.updatedBy}}</span>
                <span class="activity-history__note-date"> - {{note.updatedAt | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}</span>
              </div>
              <div class="activity-history__note-text">{{note.note}}</div>
            </div>
          </div>
        </div>
      </div>

      <pagination *ngIf="allRecords.length"
                  [totalElements]="allRecords.length"
                  [pageSize]="pageSize"
                  [currentPage]="currentPage"
                  (pageRight)="pageRight()"
                  (pageLeft)="pageLeft()">
      </pagination>
    </div>
  </div>
</div>
