import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'spot-nav',
  templateUrl: './simple-nav.component.html',
  styleUrls: ['./simple-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleNavComponent {
  @Input() public title: string;
  @Input() public actionMenu: boolean = false;
  @Input() public actionMenuLoading: boolean = false;
  @Input() public info: any;
  @Input() public loading: boolean;
  @Input() public primaryActionsIds: any;
  @Input() public customerToEngage: string[] = [];
  @Input() public isFavoriteLocation: boolean = false;
  @Input() public isSmallNav: boolean = false;
  @Input() public disableActionMenu: boolean;
  @Input() public isMobile: boolean = false;
  @Input() public isBackChevronDT: boolean = false;
  @Input() public isCloseIcon: boolean = false;
  @Input() public hideBackArrow: boolean = false;
  @Input() public unsavedChangesTitle: string;
  @Input() public unsavedChangesText: string;
  @Input() public unsavedChangesIcon: string;
  @Input() public unsavedChangesCancel: string;
  @Input() public unsavedChangesConfirm: string;
  @Input() public shouldDisplayUnsavedChangesDialog: boolean = false;
  @Input() public analyticsPage: string;

  @Output() public back = new EventEmitter();
}
