import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-chip-section',
  templateUrl: './button-chip-section.component.html',
  styleUrls: ['./button-chip-section.component.scss']
})
export class ButtonChipSectionComponent {
  @Input() public name: string;
  @Input() public buttonChips: any[] = [];
  @Input() isMobile;

  @Output() public buttonChipToggled = new EventEmitter<any>();
}
