<mat-dialog-content>
  <div *ngIf="(modifySavedGroupLoading$ | async)"
       class="save-group-name__loading"
       [cfaLoading]="true">
  </div>
  <div *ngIf="!(modifySavedGroupLoading$ | async)" fxLayout="column">
    <div class="save-group-name__title">
        What would you like to name this group?
    </div>

    <div class="save-group-name__search">
      <input matInput
             class="save-group-name__name-text"
             maxlength="50"
             [(ngModel)]="groupName">
      <mat-icon *ngIf="groupName.trim().length > 0"
                matSuffix
                class="save-group-name__clear-icon"
                (click)="clearGroupName()">
        highlight_off
      </mat-icon>
    </div>
    <mat-error id ="save-group-name__name_exists_error" *ngIf="savedGroupNameAlreadyExists(otherGroupNames$|async, meetSavedGroupBeingEdited$ | async)"
      style="margin-top: 10px;">
      Saved group name already exists, please choose another.
    </mat-error>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="save-group-name__button-wrapper">
      <button mat-button class="save-group-name__back-button" (click)="dialogRef.close()">
        Go Back
      </button>
      <button mat-button
              id ="save-group-name__create-group-button"
              *ngIf="!(meetSavedGroupBeingEdited$ | async)"
              [ngClass]="groupName.trim().length < 1 || savedGroupNameAlreadyExists(otherGroupNames$ | async) ? 'save-group-name__create-button-disabled' : 'save-group-name__create-button'"
              [disabled]="groupName.trim().length < 1"
              (click)="saveGroup()">
        Create Group
      </button>
      <button mat-button
              id ="save-group-name__rename-group-button"
              *ngIf="meetSavedGroupBeingEdited$ | async"
              [ngClass]="(groupName.trim().length < 1) || (groupName === (meetSavedGroupBeingEdited$ | async).name) || savedGroupNameAlreadyExists(otherGroupNames$ | async)
                ? 'save-group-name__create-button-disabled' : 'save-group-name__create-button'"
              [disabled]="(groupName.trim().length < 1) || (groupName === (meetSavedGroupBeingEdited$ | async).name) || savedGroupNameAlreadyExists(otherGroupNames$ | async)"
              (click)="updateExistingGroup()">
        Save Group Name
      </button>
    </div>
  </div>
</mat-dialog-content>
