export class SettingsConstants {
  public static get confirmationTitle(): string {
    return '<b>Discard changes?</b>';
  }

  public static get confirmationText(): string {
    return 'There are unsaved changes on this page. To save your' +
      ' work click the "Cancel" button to return to the settings page.';
  }

  public static get confirmationButtonText(): string {
    return 'Discard Changes';
  }

  public static get eventUpdateSuccess(): string {
    return 'Event settings successfully updated';
  }

  public static automationCancelSuccess(tileSummary): string {
    return `${tileSummary} Reward Automation Campaign stopped successfully`;
  }

  public static get defaultLocationUpdateSuccess(): string {
    return 'Default location successfully updated';
  }

  public static get rsvpError(): string {
    return 'Error saving event RSVP options';
  }

  public static get disableSendingFlag(): string {
    return 'disableAllSending';
  }
}
