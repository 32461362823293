import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss']
})
export class CollapsibleCardComponent {
    @Input() public title: string = '';
    @Input() public open: boolean = true;
    @Input() public loading: boolean;
    @Input() public error: string;
    @Input() public icon: any;
    @Input() public subtitle: string;
    @Input() public preTitle: string;
    @Input() public displayDelete: boolean = false;
    @Input() public postTitle: string = '';
    @Input() public isRestyled: boolean = false;
    @Input() public isMobile: boolean = false;

    @Output() public changeOpen = new EventEmitter<any>();
    @Output() public deleteClicked = new EventEmitter<any>();

    public toggleOpen() {
      this.open = !this.open;
      this.changeOpen.emit(this.open);
    }

    public deleteAction() {
      this.deleteClicked.emit();
    }
}
