<div class="cancellation-dialog">
  <div *ngIf="loading$ | async"
       class="cancellation-dialog__loading"
       [cfaLoading]="true">
  </div>
  <ng-container *ngIf="error$ | async">
    <error-message [error]="error$ | async" [page]="'Automation Cancellation Dialog'">
    </error-message>
    <div mat-dialog-actions class="cancellation-dialog__wrapper">
      <button mat-button class= "cancellation-dialog__cancel-button" [disabled]="loading$ | async" (click)="closeDialog()">Close</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!(error$ | async) && !(loading$ | async)">
    <div *ngIf="title" class="cancellation-dialog__title" [innerHTML]=title></div>
    <div class="cancellation-dialog__text" [innerHTML]=displayText></div>
    <div mat-dialog-actions class="cancellation-dialog__wrapper">
      <button mat-button class ="cancellation-dialog__cancel-button" *ngIf="id !== thirdPartyPromotionId" (click)="closeDialog()">Cancel</button>
      <button mat-button class ="cancellation-dialog__cancel-button" *ngIf="id === thirdPartyPromotionId" (click)="closeDialogAndReturnToDiscover()">Cancel</button>

      <button mat-button *ngIf="id !== communityCareEventId && id !== thirdPartyPromotionId && id !== freeItemInCartId"
              class="cancellation-dialog__send-button" (click)="cancelAutomation()">
        Stop Automation
      </button>
      <button mat-button *ngIf="id === communityCareEventId" class="cancellation-dialog__send-button"
              (click)="cancelCommunityCareEvent()">
        Stop Event
      </button>
      <button mat-button *ngIf="id === thirdPartyPromotionId" class="cancellation-dialog__send-button"
              (click)="cancelThirdPartyPromotion()">
        Stop Promotion
      </button>
      <button mat-button *ngIf="id === freeItemInCartId" class="cancellation-dialog__send-button"
              (click)="cancelFreeInCart()">
        Stop Promotion
      </button>
    </div>
  </ng-container>
</div>
