import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.scss']
})
export class RewardCardComponent implements OnInit {
  @Input() public name: string = '';
  @Input() public imageUrl: string = '';
  @Input() public displayIncrementer: string = '';
  @Input() public numberSelected: number;
  @Input() public disableIncrement: boolean;
  @Input() public isCardListStyle: boolean;
  @Input() public endDateRestriction: string;

  @Output() public rewardSelected = new EventEmitter<any>();
  @Output() public incremented = new EventEmitter<any>();
  @Output() public decremented = new EventEmitter<any>();

  public fontSize: string;

  public ngOnInit() {
    this.fontSize = '90%'
    //change size based off length
    if (this.name && ( this.endDateRestriction && this.name.length > (25*3))) {
      let linePercent= 90 - ((this.name.length/25)-3)*15;
      this.fontSize = linePercent + '%';
    } else if (this.name && (!this.endDateRestriction && this.name.length > (25*5))) {
      let linePercent= 90 - ((this.name.length/25)-5)*15;
      this.fontSize = linePercent + '%';
    }
  }

  public cardClicked() {
    if (this.displayIncrementer) {
      if (!this.disableIncrement) {
        this.incremented.emit();
      }
    } else {
      this.rewardSelected.emit();
    }
  }
}
