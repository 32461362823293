import * as calendar from '../actions/calendar';
import * as user from '../actions/user';
import * as engage from '../actions/engage';
import * as engagement from '../actions/engagement';
import { CalendarEventDetails } from '../models/calendar/calendar-event-details.model';
import { CalendarConstants } from '../calendar/calendar.constants';
import { equals } from 'ramda';
import { DiscoverConstants } from '../discover/discover.constants';
import * as moment from 'moment-timezone';

export interface State {
  calendarEventDetails: CalendarEventDetails;
  calendarEvents: any[];
  campaignToggleActive: boolean;
  communityCareToggleActive: boolean;
  currentDate: any;
  currentView: string;
  earliestShownDate?: any;
  emailToggleActive: boolean;
  error?: any;
  eventToggleActive: boolean;
  freeItemInCartPromotionActive: boolean;
  incentivizeOrderToggleActive: boolean;
  latestShownDate?: any;
  loading: boolean;
  nationalEmailToggleActive: boolean;
  nationalThirdPartyPromotionToggleActive: boolean;
  previewHtml: string;
  rewardAutomationToggleActive: boolean;
  rewardOneRecipientToggleActive: boolean;
  rewardToggleActive: boolean;
  searchObj: any;
  thirdPartyPromotionToggleActive: boolean;
}

export const initialState: State = {
  calendarEventDetails: null,
  calendarEvents: [],
  campaignToggleActive: false,
  communityCareToggleActive: true,
  currentDate: moment(),
  currentView: 'Month',
  emailToggleActive: true,
  eventToggleActive: true,
  freeItemInCartPromotionActive: true,
  incentivizeOrderToggleActive: true,
  loading: true,
  nationalEmailToggleActive: true,
  nationalThirdPartyPromotionToggleActive: true,
  previewHtml: '',
  rewardAutomationToggleActive: true,
  rewardOneRecipientToggleActive: false,
  rewardToggleActive: true,
  searchObj: {
    searchTerm: '',
    searchBy: CalendarConstants.searchByCategories[0].value
  },
  thirdPartyPromotionToggleActive: true
};

export function reducer(state = initialState, action: calendar.Actions | user.Actions | engage.Actions | engagement.Actions): State {
  switch (action.type) {
    case calendar.ActionTypes.CALENDAR_CLEAR_SEARCH: {
      let searchTerm = action.payload;
      const searchObj = {
        searchTerm,
        searchBy: state.searchObj.searchBy
      };
      return {
        ...state,
        searchObj,
        loading: true
      };
    }

    case calendar.ActionTypes.CALENDAR_DATES_CHANGED: {
      let { oldValue, newValue, view, currDate } = action.payload;
      let currentDate = equals(newValue, oldValue) ? state.currentDate : currDate;
      let currentView = equals(newValue, oldValue) ? state.currentView : view;
      let earliestShownDate = equals(newValue, oldValue) && state.earliestShownDate
        ? state.earliestShownDate : newValue.startDate;
      let latestShownDate = equals(newValue, oldValue) && state.latestShownDate
        ? state.latestShownDate : newValue.endDate;

      return {
        ...state,
        earliestShownDate,
        latestShownDate,
        currentView,
        currentDate,
        loading: true
      };
    }

    case calendar.ActionTypes.CALENDAR_SEARCH_CRITERIA_CHANGED: {
      const searchObj = {
        searchTerm: '',
        searchBy: action.payload
      };
      return {
        ...state,
        searchObj,
        loading: true
      };
    }

    case calendar.ActionTypes.CALENDAR_SEARCH_TERM_CHANGED: {
      const searchObj = {
        searchTerm: action.payload,
        searchBy: state.searchObj.searchBy
      };
      return {
        ...state,
        searchObj,
        loading: true
      };
    }

    case calendar.ActionTypes.CALENDAR_SWITCH_LOCATIONS: {
      const searchObj = {
        searchTerm: null,
        searchBy: state.searchObj.searchBy
      };
      return {
        ...state,
        searchObj
      };
    }

    case calendar.ActionTypes.CALENDAR_TOGGLED: {
      let calendar = action.payload;
      let rewardToggleActive = state.rewardToggleActive;
      let rewardAutomationToggleActive = state.rewardAutomationToggleActive;
      let rewardOneRecipientToggleActive = state.rewardOneRecipientToggleActive;
      let eventToggleActive = state.eventToggleActive;
      let emailToggleActive = state.emailToggleActive;
      let nationalEmailToggleActive = state.nationalEmailToggleActive;
      let incentivizeOrderToggleActive = state.incentivizeOrderToggleActive;
      let communityCareToggleActive = state.communityCareToggleActive;
      let thirdPartyPromotionToggleActive = state.thirdPartyPromotionToggleActive;
      let nationalThirdPartyPromotionToggleActive = state.nationalThirdPartyPromotionToggleActive;
      let freeItemInCartPromotionActive = state.freeItemInCartPromotionActive;
      let loading = state.loading;

      if (calendar.calendarId === CalendarConstants.offer) {
        rewardToggleActive = calendar.active;
        calendar.subToggles.map(subToggle => {
          if (subToggle.categoryId === CalendarConstants.automationId) {
            rewardAutomationToggleActive = subToggle.active;
          } else if (subToggle.categoryId === CalendarConstants.singleRecipientId) {
            rewardOneRecipientToggleActive = subToggle.active;
          }
        });
      } else if (calendar.calendarId === CalendarConstants.event) {
        eventToggleActive = calendar.active;
      } else if (calendar.calendarId === CalendarConstants.email) {
        emailToggleActive = calendar.active;
      } else if (calendar.calendarId === CalendarConstants.nationalEmail) {
        nationalEmailToggleActive = calendar.active;
      } else if (calendar.calendarId === CalendarConstants.bonusPoint) {
        incentivizeOrderToggleActive = calendar.active;
      } else if (calendar.calendarId === CalendarConstants.communityCareEvent) {
        communityCareToggleActive = calendar.active;
      } else if (calendar.calendarId === CalendarConstants.thirdPartyPromotion) {
        thirdPartyPromotionToggleActive = calendar.active;
      } else if(calendar.calendarId === CalendarConstants.nationalThirdPartyPromotion){
        nationalThirdPartyPromotionToggleActive = calendar.active;
      } else if(calendar.calendarId === DiscoverConstants.freeItemInCartPromotion) {
        freeItemInCartPromotionActive = calendar.active;
      }

      if (calendar.subCategoryToggled) {
        loading = true;
      }

      return {
        ...state,
        loading,
        rewardToggleActive,
        rewardAutomationToggleActive,
        rewardOneRecipientToggleActive,
        eventToggleActive,
        emailToggleActive,
        nationalEmailToggleActive,
        incentivizeOrderToggleActive,
        communityCareToggleActive,
        thirdPartyPromotionToggleActive,
        nationalThirdPartyPromotionToggleActive,
        freeItemInCartPromotionActive
      };
    }

    case calendar.ActionTypes.CAMPAIGNS_TOGGLED: {
      return { ...state, campaignToggleActive: !state.campaignToggleActive };
    }

    case calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS:
    case calendar.ActionTypes.FETCH_REWARD_DETAILS: {
      return { ...state, loading: true };
    }

    case calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_SUCCESS:
    case calendar.ActionTypes.FETCH_REWARD_DETAILS_SUCCESS: {
      const calendarEventDetails = action.payload;
      return { ...state, calendarEventDetails, loading: false };
    }

    case calendar.ActionTypes.FETCH_CALENDAR_EVENTS: {
      return { ...state, loading: true };
    }

    case calendar.ActionTypes.FETCH_CALENDAR_EVENTS_FAILURE: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    case calendar.ActionTypes.FETCH_CALENDAR_EVENTS_SUCCESS: {
      let payload = action.payload;
      let calendarEvents = []
      payload.forEach(eventsByType => {
        calendarEvents = calendarEvents.concat(eventsByType);
      })
      return { ...state, calendarEvents, loading: false };
    }

    case engage.ActionTypes.CANCEL_EMAIL_SUCCESS:
    case engage.ActionTypes.CANCEL_EVENT_SUCCESS:
    case engage.ActionTypes.CANCEL_REWARD_SUCCESS:
    case engage.ActionTypes.EDIT_EMAIL_SUCCESS:
    case engage.ActionTypes.EDIT_EVENT_SUCCESS:
    case engage.ActionTypes.SEND_EMAIL_SUCCESS:
    case engage.ActionTypes.SEND_EVENT_SUCCESS:
    case engage.ActionTypes.SEND_REWARD_SUCCESS:
    case engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS:
    case engagement.ActionTypes.SEND_COMMUNITYEVENT_SUCCESS:
    case engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS: {
      return { ...state, calendarEvents: [] };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      return { ...state, loading: true };
    }

    default: {
      return state;
    }
  }
}

export const getCalendarEventDetails = (state: State) => state.calendarEventDetails;

export const getCalendarEvents = (state: State) => state.calendarEvents;

export const getCampaignToggleActive = (state: State) => state.campaignToggleActive;

export const getCommunityCareToggleActive = (state: State) => state.communityCareToggleActive;

export const getCurrentDate = (state: State) => state.currentDate;

export const getCurrentView = (state: State) => state.currentView;

export const getEarliestShownDate = (state: State) => state.earliestShownDate;

export const getEmailToggleActive = (state: State) => state.emailToggleActive;

export const getError = (state: State) => state.error;

export const getEventToggleActive = (state: State) => state.eventToggleActive;

export const getFreeItemInCartPromotionToggleActive = (state: State) => state.freeItemInCartPromotionActive;

export const getIncentivizeOrderToggleActive = (state: State) => state.incentivizeOrderToggleActive;

export const getLatestShownDate = (state: State) => state.latestShownDate;

export const getLoading = (state: State) => state.loading;

export const getNationalEmailToggleActive = (state: State) => state.nationalEmailToggleActive;

export const getNationalThirdPartyPromotionToggleActive = (state: State) => state.nationalThirdPartyPromotionToggleActive;

export const getPreviewHtml = (state: State) => state.previewHtml;

export const getRewardAutomationToggleActive = (state: State) => state.rewardAutomationToggleActive;

export const getRewardOneRecipientToggleActive = (state: State) => state.rewardOneRecipientToggleActive;

export const getRewardToggleActive = (state: State) => state.rewardToggleActive;

export const getSearchObj = (state: State) => state.searchObj;

export const getThirdPartyPromotionToggleActive = (state: State) => state.thirdPartyPromotionToggleActive;
