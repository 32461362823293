import { Component, Input } from '@angular/core';
import { isEmpty } from 'ramda';
import { SharedConstants } from '../../../shared.constants';

@Component({
  selector: 'horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})

export class HorizontalBarChartComponent {
  @Input()
  set chartData(data: any[]) {
    this._chartData = data;

    // Don't show chart if data is an empty array
    this.showChart = !isEmpty(data);
  }

  get chartData() {
    return this._chartData;
  }

  @Input() public category: string;
  @Input() public title: string;
  @Input() public listTitleProp: string;
  @Input() public listValueProp: string;
  @Input() public listValueFormat: string;
  @Input() public isMobile: boolean = false;

  public _chartData;
  public showChart;
  public noDataText = SharedConstants.noDataText;

  public getData(row, prop) {
    return row[prop] || 0;
  }

  public getBarPercentage(row, prop) {
    const maxVal = this.getData(this.chartData[0], prop);
    const currentVal = this.getData(row, prop);
    const width = (currentVal / maxVal) * 100;
    return `${width}%`;
  }
}
