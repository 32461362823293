export class CalendarConstants {
  public static get discover(): string {
    return 'discoverTreat';
  }

  public static get offer(): string {
    return 'offer';
  }

  public static get email(): string {
    return 'email';
  }

  public static get event(): string {
    return 'event';
  }

  public static get nationalEmail(): string {
    return 'nationalEmail';
  }

  public static get bonusPoint(): string {
    return 'bonus-point';
  }

  public static get communityCare(): string {
    return 'communityCareEvent';
  }

  public static get communityCareEvent(): string {
    return 'communityCareEvent';
  }

  public static get thirdPartyPromotion(): string {
    return 'third-party-promotion';
  }

  public static get nationalThirdPartyPromotion(): string {
    return 'nationalThirdPartyPromotion';
  }

  public static get freeItemInCartPromotion(): string {
    return 'free-item-in-cart-promotion';
  }

  public static get calendarNote(): string {
    return 'Note: Spotlight Recover and CEM reward messages are not displayed on Calendar.' +
      ' Please see a member’s individual profile or Understand tiles to view these rewards.';
  }

  public static get searchByCategories(): any {
    return [{
      'defaultCategory': true,
      'title': 'Customer Name',
      'value': 'customerName',
      'isAutocomplete': true,
      'exampleText': 'Disabled Customers are no longer in your Restaurant Spotlight'
    },
    {
      'title': 'Template Title',
      'value': 'templateTitle'
    },
    {
      'title': 'Internal Campaign Name',
      'value': 'campaignName'
    },
    {
      'title': 'Subject Line',
      'value': 'emailSubject'
    }]
  }

  public static get automationId(): string {
    return 'automation';
  }

  public static get singleRecipientId(): string {
    return 'singleRecipient';
  }

  public static get rewardSubToggles(): any[] {
    return [{
      categoryId: this.automationId,
      name: 'Automated Campaigns'
    }, {
      categoryId: this.singleRecipientId,
      name: 'Single-Recipient Campaigns'
    }];
  }

  public static get noCustomerSearchResultsText(): string {
    return 'No customers found';
  }

  public static get searchPlaceHolderForDesktop(): string {
    return 'Search Calendar';
  }

  public static get bonusPointPromotionsFlag(): string {
    return 'bonusPointPromotions';
  }
}
