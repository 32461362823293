<div [fxLayout]="'row wrap'"
     class="dropdown-search"
     [ngClass]="{'dropdown-search__error': displayErrorText && errorText && !isMobile}"
     fxLayoutAlign="start start">
  <div *ngIf="categories && displayAdvancedSearchBox"
       [ngClass]="{'dropdown-search__mobile': isMobile}"
       id="dropdown">
    <div class="dropdown-search__base-container"
         [ngClass]="{'dropdown-search__base-container-desktop': !isMobile,
                     'dropdown-search__base-container-mobile': isMobile,
                     'dropdown-search__mobile-error-base': displayErrorText && errorText && isMobile}"
         (click)="toggleDropdown()">
      <div class="dropdown-search__base-header-text"
           [ngClass]="{'dropdown-search__base-header-text-desktop': !isMobile,
                       'dropdown-search__base-header-text-mobile': isMobile}">
        {{headerText}}
      </div>
      <div *ngIf="!isMobile" class="dropdown-search__selected">
        {{this.selectedCategory}}
      </div>
      <div class="dropdown-search__chevron-closed"
           [ngClass]="{'dropdown-search__chevron-closed-mobile': isMobile}">
        <img alt="" class="dropdown-search__expand-more-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/DropdownArrow.png"/>
      </div>
    </div>

    <div *ngIf="dropdownOpen && !isMobile"
         class="dropdown-search__expanded-container"
         id="expanded-dropdown"
         [ngClass]="{'dropdown-search__expanded-container-desktop': !isMobile,
                     'dropdown-search__expanded-container-mobile': isMobile}">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="dropdown-search__expanded-header-text">{{headerText}}</div>
        <mat-icon matSuffix
                  (click)="toggleDropdown()">
          <img alt="" class="dropdown-search__chevron-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/DropdownArrow.png"/>
        </mat-icon>
      </div>
      <div *ngFor="let category of categories; last as isLast"
           class="dropdown-search__row-hover"
           [ngClass]="{'dropdown-search__selected-category': category.title === selectedCategory}">
        <div class="dropdown-search__categories" (click)="selectCategory(category)">{{category.title}}</div>
        <hr *ngIf="!isLast">
      </div>
    </div>

    <div *ngIf="dropdownOpen && isMobile"
         class="dropdown-search__mobile-slide-up"
         (click)="openMobileCategories()">
    </div>
  </div>

  <div class="dropdown-search__search"
       appearance="none"
       [hidden]="selectedCategoryObject && selectedCategoryObject.isAutocomplete"
       [ngClass]="{'dropdown-search__search-desktop': !isMobile,
                   'dropdown-search__search-mobile': isMobile,
                   'dropdown-search__search-without-advanced-search': !displayAdvancedSearchBox,
                   'dropdown-search__mobile-error-search': displayErrorText && errorText && isMobile}">
    <mat-icon matPrefix class="dropdown-search__search-icon">search</mat-icon>
    <input #searchInput matInput
           class="dropdown-search__search-placeholder-text"
           [maxlength]="getMaxLength()"
           [ngModel]="searchTerm"
           [placeholder]="getPlaceholderText()"
           (keyup)="search(searchInput.value, $event)">
    <mat-icon matSuffix
              *ngIf="searchTerm || displayErrorText"
              class="dropdown-search__search-close"
              (click)="clearSearch.emit(clearSearchTerm())">
      <img alt="close" class="dropdown-search__close-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/Close.png"/>
    </mat-icon>
  </div>
  <spot-autocomplete *ngIf="selectedCategoryObject && selectedCategoryObject.isAutocomplete"
                     [ngClass]="{'dropdown-search__mobile-margin': isMobile}"
                     [items]="(autocompleteCustomers$ | async)"
                     [loading]="(autocompleteCustomersLoading$ | async)"
                     [placeholder]="getPlaceholderText()"
                     [noResultsText]="noResultsText"
                     [isBorderStyling]="true"
                     [shouldMaintainSelectionText]="true"
                     [autoCompleteSearchTerm]="autoCompleteSearchTerm"
                     [isWithDropdown]="true"
                     [isMobile]="isMobile"
                     (valueChange)="valueChange.emit($event)"
                     (selectionMade)="selectionMade.emit($event)"
                     (clearSearchCriteria)="clearAutocompleteSearchTerm.emit()">
  </spot-autocomplete>
</div>
<div *ngIf="displayErrorText && errorText"
     class="dropdown-search__error-text"
     [ngClass]="{'dropdown-search__error-text-desktop': !isMobile,
                 'dropdown-search__error-text-mobile': isMobile}">
  <div [fxLayout]="'row wrap'">
    <mat-icon class="dropdown-search__error-text-icon">warning_amber</mat-icon>
    <div class="dropdown-search__error-text-message">{{errorText}}</div>
  </div>
</div>

<div class="dropdown-search__example-text"
     [ngClass]="{'dropdown-search__example-text-desktop': !isMobile,
                 'dropdown-search__example-text-mobile': isMobile}">
  <div *ngIf="exampleText">
    {{exampleText}}
  </div>
  <div *ngIf="exampleTextNote" class="dropdown-search__example-text-note">
    {{exampleTextNote}}
  </div>
</div>
