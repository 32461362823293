import * as error from '../actions/error';
import { flatten, uniq } from 'ramda';

export interface State {
  errorPages: any[];
}

export const initialState: State = {
  errorPages: []
};

export function reducer(state = initialState, action: error.Actions): State {
  switch (action.type) {
    case error.ActionTypes.PUSH_ERROR_PAGE: {
      const errorPage = action.payload;
      let errorPages = uniq(flatten([state.errorPages, errorPage]));

      return { ...state, errorPages };
    }

    default: {
      return state;
    }
  }
}

export const getErrorPages = (state: State) => state.errorPages;
