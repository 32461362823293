import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  ADD_CUSTOM_DATE_FILTER:                      type('[Meet] Add Custom Date Filter'),
  ADD_FILTER_FROM_DISCOVER:                    type('[Meet] Add Filter From Discover'),
  ADD_INDIVIDUAL_EXCLUDE_CUSTOMER:             type('[Meet] Add Individual Exclude Customer'),
  ADD_INDIVIDUAL_INCLUDE_CUSTOMER:             type('[Meet] Add Individual Include Customer'),
  ADD_SAVED_GROUP:                             type('[Meet] Add Saved Group'),
  ADD_SELECTED_FILTER:                         type('[Meet] Add Selected Filter'),
  CLEAR_FILTERS:                               type('[Meet] Clear Filters'),
  CREATE_SAVED_GROUP:                          type('[Meet] Create Saved Group'),
  CREATE_SAVED_GROUP_ERROR:                    type('[Meet] Create Saved Group Error'),
  CREATE_SAVED_GROUP_SUCCESS:                  type('[Meet] Create Saved Group Success'),
  DELETE_SAVED_GROUP:                          type('[Meet] Delete Saved Group'),
  DELETE_SAVED_GROUP_ERROR:                    type('[Meet] Delete Saved Group Error'),
  DELETE_SAVED_GROUP_SUCCESS:                  type('[Meet] Delete Saved Group Success'),
  EDIT_SAVED_GROUP:                            type('[Meet] Edit Saved Group'),
  EXIT_SAVED_GROUP_EDIT:                       type('[Meet] Exit Saved Group Edit'),
  LOAD_FILTERS:                                type('[Meet] Load Filters'),
  LOAD_FILTERS_FAILURE:                        type('[Meet] Load Filters Failure'),
  LOAD_FILTERS_SUCCESS:                        type('[Meet] Load Filters Success'),
  LOAD_SAVED_GROUPS_FAILURE:                   type('[Meet] Load Saved Groups Failure'),
  LOAD_SAVED_GROUPS_SUCCESS:                   type('[Meet] Load Saved Groups Success'),
  LOAD_SELECTED_SAVED_GROUP_CUSTOMERS:         type('[Meet] Load Selected Saved Group Customers'),
  LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_FAILURE: type('[Meet] Load Selected Saved Group Customers Failure'),
  LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_SUCCESS: type('[Meet] Load Selected Saved Group Customers Success'),
  REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER:          type('[Meet] Remove Individual Exclude Customer'),
  REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER:          type('[Meet] Remove Individual Include Customer'),
  REMOVE_SAVED_GROUP:                          type('[Meet] Remove Saved Group'),
  REMOVE_SELECTED_FILTER:                      type('[Meet] Remove Selected Filter'),
  REMOVE_SELECTED_SAVED_GROUP_CUSTOMER:        type('[Meet] Remove Selected Saved Group Customer'),
  TOGGLE_CATEGORY_EXPANSION:                   type('[Meet] Toggle Category Expansion'),
  UPDATE_BUTTON_CHIP_ATTRIBUTE:                type('[Meet] Update Button Chip Attribute'),
  UPDATE_BUTTON_TOGGLE_ATTRIBUTE:              type('[Meet] Update Button Toggle Attribute'),
  UPDATE_FILTER_DATE_RANGE_OPTION:             type('[Meet] Update Filter Date Range Option'),
  UPDATE_FILTER_DATES:                         type('[Meet] Update Filter Dates'),
  UPDATE_SAVED_GROUP:                          type('[Meet] Update Saved Group'),
  UPDATE_SAVED_GROUP_ERROR:                    type('[Meet] Update Saved Group Error'),
  UPDATE_SAVED_GROUP_SUCCESS:                  type('[Meet] Update Saved Group Success'),
  UPDATE_SLIDER_VALUE:                         type('[Meet] Update Slider Value'),
  UPDATE_SUB_OPTION_ATTRIBUTE:                 type('[Meet] Update Sub Option Attribute'),
  UPDATE_TWO_VARIABLE_SLIDER_VALUE:            type('[Meet] Update Two Variable Slider Value')
};

export class AddCustomDateFilter implements Action {
  public type = ActionTypes.ADD_CUSTOM_DATE_FILTER;
  constructor(public payload: any) { }
}

export class AddFilterFromDiscover implements Action {
  public type = ActionTypes.ADD_FILTER_FROM_DISCOVER;
  constructor(public payload: any) { }
}

export class AddIndividualExcludeCustomer implements Action {
  public type = ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER;
  constructor(public payload: any) { }
}

export class AddIndividualIncludeCustomer implements Action {
  public type = ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER;
  constructor(public payload: any) { }
}

export class AddSavedGroup implements Action {
  public type = ActionTypes.ADD_SAVED_GROUP;
  constructor(public payload: any) { }
}

export class AddSelectedFilter implements Action {
  public type = ActionTypes.ADD_SELECTED_FILTER;
  constructor(public payload: any) { }
}

export class ClearFilters implements Action {
  public type = ActionTypes.CLEAR_FILTERS;
  constructor(public payload: any = '') { }
}

export class CreateSavedGroup implements Action {
  public type = ActionTypes.CREATE_SAVED_GROUP;
  constructor(public payload: any) { }
}

export class CreateSavedGroupError implements Action {
  public type = ActionTypes.CREATE_SAVED_GROUP_ERROR;
  constructor(public payload: any) { }
}

export class CreateSavedGroupSuccess implements Action {
  public type = ActionTypes.CREATE_SAVED_GROUP_SUCCESS;
  constructor(public payload: any) { }
}

export class DeleteSavedGroup implements Action {
  public type = ActionTypes.DELETE_SAVED_GROUP;
  constructor(public payload: any) { }
}

export class DeleteSavedGroupError implements Action {
  public type = ActionTypes.DELETE_SAVED_GROUP_ERROR;
  constructor(public payload: any) { }
}

export class DeleteSavedGroupSuccess implements Action {
  public type = ActionTypes.DELETE_SAVED_GROUP_SUCCESS;
  constructor(public payload: any) { }
}

export class EditSavedGroup implements Action {
  public type = ActionTypes.EDIT_SAVED_GROUP;
  constructor(public payload: any) { }
}

export class ExitSavedGroupEdit implements Action {
  public type = ActionTypes.EXIT_SAVED_GROUP_EDIT;
  constructor(public payload: any = '') { }
}

export class LoadFilters implements Action {
  public type = ActionTypes.LOAD_FILTERS;
  constructor(public payload: any = {}) { }
}

export class LoadFiltersFailure implements Action {
  public type = ActionTypes.LOAD_FILTERS_FAILURE;
  constructor(public payload: string) { }
}

export class LoadFiltersSuccess implements Action {
  public type = ActionTypes.LOAD_FILTERS_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadSavedGroupsFailure implements Action {
  public type = ActionTypes.LOAD_SAVED_GROUPS_FAILURE;
  constructor(public payload: string) { }
}

export class LoadSavedGroupsSuccess implements Action {
  public type = ActionTypes.LOAD_SAVED_GROUPS_SUCCESS;
  constructor(public payload: any[]) { }
}

export class LoadSelectedSavedGroupCustomers implements Action {
  public type = ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS;
  constructor(public payload: any) { }
}

export class LoadSelectedSavedGroupCustomersFailure implements Action {
  public type = ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_FAILURE;
  constructor(public payload: any) { }
}

export class LoadSelectedSavedGroupCustomersSuccess implements Action {
  public type = ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_SUCCESS;
  constructor(public payload: any) { }
}

export class RemoveIndividualExcludeCustomer implements Action {
  public type = ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER;
  constructor(public payload: any) { }
}

export class RemoveIndividualIncludeCustomer implements Action {
  public type = ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER;
  constructor(public payload: any) { }
}

export class RemoveSavedGroup implements Action {
  public type = ActionTypes.REMOVE_SAVED_GROUP;
  constructor(public payload: any) { }
}

export class RemoveSelectedFilter implements Action {
  public type = ActionTypes.REMOVE_SELECTED_FILTER;
  constructor(public payload: any) { }
}

export class RemoveSelectedSavedGroupCustomer implements Action {
  public type = ActionTypes.REMOVE_SELECTED_SAVED_GROUP_CUSTOMER;
  constructor(public payload: any) { }
}

export class ToggleCategoryExpansion implements Action {
  public type = ActionTypes.TOGGLE_CATEGORY_EXPANSION;
  constructor(public payload: any) { }
}

export class UpdateButtonChipAttribute implements Action {
  public type = ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE;
  constructor(public payload: any = '') { }
}

export class UpdateButtonToggleAttribute implements Action {
  public type = ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE;
  constructor(public payload: any = '') { }
}

export class UpdateFilterDateRangeOption implements Action {
  public type = ActionTypes.UPDATE_FILTER_DATE_RANGE_OPTION;
  constructor(public payload: any = '') { }
}

export class UpdateFilterDates implements Action {
  public type = ActionTypes.UPDATE_FILTER_DATES;
  constructor(public payload: any = '') { }
}

export class UpdateSavedGroup implements Action {
  public type = ActionTypes.UPDATE_SAVED_GROUP;
  constructor(public payload: any = '') { }
}

export class UpdateSavedGroupError implements Action {
  public type = ActionTypes.UPDATE_SAVED_GROUP_ERROR;
  constructor(public payload: any) { }
}

export class UpdateSavedGroupSuccess implements Action {
  public type = ActionTypes.UPDATE_SAVED_GROUP_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateSliderValue implements Action {
  public type = ActionTypes.UPDATE_SLIDER_VALUE;
  constructor(public payload: any = '') { }
}

export class UpdateSubOptionAttribute implements Action {
  public type = ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE;
  constructor(public payload: any = '') { }
}

export class UpdateTwoVariableSliderValue implements Action {
  public type = ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE;
  constructor(public payload: any = '') { }
}

export type Actions
  = AddCustomDateFilter
  | AddFilterFromDiscover
  | AddIndividualExcludeCustomer
  | AddIndividualIncludeCustomer
  | AddSavedGroup
  | AddSelectedFilter
  | ClearFilters
  | CreateSavedGroup
  | CreateSavedGroupError
  | CreateSavedGroupSuccess
  | DeleteSavedGroup
  | DeleteSavedGroupError
  | DeleteSavedGroupSuccess
  | EditSavedGroup
  | ExitSavedGroupEdit
  | LoadFilters
  | LoadFiltersFailure
  | LoadFiltersSuccess
  | LoadSavedGroupsFailure
  | LoadSavedGroupsSuccess
  | LoadSelectedSavedGroupCustomers
  | LoadSelectedSavedGroupCustomersFailure
  | LoadSelectedSavedGroupCustomersSuccess
  | RemoveIndividualExcludeCustomer
  | RemoveIndividualIncludeCustomer
  | RemoveSavedGroup
  | RemoveSelectedFilter
  | RemoveSelectedSavedGroupCustomer
  | ToggleCategoryExpansion
  | UpdateButtonChipAttribute
  | UpdateButtonToggleAttribute
  | UpdateFilterDateRangeOption
  | UpdateFilterDates
  | UpdateSavedGroup
  | UpdateSavedGroupError
  | UpdateSavedGroupSuccess
  | UpdateSliderValue
  | UpdateSubOptionAttribute
  | UpdateTwoVariableSliderValue;
