<div class="flex-container ngx-data-table__footer"
     fxLayout="row">
  <div class="flex-item"
       fxFlex
       fxLayoutAlign="end center"
       [ngClass]="{'ngx-data-table__mobile-footer': (isMobile || hidePageOptions())}">
        <span *ngIf="!isMobile && !hidePageOptions()" fxLayoutAlign="end center">
            <span>Page:</span>
            <mat-select class="ngx-data-table__pagination-dropdown ngx-data-table__arrow-wrapper"
                        #pageDropdown
                        [(ngModel)]="currentPage"
                        (selectionChange)="changeCurrentPage(currentPage)">
                <mat-option *ngFor="let page of pageNumberArr; let i = index"
                            [value]="i">
                    {{page}}
                </mat-option>
            </mat-select>
            <mat-icon class="ngx-data-table__select-icon" (click)="openPageDropdown()">expand_more</mat-icon>
            <span>Rows per page:</span>
            <mat-select class="ngx-data-table__pagination-dropdown ngx-data-table__arrow-wrapper"
                        #pageSizeDropdown
                        [(ngModel)]="pageSize"
                        (selectionChange)="pageSizeChange()">
                <mat-option *ngFor="let size of pageSizeOptions"
                            [value]="size">
                    {{size}}
                </mat-option>
            </mat-select>
            <mat-icon class="ngx-data-table__select-icon" (click)="openPageSizeDropdown()">expand_more</mat-icon>
        </span>
    <pagination *ngIf="totalElements > 0"
                [totalElements]="totalElements"
                [pageSize]="pageSize"
                [currentPage]="currentPage"
                (pageRight)="pageRight()"
                (pageLeft)="pageLeft()">
    </pagination>
  </div>
</div>
