<div class="dropdown-by-id">
  <mat-form-field *ngIf="!isMobile || enableMultiSelect" appearance="none" class="dropdown-by-id__name-dropdown"
                  [ngClass]="{'dropdown-by-id__remove-top-border': removeTopBorder}">
    <mat-label class="dropdown-by-id__custom-label">{{dropdownLabel}}</mat-label>
    <mat-icon class="dropdown-by-id__expand-arrow" *ngIf="!disabled">expand_more</mat-icon>
    <mat-select *ngIf="!enableMultiSelect"
                [disabled]="disabled"
                [(ngModel)]="selectedCategoryId"
                (selectionChange)="updateCategory(selectedCategoryId)">
      <mat-option *ngFor="let category of categories"
                  class="dropdown-by-id__dropdown-options"
                  [disabled]="category.disabled"
                  [ngClass]="{'dropdown-by-id__dropdown-options-disabled': category.disabled}"
                  [value]="category.id">
        {{ category.name }}
      </mat-option>
    </mat-select>
    <mat-select *ngIf="enableMultiSelect"
                multiple
                [disabled]="disabled"
                [(ngModel)]="selectedCategoryIdsForMultiSelect"
                (selectionChange)="updateMultiSelectCategories(selectedCategoryIdsForMultiSelect)">
      <mat-option *ngFor="let category of categories"
                  class="dropdown-by-id__dropdown-options"
                  [disabled]="category.disabled"
                  [ngClass]="{'dropdown-by-id__dropdown-options-disabled': category.disabled}"
                  [value]="category.id">
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="isMobile && !enableMultiSelect"
       class="dropdown-by-id__base-container"
       [ngClass]="{'dropdown-by-id__remove-top-border': removeTopBorder}"
       (click)="openMobileSelectDropdown()">
    <div class="dropdown-by-id__base-header-text">
      {{dropdownLabel}}
    </div>
    <div *ngFor="let category of categories" class="dropdown-by-id__category">
      {{category.id === selectedCategoryId ? category.name : ''}}
    </div>
    <mat-icon *ngIf="!disabled" class="dropdown-by-id__mobile-expand-arrow">expand_more</mat-icon>
  </div>
</div>
