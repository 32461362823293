<mat-card class="card">
  <ng-container *ngIf="tile.loading">
    <div class="card__loading">
      <content-placeholder></content-placeholder>
    </div>
  </ng-container>
  <ng-container *ngIf="!tile.loading">
    <mat-card-header class="card__header" fxLayout="row" fxLayoutAlign="space-between center">
      <img mat-card-avatar class="card__avatar" [src]="tile.imageUrl" alt="">
      <mat-card-title class="card__title">
        {{tile.title}}
      </mat-card-title>
      <div fxFlex></div>
      <help-text *ngIf="tile.helpText"
                 [helpText]="tile.helpText.audienceText"
                 [filterText]="tile.helpText.filterText"
                 [strategicGuidanceText]="tile.helpText.strategicGuidanceText"
                 [displayOnLeft]="true">
      </help-text>
    </mat-card-header>
    <mat-card-content *ngIf="tile.error" class="card__error-content">
      <error-message class="card__error"
                     [error]="tile.errorText"
                     [page]="getPageTitle()">
      </error-message>
    </mat-card-content>
    <div *ngIf="!tile.error">
      <mat-card-content *ngIf="!tile.automated"
                        class="card__content"
                        [ngClass]="{'card__campaign-content': (tile.category === 'campaign' || tile.category === 'promotion' ||
                                     tile.category === communityCareEvent || tile.category === 'third-party-promotion'
                                     || tile.category == freeItemInCartPromotion)}"
                        fxLayout="column"
                        fxLayoutAlign="space-between stretch">
        <ng-content></ng-content>
        <div *ngIf="tile.automationEnabled && !isSendingDisabled && !isRewardsSendingDisabled"
             class="card__automation-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             (click)="setUpAutomation.emit()">
          <div class="card__automation-text">
            Automatically send this reward to eligible customers
          </div>
          <img class="card__chevron-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png" alt="">
        </div>
      </mat-card-content>

      <mat-card-content *ngIf="tile.automated" fxLayout="row" fxLayoutAlign="start center" class="card__automation-content">
        <mat-icon class="card__automation-icon">autorenew</mat-icon>
        <div *ngIf="tile.category !== communityCareEvent" class="card__automated-text">Campaign Automated</div>
        <div *ngIf="tile.category === communityCareEvent" class="card__automated-text">Event Created</div>
      </mat-card-content>

      <div class="card__actions-group"
           fxLayout="row"
           fxLayoutAlign="end">
        <mat-card-actions class="card__actions" *ngIf="tile.learnMore">
          <button mat-button (click)="learnMore.emit()">
            LEARN MORE <mat-icon class="card__learn-more">open_in_new</mat-icon>
          </button>
        </mat-card-actions>
        <mat-card-actions class="card__actions" *ngIf="tile.sendEmail && !tile.automated">
          <button mat-button (click)="sendEmail.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled">
            Send Emails
          </button>
        </mat-card-actions>
        <mat-card-actions class="card__actions" *ngIf="tile.sendTreat && !tile.automated && !tile.rewardDisable && tile.category !== communityCareEvent">
          <button mat-button (click)="sendReward.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled">
            Send Rewards
          </button>
        </mat-card-actions>
        <mat-card-actions class="card__actions" *ngIf="tile.sendPromotion && !tile.automated">
          <button mat-button (click)="sendPromotion.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled">
            Create Promotion
          </button>
        </mat-card-actions>
        <mat-card-actions class="card__actions" *ngIf="tile.sendEvent && !tile.automated && tile.category === communityCareEvent">
          <button mat-button (click)="sendCommunityCare.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled">
            Create Event
          </button>
        </mat-card-actions>
        <ng-container *ngIf="tile.automated">
          <mat-card-actions *ngIf="!tile.sendPromotion && tile.category !== communityCareEvent" class="card__actions">
            <button mat-button (click)="editAutomation.emit()">Edit Details</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category !== communityCareEvent && tile.category !== thirdPartyPromotion && tile.category !== freeItemInCartPromotion" class="card__actions">
            <button mat-button (click)="stopAutomation.emit()">Stop Automation</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === communityCareEvent && tile.multipleSendsEnabled" class="card__actions">
            <button mat-button (click)="sendCommunityCare.emit()"
                    [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled">
              Create Another Event
            </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === communityCareEvent && !tile.multipleSendsEnabled" class="card__actions">
            <button mat-button (click)="stopCommunityCareEvent.emit()">Stop Event</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === thirdPartyPromotion || tile.category === freeItemInCartPromotion" class="card__actions">
            <button mat-button (click)="stopPromotion.emit()">Stop Promotion</button>
          </mat-card-actions>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-card>
