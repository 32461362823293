<mat-card class="error-card">
    <ng-container *ngIf="tile.loading">
        <div class="error-card__loading">
            <content-placeholder></content-placeholder>
        </div>
    </ng-container>
    <ng-container *ngIf="!tile.loading">
        <mat-card-header class="error-card__header" fxLayout="row" fxLayoutAlign="space-between center">
            <img alt="Discover Tile Image" mat-card-avatar class="error-card__avatar" [src]="tile.imageUrl">
            <mat-card-title class="error-card__title">
                {{tile.title}}
            </mat-card-title>
            <div fxFlex></div>
            <help-text *ngIf="tile.helpText"
                       [helpText]="tile.helpText"
                       [displayOnLeft]="true">
            </help-text>
        </mat-card-header>
        <mat-card-content *ngIf="tile.error" class="error-card__error-content">
            <error-message class="error-card__error"
                           [error]="tile.errorText"
                           [page]="getPageTitle()">
            </error-message>
        </mat-card-content>
        <mat-card-content *ngIf="!tile.error"
                          class="error-card__content error-card__content-scroll"
                          fxLayout="column"
                          fxLayoutAlign="space-between stretch">
            <mat-nav-list class="error-card__error-list error-nav-list">
                <ng-container *ngFor="let error of tile.errors;">
                    <mat-list-item class="error-card__list-item" (click)="showFailedCampaignDetails.emit(error);">
                        <span matLine
                              [ngClass]="{'error-card__resolved-date' : error.markResolved, 'error-card__unresolved-date' : !error.markResolved}">{{error.date}}
                        </span>
                        <span matLine class="error-card__error-message">{{error.failureMessage}}</span>
                        <span matLine *ngIf="error.markResolved" class="error-card__status">
                            <img alt="Checkmark"
                                 class="error-card__resolved-icon"
                                 mat-card-avatar
                                 src="https://www.cfacdn.com/img/Spotlight/Icons/StatusCompleteIcon.png">Resolved
                        </span>
                        <button matLine
                                mat-button
                                *ngIf="!error.markResolved"
                                class="error-card__mark-resolved"
                                (click)="markResolved.emit(error);$event.stopPropagation();">
                            Mark Resolved
                        </button>
                        <button mat-icon-button>
                            <mat-icon class="error-card__chevron-right">chevron_right</mat-icon>
                        </button>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
        </mat-card-content>
        <div *ngIf="!tile.error"
             class="error-card__footer"
             fxLayout="row"
             fxLayoutAlign="end">
        </div>
    </ng-container>
</mat-card>
