import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { find, groupBy, propEq } from 'ramda';
import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/location.model';
import { AnalyticsService } from '../core/analytics';
import Bugsnag from '@bugsnag/js';
import { environment } from 'spotlight-ui/environments/environment';
import {DiscoverConstants} from "./discover.constants";

@Injectable()
export class DiscoverService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService,
    private analytics: AnalyticsService
  ) {

  }

  public findById(id, tiles) {
    let hasId = propEq('id', id);
    let tile = find(hasId, tiles);
    return tile && tile.enabled && tile.enabled === 'true';
  }

  public getTileById(id, tiles) {
    let hasId = propEq('id', id);
    let tile = find(hasId, tiles);
    return (tile && tile.enabled && tile.enabled === 'true') ? tile : null;
  }

  public loadDiscoverTiles() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/discover/tiles`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        Bugsnag.notify(new Error('Load Discover Tiles Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  public loadChurnCustomers() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        let url = `/api/location/${locationNumber}/churnCustomers`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'churn-flag', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadFirstTimeCustomers() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/firstTimeMobile`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'engage-first', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadLapsedBreakfastCustomers() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/lapsedBreakfast/customers`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'grow-breakfast', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadGrowDriveThruCustomers() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/cluster/customer/driveThru`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'grow-drive-thru-destination', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadGrowCurbsideCustomers() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/cluster/customer/curbSide`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'grow-curbside-destination', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadMaximizeRedemption() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/treatRedemption`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'maximize-redemption', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadOldReward() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/old/customers`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      map((res) => ({ id: 'operator-led-delivery', ...res })),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public loadFilters(tiles) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/discoverFilters`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => {
        const tilesById = groupBy((t) => t.id)(tiles);
        return {
          ...tilesById,
          ...res.responseObject
        };
      }),
      catchError((error) => this.ehs.handleError(error))
    );
  }

  public stopAutomationById(payload) {
    this.analytics.sendMParticleEvent(
      'Automation Stopped',
      { 'Automation Id': payload.id }
    );

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        let url = ''
        if (payload.fieldId !== '') {
          url = `/api/location/${locationNumber}/automation/${payload.id}?fieldId=${payload.fieldId}`;
        } else {
          url = `/api/location/${locationNumber}/automation/${payload.id}`;
        }

        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject || []),
      catchError((err) => {
        Bugsnag.notify(new Error('Stop Automation Failed'));
        return this.ehs.handleError(err);
      })
    );
  }
  public stopThirdPartyPromotionById(payload) {
    this.analytics.sendMParticleEvent(
        'Third Party Promo Stopped',
        { 'Third Party Promo Id': payload.id }
    );
    return this.us.getSelectedLocation().pipe(
        switchMap((loc: UserLocation) => {
          const locationNumber = loc.locationNumber;
          const url = `/api/location/${locationNumber}/thirdPartyPromotion/${payload.id}`;
          return this.http.delete(environment.spotlightApiUrl + url);
        }),
        map((res: any) =>  res),
        catchError((err) => {
          Bugsnag.notify(new Error('Stop Third Party Promo Failed'));
          return this.ehs.handleError(err);
        })
    );
  }

  public stopFreeItemInCartById(payload) {
    this.analytics.sendMParticleEvent(
      'Free Item In Cart Stopped',
      { 'Free Item In Cart Id': payload.id }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/freeInCart/${payload.id}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((res: any) => {return { automated: false, id: DiscoverConstants.freeItemInCartPromotion }}),
      catchError((err) => {
        Bugsnag.notify(new Error('Stop Free Item In Cart Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  public stopCommunityCareEventById(payload) {
    this.analytics.sendMParticleEvent(
      'Community Care Event Stopped',
      { 'Community Care Event Id': payload.eventId }
    );

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const eventId = payload.eventId;
        const url = `/api/location/${locationNumber}/communityCareEvent/${eventId}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject || []),
      catchError((err) => {
        Bugsnag.notify(new Error('Stop Community Care Event Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  public markResolvedFailedCampaign(payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const request = {
          'campaignId' : payload.campaignId,
          'markResolved': true,
          'type': payload.type
        };
        const url = `/api/location/${locationNumber}/discover/error-tile/resolved`;
        return this.http.put(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }
}
