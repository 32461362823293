import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'increment-stepper',
  templateUrl: './increment-stepper.component.html',
  styleUrls: ['./increment-stepper.component.scss']
})
export class IncrementStepperComponent {
  @Input() public numberSelected: number;
  @Input() public disableIncrement: boolean;
  @Input() public disableDecrement: boolean;
  @Input() public isRedTheme: boolean = false;

  @Output() public incrementSelected = new EventEmitter<any>();
  @Output() public decrementSelected = new EventEmitter<any>();

  public increment() {
    if (!this.disableIncrement) {
      this.incrementSelected.emit(this.numberSelected + 1);
    }
  }

  public decrement() {
    if (!this.disableDecrement) {
      this.decrementSelected.emit(this.numberSelected - 1);
    }
  }
}
