import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as user from '../actions/user';
import * as notifications from '../actions/notifications';
import { NotificationsService } from '../core/notifications/notifications.service';

@Injectable()
export class NotificationEffects {
  @Effect()
  public fetchNotifications$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      notifications.ActionTypes.NOTIFICATIONS_PAGE_VIEWED,
      notifications.ActionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS,
      notifications.ActionTypes.NOTIFICATIONS_CLEAR_SUCCESS
    ),
    switchMap(() =>
      this.ns.getNotifications().pipe(
        map((res) => new notifications.NotificationsFetchSuccess(res)),
        catchError((err) => of(new notifications.NotificationsFetchFailure(err)))
      )
    )
  );

  @Effect()
  public notificationsPageLoaded$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.NOTIFICATIONS_PAGE_VIEWED),
    switchMap(() =>
      this.ns.getNotifications().pipe(
        map((res) => new notifications.InitialPagViewFetchSuccess(res)),
        catchError((err) => of(new notifications.NotificationsFetchFailure(err)))
      )
    )
  );

  @Effect()
  public markAllNotificationsAsRead$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.INITIAL_PAGE_VIEW_FETCH_SUCCESS),
    switchMap(() => this.ns.markAllNotificationsAsRead().pipe(
      map(() => new notifications.MarkedAsReadSuccess()),
      catchError((err) => of(new notifications.MarkedAsReadFailure(err))
      ))
    )
  );

  @Effect()
  public clearNotification$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.NOTIFICATIONS_CLEAR),
    map((action: any) => action.payload),
    switchMap((id) => this.ns.clearNotification(id).pipe(
      map(() => new notifications.NotificationClearSuccess(id)),
      catchError((error) => of(new notifications.NotificationClearFailure({ id, error } )))
    ))
  );

  @Effect()
  public clearAllNotifications$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.CLEAR_ALL_NOTIFICATIONS),
    switchMap(() => this.ns.clearAllNotifications().pipe(
      map(() => new notifications.ClearAllNotificationsSuccess()),
      catchError((err) => of(new notifications.ClearAllNotificationsFailure(err)))
    ))
  );

  @Effect({ dispatch: false })
  public showDeleteToast$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.NOTIFICATIONS_CLEAR_SUCCESS),
    tap(() => {
      return this.snackBar.open(
        `Notification successfully cleared.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public showClearAllToast$: Observable<Action> = this.actions$.pipe(
    ofType(notifications.ActionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS),
    tap(() => {
      return this.snackBar.open(
        `All notifications successfully cleared.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  constructor(
    private actions$: Actions,
    private ns: NotificationsService,
    private snackBar: MatSnackBar
  ) { }
}
