<div *ngIf="isEnableSignatureMembership">
    <span *ngIf="membership.silver">
          <img *ngIf="shouldDisplayIcon"
               alt="silver"
               title="Silver status"
               src="https://www.cfacdn.com/img/Spotlight/Icons/SilverTierIcon.png"
               [ngClass]="shouldDisplayIcon && !shouldDisplayLabel ? 'member-status__silver-mobile-icon' : 'member-status__silver-icon'"/>
          <span *ngIf="shouldDisplayLabel" [ngClass]="{'member-status__label': shouldDisplayIcon && shouldDisplayLabel}">
               Silver <span *ngIf="shouldDisplayMemberText">Member</span>
          </span>
     </span>
     <span *ngIf="membership.red">
          <img *ngIf="shouldDisplayIcon"
               alt="red"
               title="Red status"
               src="https://www.cfacdn.com/img/Spotlight/Icons/RedTierIcon.png"
               [ngClass]="shouldDisplayIcon && !shouldDisplayLabel ? 'member-status__red-icon-mobile' : 'member-status__red-icon'"/>
          <span *ngIf="shouldDisplayLabel" [ngClass]="{'member-status__label': shouldDisplayIcon && shouldDisplayLabel}">
               Red <span *ngIf="shouldDisplayMemberText">Member</span>
          </span>
     </span>
     <span *ngIf="membership.justCfaOne && displayCfaOne">
          <img *ngIf="shouldDisplayIcon"
               alt="Chick-fil-A one"
               title="Chick-fil-A One status"
               src="https://www.cfacdn.com/img/Spotlight/Icons/OneTierIcon.png"
               [ngClass]="shouldDisplayIcon && !shouldDisplayLabel ? 'member-status__cfa-one-mobile-icon' : 'member-status__cfa-one-icon'"/>
          <span *ngIf="shouldDisplayLabel" [ngClass]="{'member-status__label': shouldDisplayIcon && shouldDisplayLabel}">
               One <span *ngIf="shouldDisplayMemberText">Member</span>
          </span>
     </span>
     <span *ngIf="membership.signature">
          <img *ngIf="shouldDisplayIcon"
               alt="signature"
               title="Chick-fil-A One status"
               src="https://www.cfacdn.com/img/Spotlight/Icons/SignatureIcon2.png"
               [ngClass]="shouldDisplayIcon && !shouldDisplayLabel ? 'member-status__signature-icon-mobile' : 'member-status__signature-icon'"/>
          <span *ngIf="shouldDisplayLabel" [ngClass]="{'member-status__label': shouldDisplayIcon && shouldDisplayLabel}">
               Signature <span *ngIf="shouldDisplayMemberText">Member</span>
          </span>
     </span>
     <span *ngIf="membership.prospect">
          <img *ngIf="shouldDisplayIcon"
               alt="prospect"
               title="Prospect Status"
               src="../../../../assets/img/non-cfa-one-member.svg"
               [ngClass]="shouldDisplayIcon && !shouldDisplayLabel ? 'member-status__cfa-one-mobile-icon' : 'member-status__icon'"/>
     </span>
</div>
<div *ngIf="!isEnableSignatureMembership">
     <img alt="silver"
          *ngIf="membership.silver"
          title="Silver status"
          src="../../../../assets/img/silver_tier.svg"
          [ngClass]="isMobile ? 'member-status__mobile-icon' : 'member-status__icon'"/>
     <img alt="red"
          *ngIf="membership.red"
          title="Red status"
          src="../../../../assets/img/red_tier.svg"
          [ngClass]="isMobile ? 'member-status__mobile-icon' : 'member-status__icon'"/>
     <img alt="Chick-fil-A one"
          *ngIf="membership.justCfaOne && displayCfaOne"
          title="Chick-fil-A One status"
          src="../../../../assets/img/cfa-one-icon.svg"
          [ngClass]="isMobile ? 'member-status__mobile-icon' : 'member-status__icon'"/>
     <img alt="prospect"
          *ngIf="membership.prospect"
          title="Prospect Status"
          src="../../../../assets/img/non-cfa-one-member.svg"
          [ngClass]="isMobile ? 'member-status__mobile-icon' : 'member-status__icon'"/>
     <img alt="signature"
          *ngIf="membership.signature"
          title="Chick-fil-A One status"
          src="https://www.cfacdn.com/img/Spotlight/Icons/SignatureIcon2.png"
          [ngClass]="isMobile ? 'member-status__mobile-icon' : 'member-status__icon'"/>
</div>
