import { Injectable } from '@angular/core';
import { UserService } from '../../core/user/user.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../core/error-handler';
import { environment } from "../../../environments/environment";

@Injectable()
export class ActiveRewardDialogService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService
  ) {}

  public getActiveRewardIds(req) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let url = `/api/location/${loc.locationNumber}/activeTreats`;
        return this.http.post(environment.spotlightApiUrl + url, req);
      }),
      map((res) => res),
      catchError(this.ehs.handleError)
    );
  }
}
