import {
  AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'spot-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements AfterViewInit {
  @Input() public items: any[];
  @Input() public loading: boolean = false;
  @Input() public noResultsText: string = 'No results found';
  @Input() public headerText: string;
  @Input() public placeholder: string;
  @Input() public autofocus: boolean = false;
  @Input() public isBorderStyling: boolean = false;
  @Input() public shouldMaintainSelectionText: boolean = false;
  @Input() public isWithDropdown: boolean = false;
  @Input() public isMobile: boolean;

  @Input()
  set autoCompleteSearchTerm(searchTerm: string) {
    this.formCtrl.setValue(searchTerm);
  }

  @Output() public valueChange = new EventEmitter();
  @Output() public selectionMade = new EventEmitter();
  @Output() public clearSearchCriteria = new EventEmitter();

  @ViewChildren('search') public vc;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  public formCtrl = new FormControl();
  public inputValue = '';
  public isAutoCompleteOpen: boolean = false;

  public ngAfterViewInit() {
    if (this.autofocus) {
      // Timeout to ensure that all value changes have been processed since initialization
      setTimeout(() => {
        this.vc.first.nativeElement.focus();
      });
    }
  }

  public selectOption(item) {
    if (!this.shouldMaintainSelectionText) {
      this.formCtrl.setValue(null);
    } else {
      this.formCtrl.setValue(item.fullName);
    }
    if (this.inputValue) {
      this.inputValue = '';
      this.valueChange.emit('');
    }
    this.selectionMade.emit(item);
  }

  public onSearch(term) {
    // if user manually erases customer name completely
    if (this.shouldMaintainSelectionText && term === '' && term !== this.inputValue) {
      this.clearSearchCriteria.emit();
      this.autocompleteTrigger.closePanel();
    }

    if (term !== this.inputValue) {
      this.inputValue = term;
      this.valueChange.emit(term);
    }
  }

  public focus() {
    this.vc.first.nativeElement.focus();
  }

  public removeSearchTerm() {
    this.formCtrl.setValue(null);
    this.clearSearchCriteria.emit();
  }
}
