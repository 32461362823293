<mat-card class="basic-card">
    <mat-card-header class="basic-card__header">
        <div class="basic-card__header-content">
            <div fxLayout="column" fxLayoutAlign="center start">
                <mat-card-title class="basic-card__title">
                    <span>{{title}}</span>
                </mat-card-title>
                <div *ngIf="subtitle" class="basic-card__subtitle">
                    {{subtitle}}
                </div>
            </div>
        </div>
    </mat-card-header>
    <ng-container *ngIf="!loading && !error">
        <mat-card-content class="basic-card__content">
            <ng-content></ng-content>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="loading">
        <mat-card-content class="basic-card__content">
            <content-placeholder></content-placeholder>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="!loading && !!error">
        <mat-card-content class="basic-card__content">
            {{error}}
        </mat-card-content>
    </ng-container>
</mat-card>
