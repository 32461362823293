<div class="confirmation-dialog">
    <div *ngIf="title" class="confirmation-dialog__title" [innerHTML]="title"></div>
    <div class="confirmation-dialog__text" [innerHTML]="displayText"></div>
    <br *ngIf="warningText">
    <div *ngIf="warningText" class="confirmation-dialog__warning-text" [innerHTML]="warningText"></div>
    <div mat-dialog-actions class="confirmation-dialog__wrapper">
        <button mat-button *ngIf="!hideCancel" (click)="closeDialog(false)" class="confirmation-dialog__cancel-button">Cancel</button>
        <button mat-button (click)="closeDialog(true)" class="confirmation-dialog__send-button">
            {{confirmText}}
        </button>
    </div>
</div>
