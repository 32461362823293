import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  DISMISS_ALERT:                     type('[User] Dismiss Alert'),
  DISMISS_ALERT_SUCCESS:             type('[User] Dismiss Alert Success'),
  DISMISS_BANNER:                    type('[User] Dismiss Banner'),
  DISMISS_BANNER_SUCCESS:            type('[User] Dismiss Banner Success'),
  GET_LOCATION_DETAILS_FAILURE:      type('[User] Get Location Details Failure'),
  GET_LOCATION_DETAILS_SUCCESS:      type('[User] Get Location Details Success'),
  LAUNCH_DARKLY_INITIALIZED:         type('[User] LD Initialized'),
  SELECT_LOCATION:                   type('[User] Select Location'),
  USER_DATA_ERROR:                   type('[User] User Data Error'),
  USER_DATA_FOUND:                   type('[User] User Data Found')
};

export class DismissAlertAction implements Action {
  public type = ActionTypes.DISMISS_ALERT;

  constructor(public payload: any = '') { }
}

export class DismissAlertSuccessAction implements Action {
  public type = ActionTypes.DISMISS_ALERT_SUCCESS;

  constructor(public payload: any = '') { }
}

export class DismissBannerAction implements Action {
  public type = ActionTypes.DISMISS_BANNER;

  constructor(public payload: any = '') { }
}

export class DismissBannerSuccessful implements Action {
  public type = ActionTypes.DISMISS_BANNER_SUCCESS;

  constructor(public payload: any = '') { }
}

export class GetLocationDetailsFailure implements Action {
  public type = ActionTypes.GET_LOCATION_DETAILS_FAILURE;

  constructor(public payload: any) { }
}

export class GetLocationDetailsSuccess implements Action {
  public type = ActionTypes.GET_LOCATION_DETAILS_SUCCESS;

  constructor(public payload: any) { }
}

export class LaunchDarklyInitialized implements Action {
  public type = ActionTypes.LAUNCH_DARKLY_INITIALIZED;

  constructor(public payload: any = '') { }
}

export class SelectLocation implements Action {
  public type = ActionTypes.SELECT_LOCATION;

  constructor(public payload: any) { }
}

export class UserDataErrorAction implements Action {
  public type = ActionTypes.USER_DATA_ERROR;

  constructor(public payload: any = '') { }
}

export class UserDataFoundAction implements Action {
  public type = ActionTypes.USER_DATA_FOUND;

  constructor(public payload: any = '') { }
}

export type Actions
  = DismissAlertAction
  | DismissAlertSuccessAction
  | DismissBannerAction
  | DismissBannerSuccessful
  | GetLocationDetailsFailure
  | GetLocationDetailsSuccess
  | LaunchDarklyInitialized
  | SelectLocation
  | UserDataErrorAction
  | UserDataFoundAction;
