<div fxLayout="column" fxLayoutAlign="start start" class="bottom-sheet">
  <div *ngIf="content.title" class="bottom-sheet__title">{{content.title}}</div>
  <mat-nav-list class="bottom-sheet__list list-item-row">
    <mat-list-item *ngFor="let listItem of content.list"
                   (click)="dismissSheet(listItem)"
                   class="bottom-sheet__list-item-row-divider"
                   [disabled] = "listItem.disabled"
                   [ngClass]="{'bottom-sheet__selected-list-item' : content.selectedItem === listItem.value}">
      <div *ngIf="listItem.image">
        <img alt="Reward Image" fxFlex="40px" class="bottom-sheet__reward-image" src="{{listItem.image}}"/>
      </div>
      <div class="bottom-sheet__list-item">{{listItem.name}}</div>
    </mat-list-item>
    <mat-list-item fxLayout="row"
                   fxLayoutAlign="start center"
                   *ngIf="content.isCancel"
                   (click)="bottomSheetRef.dismiss()">
      <mat-icon class="bottom-sheet__cancel-icon">close</mat-icon>
      <span class="bottom-sheet__cancel-list-item">
        Cancel
      </span>
    </mat-list-item>
  </mat-nav-list>
</div>
