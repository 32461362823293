import { Component, Input, OnInit } from '@angular/core';
import { pluck } from 'ramda';
import { SharedConstants } from '../../../shared.constants';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html'
})

export class LineChartComponent implements OnInit {
  @Input() public data: any = [];
  @Input() public category: string;
  @Input() public labelProp: string;
  @Input() public valueProp: string;
  @Input() public chartTitle: string;
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel: string;
  @Input() public yAxisFormat: string;
  @Input()
  set isMobile(isMobile: boolean) {
    this.options = SharedConstants.chartOptions(this.yAxisFormat, isMobile);
    this.width = isMobile ? 340 : 500;
  }

  public chartData;
  public options;
  public width;

  public ngOnInit() {
    this.options.title.text = this.chartTitle;
    if (this.chartTitle) {
      this.options.title.display = true;
      this.options.title.text = this.chartTitle;
    }
    if (this.yAxisLabel) {
      this.options.scales.yAxes[0].scaleLabel.labelString = this.yAxisLabel;
      this.options.scales.yAxes[0].scaleLabel.display = true;
    }

    if (this.xAxisLabel) {
      this.options.scales.xAxes[0].scaleLabel.labelString = this.xAxisLabel;
      this.options.scales.xAxes[0].scaleLabel.display = true;
    }

    let labels = pluck(this.labelProp)(this.data);
    let values = pluck(this.valueProp)(this.data);

    // Temporary solution to mostly mitigate the ChartJS defect where it skips the penultimate
    // tick mark on the x-axis if the number of data points does not divide equally
    // TODO: remove tickCount once chartJS fixes this issue
    let tickCount = 16;
    for (let i = 16; i > 7; i--) {
      if (((values.length - i) % (i - 1) === 0) || values.length === i) {
        tickCount = i;
        break;
      }
    }

    // Work around for if there is only one data point to center the x-axis
    if (labels.length === 1) {
      labels = ['', ...labels, ''];
      values = [null, ...values, null];
    }

    this.options.scales.xAxes[0].ticks.maxTicksLimit = tickCount;

    this.chartData = {
      labels,
      datasets: [{
        data: values,
        fill: false,
        borderColor: SharedConstants.categoryColor(this.category),
        backgroundColor: SharedConstants.categoryColor(this.category),
        borderWidth: 1
      }]
    };
  }
}
