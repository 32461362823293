import * as user from '../actions/user';
import * as settings from '../actions/settings';
import { UserLocation } from '../models/location.model';
import { find, propEq } from 'ramda';
import * as moment from 'moment-timezone';
import { DiscoverConstants } from '../discover/discover.constants';

export interface State {
  adminFull?: boolean;
  adminView?: boolean;
  alertDialog?: any;
  error?: string;
  initialLocation: boolean;
  LDFlags: any;
  loading: boolean;
  locationError?: string;
  locations: any[];
  selectedLocation: UserLocation;
  userName?: string;
  userSettings: any;
  versionNumber: number;
  welcomeModal: boolean;
}

export const initialState: State = {
  initialLocation: true,
  LDFlags: {},
  loading: true,
  locations: [],
  selectedLocation: null,
  userSettings: { defaultLocation: null },
  versionNumber: null,
  welcomeModal: false
};

export function reducer(state = initialState, action: user.Actions | settings.Actions): State {
  switch (action.type) {
    case settings.ActionTypes.DEFAULT_LOCATION_UPDATED: {
      const userSettings = action.payload;
      return { ...state, userSettings };
    }

    case user.ActionTypes.DISMISS_BANNER_SUCCESS: {
      let banner = action.payload;
      if (banner === DiscoverConstants.welcomeModalBanner) {
        return { ...state, welcomeModal: false }
      }
      return state;
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_FAILURE: {
      let locationError = action.payload;
      return { ...state, loading: false, locationError };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      const selectedLocation = action.payload;
      let cfaDelivery = true;
      let doorDash = true;
      let LDFlags = state.LDFlags;
      let pickup = true;

      if (selectedLocation && selectedLocation.timeZone) {
        moment.tz.setDefault(selectedLocation.timeZone);
      }

      // Turn off the FIC tile if all 3 FIC destination option flags are false
      if (LDFlags && LDFlags.freeItemInCartCFADelivery) {
        cfaDelivery = LDFlags.freeItemInCartCFADelivery.enabledForAllLocations || (LDFlags.freeItemInCartCFADelivery.enabledForLocations
          && LDFlags.freeItemInCartCFADelivery.enabledForLocations.includes(selectedLocation.locationNumber));
      }
      if (LDFlags && LDFlags.freeItemInCartDoorDash) {
        doorDash = LDFlags.freeItemInCartDoorDash.enabledForAllLocations || (LDFlags.freeItemInCartDoorDash.enabledForLocations
          && LDFlags.freeItemInCartDoorDash.enabledForLocations.includes(selectedLocation.locationNumber));
      }
      if (LDFlags && LDFlags.freeItemInCartPickup) {
        pickup = LDFlags.freeItemInCartPickup.enabledForAllLocations || (LDFlags.freeItemInCartPickup.enabledForLocations
          && LDFlags.freeItemInCartPickup.enabledForLocations.includes(selectedLocation.locationNumber));
      }

      if (!pickup && !cfaDelivery && !doorDash) {
        const index = LDFlags.freeItemInCartEnabled.enabledForLocations.indexOf(selectedLocation.locationNumber);
        LDFlags.freeItemInCartEnabled.enabledForLocations.splice(index, 1);
      }

      return {
        ...state,
        LDFlags,
        loading: false,
        selectedLocation
      };
    }

    case user.ActionTypes.LAUNCH_DARKLY_INITIALIZED: {
      let LDFlags = action.payload;

      // Get the current time in the US eastern timezone regardless of the user's location timezone
      const timeFormat = 'HH:mm:ss';
      const easternTime = moment.tz('America/New_York').format(timeFormat);
      // Check if the time is after 11:00 PM EST or before 5:00 AM EST (nightly maintenance window)
      if (moment(easternTime,timeFormat).isSameOrAfter(moment("23:00:00", timeFormat), "hour")
          || (moment(easternTime,timeFormat).isSameOrBefore(moment("05:00:00", timeFormat), "hour"))) {
        // If it is during the maintenance window then set all the tab disable flags to true, aside from recover
        LDFlags = {
          ...LDFlags,
          disableCalendarTab: { 'enabledForAllLocations': true, 'enabledForLocations': [] },
          disableDiscoverTab: { 'enabledForAllLocations': true, 'enabledForLocations': [] },
          disableEngageTab: { 'enabledForAllLocations': true, 'enabledForLocations': [] },
          disableMeetTab: { 'enabledForAllLocations': true, 'enabledForLocations': [] },
          disableUnderstandTab: { 'enabledForAllLocations': true, 'enabledForLocations': [] }
        }
      }

      return { ...state, LDFlags }
    }

    case user.ActionTypes.SELECT_LOCATION: {
      const newLocation = action.payload;
      // For admin users the location payload will be mostly empty data
      const selectedLocation = (state.adminFull || state.adminView) ? state.selectedLocation : newLocation;
      // On initial load the default location on the state will be the same as the payload
      // For all other cases it will be different, both for admin and normal users
      const initialLocation = newLocation.locationNumber === state.selectedLocation.locationNumber;

      if (selectedLocation && selectedLocation.locationTimeZone) {
        moment.tz.setDefault(selectedLocation.timeZone);
      }

      return { ...state, selectedLocation, initialLocation, loading: true };
    }

    case user.ActionTypes.USER_DATA_ERROR: {
      let error = action.payload;

      return {
        ...state,
        error,
        loading: false
      };
    }

    case user.ActionTypes.USER_DATA_FOUND: {
      const userData = action.payload;
      let selectedLocation = userData.locations[0];
      let welcomeModal = false;

      if (selectedLocation.notificationBanners) {
        selectedLocation.notificationBanners.map(banner => {
          if (banner.id === DiscoverConstants.welcomeModalBanner) {
            welcomeModal = true;
          }
        });
      }
      if (userData && userData.userSettings && userData.userSettings.defaultLocation) {
        selectedLocation = find(propEq('locationNumber', userData.userSettings.defaultLocation))(userData.locations);
      }

      return {
        ...state,
        ...userData,
        loading: false,
        selectedLocation,
        welcomeModal
      };
    }

    default: {
      return state;
    }
  }
}

export const getAdminFull = (state: State) => state.adminFull;

export const getAdminView = (state: State) => state.adminView;

export const getAlertDialog = (state: State) => state.alertDialog;

export const getDefaultLocation = (state: State) => state.userSettings.defaultLocation;

export const getError = (state: State) => state.error;

export const getInitialLocation = (state: State) => state.initialLocation;

export const getLDFlags = (state: State) => state.LDFlags;

export const getLoading = (state: State) => state.loading;

export const getLocationError = (state: State) => state.locationError;

export const getLocations = (state: State) => state.locations;

export const getSelectedLocation = (state: State) => state.selectedLocation;

export const getUserName = (state: State) => state.userName;

export const getVersionNumber = (state: State) => state.versionNumber;

export const getWelcomeModal = (state: State) => state.welcomeModal;
