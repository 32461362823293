import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent {
  @Input() public name: string = '';
  @Input() public title: string = '';
  @Input() public imageUrl: string = '';
  @Input() public description: string = '';
  @Input() public isDraft: boolean;
  @Input() public readOnlyUser: boolean = false;
  @Input() public createdBy: string;
  @Input() public createdAt: string;

  @Output() public editClicked = new EventEmitter<any>();
  @Output() public deleteClicked = new EventEmitter<any>();
}
