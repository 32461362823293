import { Injectable } from '@angular/core';
import { AnalyticsConstants } from './analytics.constants';
import { WindowService } from '../window/window.service';

@Injectable()
export class AnalyticsService {
  public hostname: string;

  constructor(public ws: WindowService) {
    let loc = ws.getWindow().location;
    this.hostname = loc.hostname.toLowerCase();
  }

  public sendMParticleEvent(title, payload) {
    if (mParticle.IdentityType) {
      mParticle.logEvent(
        title,
        mParticle.EventType.Other,
        payload,
        {}
      );
    }
  }

  // Both new and old router will be sending analytics
  // Old router sends screen names with a '#' sign before it (ex: '#/meet')
  // New router sends screen names without a '#' sign before it (ex: '/meet')
  // If a page is defined in both routers, then it will get sent twice although
  // we can differentiate based on the '#' sign
  public logPageView(screenName, modal) {
    if (mParticle.IdentityType) {
      // Needed in order to handle mParticle's limitation of 1000 unique
      // events (screenName cannot have ids attached to them when logged)
      let screen = screenName.split('?')[0];
      mParticle.logPageView(screen, { modal });
    }
  }

  public setLocationAttributes(location) {
    if (mParticle.IdentityType) {
      this.setUserAttribute('Restaurant Name', location.locationName);
      this.setUserAttribute('Restaurant ID', location.locationNumber);
    }
  }

  public setUserAttribute(attributeName, value) {
    mParticle.setUserAttribute(attributeName, value);
  }

  public setUserIdentity(userName) {
    mParticle.setUserIdentity(userName, mParticle.IdentityType.CustomerId);
  }

  public setup(user) {
    if (mParticle.IdentityType) {
      let env = this.getEnvironment();

      this.setUserIdentity(user.userName);
      this.setUserAttribute('$environment', env);
    }
  }

  private getEnvironment() {
    if (AnalyticsConstants.dimensionMap[this.hostname]) {
      return AnalyticsConstants.dimensionMap[this.hostname].env;
    } else {
      return 'LOCALHOST';
    }
  }
}
