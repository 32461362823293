<div fxLayout="column" fxLayoutAlign="start stretch">
  <spot-nav fxFlex="100"
            title="How Automated Rewards Work"
            [actionMenu]="false"
            [isSmallNav]="true"
            [isCloseIcon]="true"
            [isMobile]="true"
            (back)="goBack()">
  </spot-nav>
  <automation-info class="automation-info-page__info"></automation-info>
</div>
