import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { clone } from 'ramda';
import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/location.model';
import { environment } from "../../environments/environment";

@Injectable()
export class UnderstandService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService
  ) {}

  public loadUnderstandTiles() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/understand/tiles`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public getUnderstandTileData(tile) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const onePlus = clone(tile.endDate).add(1, 'd');
        const fromDate = tile.startDate.format('YYYY-MM-DD');
        const toDate = onePlus.format('YYYY-MM-DD');
        const isCemCheck = tile.excludeCemRewards;
        let url =
          `/api/location/${locationNumber}/${tile.url}?fromDate=${fromDate}&toDate=${toDate}`;
        if (tile.category === 'treat') {
          url += `&isCemCheck=${isCemCheck}`;
        }
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public getUnderstandDrillDownConfig(drillDownId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        let url =
          `/api/location/${locationNumber}/understand/drilldown?drilldownId=${drillDownId}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => this.ehs.handleError(err))
    );
  }
}
