import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import * as discover from '../actions/discover';
import * as user from '../actions/user';
import * as engage from '../actions/engage';
import * as engagement from '../actions/engagement';
import { DiscoverService } from '../discover/discover.service';

@Injectable()
export class DiscoverEffects {

  @Effect()
  public loadDiscoverTiles$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      engagement.ActionTypes.SEND_COMMUNITYEVENT_SUCCESS,
      engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS,
      engage.ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR,
      engagement.ActionTypes.STOP_AUTOMATION_SUCCESS,
      engagement.ActionTypes.STOP_FREE_IN_CART_SUCCESS,
      engagement.ActionTypes.SEND_THIRD_PARTY_PROMOTION_SUCCESS,
      engage.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS,
      engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS
    ),
    switchMap(() => this.ds.loadDiscoverTiles().pipe(
      map((res) => new discover.LoadDiscoverTilesSuccessAction(res)),
      catchError((error) => of(new discover.LoadDiscoverTilesErrorAction(error)))
    ))
  );

  @Effect()
  public loadFirstTimeCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('engage-first', res)),
    switchMap(() => this.ds.loadFirstTimeCustomers().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'engage-first', error })
      ))
    )),
  );

  @Effect()
  public loadLapsedBreakfastCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('grow-breakfast', res)),
    switchMap(() => this.ds.loadLapsedBreakfastCustomers().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'grow-breakfast', error })
      ))
    )),
  );

  @Effect()
  public loadGrowDriveThruCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('grow-drive-thru-destination', res)),
    switchMap(() => this.ds.loadGrowDriveThruCustomers().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'grow-drive-thru-destination', error })
      ))
    )),
  );

  @Effect()
  public loadGrowCurbsideCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('grow-curbside-destination', res)),
    switchMap(() => this.ds.loadGrowCurbsideCustomers().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'grow-curbside-destination', error })
      ))
    )),
  );

  @Effect()
  public loadChurnCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('churn-flag', res)),
    switchMap(() => this.ds.loadChurnCustomers().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'churn-flag', error })
      ))
    )),
  );

  @Effect()
  public loadMaximizeRedemption$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('maximize-redemption', res)),
    switchMap(() => this.ds.loadMaximizeRedemption().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'maximize-redemption', error })
      ))
    )),
  );

  @Effect()
  public loadOldReward$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    filter((res) => this.ds.findById('operator-led-delivery', res)),
    switchMap(() => this.ds.loadOldReward().pipe(
      map((res) => new discover.LoadTileDataSuccessAction(res)),
      catchError((error) => of(
        new discover.LoadTileDataErrorAction({ id: 'operator-led-delivery', error })
      ))
    )),
  );

  @Effect()
  public loadFilters$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    switchMap((tiles) => this.ds.loadFilters(tiles).pipe(
      mergeMap((filters) => {
        let actionArr = [];
        if (filters['recognize-catering']) {
          actionArr.push(
            new discover.LoadTileDataSuccessAction({
              id: 'recognize-catering',
              ...filters['online'][0]
            }));
        }
        if (filters['cfa-one-filters']) {
          actionArr.push(
            new discover.LoadTileDataSuccessAction({
              id: 'cfa-one-filters',
              filters: filters['cfa']
            }));
        }
        return actionArr;
      }),
      catchError((error) => of(new discover.LoadTileFiltersErrorAction(error)))
    )),
  );

  @Effect()
  public loadCampaignErrorsTile$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS),
    map((action: any) => action.payload),
    map((res) => this.ds.getTileById('error-tile', res)),
    map((res) => new discover.LoadTileDataSuccessAction(res)),
    catchError((error) => of(
      new discover.LoadTileDataErrorAction({ id: 'error-tile', error })
    ))
  );

  @Effect()
  public markResolvedFailedCampaign$: Observable<Action> = this.actions$.pipe(
    ofType(discover.ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED),
    map((action: any) => action.payload),
    switchMap((req) => this.ds.markResolvedFailedCampaign(req).pipe(
      map((res) => new discover.FailedCampaignMarkResolvedSuccess(res)),
      catchError((error) => of(
        new discover.FailedCampaignMarkResolvedFailure({ id: 'error-tile', error }),
      ))
    ))
  );

  constructor(
    private actions$: Actions,
    private ds: DiscoverService
  ) { }
}
