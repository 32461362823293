import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedConstants } from '../shared.constants';

@Component({
  selector: 'app-community-care-confirmation-dialog',
  templateUrl: './community-care-confirmation-dialog.component.html',
  styleUrls: ['./community-care-confirmation-dialog.component.scss']
})

export class CommunityCareConfirmationDialogComponent implements OnInit {

  public givingStrategyUrl = SharedConstants.givingStrategyUrl;
  public operatingStandardUrl = SharedConstants.operatingStandardUrl;
  public confirmationButton = SharedConstants.CCEConfirmationButton;
  public goBackButton = SharedConstants.CCEGoBackButton;

  constructor(
      @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
      public dialogRef: MatDialogRef<CommunityCareConfirmationDialogComponent>) {}

  ngOnInit(): void { }

  public closeDialog(shouldSend: boolean) {
    this.dialogRef.close(shouldSend);
  }
}
