<div class="welcome-dialog">
  <div [cfaLoading]="discoverTilesLoading$ |async">
    <ng-container *ngIf = "!(isMobile$ | async)">
      <div>
        <h4 class="welcome-dialog__header">Welcome to Spotlight!</h4>
        <p class = "welcome-dialog__subtext">The restaurant tool to connect with your unique, local audience. Please select an option below to get started.</p>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-around" style="margin: 20px">
        <div *ngFor="let button of welcomeButtons">
          <button mat-icon-button class ="welcome-dialog__button"  (click)="closeDialog(button.buttonPath)">
            <div fxLayout="row" fxLayoutAlign="space-around">
              <img alt="Welcome Button" class="welcome-dialog__button-img" src="{{button.imageUrl}}"/>
              <span class="welcome-dialog__button-text">{{button.title}}</span>
              <help-text [helpText]="button.description" [displayOnLeft]="true" [blockDisplay]="true"></help-text>
            </div>
          </button>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="(isMobile$ | async)">
      <div >
        <h4 class="welcome-dialog__header-mobile">Welcome to Spotlight!</h4>
        <p class = "welcome-dialog__subtext-mobile">The restaurant tool to connect with your unique, local audience. Please select an option below to get started.</p>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-around">
        <div *ngFor="let button of welcomeButtons">
          <button  mat-icon-button class ="welcome-dialog__button-mobile"  (click)="closeDialog(button.buttonPath)">
            <div fxLayout="row" fxLayoutAlign="space-around">
              <img alt="Welcome Button" class="welcome-dialog__button-img" src="{{button.imageUrl}}"/>
              <span class="welcome-dialog__button-text">{{button.title}}</span>
              <help-text [helpText]="button.description" [displayOnLeft]="true" [blockDisplay]="true"></help-text>
            </div>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class = "welcome-dialog__or-text"> or </div>
    </ng-container>

    <ng-container>
      <div>
        <button class= "welcome-dialog__cancel-button" (click)="closeDialog('/discover')">Take me to Spotlight Discover</button>
      </div>
    </ng-container>
  </div>

</div>
