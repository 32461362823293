<mat-card class="understand-card" *ngIf="engageAnalyticsShowCheckboxTile">
  <mat-card-header class="understand-card__header-treat"></mat-card-header>
  <mat-card-content class="understand-card__checkbox-section">
    <mat-checkbox [checked]="isExcludeCemRewardsChecked"
                  (change)="excludeCemRewardsChecked($event.checked)">
      <div class="understand-card__analytics-checkbox-font">
          Exclude CEM Rewards
      </div>
    </mat-checkbox>
  </mat-card-content>
  <div class="understand-card__bottom-treat understand-card__bottom-blank-section">
  </div>
</mat-card>
<mat-card class="understand-card" *ngIf="!engageAnalyticsShowCheckboxTile && tile">
    <mat-card-content class="understand-card__loading" *ngIf="!tile.error && tile.loading">
        <content-placeholder></content-placeholder>
    </mat-card-content>
    <div *ngIf="!tile.loading">
        <mat-card-header class="understand-card__header-{{tile.category}}">
            <mat-card-title class="understand-card__title">
                {{tile.title}}
            </mat-card-title>
            <div fxFlex></div>
            <help-text
                class="understand-card__help"
                *ngIf="tile.helpText"
                [helpText]="tile.helpText"
                [displayOnLeft]="true">
            </help-text>
        </mat-card-header>
        <mat-card-content *ngIf="tile.error" class="understand-card__error-content">
            <error-message class="understand-card__error"
                           [error]="tile.error"
                           [page]="getPageTitle()">
            </error-message>
        </mat-card-content>
        <div *ngIf="!tile.error">
            <mat-card-content
                class="understand-card__content"
                [ngClass]="{'understand-card__data-content': (hasData(tile.data) && !tile.drillDownDisabled)}">
                <ng-content></ng-content>
                <div *ngIf="!hasData(tile.data)" fxLayout="row" fxLayoutAlign="center center">
                    <div class="understand-card__no-data-message">
                        <span class="understand-card__top-message">No {{tile.title | titlecase}} data was found.</span>
                        <br>
                        <span class="understand-card__bottom-message">Try another date range or location.</span>
                    </div>
                </div>
            </mat-card-content>
        </div>
      <div class="understand-card__bottom-button"
           *ngIf="tile.buttonText && hasData(tile.data)"
           (click)="onClicked($event)">
          {{tile.buttonText}}
      </div>
    </div>
</mat-card>
