import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscoverTile } from '../../reducers/discover';
import { DiscoverConstants } from '../discover.constants';

@Component({
  selector: 'discover-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class DiscoverCardComponent {
  public communityCareEvent = DiscoverConstants.categoryCommunityCare;
  public thirdPartyPromotion = DiscoverConstants.thirdPartyPromotion;
  public freeItemInCartPromotion = DiscoverConstants.freeItemInCartPromotion;


  @Input() public tile: DiscoverTile;
  @Input() public isSendingDisabled: boolean;
  @Input() public isRewardsSendingDisabled: boolean;

  @Output() public sendEmail = new EventEmitter();
  @Output() public sendReward = new EventEmitter();
  @Output() public sendPromotion = new EventEmitter();
  @Output() public setUpAutomation = new EventEmitter();
  @Output() public editAutomation = new EventEmitter();
  @Output() public stopAutomation = new EventEmitter();
  @Output() public stopCommunityCareEvent = new EventEmitter();
  @Output() public learnMore = new EventEmitter();
  @Output() public sendCommunityCare = new EventEmitter();
  @Output() public stopPromotion = new EventEmitter();

  public getPageTitle() {
    return `Discover Tile: ${this.tile.id}`;
  }
}
