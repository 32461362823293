<div class="note">
  <div>
    <span *ngIf="!note.updatedBy" class="note__name" [ngClass]="{'note__name-error': note.error && note.isEditMode }">
      {{note.createdBy}}
    </span>
    <span *ngIf="note.updatedBy" class="note__name" [ngClass]="{'note__name-error': note.error && note.isEditMode }">
      {{note.updatedBy}}
    </span>
    <span *ngIf="!note.isNewNote" class="note__date"> - {{note.updatedAt | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}</span>
  </div>
  <div *ngIf="!note.isEditMode && !note.isNewNote">
    <div class="note__text">{{note.note}}</div>
    <div *ngIf="!disableChanges" fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="!readOnly" class="note__edit-button" (click)="editNote()">Edit Note</div>
      <div *ngIf="deleteEnabled" class="note__delete-button" (click)="deleteNote()">Delete Note</div>
    </div>
  </div>
  <div *ngIf="note.isEditMode || note.isNewNote" class="note__edit-container" fxLayout="column" fxLayoutAlign="space-around start">
    <mat-hint *ngIf="subText" class="note__sub-text" align="left">{{subText}}</mat-hint>
    <div class="note__full-width-container">
      <mat-form-field class="note__full-width-container" floatLabel="never">
        <mat-placeholder class="note__placeholder">Add a note</mat-placeholder>
        <textarea #textInput
                  matInput
                  cdkTextareaAutosize
                  maxlength="500"
                  rows="5"
                  class="note__text-area"
                  [ngClass]="{'note__text-area-error': note.error}"
                  [ngModel]="note.updatedText"
                  (keyup)="updateText($event)">
        </textarea>
        <mat-hint class="note__disclaimer" align="left">
          *Do not enter sensitive guest details such as credit card numbers, emails, etc.
        </mat-hint>
        <mat-hint class="note__character-count" align="end">{{note.updatedText.length}} / 500</mat-hint>
      </mat-form-field>
    </div>
    <div *ngIf="note.error" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="note__error-icon">warning</mat-icon>
      <div class="note__error-text">We were unable to save your note. Please try again later.</div>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end center"
         class="note__full-width-container">
      <button mat-button
              class="note__cancel-button"
              (click)="cancelEdit()">
        Cancel
      </button>
      <button mat-raised-button
              class="note__save-button"
              [disabled]="note.updatedText.trim().length < 1"
              (click)="saveNote()">
        Save
      </button>
    </div>
  </div>
</div>
