import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})

export class SnackbarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar) {}
  public ngOnInit() {
    if (this.data.fadeOut) {
      setTimeout(function() {
        Array.from(document.getElementsByClassName('fade-out')).map((snackbar) => {
          snackbar.classList.add('hidden');
        });
      }, 4000);
    }
  }
}
