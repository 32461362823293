import { Action } from '@ngrx/store';
import { type } from '../util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */
export const ActionTypes = {
  CHANGED_VIEW_SIZE:            type('[Layout] Changed view size')
};

export class ChangedViewSizeAction implements Action {
  public type = ActionTypes.CHANGED_VIEW_SIZE;

  constructor(public payload: string) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions = ChangedViewSizeAction;
