<spot-nav [title]="(title$ | async) | titlecase"
          [isSmallNav]="isMobile$ | async"
          [isMobile]="isMobile$ | async"
          [isBackChevronDT]="true"
          (back)="checkForUnsavedChanges(goBack)">
</spot-nav>
<div class="copy-review__wrapper">
    <h3 class="copy-review__review">Review Message</h3>
    <mat-tab-group class="copy-review__tab-group" [selectedIndex]="index">
        <mat-tab label="Email">
            <span class="copy-review__required-message">Customized message (required)</span>
            <text-box [text]="emailCopy"
                      (textChanged)="emailMessageUpdate($event)">
            </text-box>
        </mat-tab>
        <mat-tab *ngIf="(engagementType$ | async) !== emailType" label="Chick-fil-A App">
            <span class="copy-review__required-message">Customized message for Chick-fil-A App (required)</span>
            <mat-form-field style="width:100%;">
                <textarea matInput
                          maxlength="140"
                          #cfaInput
                          matAutosizeMinRows="5"
                          mdAutoSizeMaxRows="10"
                          rows="3"
                          class="copy-review__textarea"
                          [value]="appCopy"
                          (keyup)="appMessageUpdate($event)">
                </textarea>
                <mat-hint align="end">{{cfaInput.value.length}} / 140</mat-hint>
            </mat-form-field>
        </mat-tab>
        <mat-tab *ngIf="(engagementType$ | async) !== emailType && (isPushNotificationEnabled$ | async)">
            <ng-template mat-tab-label>
                <div class="copy-review__wrap-label">
                  <span>Push Message</span>
                  <span>(View Only)</span>
                </div>
            </ng-template>
            <div class="copy-review__push-message">
                <span class="copy-review__message-height"><b>{{pushMessageTitle$ | async}}</b></span><br>
                <span>{{pushMessageText$ | async}}</span>
           </div>
           <span class="copy-review__required-message">{{pushMessageWarningText}}</span>
        </mat-tab>
    </mat-tab-group>
    <div [ngClass]="{'copy-review__button-container-mobile' : isMobile$ | async}">
        <button *ngIf="(!emailMessageChanges && !appMessageChanges) || isEmpty()" mat-button (click)="goBack()" class="copy-review__back-button">
            Back
        </button>
        <button *ngIf="(emailMessageChanges || appMessageChanges) && !isEmpty()"
                mat-button
                class="copy-review__apply-button"
                (click)="apply()">
            Apply
        </button>
    </div>
</div>
