import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { SharedConstants } from '../../shared/shared.constants';
import { Router } from '@angular/router';
import * as engagement from '../../actions/engagement';
import { DiscoverConstants } from '../discover.constants';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {

  public welcomeButtons;
  public star: string = SharedConstants.starIcon;
  public isMobile$ : Observable<any>;
  public discoverTilesLoading$: Observable<boolean>;
  private tile;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: Observable<any>,
              public dialogRef: MatDialogRef<WelcomeDialogComponent>,
              private store: Store<fromRoot.State>, private router: Router,) {
    dialogData.subscribe(buttons => this.welcomeButtons = buttons)

  }

  ngOnInit(): void {
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    this.discoverTilesLoading$ = this.store.select(fromRoot.getDiscoverLoading)
  }

  public closeDialog(path) {
    if (!path.includes('tile')) {
      this.router.navigate([path]);
    } else {
      this.store.select(fromRoot.getDiscoverTiles).subscribe(tiles => {this.tile = tiles.filter(tile => tile.id == path.slice(5))[0]})
      if (this.tile.automated) {
        let queryParams = {
          type: this.tile.id,
          mode: 'review',
          id: this.tile.bonusPointPromotionId
        };
        this.router.navigate(['/engage/engage-details'],{ queryParams });
      } else {
        this.store.dispatch(new engagement.StartOnePageFlow({ tile: this.tile, engagementType: DiscoverConstants.promotion }))
      }
    }
    this.dialogRef.close();
  }

}
