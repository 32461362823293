import { Component, OnInit } from '@angular/core';
import { browserRefreshed } from '../../../app.component';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { Observable } from 'rxjs';
import { DiscoverTile } from '../../../reducers/discover';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SharedConstants } from 'spotlight-ui/app/shared/shared.constants';

import * as engagement from '../../../actions/engagement';
import {
  CommunityCareConfirmationDialogComponent
} from '../../../shared/community-care-confirmation-dialog/community-care-confirmation-dialog.component';

@Component({
  templateUrl: './edit-community-care-detail.component.html',
  styleUrls: ['./edit-community-care-detail.component.scss']
})
export class DiscoverEditCommunityCareDetailComponent implements OnInit {
  public tile$: Observable<DiscoverTile>;
  public pageTitle$: Observable<string>;
  public campaignName$: Observable<string>;
  public engagementType$: Observable<string>;
  public engagementMode$: Observable<string>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public readOnlyUser$: Observable<boolean>;
  public organizations$: Observable<Array<any>>;
  public selectedLocation$: Observable<any>;
  public optInButtons$: Observable<any>;
  public selectedOptIn$: Observable<any>;
  public datesAndTimes$: Observable<any>;
  public disabledDays$: Observable<any>;
  public disabledTimes$: Observable<any>;


  constructor(private router: Router, private store: Store<fromRoot.State>, private dialog: MatDialog) { }

  public ngOnInit() {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.pageTitle$ = this.tile$.pipe(map((tile) => tile.title));
      this.readOnlyUser$ = this.store.select(fromRoot.getReadOnlyUser);
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.engagementMode$ = this.store.select(fromRoot.getEngagementMode);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);

      this.datesAndTimes$ = this.store.select(fromRoot.getEngagementDatesAndTimes);
      this.disabledDays$ = this.store.select(fromRoot.getEngagementDisabledDays);
      this.disabledTimes$ = this.store.select(fromRoot.getEngagementDisabledTimes);

      this.selectedLocation$ = this.store.select(fromRoot.getSelectedLocation);

      this.optInButtons$ = this.store.select(fromRoot.getEngagementOptInOptionButtons);
      this.selectedOptIn$ = this.store.select(fromRoot.getEngagementSelectedOptIn);
      this.campaignName$ = this.store.select(fromRoot.getEngagementCampaignName);
      this.organizations$ = this.store.select(fromRoot.getEngagementOrganizations);
    }
  }

  public goToOverlappingEvents() {
    this.store.dispatch(new engagement.GoToCommunityCareOverLappingEngagement());
    this.store.dispatch(new engagement.SelectOverLappingEngagementType(SharedConstants.discoverPage));
    this.router.navigate(['/discover/edit/overlapping-engagements']);
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }

  public onAnyEngagementChange(engagementPayload: any) {
    this.store.dispatch(new engagement.UpdateEngagement(engagementPayload))
  }

  public onAnyDateChange(datePayload) {
    this.store.dispatch(new engagement.UpdateEngagementDates(datePayload));
  }

  public onStartTimeChange(timePayload) {
    this.store.dispatch(new engagement.UpdateEngagementStartTime(timePayload));
  }

  public goToPreviewPage() {
    this.router.navigate(['/discover/edit/email-preview']);
  }

  public sendCommunityEvent() {
    this.dialog.open(CommunityCareConfirmationDialogComponent, { }).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.SendEngagement());
      }
    });
  }
}
