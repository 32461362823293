import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'treat-summary',
  templateUrl: './treat-summary.component.html',
  styleUrls: ['./treat-summary.component.scss']
})

export class TreatSummaryComponent {
  @Input() public title: string;
  @Input() public infoText: string;
  @Input() public imageURL: string;
  @Input() public details: string;
  @Input() public isUpdated: boolean;
  @Input() public type: string;
  @Input() public mode: string;
  @Input() public isMultiReward: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public isDisabled: boolean;

  @Output() public onSummaryClick = new EventEmitter<any>();

  public summaryClicked() {
    if (!this.isDisabled) {
      this.onSummaryClick.emit();
    }
  }
}
