import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AnalyticsService } from '../../core/analytics';
import {
  DialogOpened, FetchActiveRewardCustomers, ToggleActiveRewardCustomers
} from '../../actions/active-rewards';
import * as fromRoot from '../../reducers';
import { EngageConstants } from '../../engage/engage.constants';

@Component({
  selector: 'active-reward-dialog',
  templateUrl: 'active-reward-dialog.component.html',
  styleUrls: ['./active-reward-dialog.component.scss']
})

export class ActiveRewardDialogComponent implements OnInit {
  public activeRewardCustomers$: Observable<any>;
  public loading$: Observable<boolean>;
  public error$: Observable<any>;
  public toggleChecked: boolean = false;
  public startDate;
  public endDate;
  public count;
  public rewardLength;
  public isNotImmediateSend: boolean = false;
  public isDynamicGroupSend: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<ActiveRewardDialogComponent>,
              private analytics: AnalyticsService) {

    this.activeRewardCustomers$ = store.select(fromRoot.getActiveRewardCustomers);
    this.loading$ = store.select(fromRoot.getActiveRewardLoading);
    this.error$ = store.select(fromRoot.getActiveRewardError);
  }

  public ngOnInit() {
    this.store.dispatch(new DialogOpened());

    let ids = this.dialogData.ids;
    this.startDate = this.dialogData.startDate;
    this.isDynamicGroupSend = this.dialogData.isDynamicGroupSend;
    this.endDate = this.dialogData.endDate;
    this.isNotImmediateSend = this.dialogData.isNotImmediateSend;
    this.rewardLength = this.endDate.diff(this.startDate, 'days');
    this.rewardLength = this.rewardLength === 0 ? 1 : this.rewardLength;
    this.activeRewardCustomers$.subscribe((activeRewardCustomers) => {
      this.count = this.dialogData.count - activeRewardCustomers.length;
    });

    if (!this.dialogData.confirmationOnly) {
      this.store.dispatch(new FetchActiveRewardCustomers(
        { ids, startDate: this.startDate, endDate: this.endDate }));
    }
  }

  public slideToggled(toggle: MatSlideToggleChange) {
    this.toggleChecked = toggle.checked;
    this.activeRewardCustomers$.subscribe((activeRewardCustomers) => {
      this.count = this.toggleChecked ? this.count + activeRewardCustomers.length :
        this.count - activeRewardCustomers.length;
    });
    this.store.dispatch(new ToggleActiveRewardCustomers(this.toggleChecked));
  }

  public closeDialog(shouldSend: boolean) {
    if (shouldSend) {
      this.analytics.sendMParticleEvent(
        'Include Active Reward Customers Toggle When Sending',
        {
          'Toggled to': this.toggleChecked,
        }
      );
    }
    this.dialogRef.close(shouldSend);
  }

  public sendDisabled(loading, count) {
    return (loading || count === 0);
  }
}
