<ng-container *ngIf="!(loading$ | async) && !(this.discoverTilesLoading$ | async) && !(error$ | async)">
  <div fxLayout="row" fxLayoutAlign="none center">
    <spot-nav fxFlex="100"
              [actionMenu]="false"
              [hideBackArrow]="!(isMobile$ | async)"
              [isMobile]="isMobile$ | async"
              [isSmallNav]="true"
              [title]="title$ | async"
              (back)="goToDiscover()">
    </spot-nav>
  </div>

  <app-third-party-promotion [customerTypes]="customerTypes$ | async"
                             [disabledDays]="disabledDays$ | async"
                             [allDaysDisabled]="allDaysBlocked$ | async"
                             [endDate]="endDate$ | async"
                             [endDateRestriction]="endDateRestriction$ | async"
                             [isMobile]="isMobile$| async"
                             [platforms]="availablePlatforms$ | async"
                             [rewardCategories]="rewardCategories$ | async"
                             [rewardList]="rewardList$ | async"
                             [selectedReward]="selectedReward$ | async"
                             [campaignName]="campaignName$ | async"
                             [startDate]="startDate$ | async"
                             [tile]="tile$ | async"
                             [enableTermsAndConditions] = "termsAndConditionsEnabled$ | async"
                             (thirdPartyCustomerTypesUpdate)="promotionCustomerTypesUpdate($event)"
                             (thirdPartyDateUpdate)="promotionDateUpdated($event)"
                             (thirdPartyPlatformUpdated)="promotionPlatformsUpdated($event)"
                             (thirdPartyPromotionUpdated)="promotionUpdated($event)"
                             (thirdPartyUpdateReward)="updateReward($event)"
                             (thirdPartyUpdateCampaignName)="updateCampaignName($event)"
                             (onGoToPreview)="goToPreviewPage()"
                             (onSendThirdPartyPromotion)="sendThirdPartyPromotion()">
  </app-third-party-promotion>
</ng-container>

