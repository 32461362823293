<div class = "read-more__text">
  <span>{{display}}</span>
  <br>
  <span class="read-more__button" *ngIf="!readMoreExpanded && this.continuedString.length != 0" (click)="expandReadMore($event)">Read more</span>
</div>
<div *ngIf="readMoreExpanded" class = "read-more__text" >
  {{continuedString}}
  <span class="read-more__button" (click)="collapseReadMore($event)">Read less</span>
</div>

