import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import * as fromRoot from '../reducers';
import * as R from 'ramda';
import * as fromProfile from '../actions/profile';
import { ProfileConstants } from '../customer-profile/customer-profile.constants';
import { CustomerProfileService } from '../customer-profile/customer-profile.service';
import { UserService } from '../core/user';

@Injectable()
export class ProfileEffects {

  @Effect()
  public fetchCustomerInfoIfNeeded: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    filter(([payload, state]) => {
      return this.shouldFetchData(state.profile[payload.id], ProfileConstants.customerInfo);
    }),
    switchMap(([payload]) => {
      const id = payload.id;
      const type = ProfileConstants.customerInfo;
      return this.cps.getCustomerInformation(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data, actionMenuLoading: false })),
        catchError((err) => this.catchError(id, type, err, 'Customer Information'))
      );
    })
  );

  @Effect()
  public fetchCemDataIfNeeded: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.CEM_FETCH,
      fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH
    ),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    filter(([payload, state]) => {
      return this.shouldFetchData(state.profile[payload.id], ProfileConstants.cemData);
    }),
    switchMap(([payload]) => {
      const id = payload.id;
      const type = ProfileConstants.cemData;
      return this.cps.getCEMData(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'CEM Data'))
      );
    })
  );

  @Effect()
  public fetchCaresDataIfNeeded: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.CARES_FETCH,
      fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH
    ),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    filter(([payload, state]) => {
      return this.shouldFetchData(state.profile[payload.id], ProfileConstants.caresData);
    }),
    switchMap(([payload]) => {
      const id = payload.id;
      const type = ProfileConstants.caresData;
      return this.cps.getCaresData(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'CARES Data'))
      );
    })
  );

  @Effect()
  public fetchDigitalRefundsData: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.digitalRefundsData;
      return this.cps.getDigitalRefundsData(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Digital Refunds Data'))
      );
    })
  );

  @Effect()
  public fetchCateringData: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.FETCH_CATERING_DATA),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.cateringData;
      return this.cps.loadCateringData(payload.id, payload.transactionDateRange).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Catering Transaction Details'))
      );
    })
  );

  @Effect()
  public fetchTransactionStats: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.FETCH_TRANSACTION_DATA,
      fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH
    ),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.transactionStats;
      return this.cps.loadTransactionStats(payload.id, payload.transactionDateRange).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Transaction Stats'))
      );
    })
  );

  @Effect()
  public fetchTransactionHistory: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.FETCH_TRANSACTION_DATA,
      fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH
    ),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.transactionHistory;
      return this.cps.loadTransactionHistory(payload.id, payload.transactionDateRange).pipe(
        map((data) => new fromProfile.FetchTransactionHistorySuccess({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Transaction History'))
      );
    })
  );

  @Effect()
  public fetchOffers: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.offers;
      return this.cps.getCustomerOffers(payload.id).pipe(
        map((data) => new fromProfile.FetchOffersSuccess({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Customer Offers'))
      );
    })
  );

  @Effect()
  public fetchActionHistory: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.id;
      const type = ProfileConstants.actionHistory;
      return this.cps.getActionHistory(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'CARES Action History'))
      );
    })
  );

  @Effect()
  public fetchRecoverEmailDetails: Observable<Action> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.FETCH_RECOVER_EMAIL_DETAILS),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.customerId;
      const responseId = payload.responseId;
      const type = ProfileConstants.recoverEmail;
      return this.cps.getRecoverEmailDetails(responseId).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'CARES Recover Email Details'))
      );
    })
  );

  @Effect()
  public reloadCustomerInfo: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.NOTE_ACTION_COMPLETE,
      fromProfile.ActionTypes.DELETE_NOTE_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap((payload) => {
      const id = payload.data.id;
      const type = ProfileConstants.customerInfo;
      return this.cps.getCustomerInformation(id).pipe(
        map((data) => new fromProfile.FoundDataAction({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'Customer Information Only'))
      );
    })
  );

  @Effect()
  public createNote: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.CREATE_NOTE),
    map((action: any) => action.payload),
    switchMap((noteData) => {
      const id = noteData.id;
      const type = ProfileConstants.customerInfo;
      return this.cps.createNote(noteData.noteObj).pipe(
        map((res) => new fromProfile.NoteCompleteAction({ id, type, data: res, noteId: noteData.noteObj.noteId })),
        catchError((err) => of(new fromProfile.NoteErrorAction({ id, type, err, noteId: noteData.noteObj.noteId })))
      );
    })
  );

  @Effect()
  public updateNote: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.UPDATE_NOTE),
    map((action: any) => action.payload),
    switchMap((noteData) => {
      const id = noteData.id;
      const type = ProfileConstants.customerInfo;
      return this.cps.updateNote(noteData.noteObj).pipe(
        map((res) => new fromProfile.NoteCompleteAction({ id, type, data: res, noteId: noteData.noteObj.noteId })),
        catchError((err) => of(new fromProfile.NoteErrorAction({ id, type, err, noteId: noteData.noteObj.noteId })))
      );
    })
  );

  @Effect()
  public deleteNote: Observable<any> = this.actions$.pipe(
    ofType(fromProfile.ActionTypes.DELETE_NOTE),
    map((action: any) => action.payload),
    switchMap((noteData) => {
      const id = noteData.id;
      const type = ProfileConstants.customerInfo;
      return this.cps.deleteNote(noteData.noteObj).pipe(
        map((res) => new fromProfile.DeleteNoteSuccess({ id, type, data: res })),
        catchError((err) => of(new fromProfile.ErrorAction({ id, type, err })))
      );
    })
  );

  @Effect()
  public fetchDoorDashRecoveryData: Observable<any> = this.actions$.pipe(
    ofType(
      fromProfile.ActionTypes.DOORDASH_RECOVERY_FETCH,
      fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH
    ),
    map((action: any) => action.payload),
    concatLatestFrom(() => {
      return [
        this.store,
        this.store.select(fromRoot.getFeatureEnabled(ProfileConstants.doorDashRecovery))
      ]
    }),
    filter(([payload, state, isEnableDoorDashRecovery]) => {
      return isEnableDoorDashRecovery && this.shouldFetchData(state.profile[payload.id], ProfileConstants.doorDashRecoveryData);
    }),
    switchMap(([payload]) => {
      const id = payload.id;
      const type = ProfileConstants.doorDashRecoveryData;
      return this.cps.getDoorDashRecoveryData(id).pipe(
        map((data) => new fromProfile.FetchDoorDashRecoveryDataSuccess({ id, type, data })),
        catchError((err) => this.catchError(id, type, err, 'DoorDash Recovery Data'))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
    private us: UserService,
    private cps: CustomerProfileService
  ) {}

  private shouldFetchData(profile, type) {
    if (!profile) {
      return true;
    } else {
      const profileData = profile[type];
      if (!profileData || R.isEmpty(profileData.data)) {
        return true;
      } else if (profileData.loading) {
        return true;
      } else {
        return profileData.error;
      }
    }
  }

  private catchError(id, type, error, dataType: string) {
    let err = `${error.status} : There was an error loading ${dataType}.`;
    return of(new fromProfile.ErrorAction({ id, type, err }));
  }
}
