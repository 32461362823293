import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent {
  @Input() public buttons: any[] = [];
  @Input() public isFullWidth: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public disabled: boolean = false;

  @Output() public buttonToggled = new EventEmitter<any>();
}
