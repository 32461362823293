import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import * as manageAttendees from '../actions/manage-attendees';
import { ManageAttendeesService } from '../manage-attendees/manage-attendees.service';
import { getManageAttendeesUpdatePayload } from '../reducers';
import { State } from '../reducers/engage';

@Injectable()
export class ManageAttendeesEffects {
  @Effect()
  public FetchAttendeeData$: Observable<Action> = this.actions$.pipe(
    ofType(manageAttendees.ActionTypes.FETCH_ATTENDEE_DATA),
    map((action: any) => action.payload),
    switchMap((id) => this.ms.getAttendeeData(id)),
    map((res) => new manageAttendees.FetchAttendeeDataSuccess(res)),
    catchError((error) => of(new manageAttendees.FetchAttendeeDataFailure(error)))
  );

  @Effect()
  public UpdateAttendeeData$: Observable<Action> = this.actions$.pipe(
    ofType(manageAttendees.ActionTypes.UPDATE_ATTENDEE_DATA),
    map((action: any) => action.payload),
    switchMap((id) => this.store.select(getManageAttendeesUpdatePayload(id)).pipe(take(1))),
    switchMap((payload) => this.ms.updateAttendeeData(payload)),
    map((res) => new manageAttendees.UpdateAttendeeDataSuccess(res)),
    catchError((error) => of(new manageAttendees.UpdateAttendeeDataFailure(error)))
  );

  @Effect({ dispatch: false })
  public UpdateAttendeeDataSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(manageAttendees.ActionTypes.UPDATE_ATTENDEE_DATA_SUCCESS),
    map((action: any) => action.payload),
    tap((payload) => {
      return this.router.navigate(
        ['/manage-attendees/review-attendees'],
        { queryParams: { id: payload.id } }
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private ms: ManageAttendeesService
  ) { }
}
