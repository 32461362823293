import { Component, Input } from '@angular/core';

@Component({
  selector: 'stat-section',
  templateUrl: './sections.component.html'
})

export class SectionsComponent {
  @Input() public data: any = {};
  @Input() public category: string = '';
  @Input() public isPrimary: boolean = false;
  @Input() public section: any = {};
  @Input() public isMobile: boolean = false;

  public get slicedData() {
    if (this.data != null && this.data.length >= 5) {
      return this.data.slice(0, 5);
    } else {
      return this.data;
    }
  }
}
