<div [ngClass]="{'email-copy__header': !isRestyle, 'email-copy__customize-header': isRestyle}">
    {{headerText}}
</div>
<mat-nav-list class="email-copy__list">
    <mat-list-item (click)="goToEmailCopy()">
        <h4 matLine *ngIf="emailCopyText" [ngClass]="{'email-copy__copy-text': showGreyText , 'email-copy__copy': isEmailCopyUpdated}">
            {{emailCopyText}}
        </h4>
        <span matLine *ngIf="emailCopy" class="email-copy__copy">
            {{emailCopy}}
         </span>
        <button mat-icon-button>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
    <mat-divider class="email-copy__divider" *ngIf="displayAppOption"></mat-divider>
    <mat-list-item (click)="goToAppCopy()" *ngIf="displayAppOption">
        <h4 matLine [ngClass]="{'email-copy__copy-text' : isAppCopyUpdated}">
            {{appCopyText}}
        </h4>
        <span matLine *ngIf="appCopy" class="email-copy__copy">
            {{appCopy}}
        </span>
        <button mat-icon-button>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
    <mat-divider class="email-copy__divider" *ngIf="displayAppOption && displayPushMessageOption"></mat-divider>
    <mat-list-item (click)="goToPushMessageCopy()" *ngIf="displayAppOption && displayPushMessageOption">
        <h4 matLine [ngClass]="{'email-copy__copy-text' : isPushMessageUpdated}">
            {{pushMessageText}}
        </h4>
        <span matLine *ngIf="pushMessageCopy" class="email-copy__copy">
            {{pushMessageCopy}}
        </span>
        <button mat-icon-button>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
</mat-nav-list>
