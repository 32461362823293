import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsoleErrorHandlerService } from './console-error-handler.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: ErrorHandler, useClass: ConsoleErrorHandlerService }
  ]
})
export class ConsoleErrorHandlerModule {}
