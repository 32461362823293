<div class="ngx-data-table">
  <data-table-header [rowIdProp]="rowIdProp"
                     [isLoading]="isLoading"
                     [columns]="columns"
                     [rows]="rows"
                     [showCheckboxes]="false"
                     [canSelectAll]="false"
                     [useFullMobileTable]="true"
                     [isMobile] = "true">
  </data-table-header>

  <!--Data table body-->
  <div class="flex-container"
       fxLayout="row"
       fxLayoutAlign="start center"
       *ngIf="!isDataAvailable() && !isLoading">
    <data-table-error [errorText]="noResultsMessage"
                      [actionableText]="actionableErrorText"
                      (textClick)="errorTextClicked()">
    </data-table-error>
  </div>
  <div *ngIf="isDataAvailable()">
    <div class="flex-container mobile-data-table-full__row"
         [ngClass]="{'ngx-data-table__row-bottom-border' : row.bottomBorder,
                     'ngx-data-table__blue-row-background' : (i % 2  !== 0),
                     'mobile-data-table-full__large-row': !useSmallRowSize }"
         fxLayout="row wrap"
         *ngFor="let row of rows; let i = index"
         [id]="row[rowId]">
      <div fxLayout="row" fxFlex="100">
        <div [fxFlex]="getColSize(col)" *ngFor="let col of columns">
          <span *ngIf="!col.hide" [ngClass]="{
          'ngx-data-table__row-disabled': shouldDisableRow(row),
          'ngx-data-table__center-column': col.centerContent}"
                class="flex-item"
                (click)="rowClicked(row)">
            <data-table-cell class="mobile-data-table-full__data-cell"
                             [row]="row"
                             [col]="col"
                             [shouldDisableButton]= "shouldDisableRow(row)"
                             [useFullMobileTable]="true">
            </data-table-cell>
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="10" *ngIf="isDetailView && !row.open">
          <img alt="Chevron Icon Facing Down"
               class="mobile-data-table-full__chevron-icon-down"
               (click)="openDetail(row)"
               src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="10" *ngIf="isDetailView && row.open">
          <img alt="Chevron Icon Facing Up"
               class="mobile-data-table-full__chevron-icon-up"
               (click)="openDetail(row)"
               src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </div>
        <div *ngIf="!shouldHideChevron(row)"
             fxFlex="32px"
             (click)="rowClicked(row)">
          <img alt="Chevron Icon"
               class="ngx-data-table__chevron-icon"
               src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </div>
      </div>

      <div *ngIf="isDetailView && row.open"
           class="mobile-data-table-full__detail-table"
           [ngClass]="{'ngx-data-table__blue-row-background' : (i % 2  !== 0)}"
           fxLayout="row wrap">
          <div [fxFlex]="getColSize(detRowCol)" *ngFor="let detRowCol of detailColumns">
            {{detRowCol.title}}
          </div>
        <div fxLayout="row"
             fxFlex="100"
             *ngFor="let detRow of row[detailProp]">
          <div [fxFlex]="getColSize(detCol)" *ngFor="let detCol of detailColumns">
              <data-table-cell [row]="detRow"
                               [col]="detCol"
                               [useFullMobileTable]="true">
              </data-table-cell>
          </div>
        </div>
        <div *ngIf="row.doorDash"
             class="ngx-data-table__mobile-notes">
             {{detailViewNoteText}}
        </div>
      </div>

    </div>
  </div>

  <data-table-footer *ngIf="showFooter"
                     [totalElements]="totalElements"
                     [pageSize]="pageSize"
                     [currentPage]="currentPage"
                     [numberOfPages]="numberOfPages"
                     [isMobile]="isMobile"
                     (onPageChange)="pageChange($event)">
  </data-table-footer>
</div>
