import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  PUSH_ERROR_PAGE:      type('[Error] Push Error Page'),
};

export class PushErrorPageAction implements Action {
  public type = ActionTypes.PUSH_ERROR_PAGE;

  constructor(public payload: any) { }
}

export type Actions
  = PushErrorPageAction;
