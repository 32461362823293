import * as layout from '../actions/layout';

export interface State {
  screenSize: string;
}

export const initialState: State = {
  screenSize: 'lg'
};

export function reducer(state = initialState, action: layout.Actions): State {
  switch (action.type) {
    case layout.ActionTypes.CHANGED_VIEW_SIZE: {
      const screenSize = action.payload;
      return { ...state, screenSize };
    }

    default: {
      return state;
    }
  }
}

export const getScreenSize = (state: State) => state.screenSize;
