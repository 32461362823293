<div *ngIf="!isRedTheme">
    <span (click)="$event.stopPropagation(); decrement()">
        <button mat-raised-button
                class="increment-stepper__icon-button"
                [disabled]="disableDecrement">
            <mat-icon>remove</mat-icon>
        </button>
    </span>
    <span class="increment-stepper__value">{{numberSelected}}</span>
    <span (click)="$event.stopPropagation(); increment()">
        <button mat-raised-button
                class="increment-stepper__icon-button"
                [disabled]="disableIncrement">
            <mat-icon>add</mat-icon>
        </button>
    </span>
</div>
<div *ngIf="isRedTheme"
    fxLayout="row"
    fxLayoutAlign="start center"
    class="increment-stepper-restyle">
    <span (click)="$event.stopPropagation(); decrement()">
        <button mat-raised-button
                class="increment-stepper-restyle__remove increment-stepper-restyle__button"
                [ngClass]="{'increment-stepper-restyle__button-disabled': disableDecrement}"
                [disabled]="disableDecrement">
            <mat-icon class="increment-stepper-restyle__icon"
                      [ngClass]="{'increment-stepper-restyle__icon-disabled': disableDecrement}">
                    remove
            </mat-icon>
        </button>
    </span>
    <span class="increment-stepper-restyle__number"
          [ngClass]="{'increment-stepper-restyle__disabled-number': disableIncrement && disableDecrement}">
          {{numberSelected}}
    </span>
    <span (click)="$event.stopPropagation(); increment()">
        <button mat-raised-button
                class="increment-stepper-restyle__add increment-stepper-restyle__button"
                [ngClass]="{'increment-stepper-restyle__button-disabled': disableIncrement}"
                [disabled]="disableIncrement">
            <mat-icon class="increment-stepper-restyle__icon"
                      [ngClass]="{'increment-stepper-restyle__icon-disabled': disableIncrement}">
                    add
            </mat-icon>
        </button>
    </span>
</div>

