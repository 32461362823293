<mat-card *ngIf="!isCardListStyle"
          class="reward-card__card"
          [ngClass]="{'reward-card__selected-reward': numberSelected > 0, 'reward-card__disabled-select': disableIncrement}"
          (click)="cardClicked()">
    <div fxLayout="column"
         fxLayoutAlign="space-between center"
         [ngClass]="{'reward-card__main-container': !displayIncrementer, 'reward-card__main-container-resize': displayIncrementer}">
        <div [ngClass]="{'reward-card__image-container': !displayIncrementer, 'reward-card__image-container-resize': displayIncrementer}"
             fxLayout="column"
             fxLayoutAlign="center center">
            <img [src]="imageUrl" class="reward-card__image" alt="">
        </div>
        <div [ngClass]="{'reward-card__text-container' :!endDateRestriction, 'reward-card__text-contanier-lto': endDateRestriction }"
             fxLayout="column"
             fxLayoutAlign="space-evenly center">
            <div class="reward-card__title"
                [ngStyle]="{'font-size': fontSize}">
                {{name}}
            </div>
        </div>
        <div *ngIf="endDateRestriction"
             class = "reward-card__limited-time-offer-text"
             fxLayout="column"
             fxLayoutAlign="centercenter">
          <div class="reward-card__text">Limited time offer only!</div>
          <div  class="reward-card__subtext">Valid Until {{endDateRestriction}}</div>
        </div>


        <div *ngIf="displayIncrementer"
             fxLayout="row"
             fxLayoutAlign="center center"
             class="reward-card__stepper-container">
            <increment-stepper [numberSelected]="numberSelected"
                        [disableDecrement]="numberSelected === 0"
                        [disableIncrement]="disableIncrement"
                        [isRedTheme]=true
                        (decrementSelected)="decremented.emit()"
                      (incrementSelected)="incremented.emit()">
            </increment-stepper>
        </div>
    </div>
</mat-card>
<mat-card *ngIf="isCardListStyle" class="reward-card__card" (click)="cardClicked()">
    <div fxLayout="row" fxLayoutAlign="start center" class="reward-card__mb-container">
        <div class="reward-card__image-mb-container" fxLayout="column" fxLayoutAlign="center center">
            <img [src]="imageUrl" class="reward-card__image-mb" alt="">
        </div>
        <div class="reward-card__item-name-mb-wrapper" fxLayout="column" fxLayoutAlign="center start">
            <p class="reward-card__item-name-mb">
                {{name}}
            </p>
        </div>
    </div>
</mat-card>
