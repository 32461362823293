<div class="sections-layout">
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around">
    <div *ngFor="let section of sections; first as isFirst; last as isLast" fxFlex="{{flexAmount()}}">
      <div *ngIf="section && section.length === 1"
           class="sections-layout__group-container"
           [ngClass]="getAlignedClass(isFirst, isLast) + ' ' + applyCardMinWidth(section)"
           fxLayout="column"
           fxLayoutAlign="space-around stretch">
        <stat-section class="sections-layout__aligned-groups"
                      [data]="getSectionData(section[0])"
                      [isPrimary]="true"
                      [category]="category"
                      [section]="section[0]"
                      [isMobile]="isMobile">
        </stat-section>
      </div>
      <div *ngIf="section && section.length > 1"
           fxLayout="column"
           class="sections-layout__group-container sections-layout__equal-card-size">
        <stat-section class="sections-layout__top-grouped-row"
                      [data]="getSectionData(section[0])"
                      [isPrimary]="true"
                      [category]="category"
                      [section]="section[0]"
                      [isMobile]="isMobile">
        </stat-section>
        <div fxLayout="row"
             fxLayoutAlign="space-around"
             [ngClass]="{'sections-layout__bottom-grouped-row': section.length > 2,
                        'sections-layout__single-grouped-row': section.length <= 2}">
          <div *ngFor="let segment of getAllButFirstSection(section); first as isFirst"
               fxFlex [ngClass]="getBorderClass(isFirst)">
            <stat-section [data]="getSectionData(segment)"
                          [category]="category"
                          [isPrimary]="section.length <= 2"
                          [section]="segment"
                          [isMobile]="isMobile">
            </stat-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
