import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'icon-confirmation-dialog',
  templateUrl: 'icon-confirmation-dialog.component.html',
  styleUrls: ['./icon-confirmation-dialog.component.scss']
})

export class IconConfirmationDialogComponent implements OnInit {
  public title;
  public displayText;
  public iconUrl;
  public cancelText;
  public confirmText;
  public isMobile;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<IconConfirmationDialogComponent>) {}

  public ngOnInit() {
    this.title = this.dialogData.title;
    this.displayText = this.dialogData.displayText;
    this.iconUrl = this.dialogData.iconUrl;
    this.cancelText = this.dialogData.cancelText;
    this.confirmText = this.dialogData.confirmText;
    this.isMobile = this.dialogData.isMobile;
  }

  public closeDialog(isConfirm: boolean) {
    this.dialogRef.close(isConfirm);
  }
}
