export class DataTableConstants {
  public static get mobileTitle(): string {
    return 'title';
  }

  public static get mobileSubtitle(): string {
    return 'subtitle';
  }

  public static get defaultPageSize(): number {
    return 20;
  }

  public static get f2SToolTipText(): string {
    return 'Forgot2Scan captures transactions in which customers did not scan or mobile order but manually submit ' +
           'their receipt for points via app or website.';
  }

  public static get statusIcon() {
    return {
      'completed' : 'https://www.cfacdn.com/img/Spotlight/Icons/StatusCompleteIcon.png',
      'failure' : 'https://www.cfacdn.com/img/Spotlight/Icons/FailedIcon.png',
      'pending' : 'https://www.cfacdn.com/img/Spotlight/Icons/PendingIcon.png'
    };
  }

  public static get statusIconInfo() {
    return {
      'completed' : 'Completed',
      'failure' : 'Failed',
      'pending' : 'Scheduled'
    };
  }
}
