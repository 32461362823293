import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { DiscoverComponent } from './discover.component';
import { DiscoverCardComponent } from './card/card.component';
import { BannerCardComponent } from './banner-card/banner-card.component';
import { FiltersComponent } from './filters/filters.component';
import { StatisticComponent } from './statistic/statistic.component';
import { CampaignComponent } from './campaign/campaign.component';
import { DiscoverEditComponent } from './edit/edit.component';
import { DiscoverCopyReviewComponent } from './edit/copy-review/copy-review.component';
import { DiscoverEmailPreviewComponent } from './edit/email-preview/email-preview.component';
import { RewardSelectionComponent } from './edit/reward-selection/reward-selection.component';
import { DiscoverErrorComponent } from './error/error.component';
import { CancellationDialogComponent } from './cancellation-dialog/cancellation-dialog.component';
import { DiscoverEditDetailsComponent } from './edit/edit-details/edit-details.component';
import { DiscoverEditPromotionDetailsComponent } from './edit/edit-promotion-details/edit-promotion-details.component';
import { ErrorCardComponent } from './error-card/error-card.component';
import { AutomationInfoComponent } from './automation-info/automation-info.component';
import { AutomationInfoDialogComponent } from './automation-info-dialog/automation-info-dialog.component';
import { AutomationInfoPageComponent } from './automation-info-page/automation-info-page.component';
import { DiscoverEditCommunityCareDetailComponent } from './edit/edit-community-care-detail/edit-community-care-detail.component';
import { OverlappingEngagementsComponent } from '../shared/overlapping-engagements/overlapping-engagements.component';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { DiscoverEditThirdPartyPromotionComponent } from './edit/edit-third-party-promotion/discover-edit-third-party-promotion.component';
import {
  DiscoverEditFreeItemInCartComponent
} from "./edit/edit-free-item-in-cart-promotion/discover-edit-free-item-in-cart.component";

const routes: Routes = [
  { path: '', component: DiscoverComponent, data: { animationState: 'discover', isBlueBackground: true } },
  { path: 'error', component: DiscoverErrorComponent },
  {
    path: 'edit',
    component: DiscoverEditComponent,
    data: { animationState: 'discoverEdit' },
    children: [
      { path: 'edit-details', component: DiscoverEditDetailsComponent },
      { path: 'edit-promotion-details', component: DiscoverEditPromotionDetailsComponent },
      { path: 'edit-community-care-detail', component: DiscoverEditCommunityCareDetailComponent },
      { path: 'edit-third-party-promotion', component: DiscoverEditThirdPartyPromotionComponent},
      { path: 'edit-free-item-in-cart-details', component: DiscoverEditFreeItemInCartComponent },
      { path: 'copy-review', component: DiscoverCopyReviewComponent },
      { path: 'overlapping-engagements', component: OverlappingEngagementsComponent },
    ]
  },
  { path: 'edit/reward-selection', component: RewardSelectionComponent },
  { path: 'edit/email-preview', component: DiscoverEmailPreviewComponent },
  { path: 'automation-info', component: AutomationInfoPageComponent, data: { animationState: 'automationInfo' } }
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    DiscoverComponent,
    DiscoverCardComponent,
    BannerCardComponent,
    ErrorCardComponent,
    StatisticComponent,
    CampaignComponent,
    FiltersComponent,
    DiscoverEditComponent,
    DiscoverEditDetailsComponent,
    DiscoverEditPromotionDetailsComponent,
    DiscoverCopyReviewComponent,
    DiscoverEmailPreviewComponent,
    RewardSelectionComponent,
    DiscoverErrorComponent,
    CancellationDialogComponent,
    AutomationInfoComponent,
    AutomationInfoDialogComponent,
    AutomationInfoPageComponent,
    DiscoverEditCommunityCareDetailComponent,
    WelcomeDialogComponent,
    DiscoverEditThirdPartyPromotionComponent,
    DiscoverEditFreeItemInCartComponent
  ],
  exports: [
    DiscoverComponent
  ]
})
export class DiscoverModule {}
