import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MeetConstants } from 'spotlight-ui/app/meet/meet.constants';

@Component({
  selector: 'filter-chip-box',
  templateUrl: './filter-chip-box.component.html',
  styleUrls: ['./filter-chip-box.component.scss']
})
export class FilterChipBoxComponent {
  @Input() public filters: any[] = [];
  @Input() public isLoading: boolean;
  @Input() public totalCustomers: number;
  @Input() public includeCustomerFilter: any[] = [];
  @Input() public excludeCustomerFilter: any[] = [];
  @Input() public filterCount;
  @Input() public selectedSavedGroups: any[] = [];
  @Input() public displaySavedGroupActions: boolean;
  @Input() public isMobile: boolean;
  @Input() public savedGroupBeingEdited: null;

  @Output() public filterRemoved = new EventEmitter<any>();
  @Output() public includeCustomerFilterRemoved = new EventEmitter<any>();
  @Output() public excludeCustomerFilterRemoved = new EventEmitter<any>();
  @Output() public clearFilters = new EventEmitter<any>();
  @Output() public moreCustomersClicked = new EventEmitter<any>();
  @Output() public showGroupClicked = new EventEmitter<any>();
  @Output() public saveNewGroup = new EventEmitter<any>();
  @Output() public savedGroupFilterRemoved = new EventEmitter<any>();
  @Output() public renameSavedGroup = new EventEmitter<any>();
  @Output() public deleteSavedGroup = new EventEmitter<any>();

  public infoHelpText = MeetConstants.infoHelpText;

  public maxIndividualCustomer = MeetConstants.maxIndividualCustomer;
  public editIconUrl = MeetConstants.editIcon;
  public deleteIconUrl = MeetConstants.deleteIcon;

  public moreIncludedCustomersClicked() {
    this.moreCustomersClicked.emit(MeetConstants.includeFilterId);
  }

  public moreExcludedCustomersClicked() {
    this.moreCustomersClicked.emit(MeetConstants.excludeFilterId);
  }

  public getFlexLayout(isMobile) {
    return isMobile ? 'column' : 'row';
  }

  public savedGroup() {
    if (this.selectedSavedGroups.length == 0) {
      this.saveNewGroup.emit()
    }
  }
}
