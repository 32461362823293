import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'overlapping-engagement-summary',
  templateUrl: './overlapping-engagement-summary.component.html',
  styleUrls: ['./overlapping-engagement-summary.component.scss']
})

export class OverlappingEngagementSummaryComponent {
  @Output() public onSummaryClick = new EventEmitter<any>();
}
