import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import * as fromRoot from '../../reducers';
import * as meet from '../../actions/meet';
import { AnalyticsService } from '../../core/analytics';
import { take } from 'rxjs/operators';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'save-group-name-dialog',
  templateUrl: 'save-group-name-dialog.component.html',
  styleUrls: ['./save-group-name-dialog.component.scss']
})

export class SaveGroupNameDialogComponent implements OnDestroy {
  public modifySavedGroupLoading$: Observable<boolean>;
  public customerCount$: Observable<number>;
  public selectedFilters$: Observable<any[]>;
  public includedCustomers$: Observable<any[]>;
  public excludedCustomers$: Observable<any[]>;
  public meetSavedGroupBeingEdited$: Observable<any>;
  public otherGroupNames$: Observable<any[]>;

  public loadingSub;
  public groupId;
  public groupName = '';

  constructor(public dialogRef: MatDialogRef<SaveGroupNameDialogComponent>,
              public store: Store<fromRoot.State>,
              public analytics: AnalyticsService) {
    this.modifySavedGroupLoading$ = store.select(fromRoot.getModifySavedGroupsLoading);
    this.customerCount$ = store.select(fromRoot.getTotalCountOfCustomers)
    this.selectedFilters$ = store.select(fromRoot.getMeetSelectedFilters);
    this.otherGroupNames$ = store.select(fromRoot.getMeetSavedGroups)
    this.includedCustomers$ = store.select(fromRoot.getIncludedCustomersByPage(AppConstants.meetPage));
    this.excludedCustomers$ = store.select(fromRoot.getExcludedCustomersByPage(AppConstants.meetPage));
    this.meetSavedGroupBeingEdited$ = this.store.select(fromRoot.getMeetSavedGroupBeingEdited);
    this.meetSavedGroupBeingEdited$.pipe(take(1)).subscribe((savedGroup) => {
      if (savedGroup && savedGroup.name) {
        this.groupName = savedGroup.name;
      }
    });
  }

  public clearGroupName() {
    this.groupName = '';
  }

  public savedGroupNameAlreadyExists(otherGroups: any[], currentGroup?){
    let nameExists = false
    let currentEditGroupName = currentGroup && currentGroup.name ? currentGroup.name : null
    if(currentEditGroupName === this.groupName){
      return false;
    }
    otherGroups.forEach((savedGroup: any) =>{
      nameExists = nameExists || savedGroup.name === this.groupName;
    })
    return nameExists
  }

  public saveGroup() {
    this.analytics.sendMParticleEvent(
      'Saved Group Created',
      { 'Saved Group Name': this.groupName }
    );
    this.store.dispatch(new meet.CreateSavedGroup(this.groupName));
    this.loadingSub = this.modifySavedGroupLoading$.subscribe(loading => {
      if (!loading) {
        this.dialogRef.close();
      }
    });
  }

  public updateExistingGroup() {
    this.meetSavedGroupBeingEdited$.pipe(take(1)).subscribe((savedGroup) => {
      this.analytics.sendMParticleEvent(
        'Saved Group Renamed',
        { 'Old Saved Group Name': savedGroup.name, 'New Saved Group Name': this.groupName }
      );
      this.store.dispatch(new meet.UpdateSavedGroup(this.groupName));
      this.loadingSub = this.modifySavedGroupLoading$.subscribe(loading => {
        if (!loading) {
          this.dialogRef.close();
        }
      });
    });
  }

  public ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
  }
}
