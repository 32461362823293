import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeetConstants } from '../../meet/meet.constants';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromRoot from '../../reducers';
import { AnalyticsService } from '../../core/analytics';
import * as meet from '../../actions/meet';

@Component({
  selector: 'additional-filter-customers-dialog',
  templateUrl: 'additional-filter-customers-dialog.component.html',
  styleUrls: ['./additional-filter-customers-dialog.component.scss']
})

export class AdditionalFilterCustomersDialogComponent implements OnInit {

  public customerFilter$: Observable<any[]>;
  public isSelectedSavedGroupLarge$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public filterTitle;
  public id;
  public page;
  public startFromIndex;
  public selectedSavedGroupId;
  public noCustomersAvailableMsg;
  public maxCustomersMessage;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<AdditionalFilterCustomersDialogComponent>,
              private analytics: AnalyticsService) {
  }

  public ngOnInit() {
    this.filterTitle = this.dialogData.filterTitle;
    this.id = this.dialogData.id;
    this.page = this.dialogData.page;
    switch (this.id) {
      case MeetConstants.includeFilterId: {
        this.customerFilter$ = this.store.select(fromRoot.getIncludedCustomersByPage(this.page));
        this.startFromIndex = MeetConstants.maxIndividualCustomer;
        break;
      }
      case MeetConstants.excludeFilterId: {
        this.customerFilter$ = this.store.select(fromRoot.getExcludedCustomersByPage(this.page));
        this.startFromIndex = MeetConstants.maxIndividualCustomer;
        break;
      }
      case MeetConstants.savedGroupsFilterId: {
        this.loading$ = this.store.select(fromRoot.getSelectedSavedGroupCustomersLoading);
        this.error$ = this.store.select(fromRoot.getSelectedSavedGroupCustomersError);
        this.customerFilter$ = this.store.select(fromRoot.getSelectedSavedGroupCustomers);
        this.isSelectedSavedGroupLarge$ = this.store.select(fromRoot.getIsSelectedSavedGroupLarge);
        this.selectedSavedGroupId = this.store.select(fromRoot.getSelectedSavedGroupId);
        this.startFromIndex = 0;
        this.noCustomersAvailableMsg = MeetConstants.noCustomersAvailableMsg;
        this.maxCustomersMessage = MeetConstants.maxSavedGroupCustomersMessage;
        break;
      }
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public removedCustomer(customer) {
    switch (this.id) {
      case MeetConstants.includeFilterId: {
        this.analytics.sendMParticleEvent(
          `${this.page} Page Included Individual Customer Removed Via Dialog`,
          {
            'Customer Id': customer.id,
          }
        );
        this.store.dispatch(new meet.RemoveIndividualIncludeCustomer({ customer: customer, page: this.page }));
        break;
      }
      case MeetConstants.excludeFilterId: {
        this.analytics.sendMParticleEvent(
          `${this.page} Page Excluded Individual Customer Removed Via Dialog`,
          {
            'Customer Id': customer.id,
          }
        );
        this.store.dispatch(new meet.RemoveIndividualExcludeCustomer({ customer: customer, page: this.page }));
        break;
      }
      case MeetConstants.savedGroupsFilterId: {
        this.analytics.sendMParticleEvent(
          'Saved Group Customer Removed',
          {
            'Group Id': this.selectedSavedGroupId,
            'Customer Id': customer.id,
          }
        );
        this.store.dispatch(new meet.RemoveSelectedSavedGroupCustomer(customer));
        this.store.dispatch(new meet.AddIndividualExcludeCustomer({ customer: customer, page: this.page }));
        break;
      }
    }

    // to close dialog if user is removing last customer from dialog
    this.customerFilter$.pipe(take(1)).subscribe(customers => {
      if (customers.length === this.startFromIndex) {
        this.dialogRef.close();
      }
    });
  }
}
