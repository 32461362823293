<mat-button-toggle-group class="button-toggle__button-container"
                         (change)="buttonToggled.emit($event.value)"
                         [ngClass]="{'button-toggle__button-container-mobile' : isMobile,
                                     'button-toggle__button-container-full-width' : isFullWidth}">
  <mat-button-toggle *ngFor="let button of buttons"
                     class="button-toggle__button"
                     [disabled]="disabled"
                     [value]="button.id"
                     [checked]="button.selected">
    {{button.name}}
  </mat-button-toggle>
</mat-button-toggle-group>
