import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'preview-selection',
  templateUrl: './preview-selection.component.html',
  styleUrls: ['./preview-selection.component.scss']
})
export class PreviewSelectionComponent {
  @Input() public elementId: string;
  @Input() public isDisabled: boolean;
  // Pass in container id in case there are multiples of this component since id must be unique
  @Input() public containerId: string;
  @Input() public text: string;
  @Input()
  set html(html) {
    // Workaround for DOM not registering as rendered
    setTimeout(() => {
      let element: Element = document.getElementById(this.elementId);
      if (element) {
        this.setElementContainer(element);
      }
    }, 500);
  }

  @Output() public elementClicked = new EventEmitter();

  public elementContainer: Element;
  public textContainer: Element;

  public constructor(private elem: ElementRef) {
    // Workaround for DOM not registering as rendered
    setTimeout(() => {
      this.elementContainer = document.getElementById(this.containerId);
      this.textContainer = this.elem.nativeElement.querySelector('.text-container');
      this.setContainerListeners();
    }, 0);
  }

  // Set styling for inserted container based on email's element dimensions
  public setElementContainer(element: Element) {
    if (this.elementContainer) {
      let rect = element.getBoundingClientRect();
      let width = rect.width;
      let height = rect.height;

      this.elementContainer.setAttribute(
        'style',
        `width: ${width}px; height: ${height}px; margin-top: -${height}px`
      );

      element.insertAdjacentElement('afterend', this.elementContainer);
    }
  }

  // Set listeners to display/hide bottom text when the user hovers over element
  public setContainerListeners() {
    if (this.elementContainer) {
      this.elementContainer.addEventListener('mouseover', () => {
        this.textContainer.setAttribute(
          'style',
          `display: block;`
        );
      });

      this.elementContainer.addEventListener('mouseout', () => {
        this.textContainer.setAttribute(
          'style',
          `display: none;`
        );
      });
    }
  }
}
