import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { RoleGuard } from './roleGuard.service';


const routes: Routes = [
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  { path: '',
    redirectTo: 'discover',
    pathMatch: 'full'
  },
  { path: '#',
    redirectTo: '/discover/',
  },
  { path: 'engage',
    redirectTo: '/engage/template-selection',
    pathMatch: 'full'
  },
  {
    path: 'eula',
    loadChildren: () => import('./eula/eula.module').then(m => m.EulaModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'menu',
    loadChildren: () => import('./nav-menu/nav-menu.module').then(m => m.NavMenuModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'discover',
    loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'meet',
    loadChildren: () => import('./meet/meet.module').then(m => m.MeetModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer-profile/customer-profile.module').then(m => m.CustomerProfileModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'care',
    loadChildren: () => import('./care/care.module').then(m => m.CareModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'filter',
    loadChildren: () => import('./filter/filter.module').then(m => m.FilterModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'engage',
    loadChildren: () => import('./engage/engage.module').then(m => m.EngageModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'understand',
    loadChildren: () => import('./understand/understand.module').then(m => m.UnderstandModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'manage-attendees',
    loadChildren: () => import('./manage-attendees/manage-attendees.module').then(m => m.ManageAttendeesModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  { path: '**', redirectTo: 'discover' }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy, relativeLinkResolution: 'legacy' }),
  ],
  exports: [ RouterModule ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy}
  ]
})
export class AppRoutingModule { }
