import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation,
  OnInit, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { forEach, findIndex, propEq } from 'ramda';
import { Subject } from 'rxjs';

@Component({
  selector: 'data-table-body',
  templateUrl: './data-table-body.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableBodyComponent implements OnInit, OnDestroy {
  @Input() public rowId: string;
  @Input() public columns: any[];
  @Input() public detailColumns: any[];
  @Input() public detailProp: string;
  @Input() public isDetailView: boolean;
  @Input() public actionableErrorText: string;
  @Input() public noteProp: string;

  @Input() public rows: any[];
  @Input() public noResultsMessage: string;
  @Input() public isLoading: boolean;
  @Input() public showCheckboxes: boolean;
  @Input() public rowIdProp: string;
  @Input() public disableIndividualRowAttribute: string;
  @Input() public disableCheckbox: boolean;
  @Input() public disableDeleteIcon: boolean;
  @Input() public disableSelect: boolean;
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public useSmallRowSize: boolean = false;
  @Input() public hasOnClickListeners: boolean;
  @Input() public useFullMobileTable: boolean = false;
  @Input() public detailViewNoteText: string;
  @Input() public deepLinkMessage: string;
  @Input() public isVisibleDeepLinkMsg: string;
  @Input() public openRowSubject$: Subject<any>;
  @Input() public isRowNotInteractable: boolean;

  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onCheckboxClick = new EventEmitter<any>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onButtonClick = new EventEmitter<any>();
  @Output() public onExternalLinkClick = new EventEmitter<any>();
  @Output() public deepLinkMessageClick = new EventEmitter<any>();
  @Output() public onOrderNumberClicked = new EventEmitter<any>();

  public subscriptionArray = [];

  constructor(public cd: ChangeDetectorRef) {}

  public ngOnInit() {
    if (this.openRowSubject$) {
      this.subscriptionArray.push(this.openRowSubject$.subscribe(row => {
        if (row && this.rowId) {
          let rowDataIndex = findIndex(propEq(this.rowId, row[this.rowId]))(this.rows);
          this.rows[rowDataIndex].open = false;
          this.openDetail(this.rows[rowDataIndex]);
          this.cd.detectChanges();
        }
      }));
    }
  }

  public rowClicked(rowData: any) {
    if (!this.shouldDisableRow(rowData)) {
      // If only action is to check a checkbox, then do that on row click as well
      if ((!this.hasOnClickListeners) && !(!this.showCheckboxes || this.disableCheckbox || this.isDetailView)) {
        // Manually set row's state since the checkbox itself wasn't clicked
        rowData.state = !rowData.state;
        this.checkboxClick(rowData);
      } else {
        this.onRowClick.emit(rowData);
      }
    }
  }

  public openDetail(rowData) {
    if (rowData) {
      rowData['open'] = !rowData.open;
    }
  }

  public checkboxClick(row: any) {
    let lastRow = {}; // Only use last row with matching id to send consistent data
    forEach((dr) => {
      if (dr[this.rowIdProp] === row[this.rowIdProp]) {
        dr['state'] = row.state; // Make all rows with same id be the same state
        lastRow = dr;
      }
    })(this.rows);
    this.onCheckboxClick.emit(lastRow);
  }

  public shouldDisableRow(row: any) {
    return row[this.disableIndividualRowAttribute];
  }

  public isDataAvailable() {
    return this.rows
      && this.rows.length
      && this.rows.length > 0;
  }

  public errorTextClicked() {
    this.errorTextClick.emit();
  }

  public getFlex() {
    return this.showCheckboxes ? '65px' : '25px';
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public buttonClicked(row, id) {
    this.onButtonClick.emit({ row, id });
  }

  public externalLinkClicked(row) {
    this.onExternalLinkClick.emit(row);
  }

  public getColSize(col) {
    // undefined doesn't work with fxFlex so need to return null
    return col.size ? col.size : null;
  }

  // returns true if row cannot be selected and the checkbox is either disabled or hidden
  public isRowNonInteractive(row) {
    return this.shouldDisableRow(row)
      || ((!this.hasOnClickListeners) && (!this.showCheckboxes || this.disableCheckbox || this.isDetailView))
      || this.isRowNotInteractable;
  }

  public shouldHideChevron(row: any) {
    return this.shouldDisableRow(row) || (!this.hasOnClickListeners) || this.isRowNotInteractable;
  }

  public onClickDeepLinkMsg() {
    this.deepLinkMessageClick.emit();
  }

  public orderNumberClicked(row) {
    this.onOrderNumberClicked.emit(row);
  }

  public viewNotesClicked(rowData) {
    if (rowData) {
      rowData.isNoteOpen = !rowData.isNoteOpen;
    }
  }

  public ngOnDestroy() {
    this.subscriptionArray.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
