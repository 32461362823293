import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import { SharedConstants } from '../shared.constants';

@Component({
  selector: 'activity-history',
  templateUrl: 'activity-history.component.html',
  styleUrls: ['./activity-history.component.scss']
})

export class ActivityHistoryComponent {
  @Input()
  set activityHistory(history: any[]) {
    this.allRecords = history;
    this.displayRecords = this.allRecords.slice(0, this.pageSize);
  }

  @Input() public columns: any[];
  @Input() public noResultsText: string;
  @Input() public statusVariable: string;
  @Input() public dateLabel: string;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public isMobile: boolean = false;
  @Input() public noteProp: string;

  @Output() public viewEmailDetails = new EventEmitter<any>();

  public pageSize: number = SharedConstants.activityHistoryPageSize;
  public currentPage: number = 0;
  public allRecords = [];
  public displayRecords;

  constructor(public store: Store<fromRoot.State>) {}

  public getNumberOfPages(totalElements) {
    return Math.ceil(totalElements / this.pageSize);
  }

  public sliceArrBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.allRecords.length) {
      this.displayRecords = this.allRecords.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this.displayRecords = this.allRecords.slice(nextRowIndex, this.allRecords.length);
    }
  }

  public pageLeft() {
    this.currentPage--;
    this.sliceArrBasedOnCurrentPage();
  }

  public pageRight() {
    this.currentPage++;
    this.sliceArrBasedOnCurrentPage();
  }

  public getViewNotesLabel(action) {
    if (action.isNoteOpen) {
      return 'Hide Notes';
    } else {
      return 'View Notes';
    }
  }
}
