<collapsible-card title="Personal Information"
                  [error]="error"
                  [loading]="loading">
  <div fxLayout="row" class="personal-information__row-container">
    <span fxFlex="50" class="personal-information__label">Customer Name:</span>
    <span fxFlex="50" class="personal-information__info">{{ name }}</span>
  </div>
  <div *ngIf="info.loyaltyCardNumber" fxLayout="row" class="personal-information__row-container">
    <span fxFlex="50" class="personal-information__label">Membership Number:</span>
    <span fxFlex="50" class="personal-information__info">{{ info.loyaltyCardNumber }}</span>
  </div>
  <div *ngIf="info.birthDate" fxLayout="row" class="personal-information__row-container">
    <span fxFlex="50" class="personal-information__label">Birth Date:</span>
    <span fxFlex="50" class="personal-information__info">{{ info.birthDate }}</span>
  </div>
  <div fxLayout="row" class="personal-information__row-container" *ngIf="info.phone">
    <span fxFlex="50" class="personal-information__label">Phone:</span>
    <span fxFlex="50" class="personal-information__info">
      <span *ngIf="info.phone">{{info.phone}}</span>
    </span>
  </div>
  <div fxLayout="row" class="personal-information__row-container" *ngIf="info.preferredContact">
    <span fxFlex="50" class="personal-information__label">Contact Method:</span>
    <span fxFlex="50" class="personal-information__info">{{info.preferredContact}}</span>
  </div>
  <div *ngIf="info.kids && info.kids.length > 0">
    <div fxLayout="row" class="personal-information__row-container">
      <span fxFlex="50" class=personal-information__label>Legacy Kids Club:</span>
      <span fxFlex="50" class="personal-information__info">True</span>
    </div>
  </div>
</collapsible-card>
