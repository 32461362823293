<ng-container *ngIf="!(loading$ | async) && !(this.discoverTilesLoading$ | async) && !(error$ | async)">
  <div fxLayout="row" fxLayoutAlign="none center">
    <spot-nav fxFlex="100"
              [title]="title$ | async"
              [actionMenu]="false"
              [isSmallNav]="true"
              [isMobile]="isMobile$ | async"
              [hideBackArrow]="!(isMobile$ | async)"
              (back)="goToDiscover()">
    </spot-nav>
  </div>
  <app-bonus-points-promotion [title]="title$ | async"
                              [suggestedUseText]="suggestedUseText$ | async"
                              [isMobile]="isMobile$ |async"
                              [noteText]="noteText$ | async"
                              [campaignName]="campaignName$ | async"
                              [selectedDestination]="selectedDestination$ | async"
                              [selectedDestinationIdsForMultiSelect]="selectedDestinationIdsForMultiSelect$ | async"
                              [destinations]="destinations$ | async"
                              [selectedTimeOfDay]="selectedTimeOfDay$ | async"
                              [timeOfDayOptions]="timeOfDayOptions$ | async"
                              [startTime]="startTime$ | async"
                              [startTimeRestriction]="startTimeRestriction$ | async"
                              [selectedLocation]="selectedLocation$ | async"
                              [endTime]="endTime$ | async"
                              [endTimeRestriction]="endTimeRestriction$ | async"
                              [disabledDays] = "disabledDays$ | async"
                              [areDatesWithinSevenDays]="areDatesWithinSevenDays$ | async"
                              [repeatThisPromotion]="repeatThisPromotion$ | async"
                              [selectedRepeatPromotion]="selectedRepeatPromotion$ | async"
                              [repeatPromotionButtons]="repeatPromotionButtons$ | async"
                              [startDate]="startDate$ | async"
                              [endDate]="endDate$ | async"
                              [maxDaysAllowed]="maxDaysAllowed$ | async"
                              [readOnlyUser]="readOnlyUser$ | async"
                              (onUpdateDate)="onAnyDateChange($event)"
                              (onStartTimeChanged)="onStartTimeChange($event)"
                              (onAnyEngagementChange)="onAnyEngagementChange($event)"
                              (onSendPromotion)="sendPromotion()"
                              (onRepeatPromotionSelected)="updateRepeatPromotion($event)"
                              (goToDiscover)="goToDiscover()"
                              (goToPreviewPage)="goToPreviewPage()"
                              (onSelectedTimeOfDayChange)="onSelectedTimeOfDayChange($event)"
                              (onUpdateMultiSelectDestinations)="onUpdateMultiSelectDestinations($event)">
  </app-bonus-points-promotion>
</ng-container>
