import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'member-status',
  templateUrl: './member-status.component.html',
  styleUrls: ['./member-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberStatusComponent {
  @Input() public membership: any;
  @Input() public displayCfaOne: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public shouldDisplayIcon: boolean = true;
  @Input() public shouldDisplayLabel: boolean = true;
  @Input() public shouldDisplayMemberText: boolean = false;
}
