<div class="number-display__background">
    <div [ngSwitch]="format"
         [ngClass]="isPrimary ? 'number-display__primary-number' :
                                    'number-display__secondary-number'">
        <ng-container *ngSwitchCase="'percentage'">
            <div>{{number | percent: '1.0-2'}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'roundedPercentage'">
            <div>{{number | percent: '1.0-0'}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'currency'">
            <div>${{number | number: '1.2-2'}}</div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div>{{number}}</div>
        </ng-container>
    </div>
    <ng-container *ngIf="isPrimary">
        <div class="number-display__horizontal-bar-category"></div>
        <span class="number-display__primary-title">{{title}}</span>
    </ng-container>
    <ng-container *ngIf="!isPrimary">
        <span class="number-display__secondary-title">{{title}}</span>
    </ng-container>
</div>
