import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscoverTile } from '../../reducers/discover';

@Component({
  selector: 'error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss'],
})
export class ErrorCardComponent {
  @Input() public tile: DiscoverTile;
  @Input() public isMobile: boolean;

  @Output() public markResolved = new EventEmitter();
  @Output() public showFailedCampaignDetails = new EventEmitter();

  public getPageTitle() {
    return `Discover Tile: ${this.tile.id}`;
  }
}