import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getEngageThemeTitle, State } from '../../reducers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'theme-card',
  templateUrl: './theme-card.component.html',
  styleUrls: ['./theme-card.component.scss']
})

export class ThemeCardComponent {
  @Input() public template: any;
  @Input() public isMobile: boolean = false;

  @Output() public templateSelected = new EventEmitter<any>();

  public selectedThemeTitle$: Observable<string>;

  constructor(private store: Store<State>) {
    this.selectedThemeTitle$ = this.store.select(getEngageThemeTitle);
  }

  public setSelectedTemplate(template: any) {
    this.templateSelected.emit(template);
  }

  public isSelected$(title: string) {
    return this.selectedThemeTitle$.pipe(map((selectedTitle: string) => title === selectedTitle));
  }
}
