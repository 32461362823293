import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { find, propEq } from 'ramda';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AnalyticsService } from '../../core/analytics';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { MeetConstants } from '../../meet/meet.constants';
import { SharedConstants } from '../shared.constants';

@Component({
  selector: 'dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnInit, AfterViewInit {
  @Input() public autoCompleteSearchTerm: string;
  @Input() public categories: any[];
  @Input() public displayAdvancedSearchBox: boolean;
  @Input() public displayErrorText: boolean = false;
  @Input() public headerText: string;
  @Input() public isMobile: boolean;
  @Input() public noResultsText: string;
  @Input() public placeholderText: string = '';
  @Input() public searchTerm: string = '';
  @Input() public selectedCategory: string = '';

  @Output() public categoryChanged = new EventEmitter();
  @Output() public clearAutocompleteSearchTerm = new EventEmitter<any>();
  @Output() public clearSearch = new EventEmitter();
  @Output() public onSearch = new EventEmitter<any>();
  @Output() public selectionMade = new EventEmitter<any>();
  @Output() public valueChange = new EventEmitter<any>();
  @ViewChild('searchInput') searchInputField: ElementRef;

  public autocompleteCustomers$: Observable<any[]>;
  public autocompleteCustomersLoading$: Observable<boolean>;
  public currentPage;
  public dropdownOpen = false;
  public exampleText = '';
  public errorText = '';
  public exampleTextNote = '';
  public previousSearchTerm = '';
  public selectedCategoryObject;
  public bottomSheetData = {
    'list': [],
    'title': 'Search by',
    'searchBy': true,
    'isCancel': true,
    'selectedItem': this.selectedCategory
  };

  @HostListener('document:click', ['$event'])
  public clickOutside(event) {
    if (!(this.elemRef.nativeElement.contains(event.target)) || this.elemRef.nativeElement.lastChild.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  constructor(private bottomSheet: MatBottomSheet,
              private analytics: AnalyticsService,
              private store: Store<fromRoot.State>,
              private router: Router,
              private elemRef: ElementRef) { }

  public ngOnInit() {
    if (this.selectedCategory) {
      this.selectedCategoryObject = find(propEq('title', this.selectedCategory))(this.categories);
      this.exampleText = this.selectedCategoryObject.exampleText;
      this.exampleTextNote = this.selectedCategoryObject.exampleTextNote;
      this.errorText = this.selectedCategoryObject.errorText;
    } else if (this.categories) {
      const defaultCategory = find(propEq('defaultCategory', true))(this.categories) || this.categories[0];
      this.selectedCategory = defaultCategory.title;
      this.selectedCategoryObject = defaultCategory;
      this.exampleText = defaultCategory.exampleText;
      this.exampleTextNote = defaultCategory.exampleTextNote;
      this.errorText = defaultCategory.errorText;
      this.categoryChanged.emit(defaultCategory.value);
    }
    this.autocompleteCustomersLoading$ = this.store.select(fromRoot.getAutocompleteLoading);
    this.autocompleteCustomers$ = this.store.select(fromRoot.getAutocompleteCustomers);
    this.currentPage = this.router.url.split('?')[0];
  }

  public ngAfterViewInit() {
    if (this.searchInputField && this.searchTerm) {
      this.searchInputField.nativeElement.value = this.searchTerm;
    }
  }

  public toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.isMobile) {
      this.openMobileCategories();
    }
  }

  public getMaxLength() {
    return this.selectedCategory === MeetConstants.fourDigitMembershipNumberTitle ? 4 :
      this.selectedCategory === MeetConstants.fullMembershipNumberTitle ? 14 :
        this.selectedCategory === MeetConstants.phoneNumberTitle ? 14 : null;
  }

  public getPlaceholderText() {
    if (this.isMobile && this.categories) {
      return 'Search by ' + this.selectedCategory;
    }
    return this.placeholderText;
  }

  public getSearchObject(searchTerm, event) {
    if (this.selectedCategory === MeetConstants.phoneNumberTitle) {
      let phoneNumber = event.target.value.replace(/\D/g, '');
      if (phoneNumber.length === 0) {
        phoneNumber = '';
      } else if (phoneNumber.length <= 3) {
        phoneNumber = phoneNumber.replace(/^(\d{0,3})/, '($1');
      } else if (phoneNumber.length <= 6) {
        phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
      } else if (phoneNumber.length <= 10) {
        phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      }
      event.target.value = phoneNumber;
    }

    return { searchTerm, key: event.key };
  }

  // In order to test for correct length we need to remove formatting characters added to phone number
  public removeSearchTermFormatting(searchTerm) {
    return searchTerm.replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '')
      .replace(/-/g, '');
  }

  public clearSearchTerm() {
    if (this.searchInputField) {
      this.searchInputField.nativeElement.value = '';
      this.previousSearchTerm = '';
    }
  }

  public selectCategory(newCategory) {
    this.selectedCategoryObject = newCategory;
    this.displayErrorText = false;
    this.analytics.sendMParticleEvent(
      'Category Selected',
      {
        'Category Name': newCategory.title,
        'Category Value': newCategory.value,
        'Current Page': this.currentPage
      }
    );
    this.dropdownOpen = !this.dropdownOpen;

    // Only make a new call if the category has been changed
    if (this.selectedCategory !== newCategory.title) {
      this.selectedCategory = newCategory.title;
      this.errorText = newCategory.errorText;
      this.exampleText = newCategory.exampleText;
      this.exampleTextNote = newCategory.exampleTextNote;
      if (this.searchInputField) {
        this.searchInputField.nativeElement.value = '';
      }
      this.categoryChanged.emit(newCategory.value);
    }
  }

  public openMobileCategories() {
    // Convert the category array into an array of objects usable for the bottomSheetData.list
    let categoryObjectArray = this.categories.map((category) => {
      return {
        errorText: category.errorText,
        exampleText: category.exampleText,
        exampleTextNote: category.exampleTextNote,
        isAutocomplete: category.isAutocomplete,
        maxLength: category.maxLength,
        minLength: category.minLength,
        name: category.title,
        numbersOnly: category.numbersOnly,
        value: category.value
      }
    });
    this.bottomSheetData.list = categoryObjectArray;

    const bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: { content: this.bottomSheetData }
    });

    bottomSheetRef.afterDismissed().pipe(take(1)).subscribe((dataFromBottomSheet) => {
      this.dropdownOpen = !this.dropdownOpen;

      // Only make a new call if the category has been changed
      if (dataFromBottomSheet && dataFromBottomSheet.value && this.selectedCategory !== dataFromBottomSheet.value) {
        this.selectedCategoryObject = dataFromBottomSheet;
        if (this.searchInputField) {
          this.searchInputField.nativeElement.value = '';
        }
        this.selectedCategory = dataFromBottomSheet.name;
        this.errorText = dataFromBottomSheet.errorText;
        this.displayErrorText = false;
        this.exampleText = dataFromBottomSheet.exampleText;
        this.exampleTextNote = dataFromBottomSheet.exampleTextNote;
        this.categoryChanged.emit(dataFromBottomSheet.value);
      }
    });
  }

  public search(searchTerm, event) {
    const category = this.selectedCategoryObject;
    const searchObject = this.getSearchObject(searchTerm, event);
    const realSearchTerm = this.removeSearchTermFormatting(searchTerm);
    this.displayErrorText = false;
    if (category && category.numbersOnly && !(/^\d+$/.test(searchTerm))) {
      if (event.key === 'Enter') {
        this.displayErrorText = true;
        this.errorText = SharedConstants.numbersOnlyErrorMessage;
      }
    } else if (category && category.maxLength && realSearchTerm.length > category.maxLength) {
      if (event.key === 'Enter') {
        this.displayErrorText = true;
        this.errorText = SharedConstants.maxLengthErrorMessage(category.maxLength);
      }
    } else if (category && category.minLength && realSearchTerm.length < category.minLength) {
      if (event.key === 'Enter') {
        this.displayErrorText = true;
        this.errorText = SharedConstants.minLengthErrorMessage(category.minLength);
      }
    } else if (category && category.value === 'customerEmail' && !/^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/.test(searchTerm)) {
      if (event.key === 'Enter') {
        this.displayErrorText = true;
      }
    } else if (searchTerm !== this.previousSearchTerm
               && ((category && category.value === 'customerName') || event.key === 'Enter')
               || !(category)) {
      this.previousSearchTerm = searchTerm;
      this.onSearch.emit(searchObject);
    }
  }
}
