<!--Have ngIf as a work around to resolve flex-direction issue with refreshing on pages that are defined in app routing module-->
<mat-toolbar *ngIf="true"
             [ngClass]="{'nav__mobile-nav-position': isSmallNav, 'nav': !isSmallNav, 'nav__mobile-nav-container': isMobile,
                         'nav__nav-container': !isMobile}">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between stretch">
    <div *ngIf="hideBackArrow" [ngClass]="{'nav__action-btn-small': isSmallNav, 'nav__action-btn-large': !isSmallNav}"></div>
    <div *ngIf="!hideBackArrow">
      <button mat-button
              [ngClass]="{'nav__button-small': isSmallNav, 'nav__button': !isSmallNav, 'nav__mobile-nav-container__button-small': isMobile,
                          'nav__nav-container__button': !isMobile}"
              (click)="back.emit($event)">
        <i *ngIf="(isMobile || isBackChevronDT) && !isCloseIcon" class="material-icons">arrow_back_ios</i>
        <i *ngIf="!isMobile && !isBackChevronDT && !isCloseIcon" class="material-icons">arrow_back</i>
        <i *ngIf="isCloseIcon" class="material-icons">close</i>
      </button>
    </div>
    <span class="nav__title"
          fxFlex="auto"
          fxLayoutAlign="center center"
          [ngClass]="{'nav__small-title': (title && title.length > 25),
                      'nav__small-title-for-mobile': (title && title.length > 45 && isSmallNav)}">
      {{title}}
      <i *ngIf="isFavoriteLocation" class="material-icons nav__favorite-icon-red" matTooltip="Favorited This Location">favorite</i>
    </span>
    <div *ngIf="!actionMenu" [ngClass]="{'nav__action-btn-small': isSmallNav, 'nav__action-btn-large': !isSmallNav}"></div>
    <div *ngIf="actionMenu" [ngClass]="{'nav__action-btn-small': isSmallNav, 'nav__action-btn-large': !isSmallNav}">
      <actions-menu [selectedCustomerIds]="customerToEngage"
                    [canMakeInactive]="(info) ? info.isInactive : false"
                    [isMobile]="isSmallNav"
                    [isLoading]="actionMenuLoading"
                    [unsavedChangesTitle]="unsavedChangesTitle"
                    [unsavedChangesText]="unsavedChangesText"
                    [unsavedChangesIcon]="unsavedChangesIcon"
                    [unsavedChangesCancel]="unsavedChangesCancel"
                    [unsavedChangesConfirm]="unsavedChangesConfirm"
                    [shouldDisplayUnsavedChangesDialog]="shouldDisplayUnsavedChangesDialog"
                    [disableActionMenu]="disableActionMenu">
      </actions-menu>
    </div>
  </div>
</mat-toolbar>
