import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  NOTIFICATIONS_PAGE_VIEWED:                type('[Notifications] user opened notifications page'),
  INITIAL_PAGE_VIEW_FETCH_SUCCESS:          type('[Notifications] initial page view fetch success'),
  NOTIFICATIONS_MARKED_AS_READ_SUCCESS:     type('[Notifications] marked as read success'),
  NOTIFICATIONS_MARKED_AS_READ_FAILURE:     type('[Notifications] marked as read failure'),
  NOTIFICATIONS_FETCH_FAILURE:              type('[Notifications] fetch failure'),
  NOTIFICATIONS_FETCH_SUCCESS:              type('[Notifications] fetch success'),
  NOTIFICATIONS_CLEAR:                      type('[Notifications] clear'),
  NOTIFICATIONS_CLEAR_SUCCESS:              type('[Notifications] clear success'),
  NOTIFICATIONS_CLEAR_FAILURE:              type('[Notifications] clear failure'),
  CLEAR_ALL_NOTIFICATIONS:                  type('[Notifications] clear all'),
  CLEAR_ALL_NOTIFICATIONS_SUCCESS:          type('[Notifications] clear all success'),
  CLEAR_ALL_NOTIFICATIONS_FAILURE:          type('[Notifications] clear all failure')
};

export class NotificationsPageViewed implements Action {
  public type = ActionTypes.NOTIFICATIONS_PAGE_VIEWED;
  constructor(public payload: any = '') { }
}

// We need to distinguish the notifications fetch when the user first navigates
// to the notifications page so that we can make the backend call to mark them as read
export class InitialPagViewFetchSuccess implements Action {
  public type = ActionTypes.INITIAL_PAGE_VIEW_FETCH_SUCCESS;
  constructor(public payload: any = '') { }
}

export class MarkedAsReadSuccess implements Action {
  public type = ActionTypes.NOTIFICATIONS_MARKED_AS_READ_SUCCESS;
  constructor(public payload: any = '') { }
}

export class MarkedAsReadFailure implements Action {
  public type = ActionTypes.NOTIFICATIONS_MARKED_AS_READ_FAILURE;
  constructor(public payload: any = '') { }
}

export class NotificationsFetchFailure implements Action {
  public type = ActionTypes.NOTIFICATIONS_FETCH_FAILURE;
  constructor(public payload: any) { }
}

export class NotificationsFetchSuccess implements Action {
  public type = ActionTypes.NOTIFICATIONS_FETCH_SUCCESS;
  constructor(public payload: any) { }
}

export class NotificationClear implements Action {
  public type = ActionTypes.NOTIFICATIONS_CLEAR;
  constructor(public payload: string) { }
}

export class NotificationClearSuccess implements Action {
  public type = ActionTypes.NOTIFICATIONS_CLEAR_SUCCESS;
  constructor(public payload: any = '') { }
}

export class NotificationClearFailure implements Action {
  public type = ActionTypes.NOTIFICATIONS_CLEAR_FAILURE;
  constructor(public payload: any) { }
}

export class ClearAllNotifications implements Action {
  public type = ActionTypes.CLEAR_ALL_NOTIFICATIONS;
  constructor(public payload: any = '') { }
}

export class ClearAllNotificationsSuccess implements Action {
  public type = ActionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class ClearAllNotificationsFailure implements Action {
  public type = ActionTypes.CLEAR_ALL_NOTIFICATIONS_FAILURE;
  constructor(public payload: string) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = NotificationsPageViewed
  | InitialPagViewFetchSuccess
  | MarkedAsReadSuccess
  | MarkedAsReadFailure
  | NotificationsFetchFailure
  | NotificationsFetchSuccess
  | NotificationClear
  | NotificationClearSuccess
  | NotificationClearFailure
  | ClearAllNotifications
  | ClearAllNotificationsSuccess
  | ClearAllNotificationsFailure;
