import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../reducers';
import * as engagement from '../../actions/engagement';
import { Router } from '@angular/router';
import { DiscoverConstants } from '../discover.constants';

@Component({
  selector: 'cancellation-dialog',
  templateUrl: 'cancellation-dialog.component.html',
  styleUrls: ['./cancellation-dialog.component.scss']
})

export class CancellationDialogComponent implements OnInit {
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public title;
  public displayText;
  public id;
  public eventId;
  public fieldId;
  public communityCareEventId = DiscoverConstants.communityCareEventTileId;
  public freeItemInCartId = DiscoverConstants.freeItemInCartPromotion;
  public thirdPartyPromotionId = DiscoverConstants.thirdPartyPromotion;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<CancellationDialogComponent>,
              private router: Router,
              private store: Store<fromRoot.State>) {
    this.error$ = store.select(fromRoot.getEngagementError);
    this.loading$ = store.select(fromRoot.getEngagementLoading);
  }

  public ngOnInit() {
    this.title = this.dialogData.title;
    this.displayText = this.dialogData.displayText;
    this.id = this.dialogData.id;
    this.eventId = this.dialogData.eventId;
    this.fieldId = this.dialogData.fieldId
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public closeDialogAndReturnToDiscover() {
    this.dialogRef.close();
    this.router.navigate(['/discover']);
  }

  public cancelAutomation() {
    this.dialogRef.close();
    this.store.dispatch(new engagement.StopAutomation({ id: this.id, fieldId: this.fieldId }));
  }

  public cancelCommunityCareEvent() {
    this.dialogRef.close();
    this.store.dispatch(new engagement.StopCommunityCareEvent({ id: this.id, eventId: this.eventId }));
  }

  public cancelThirdPartyPromotion() {
    this.dialogRef.close();
    this.store.dispatch(new engagement.CancelThirdPartyPromotion({ id: this.eventId }));
  }

  public cancelFreeInCart() {
    this.dialogRef.close();
    this.store.dispatch(new engagement.StopFreeInCart({ id: this.fieldId }));
  }
}
