<div [ngSwitch]="section.type">
  <div *ngSwitchCase="'horizontalList'">
    <horizontal-bar-chart [category]="category"
                          [chartData]="slicedData"
                          [title]="section.title"
                          [listTitleProp]="section.listTitleProp"
                          [listValueProp]="section.listValueProp"
                          [listValueFormat]="section.listValueFormat"
                          [isMobile]="isMobile">
    </horizontal-bar-chart>
  </div>
  <div *ngSwitchCase="'numberDisplay'">
    <number-display [isPrimary]="isPrimary"
                    [category]="category"
                    [number]="data"
                    [format]="section.propFormat"
                    [title]="section.title">
    </number-display>
  </div>
  <div *ngSwitchCase="'doughnutChart'">
    <doughnut-chart
        [data]="data"
        [colors]="section.colors"
        [labels]="section.labels">
    </doughnut-chart>
  </div>
  <div *ngSwitchCase="'verticalBar'">
    <vertical-bar
        [chartData]="data"
        [category]="category"
        [listTitleProp]="section.listTitleProp"
        [listValueProp]="section.listValueProp">
    </vertical-bar>
  </div>
  <div *ngSwitchCase="'lineChart'">
    <line-chart [data]="data"
                [category]="category"
                [chartTitle]="section.title"
                [labelProp]="section.listTitleProp"
                [valueProp]="section.listValueProp"
                [xAxisLabel]="section.xaxisLabel"
                [yAxisLabel]="section.yaxisLabel"
                [yAxisFormat]="section.propFormat"
                [isMobile]="isMobile">
    </line-chart>
  </div>
  <div *ngSwitchCase="'barLineChart'">
    <bar-line-chart [data]="data"
                    [category]="category"
                    [chartTitle]="section.title"
                    [labelProp]="section.listTitleProp"
                    [barValueProp]="section.firstBarValueProp"
                    [barTitleProp]="section.firstBarTitleProp"
                    [lineValueProp]="section.firstLineValueProp"
                    [lineTitleProp]="section.firstLineTitleProp"
                    [lineFilterProp]="section.lineFilterProp"
                    [xAxisLabel]="section.xaxisLabel"
                    [yAxisLabel]="section.yaxisLabel"
                    [yAxisFormat]="section.propFormat"
                    [isMobile]="isMobile">
    </bar-line-chart>
  </div>
  <div *ngSwitchCase="'doubleBarLineChart'">
    <double-bar-line-chart [data]="data"
                           [category]="category"
                           [chartTitle]="section.title"
                           [labelProp]="section.listTitleProp"
                           [firstBarValueProp]="section.firstBarValueProp"
                           [secondBarValueProp]="section.secondBarValueProp"
                           [firstLineValueProp]="section.firstLineValueProp"
                           [secondLineValueProp]="section.secondLineValueProp"
                           [firstBarTitleProp]="section.firstBarTitleProp"
                           [secondBarTitleProp]="section.secondBarTitleProp"
                           [firstLineTitleProp]="section.firstLineTitleProp"
                           [secondLineTitleProp]="section.secondLineTitleProp"
                           [lineFilterProp]="section.lineFilterProp"
                           [xAxisLabel]="section.xaxisLabel"
                           [yAxisLabel]="section.yaxisLabel"
                           [yAxisFormat]="section.propFormat"
                           [isMobile]="isMobile">
    </double-bar-line-chart>
  </div>
  <div *ngSwitchDefault>{{data | json}}</div>
</div>