export class DayRangePickerConstants {
  public static get dayRange(): any[] {
    return [{
      display: 'Last One Week',
      value: 7
    }, {
      display: 'Last Two Weeks',
      value: 14
    }, {
      display: 'Last 30 Days',
      value: 30
    }, {
      display: 'Last 60 Days',
      value: 60
    }, {
      display: 'Last 90 Days',
      value: 90
    }, {
      display: 'Last 365 Days',
      value: 365
    }];
  }

  public static get dayRangeStrings(): any[] {
    return [
      'Last One Week',
      'Last Two Weeks',
      'Last 30 Days',
      'Last 60 Days',
      'Last 90 Days',
      'Last 365 Days'
    ];
  }
}
