import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-sub-option',
  templateUrl: './filter-sub-option.component.html',
  styleUrls: ['./filter-sub-option.component.scss']
})
export class FilterSubOptionComponent {
  @Input() public name: string;
  @Input() public image: string;
  @Input() public selected: boolean;
  @Input() public subtext: string;
  @Input() public buttonChips: any[] = [];

  @Output() public subOptionToggled = new EventEmitter<any>();
}
