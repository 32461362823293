import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  FETCH_ATTENDEE_DATA:              type('[Manage Attendees] Fetch Attendee Data'),
  FETCH_ATTENDEE_DATA_SUCCESS:      type('[Manage Attendees] Fetch Attendee Data Success'),
  FETCH_ATTENDEE_DATA_FAILURE:      type('[Manage Attendees] Fetch Attendee Data Failure'),
  UPDATE_CUSTOMER_ATTENDEES:        type('[Manage Attendees] Update Customer Attendees'),
  UPDATE_SPOT_WALK_IN_ATTENDEES:    type('[Manage Attendees] Update Spot Walk In Attendees'),
  REMOVE_SPOT_WALK_IN_CUSTOMER:     type('[Manage Attendees] Remove Spot Walk In Customer'),
  UPDATE_NON_SPOT_WALK_IN_ADULTS:   type('[Manage Attendees] Update Non Spot Walk In Adults'),
  UPDATE_NON_SPOT_WALK_IN_KIDS:     type('[Manage Attendees] Update Non Spot Walk In Kids'),
  SELECT_WALK_IN_CUSTOMER:          type('[Manage Attendees] Select Walk In Customer'),
  UPDATE_ATTENDEE_DATA:             type('[Manage Attendees] Update Attendee Data'),
  UPDATE_ATTENDEE_DATA_SUCCESS:     type('[Manage Attendees] Update Attendee Data Success'),
  UPDATE_ATTENDEE_DATA_FAILURE:     type('[Manage Attendees] Update Attendee Data Failure'),
};

export class FetchAttendeeData implements Action {
  public type = ActionTypes.FETCH_ATTENDEE_DATA;
  constructor(public payload: any = '') {}
}

export class FetchAttendeeDataSuccess implements Action {
  public type = ActionTypes.FETCH_ATTENDEE_DATA_SUCCESS;
  constructor(public payload: any = '') {}
}

export class FetchAttendeeDataFailure implements Action {
  public type = ActionTypes.FETCH_ATTENDEE_DATA_FAILURE;
  constructor(public payload: any = '') {}
}

export class UpdateCustomerAttendees implements Action {
  public type = ActionTypes.UPDATE_CUSTOMER_ATTENDEES;
  constructor(public payload: any = '') {}
}

export class UpdateSpotWalkInAttendees implements Action {
  public type = ActionTypes.UPDATE_SPOT_WALK_IN_ATTENDEES;
  constructor(public payload: any = '') {}
}

export class RemoveSpotWalkInCustomer implements Action {
  public type = ActionTypes.REMOVE_SPOT_WALK_IN_CUSTOMER;
  constructor(public payload: any = '') {}
}

export class UpdateNonSpotWalkInAdults implements Action {
  public type = ActionTypes.UPDATE_NON_SPOT_WALK_IN_ADULTS;
  constructor(public payload: any = '') {}
}

export class UpdateNonSpotWalkInKids implements Action {
  public type = ActionTypes.UPDATE_NON_SPOT_WALK_IN_KIDS;
  constructor(public payload: any = '') {}
}

export class SelectWalkInCustomer implements Action {
  public type = ActionTypes.SELECT_WALK_IN_CUSTOMER;
  constructor(public payload: any = '') {}
}

export class UpdateAttendeeData implements Action {
  public type = ActionTypes.UPDATE_ATTENDEE_DATA;
  constructor(public payload: any = '') {}
}

export class UpdateAttendeeDataSuccess implements Action {
  public type = ActionTypes.UPDATE_ATTENDEE_DATA_SUCCESS;
  constructor(public payload: any = '') {}
}

export class UpdateAttendeeDataFailure implements Action {
  public type = ActionTypes.UPDATE_ATTENDEE_DATA_FAILURE;
  constructor(public payload: any = '') {}
}

export type Actions
  = FetchAttendeeData
  | FetchAttendeeDataSuccess
  | FetchAttendeeDataFailure
  | UpdateCustomerAttendees
  | UpdateSpotWalkInAttendees
  | RemoveSpotWalkInCustomer
  | UpdateNonSpotWalkInAdults
  | UpdateNonSpotWalkInKids
  | SelectWalkInCustomer
  | UpdateAttendeeData
  | UpdateAttendeeDataSuccess
  | UpdateAttendeeDataFailure;
