<mat-card class="theme-card__card"
          (click)="setSelectedTemplate(template)"
          [ngClass]="{'theme-card__selected': isSelected$(template.title) | async}">
  <div fxLayout="column"
       fxLayoutAlign="space-between center"
       class="theme-card__main-panel">
    <div class="theme-card__card-title">{{template.title}}</div>
    <img alt="Theme Header Image" class="theme-card__header-image" [src]="isMobile ? template.mobileImage: template.image">
    <img alt="Theme Hero Image" class="theme-card__hero-image" [src]="isMobile ? template.mobileImageUrl: template.imageUrl">
  </div>
</mat-card>

