import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'automation-info-confirmation-dialog',
  templateUrl: 'automation-info-dialog.component.html',
  styleUrls: ['./automation-info-dialog.component.scss']
})

export class AutomationInfoDialogComponent {

  constructor(public dialogRef: MatDialogRef<AutomationInfoDialogComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
