import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataCardComponent {

  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public rows: any;
  @Input() public columns: any;
  @Input() public title: string;
  @Input() public hasPostTitle: boolean = false;
  @Input() public postTitle: string;
  @Input() public isMobile: boolean;
  @Input() public noResultsMessage: string;
  @Input() public noteProp: string;

  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onExternalLinkClick = new EventEmitter<any>();

  public get fullTitle(): string {
    if (this.hasPostTitle) {
      if (this.rows) {
        return `${this.title} (${this.rows.length})`;
      } else {
        return `${this.title} (0)`;
      }
    } else {
      return this.title;
    }
  }

  public rowClicked(row) {
    this.onRowClick.emit(row);
  }

  public externalLinkClicked(link) {
    this.onExternalLinkClick.emit(link);
  }
}
