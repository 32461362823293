import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sections-layout',
  templateUrl: './sections-layout.component.html',
  styleUrls: ['./sections-layout.component.scss']
})

export class SectionsLayoutComponent implements OnInit {
  @Input() public category: string = ' ';
  @Input() public sections: any[] = [];
  @Input() public tileData: any = {};
  @Input() public isMobile: boolean = false;
  public sectionsLayoutClass: string;

  public ngOnInit() {
    this.sectionsLayoutClass = `sections-layout__divider-${this.category}`;
  }

  public getSectionData(section: any) {
    if (section.prop) {
      return this.tileData[section.prop];
    } else {
      return this.tileData;
    }
  }

  public getAllButFirstSection(section) {
    return section.slice(1, section.length);
  }

  public flexAmount() {
    return 100 / this.sections.length;
  }

  public getBorderClass(isFirst) {
    if (!isFirst) {
      return `sections-layout__left-border-${this.category}`;
    }
  }

  public getAlignedClass(isFirst, isLast) {
    if (!isFirst) {
      return `sections-layout__divider-${this.category}`;
    } else if (!isLast) {
      return 'sections-layout__aligned-section';
    }
  }

  public applyCardMinWidth(section) {
    if (section && section[0].type !== 'numberDisplay') {
      return 'sections-layout__equal-card-size';
    }
  }
}
