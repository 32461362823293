<mat-nav-list class="template-summary__list" [ngClass]="{'template-summary__list-mobile': isMobile}">
  <mat-list-item class="template-summary__list-item"
                 [ngClass]="{'template-summary__remove-hover-effect': isDisabled}"
                 [disableRipple]="isDisabled"
                 (click)="summaryClicked()">
    <img mat-list-avatar
         alt="Template Image"
         [ngClass]="{'template-summary__image-border': isRoundedBorder}"
         src="{{imageURL}}"/>
    <h4 matLine class="template-summary__summary-header">
      {{templateName}}
    </h4>
    <span matLine class="template-summary__summary-message">
      <span [innerHTML]="details"></span>
    </span>
    <button mat-icon-button *ngIf="!isDisabled">
      <img alt="Chevron Icon"
           class="template-summary__chevron-icon"
           src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png">
    </button>
  </mat-list-item>
</mat-nav-list>
