<div fxLayout="row" fxLayoutAlign="center end" class="vertical-bar">
  <div *ngFor="let row of chartData"
       fxLayout="column"
       fxLayoutAlign="space-between center"
       [ngClass]="getData(row, listValueProp) !== 0 ? 'vertical-bar__fill' : 'vertical-bar__empty-fill'"
       [ngStyle]="{'height': getBarPercentage(row, listValueProp),
                   'width': barWidth,
                   'background-color': getData(row, listValueProp) !== 0 ? setColor(row) : '',
                   'border-color': getData(row, listValueProp) === 0 ? setColor(row) : ''
                  }">
    <div [ngClass]="getData(row, listValueProp) !== 0 ? 'vertical-bar__value' : 'vertical-bar__empty-value'">
      {{getData(row, listValueProp)}}
    </div>
    <div class="vertical-bar__title-{{category}}">
      {{getData(row, listTitleProp)}}
    </div>
  </div>
</div>
