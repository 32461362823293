<mat-chip-list>
    <ng-container *ngIf="filters.length > 0">
        <mat-chip-list [multiple]="true">
            <mat-chip
                class="filter-chips__chip"
                #chipRef="matChip"
                *ngFor="let filter of filters"
                [ngClass]="{'filter-chips__chip-selected': filter.selected}"
                [selected]="false"
                (click)="onFilterSelection.emit({refData : chipRef, filterData: filter})">
                {{filter.filterName}}
                <mat-icon *ngIf="filter.selected" class="filter-chips__done">done</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </ng-container>
</mat-chip-list>
<div *ngIf="helpText" fxLayout="row" fxLayoutAlign="end center" class="filter-chips__help">
    <help-text
        [helpText]="helpText"
        [displayOnLeft]="true">
    </help-text>
</div>
