import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { AppConstants } from './app/app.constants';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

const hostname = location.hostname;
// This is for production when the  hostname is 'spotlight.cfahome.com'
let faviconUrl = AppConstants.productionFaviconUrl;

if (hostname === 'localhost') {
  faviconUrl = AppConstants.localFaviconUrl;
} else if (hostname === 'qa.spotlight.cfahome.com') {
  faviconUrl = AppConstants.qaFaviconUrl;
}

let html = document.documentElement.innerHTML;
document.documentElement.innerHTML = html.replace('faviconUrl', faviconUrl);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

