import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  LOAD_SETTINGS:                      type('[Settings] Load Settings'),
  SAVE_DEFAULT_LOCATION:              type('[Settings] Save Default Location'),
  SAVE_EVENT_SETTINGS:                type('[Settings] Save Event Settings'),
  FUTURE_EVENTS_FOUND:                type('[Settings] Future Events Found'),
  FUTURE_EVENTS_ERROR:                type('[Settings] Event Future Error'),
  DEFAULT_LOCATION_UPDATED:           type('[Settings] Default Location Changed'),
  RSVP_REMINDER_CHANGED:              type('[Settings] Rsvp Reminder Changed'),
  EVENT_SAVE_ERROR:                   type('[Settings] Save Event Error'),
  EVENT_SAVE_SUCCESSFUL:              type('[Settings] Save Event Successful'),
  SUCCESS_MESSAGE_DISMISSED:          type('[Settings] Success Message Dismissed'),
};

export class LoadSettingsAction implements Action {
  public type = ActionTypes.LOAD_SETTINGS;
  constructor(public payload: any = '') { }
}

export class SaveDefaultLocationAction implements Action {
  public type = ActionTypes.SAVE_DEFAULT_LOCATION;
  constructor(public payload: any = '') { }
}

export class SaveEventSettingsAction implements Action {
  public type = ActionTypes.SAVE_EVENT_SETTINGS;
  constructor(public payload: any = '') { }
}

export class FutureEventsFoundAction implements Action {
  public type = ActionTypes.FUTURE_EVENTS_FOUND;
  constructor(public payload: any = '') { }
}

export class FutureEventErrorAction implements Action {
    public type = ActionTypes.FUTURE_EVENTS_ERROR;
    constructor(public payload: any = '') {}
}

export class DefaultLocationUpdatedAction implements Action {
  public type = ActionTypes.DEFAULT_LOCATION_UPDATED;
  constructor(public payload: any = '') { }
}

export class RsvpReminderChangedAction implements Action {
  public type = ActionTypes.RSVP_REMINDER_CHANGED;
  constructor(public payload: any = '') { }
}

export class EventSaveErrorAction implements Action {
  public type = ActionTypes.EVENT_SAVE_ERROR;
  constructor(public payload: any = '') { }
}

export class EventSaveSuccessfulAction implements Action {
  public type = ActionTypes.EVENT_SAVE_SUCCESSFUL;
  constructor(public payload: any = '') { }
}

export class SuccessMessageDismissedAction implements Action {
  public type = ActionTypes.SUCCESS_MESSAGE_DISMISSED;
  constructor(public payload: any = '') { }
}

export type Actions
  = LoadSettingsAction
  | SaveDefaultLocationAction
  | SaveEventSettingsAction
  | FutureEventsFoundAction
  | FutureEventErrorAction
  | DefaultLocationUpdatedAction
  | RsvpReminderChangedAction
  | EventSaveErrorAction
  | EventSaveSuccessfulAction
  | SuccessMessageDismissedAction;
