import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { browserRefreshed } from '../../../app.component';
import * as fromRoot from '../../../reducers';
import { DiscoverTile } from '../../../reducers/discover';
import { AnalyticsService } from '../../../core/analytics';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import * as engagement from '../../../actions/engagement';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-edit-third-party-promotion',
  templateUrl: './discover-edit-third-party-promotion.component.html',
  styleUrls: ['./discover-edit-third-party-promotion.component.scss']
})
export class DiscoverEditThirdPartyPromotionComponent implements OnInit {
  public tile$: Observable<DiscoverTile>;
  public title$: Observable<string>;
  public isMobile$: Observable<boolean>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public discoverTilesLoading$: Observable<boolean>;
  public availablePlatforms$: Observable<any>;
  public customerTypes$: Observable<any>;
  public startDate$: Observable<any>;
  public endDate$: Observable<any>;
  public endDateRestriction$: Observable<any>;
  public disabledDays$: Observable<string[]>;
  public termsAndConditionsEnabled$: Observable<boolean>;
  public selectedReward$: Observable<any>;
  public rewardCategories$: Observable<any[]>;
  public rewardList$: Observable<any[]>;
  public campaignName$: Observable<string>;
  public allDaysBlocked$: Observable<boolean>;

  constructor(private analytics: AnalyticsService,
              private router: Router,
              private store: Store<fromRoot.State>,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);
      this.title$ = this.tile$.pipe(map((tile) => tile.descriptionText.summary));
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.availablePlatforms$ = this.store.select(fromRoot.getEngagementPlatforms);
      this.customerTypes$ = this.store.select(fromRoot.getEngagementCustomerTypes);
      this.startDate$ = this.store.select(fromRoot.getEngagementStartDate);
      this.endDate$ = this.store.select(fromRoot.getEngagementEndDate);
      this.endDateRestriction$ = this.store.select(fromRoot.getEngagementEndDateRestriction);
      this.disabledDays$ = this.store.select(fromRoot.getEngagementDisabledDays);
      this.termsAndConditionsEnabled$ = this.store.select(fromRoot.getFeatureEnabled("third-party-promotion-terms-and-conditions"))
      this.selectedReward$ = this.store.select(fromRoot.getEngagementSelectedReward);
      this.rewardCategories$ = this.store.select(fromRoot.getCategorizedEngagementMenuItems);
      this.rewardList$ = this.store.select(fromRoot.getEngagementMenuItems);
      this.campaignName$ = this.store.select(fromRoot.getEngagementCampaignName);
      this.allDaysBlocked$ = this.store.select(fromRoot.getEngagementAllDaysBlocked);
    }
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }

  public promotionUpdated(payload) {
    this.store.dispatch(new engagement.UpdateEngagement(payload));
  }

  public updateReward(selectedReward) {
    this.store.dispatch(new engagement.SelectNewReward(selectedReward));
  }

  public updateCampaignName(event) {
    this.store.dispatch(new engagement.UpdateEngagement({ campaignName: event.target.value }));
  }

  public promotionCustomerTypesUpdate(payload) {
    this.store.dispatch(new engagement.UpdateEngagementCustomerTypes(payload));
  }

  public promotionPlatformsUpdated(payload) {
    this.store.dispatch(new engagement.UpdateEngagementPlatforms(payload));
  }

  public promotionDateUpdated(payload) {
    this.store.dispatch(new engagement.UpdateEngagementDates(payload));
  }

  public goToPreviewPage() {
    this.router.navigate(['/discover/edit/email-preview']);
  }

  public sendThirdPartyPromotion() {
    let config = {
      data: {
        title: '',
        displayText: 'Are you sure you want to create this promotion?',
        confirmText: 'Yes, Create Promotion',

      },
      autoFocus:false
    }
    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.SendEngagement());
      }
    });
  }
}
