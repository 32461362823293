import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'offer-type',
  templateUrl: './offer-type.component.html',
  styleUrls: ['./offer-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferTypeComponent {
  @Input() public offerType: string;
  @Input() public displaySnapIn: boolean = false;
}
