import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'email-copy',
  templateUrl: './email-copy.component.html',
  styleUrls: ['./email-copy.component.scss']
})

export class EmailCopyComponent {

  @Input() public displayAppOption: boolean = true;
  @Input() public isAppCopyUpdated: boolean;
  @Input() public isEmailCopyUpdated: boolean;
  @Input() public headerText: string = '';
  @Input() public emailCopyText: string = '';
  @Input() public appCopyText: string = '';
  @Input() public pushMessageText: string = '';
  @Input() public displayPushMessageOption: boolean = true;
  @Input() public emailCopy: string = '';
  @Input() public appCopy: string = '';
  @Input() public pushMessageCopy: string = '';
  @Input() public isPushMessageUpdated: boolean;
  @Input() public showGreyText: boolean;
  @Input() public isRestyle: boolean = false;

  @Output() public emailCopyClick = new EventEmitter<any>();
  @Output() public appCopyClick = new EventEmitter<any>();
  @Output() public pushMessageCopyClick = new EventEmitter<any>();

  public goToEmailCopy() {
    this.emailCopyClick.emit();
  }

  public goToAppCopy() {
    this.appCopyClick.emit();
  }

  public goToPushMessageCopy() {
    this.pushMessageCopyClick.emit();
  }
}
