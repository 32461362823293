import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscoverConstants } from '../../discover/discover.constants';
import { SharedConstants } from '../shared.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { clone, find, propEq } from 'ramda';
import * as moment from "moment-timezone";
import {EngageConstants} from "../../engage/engage.constants";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import * as engage from "../../actions/engage";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../reducers";

@Component({
  selector: 'app-free-item-in-cart-details',
  templateUrl: './free-item-in-cart-details.component.html',
  styleUrls: ['./free-item-in-cart-details.component.scss']
})

export class FreeItemInCartDetailsComponent {
  @Input() allDaysDisabled: boolean = false;
  @Input() areDatesWithinSevenDays;
  @Input() campaignName;
  @Input() customerTypes;
  @Input() destinations;
  @Input() disabledDays: number[] = [];
  @Input() endDate;
  @Input() endDateRestriction;
  @Input() endTime;
  @Input() endTimeEndRestriction;
  @Input() endTimeRestriction;
  @Input() isMobile;
  @Input() isReview;
  @Input() repeatPromotionButtons;
  @Input() repeatThisPromotion;
  @Input() rewardCategories;
  @Input() rewardList: [];
  @Input() selectedDestinationId;
  @Input() selectedDestinationIdsForMultiSelect;
  @Input() selectedLocation;
  @Input() selectedRepeatPromotion;
  @Input() selectedReward;
  @Input() selectedTimeOfDay;
  @Input() startDate;
  @Input() startTime;
  @Input() startTimeEndRestriction;
  @Input() startTimeRestriction;
  @Input() tile;
  @Input() stats;
  @Input() statsLoading;
  @Input() timeOfDayOptions;
  @Input() status;
  @Input() promoId;

  @Output() freeInCartCampaignNameUpdate = new EventEmitter<any>();
  @Output() freeInCartCustomerTypesUpdate = new EventEmitter();
  @Output() freeInCartDateUpdate = new EventEmitter<any>();
  @Output() freeInCartRewardUpdate = new EventEmitter<any>();
  @Output() onAnyEngagementChange = new EventEmitter<any>();
  @Output() onRepeatPromotionSelected = new EventEmitter<any>();
  @Output() onSelectedTimeOfDayChange = new EventEmitter<any>();
  @Output() onSendFreeInCart = new EventEmitter<any>();
  @Output() onStartTimeChanged = new EventEmitter<any>();
  @Output() updateMultiSelectDestinations = new EventEmitter();
  @Output() onGoToCalendar = new EventEmitter();
  @Output() onStopFreeInCartPromotion = new EventEmitter();
  @Output() goToDiscover = new EventEmitter<any>();
  @Output() goToPreviewPage = new EventEmitter<any>();


  public campaignNameFormControl = new FormControl();
  public campaignType = DiscoverConstants.freeItemInCartPromotion;
  public customTimeOfDay = SharedConstants.customTimeOfDay;
  public editForm = new FormGroup({ });
  public endTimeFormControl = new FormControl();
  public opened = false;
  public startTimeFormControl = new FormControl();
  public submitted = false;
  public weeklyRepeatPromotion = SharedConstants.weeklyRepeatPromotion;

  public FICAverageCheckHelp = SharedConstants.FICAverageCheckHelp;
  public FICTotalCustomersHelp = SharedConstants.FICTotalCustomersHelp;
  public FICTransactionsHelp = SharedConstants.FICTransactionsHelp;
  public FICSalesHelp = SharedConstants.FICSalesHelp;
  public FICCostHelp = SharedConstants.FICCostHelp;

  constructor(private store: Store<fromRoot.State>,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.campaignNameFormControl = new FormControl(
      '',
      [Validators.pattern('^(?!\\s*$).+'), Validators.required]
    );
    this.campaignNameFormControl.patchValue(this.campaignName);
    this.addValidationStartAndEndTime(this.selectedTimeOfDay);

  }

  public updateReward(selectedReward) {
    this.freeInCartRewardUpdate.emit(selectedReward);
    this.campaignNameFormControl.patchValue('Free ' + selectedReward.name);
    this.campaignNameFormControl.updateValueAndValidity()
  }

  public onCampaignNameChange(event) {
    this.freeInCartCampaignNameUpdate.emit(event);
  }

  public isOpened() {
    this.opened = !this.opened;
  }

  public updateRepeatPromotion(selectedRepeat) {
    this.onRepeatPromotionSelected.emit(selectedRepeat);
  }

  public updateRepeatPromotionDay(selectedDay) {
    this.onAnyEngagementChange.emit({ repeatPromotionSubId: selectedDay });
  }

  customerTypeUpdated(type) {
    let updatedType = clone(type);
    updatedType.selected = !type.selected;
    this.freeInCartCustomerTypesUpdate.emit(updatedType);
  }

  getNoCustomerTypeSelected() {
    return this.customerTypes ? find(propEq('selected', true))(this.customerTypes) === undefined : true;
  }

  public updateDestination(selectedDestinationId) {
    this.onAnyEngagementChange.emit({ selectedDestinationId });
  }

  public updateMultiSelectDestination(selectedDestinationIds) {
    this.updateMultiSelectDestinations.emit({ selectedDestinationIds });
  }

  public updateDate(dates) {
    this.freeInCartDateUpdate.emit(dates);
  }

  public updateTimeOfDay(timeOfDay) {
    this.addValidationStartAndEndTime(timeOfDay);
    this.onSelectedTimeOfDayChange.emit(timeOfDay);
  }

  public addValidationStartAndEndTime(selectedTimeOfDay) {
    if (selectedTimeOfDay === this.customTimeOfDay) {
      this.startTimeFormControl = new FormControl('', [Validators.required]);
      this.endTimeFormControl = new FormControl('', [Validators.required]);
      this.startTimeFormControl.markAsTouched();
      this.endTimeFormControl.markAsTouched();
      this.editForm.addControl('startTime', this.startTimeFormControl);
      this.editForm.addControl('endTime', this.endTimeFormControl);
    } else {
      this.editForm.removeControl('startTime');
      this.editForm.removeControl('endTime');
    }
  }

  public startTimeChanged(startTime) {
    this.onStartTimeChanged.emit(startTime)
  }

  public endTimeChanged(endTime) {
    this.onAnyEngagementChange.emit({ endTime });
  }

  public disableSubmit() {
    return this.isReview || this.campaignName.trim() === '' || this.getNoCustomerTypeSelected()
      || !(this.selectedDestinationIdsForMultiSelect.length > 0);
  }

  public isPromotionPastOrCancelled(): boolean {
    return (this.endDate).isBefore(moment()) || this.status === EngageConstants.cancelledStatus;
  }

  public cancelScheduledPromotion() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: 'Are you sure you want to cancel this promotion?',
      confirmText: 'Yes, Cancel Promotion',
      cancelText: 'No, Keep Promotion'
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engage.CancelFreeItemInCartFromCalendar({ fieldId: this.promoId }));
      }
    });
  }

  public goToConfirmation() {
    this.submitted = true;
    this.onSendFreeInCart.emit();
  }
}
