import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../reducers';
import { State } from '../../../reducers';
import { browserRefreshed } from '../../../app.component';
import { SelectNewReward } from '../../../actions/engagement';

@Component({
  templateUrl: './reward-selection.component.html',
  styleUrls: ['./reward-selection.component.scss']
})

export class RewardSelectionComponent implements OnInit {
  public rewardCategories$: Observable<any[]>;
  public rewardList$: Observable<any[]>;

  constructor(private store: Store<State>,
              private router: Router) {}

  public ngOnInit() {
    if (browserRefreshed) {
      this.router.navigate(['/discover']);
    } else {
      window.scroll(0, 0);
      this.rewardList$ = this.store.select(fromRoot.getEngagementRewards);
      this.rewardCategories$ = this.store.select(fromRoot.getCategorizedEngagementRewards);
    }
  }

  public rewardSelected(reward) {
    this.store.dispatch(new SelectNewReward(reward));
    this.router.navigate(['/discover/edit/edit-details']);
  }

  public goBack() {
    this.router.navigate(['/discover/edit/edit-details']);
  }
}
