import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import * as layout from '../actions/layout';

@Injectable()
export class LayoutEffects {
  @Effect()
  public updateView$: Observable<Action> = this.media.media$.pipe(
    debounceTime(200),
    map((mc: MediaChange) => mc.mqAlias),
    map((size) => new layout.ChangedViewSizeAction(size))
  );

  constructor(
    public dialog: MatDialog,
    public media: MediaObserver
  ) { }
}
