import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AnalyticsService } from '../../../core/analytics';
import * as fromRoot from '../../../reducers';
import * as engagement from '../../../actions/engagement';
import { browserRefreshed } from '../../../app.component';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { DiscoverTile } from '../../../reducers/discover';

@Component({
  templateUrl: './edit-promotion-details.component.html',
  styleUrls: ['./edit-promotion-details.component.scss'],
})
export class DiscoverEditPromotionDetailsComponent implements OnInit {
  public tile$: Observable<DiscoverTile>;
  public title$: Observable<string>;
  public noteText$: Observable<string>;
  public suggestedUseText$: Observable<string>;
  public campaignName$: Observable<string>;
  public engagementType$: Observable<string>;
  public engagementMode$: Observable<string>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public discoverTilesLoading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public readOnlyUser$: Observable<boolean>;
  public repeatThisPromotion$: Observable<any>;
  public selectedLocation$: Observable<any>;
  public destinations$: Observable<any[]>;
  public timeOfDayOptions$: Observable<any[]>;
  public startDate$: Observable<any>;
  public endDate$: Observable<any>;
  public disabledDays$: Observable<any[]>;
  public maxDaysAllowed$: Observable<number>;
  public selectedDestination$: Observable<any>;
  public selectedDestinationIdsForMultiSelect$: Observable<any>;
  public selectedTimeOfDay$: Observable<any>;
  public selectedRepeatPromotion$: Observable<any>;
  public repeatPromotionButtons$: Observable<any>;
  public startTime$: Observable<string>;
  public endTime$: Observable<string>;
  public startTimeRestriction$: Observable<string>;
  public endTimeRestriction$: Observable<string>;
  public areDatesWithinSevenDays$: Observable<boolean>;

  public engagementType: string;

  constructor(private analytics: AnalyticsService,
              private router: Router,
              private store: Store<fromRoot.State>,
              private dialog: MatDialog) {}

  public ngOnInit() {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.readOnlyUser$ = this.store.select(fromRoot.getReadOnlyUser);
      this.suggestedUseText$ = this.tile$.pipe(map((tile) => tile.descriptionText.suggestedUseText));
      this.title$ = this.tile$.pipe(map((tile) => tile.descriptionText.summary));
      this.noteText$ = this.tile$.pipe(map((tile) => tile.descriptionText.noteText));
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.engagementMode$ = this.store.select(fromRoot.getEngagementMode);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);
      this.selectedLocation$ = this.store.select(fromRoot.getSelectedLocation);
      this.destinations$ = this.store.select(fromRoot.getEngagementDestinationOptions);
      this.repeatThisPromotion$ = this.store.select(fromRoot.getEngageRepeatPromotionButtons);
      this.selectedDestination$ = this.store.select(fromRoot.getEngagementSelectedDestinationId);
      this.selectedDestinationIdsForMultiSelect$ = this.store.select(fromRoot.getEngagementSelectedDestinationIdsForMultiSelect);
      this.timeOfDayOptions$ = this.store.select(fromRoot.getEngagementTimeOfDayOptions);
      this.selectedTimeOfDay$ = this.store.select(fromRoot.getEngagementSelectedTimeOfDay);
      this.selectedRepeatPromotion$ = this.store.select(fromRoot.getEngagementSelectedRepeatPromotion);
      this.repeatPromotionButtons$ = this.store.select(fromRoot.getEngageWeeklyButtonsRepeatPromotion);
      this.campaignName$ = this.store.select(fromRoot.getEngagementCampaignName);
      this.startDate$ = this.store.select(fromRoot.getEngagementStartDate);
      this.endDate$ = this.store.select(fromRoot.getEngagementEndDate);
      this.disabledDays$ = this.store.select(fromRoot.getEngagementDisabledDays);
      this.maxDaysAllowed$ = this.store.select(fromRoot.getEngagementMaxDaysAllowed);
      this.startTime$ = this.store.select(fromRoot.getEngagementStartTime);
      this.endTime$ = this.store.select(fromRoot.getEngagementEndTime);
      this.startTimeRestriction$ = this.store.select(fromRoot.getEngagementStartTimeRestriction);
      this.endTimeRestriction$ = this.store.select(fromRoot.getEngagementEndTimeRestriction);
      this.areDatesWithinSevenDays$ = this.store.select(fromRoot.getEngagementAreDatesWithinSevenDays);
    }
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }

  public goToPreviewPage() {
    this.router.navigate(['/discover/edit/email-preview']);
  }

  public updateRepeatPromotion(selectedRepeatPromotion) {
    this.store.dispatch(new engagement.UpdateEngagementRepeatPromotion(selectedRepeatPromotion));
  }

  public sendPromotion() {
    this.startDate$.pipe(take(1)).subscribe((startDate) => {
      const formattedStartDate = startDate.format('dddd, MMMM DD YYYY');
      let config = new MatDialogConfig();
      config.data = {
        title: `You are about to create a promotion that starts on ${formattedStartDate}`,
        confirmText: 'Send',
        displayText: 'Once this promotion has been created, you can stop the automation from discover view.'
      };

      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.dispatch(new engagement.SendEngagement());
        }
      });
    });
  }

  public onAnyEngagementChange(engagementPayload: any) {
    this.store.dispatch(new engagement.UpdateEngagement(engagementPayload))
  }

  public onAnyDateChange(datePayload) {
    this.store.dispatch(new engagement.UpdateEngagementDates(datePayload));
  }

  public onStartTimeChange(timePayload) {
    this.store.dispatch(new engagement.UpdateEngagementStartTime(timePayload));
  }

  public onSelectedTimeOfDayChange(timeOfDay) {
    this.store.dispatch((new engagement.UpdateTimeOfDay(timeOfDay)))
  }

  public onUpdateMultiSelectDestinations(destinationIds) {
    this.store.dispatch(new engagement.UpdateEngagementMultiSelectDestinations(destinationIds));
  }
}
