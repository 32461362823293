<mat-toolbar *ngIf="!(isMobile$ | async)" color="accent" class="ngx-navbar">
    <br>
    <span routerLink="/">
        <img alt="Spotlight" class="ngx-navbar__logo" src="https://www.cfacdn.com/img/Spotlight/Icons/SpotlightFixNavLogo.svg">
    </span>
    <nav mat-tab-nav-bar [disablePagination]="true">
        <ng-container *ngFor="let link of navLinks; let i = index">
            <span *ngIf="!link.mobileOnly"
                  mat-tab-link
                  class="ngx-navbar__tab-elements"
                  [routerLink]="link.url"
                  [ngClass]="{'ngx-navbar__active': link.active}"
                  [active]="link.active">
                {{link.label}}
            </span>
        </ng-container>
    </nav>
    <span fxFlex></span>
    <button mat-icon-button (click)="locationMenu.open()" #locationTrigger>
        <mat-icon class="ngx-navbar__view-icon">location_on</mat-icon>
    </button>
    <div (click)="locationMenu.open()" #locationTrigger class="ngx-navbar__dropdown-text">
        {{(selectedLocation$ | async).locationName ? (selectedLocation$ | async).locationName : 'N/A'}}
    </div>
    <mat-select trigger="locationTrigger"
                #locationMenu
                class="ngx-navbar__view-select"
                panelClass="ngx-navbar__panel"
                [ngModel]="(selectedLocation$ | async)?.locationNumber"
                [disabled]="stateDisabled()"
                (selectionChange)="updateLocation($event.value)"
                (closed)="clearLocationFiltering()">
        <mat-form-field *ngIf="isStaffOrContractor" class="ngx-navbar__search placeholder-wrapper">
            <mat-icon matPrefix class="ngx-navbar__search-icon">search</mat-icon>
            <input matInput
                   [value]="searchTerm"
                   type="search"
                   placeholder="Search Locations"
                   (keyup)="onSearch($event)"
                   (keydown)="$event.stopPropagation()">
        </mat-form-field>
        <mat-option *ngFor="let loc of getDisplayLocations$() | async"
                    [value]="loc.locationNumber"
                    [disabled]="!loc.locationName">
            {{loc.locationName ? loc.locationName : 'N/A'}}
        </mat-option>
        <pagination *ngIf="isStaffOrContractor"
                    [totalElements]="(getFilteredLocations$() | async).length"
                    [pageSize]="pageSize"
                    [currentPage]="currentPage"
                    (pageRight)="pageRight()"
                    (pageLeft)="pageLeft()">
        </pagination>
    </mat-select>
    <span fxFlex.gt-sm="30px" fxFlex="1px"></span>
    <div class="ngx-navbar__menu-container">
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="ngx-navbar__menu-icon">menu</mat-icon>
        </button>
        <div [matMenuTriggerFor]="menu"
             class="ngx-navbar__notifications badge"
             *ngIf="(unreadNotificationCount$ | async) > 0">
            {{unreadNotificationCount$ | async}}
        </div>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openNotifications()">
            <mat-icon>notifications</mat-icon>
            <span>Notifications
                <span *ngIf="(unreadNotificationCount$ | async) > 0" class="ngx-navbar__notification-number">
                    {{unreadNotificationCount$ | async}} new
                </span>
            </span>
        </button>
        <button mat-menu-item (click)="openSettings()">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button>
        <button mat-menu-item (click)="openHelp()">
            <mat-icon>help_outline</mat-icon>
            <span>Help</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logoutUser()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>
<mat-toolbar *ngIf="(isMobile$ | async)">
    <mat-toolbar-row class="ngx-navbar-mobile__header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="ngx-navbar-mobile__location-container"
             [ngClass]="{'ngx-navbar-mobile__location-visibility-hide': !showLocationNumber}"
             (click)="navigateToMenu()">
            <span>
                <img alt="Location Marker Icon"
                     class="ngx-navbar-mobile__location-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/Location_Marker.png">
            </span>
            <span class="ngx-navbar-mobile__location-number">
                {{(selectedLocation$ | async)?.locationNumber}}
            </span>
        </div>
        <div routerLink="/" class="ngx-navbar-mobile__logo-container">
            <img alt="Spotlight"
                 class="ngx-navbar-mobile__logo"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/Spotlight_Logo.png">
        </div>
        <div routerLink="/menu" class="ngx-navbar-mobile__menu-container">
            <img alt="Sandwich Menu Bar Icon"
                 class="ngx-navbar-mobile__menu"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/Mobile_Menu.png">
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="ngx-navbar-mobile__subheader"
                     *ngIf="showSelectedLocationName"
                     fxLayout="row" fxLayoutAlign="center center">
        <span (click)="navigateToMenu()">
            <span>
                <img alt="Location Marker Icon"
                     class="ngx-navbar-mobile__location-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/Location_Marker.png">
            </span>
            <span class="ngx-navbar-mobile__location-number ngx-navbar-mobile__location-number-margin">
                {{(selectedLocation$ | async).locationName ? (selectedLocation$ | async).locationName : 'N/A'}}
            </span>
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<navbar-footer *ngIf="isMobile$ | async"
               [navLinks]=navLinks>
</navbar-footer>
