import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { EulaFoundAction } from '../actions/eula';
import * as fromRoot from '../reducers';
import { environment } from "../../environments/environment";
import { NavigationService } from "../navigation.service";


@Injectable()
export class EulaService {
  constructor(
    private store: Store<fromRoot.State>,
    private us: UserService,
    private http: HttpClient,
    private navService: NavigationService,
    private ehs: ErrorHandlerService
  ) {}

  public getEula(): Promise<any> {
    return new Promise((resolve) => {

        this.http.get(environment.spotlightApiUrl + '/api/eula/accept').pipe(
            catchError((error: any) => {
              resolve(error);
              return this.ehs.handleError(error);
            }))
            .subscribe((eulaData: any) => {

              this.store.dispatch(new EulaFoundAction(eulaData.responseObject));
              resolve(true);
            });



    });
  }

  public saveEulaAccept() {
    const url = `/api/eula/accept`;

    return this.http.put(environment.spotlightApiUrl + url, {}, { responseType: 'text' }).pipe(
      map((res: any) => res.responseObject),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public restartApp() {
    window.location.href = '/';
  }
}
