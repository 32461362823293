import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { browserRefreshed } from '../../../app.component';
import * as fromRoot from '../../../reducers';
import { DiscoverTile } from '../../../reducers/discover';
import { AnalyticsService } from '../../../core/analytics';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as engagement from '../../../actions/engagement';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-free-item-in0-cart-promotion',
  templateUrl: './discover-edit-free-item-in-cart.component.html',
  styleUrls: ['./discover-edit-free-item-in-cart.component.scss']
})

export class DiscoverEditFreeItemInCartComponent implements OnInit {
  public allDaysBlocked$: Observable<boolean>;
  public areDatesWithinSevenDays$: Observable<boolean>;
  public campaignName$: Observable<string>;
  public customerTypes$: Observable<any>;
  public destinations$: Observable<any[]>;
  public disabledDays$: Observable<string[]>;
  public discoverTilesLoading$: Observable<boolean>;
  public endDate$: Observable<any>;
  public endDateRestriction$: Observable<any>;
  public endTime$: Observable<string>;
  public endTimeEndRestriction$: Observable<string>;
  public endTimeRestriction$: Observable<string>;
  public error$: Observable<string>;
  public isMobile$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public repeatPromotionButtons$: Observable<any>;
  public repeatThisPromotion$: Observable<any>;
  public rewardCategories$: Observable<any[]>;
  public rewardList$: Observable<any[]>;
  public selectedDestinationId$: Observable<any>;
  public selectedDestinationIdsForMultiSelect$: Observable<any>;
  public selectedLocation$: Observable<any>;
  public selectedRepeatPromotion$: Observable<any>;
  public selectedReward$: Observable<any>;
  public selectedTimeOfDay$: Observable<any>;
  public startDate$: Observable<any>;
  public startTime$: Observable<string>;
  public startTimeEndRestriction$: Observable<string>;
  public startTimeRestriction$: Observable<string>;
  public tile$: Observable<DiscoverTile>;
  public timeOfDayOptions$: Observable<any[]>;

  constructor(private analytics: AnalyticsService,
              private dialog: MatDialog,
              private router: Router,
              private store: Store<fromRoot.State>) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.allDaysBlocked$ = this.store.select(fromRoot.getEngagementAllDaysBlocked);
      this.areDatesWithinSevenDays$ = this.store.select(fromRoot.getEngagementAreDatesWithinSevenDays);
      this.campaignName$ = this.store.select(fromRoot.getEngagementCampaignName);
      this.customerTypes$ = this.store.select(fromRoot.getEngagementCustomerTypes);
      this.destinations$ = this.store.select(fromRoot.getEngagementDestinationOptions);
      this.disabledDays$ = this.store.select(fromRoot.getEngagementDisabledDays);
      this.endDate$ = this.store.select(fromRoot.getEngagementEndDate);
      this.endDateRestriction$ = this.store.select(fromRoot.getEngagementEndDateRestriction);
      this.endTime$ = this.store.select(fromRoot.getEngagementEndTime);
      this.endTimeEndRestriction$ = this.store.select(fromRoot.getEngagementEndTimeEndRestriction);
      this.endTimeRestriction$ = this.store.select(fromRoot.getEngagementEndTimeRestriction);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.repeatPromotionButtons$ = this.store.select(fromRoot.getEngageWeeklyButtonsRepeatPromotion);
      this.repeatThisPromotion$ = this.store.select(fromRoot.getEngageRepeatPromotionButtons);
      this.rewardCategories$ = this.store.select(fromRoot.getCategorizedEngagementMenuItems);
      this.rewardList$ = this.store.select(fromRoot.getEngagementMenuItems);
      this.selectedDestinationId$ = this.store.select(fromRoot.getEngagementSelectedDestinationId);
      this.selectedDestinationIdsForMultiSelect$ = this.store.select(fromRoot.getEngagementSelectedDestinationIdsForMultiSelect);
      this.selectedLocation$ = this.store.select(fromRoot.getSelectedLocation);
      this.selectedRepeatPromotion$ = this.store.select(fromRoot.getEngagementSelectedRepeatPromotion);
      this.selectedReward$ = this.store.select(fromRoot.getEngagementSelectedReward);
      this.selectedTimeOfDay$ = this.store.select(fromRoot.getEngagementSelectedTimeOfDay);
      this.startDate$ = this.store.select(fromRoot.getEngagementStartDate);
      this.startTime$ = this.store.select(fromRoot.getEngagementStartTime);
      this.startTimeEndRestriction$ = this.store.select(fromRoot.getEngagementStartTimeEndRestriction);
      this.startTimeRestriction$ = this.store.select(fromRoot.getEngagementStartTimeRestriction);
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.timeOfDayOptions$ = this.store.select(fromRoot.getEngagementTimeOfDayOptions);
    }
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }

  public updateReward(selectedReward) {
    this.store.dispatch(new engagement.SelectNewReward(selectedReward));
  }

  public updateCampaignName(event) {
    this.store.dispatch(new engagement.UpdateEngagement({ campaignName: event.target.value }));
  }

  public goToPreviewPage() {
    this.router.navigate(['/discover/edit/email-preview']);
  }

  public updateRepeatPromotion(selectedRepeatPromotion) {
    this.store.dispatch(new engagement.UpdateEngagementRepeatPromotion(selectedRepeatPromotion));
  }

  public onAnyEngagementChange(engagementPayload: any) {
    this.store.dispatch(new engagement.UpdateEngagement(engagementPayload));
  }

  public updateMultiSelectDestinations(destinationIds) {
    this.store.dispatch(new engagement.UpdateEngagementMultiSelectDestinations(destinationIds));
  }

  public promotionCustomerTypesUpdate(payload) {
    this.store.dispatch(new engagement.UpdateEngagementCustomerTypes(payload));
  }

  public updateDate(payload) {
    this.store.dispatch(new engagement.UpdateEngagementDates(payload));
  }

  public onSelectedTimeOfDayChange(timeOfDay) {
    this.store.dispatch((new engagement.UpdateTimeOfDay(timeOfDay)))
  }

  public onStartTimeChange(timePayload) {
    this.store.dispatch(new engagement.UpdateEngagementStartTime(timePayload));
  }

  public onSendFreeInCart () {
    let config = {
      data: {
        title: '',
        displayText: 'Are you sure you want to create this promotion?',
        confirmText: 'Yes, Create Promotion',

      },
      autoFocus:false
    }
    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.SendEngagement());
      }
    });
  }
}
