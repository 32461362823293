<div class="chart" [ngStyle]="{'width': width + 'px', 'height': height + 'px'}">
  <canvas #myChart
          class="chart__canvas"
          [ngStyle]="{'margin-left': margin+'%',
                      'width': width/2 +'px',
                      'height': height/2 +'px'}">
  </canvas>
  <img *ngIf="backgroundImageUrl"
       alt="Chart Background Image"
       class="chart__canvas-background"
       [ngStyle]="{'width': (width-155) + 'px', 'height': (height-155) + 'px'}"
       [src]="backgroundImageUrl">
</div>
