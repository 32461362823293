import * as understand from '../actions/understand';
import * as user from '../actions/user';
import * as moment from 'moment-timezone';
import { filter, findIndex, groupBy, map, prop, propEq, values } from 'ramda';
import { UnderstandConstants } from '../understand/understand.constants';

export interface State {
  cfaOneComparison: any;
  dates: any;
  detailView: any;
  error: any;
  excludeCemRewards: boolean;
  loading: boolean;
  mobileComparison: any;
  searchBy: any;
  searchTerm: any;
  selectedAnalyticsCategories: string;
  selectedCategories: string[];
  selectedFilters: any[];
  tiles: any;
}

export const initialState: State = {
  cfaOneComparison: {
    data: { },
    error: false,
    loading: false
  },
  dates: {
    endDate: moment(),
    startDate: moment().subtract('90', 'days')
  },
  detailView : {
    error: null,
    loading: true,
    page: 0,
    pageSize: 20
  },
  error: false,
  excludeCemRewards: false,
  loading: true,
  mobileComparison: {
    data: { },
    error: false,
    loading: false,
  },
  searchBy: null,
  searchTerm: { termName: '' },
  selectedAnalyticsCategories: '',
  selectedCategories: UnderstandConstants.defaultSettingsCategories(),
  selectedFilters: [],
  tiles: []
};

export const initialTileState = {
  data: {},
  error: null,
  loading: true
};

let resetInitialState = (tiles) => {
  let setDefault = tiles.map((tile) => ({ ...tile, ...initialTileState }));
  let tilesById = groupBy(prop('id'), setDefault);
  tilesById = map((x) => x[0], tilesById);
  return tilesById;
};

export function reducer(state = initialState, action: understand.Actions | user.Actions): State {
  switch (action.type) {
    case understand.ActionTypes.CHANGE_PAGE: {
      const page = action.payload;
      return {
        ...state,
        detailView: {
          ...state.detailView,
          page,
          loading: true
        }
      };
    }

    case understand.ActionTypes.CHANGE_PAGE_SIZE: {
      const pageSize = action.payload;
      return {
        ...state,
        detailView: {
          ...state.detailView,
          pageSize,
          page: 0,
          loading: true
        }
      };
    }

    case understand.ActionTypes.CHANGE_SORT: {
      const payload = action.payload;

      return {
        ...state,
        detailView: {
          ...state.detailView,
          sortBy: payload.prop,
          sortOrder: payload.order,
          loading: true
        }
      };
    }

    case understand.ActionTypes.CLEAR_SEARCH: {
      return {
        ...state,
        searchTerm: initialState.searchTerm,
        detailView: {
          ...state.detailView,
          page: 0,
          loading: true
        }
      };
    }

    case understand.ActionTypes.ERROR_UNDERSTAND_TILE_DATA:
    case understand.ActionTypes.FOUND_UNDERSTAND_TILE_DATA: {
      const { id } = action.payload;
      return {
        ...state,
        tiles: { ...state.tiles, [id]: tileData(state.tiles[id], action) }
      };
    }

    case understand.ActionTypes.EXCLUDE_CEM_REWARDS: {
      const excludeCemRewards = action.payload;
      let tiles = (values(state.tiles));
      let isRewardTile = (tile) => (tile.category === 'treat');
      let rewardTiles = filter(isRewardTile, tiles);
      let sendRewardTile = resetInitialState(rewardTiles);
      let detailView = state.detailView;

      // If exclude CEM rewards has been toggled clear the data from the rewards drilldown
      if (excludeCemRewards !== state.excludeCemRewards) {
        detailView = {
          ...state.detailView,
          redemptionLocationComparison: {
            ...state.detailView.redemptionLocationComparison,
            viewData: []
          }
        };
      }

      return {
        ...state,
        detailView,
        excludeCemRewards,
        tiles: { ...state.tiles, ...sendRewardTile }
      };
    }

    case understand.ActionTypes.FETCH_COMPARISON: {
      const dates = action.payload;
      return {
        ...state,
        dates,
        cfaOneComparison: { loading: true },
        mobileComparison: { loading: true }
      };
    }

    case understand.ActionTypes.FETCH_UNDERSTAND_DETAIL_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        detailView: {
          ...state.detailView,
          error,
          loading: false
        }
      };
    }

    case understand.ActionTypes.FETCH_UNDERSTAND_DETAIL_SUCCESS: {
      const { viewData } = action.payload;
      let detailViewData = viewData;

      // TODO: Remove this formatting once BE sends consistent payloads for understand drill downs
      if (state.detailView.responseProp && state.detailView.responseProp !== 'responseObject') {
        detailViewData = {
          data: viewData[state.detailView.responseProp],
          numberOfPages: viewData.numberOfPages,
          totalElements: viewData.totalElements
        };
      } else {
        detailViewData = { data: viewData };
      }

      return {
        ...state,
        detailView: {
          ...state.detailView,
          ...detailViewData,
          loading: false
        }
      };
    }

    case understand.ActionTypes.FETCH_UNDERSTAND_TILES: {
      return { ...state, error: initialTileState.error, loading: true };
    }

    case understand.ActionTypes.FOUND_CFA_COMPARISON: {
      const payload = action.payload;
      return {
        ...state,
        cfaOneComparison: {  data: payload, loading: false }
      };
    }

    case understand.ActionTypes.FOUND_MOBILE_COMPARISON: {
      const payload = action.payload;
      return {
        ...state,
        mobileComparison: { loading: false, data: payload }
      };
    }

    case understand.ActionTypes.FOUND_UNDERSTAND_TILES: {
      const payload = action.payload;
      let tiles = resetInitialState(payload);
      return { ...state, tiles, loading: false };
    }

    case understand.ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG: {
      return {
        ...state,
        selectedFilters: [],
        searchTerm: initialState.searchTerm,
        detailView: {
          ...initialState.detailView
        }
      };
    }

    case understand.ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG_ERROR: {
      const error = action.payload;
      return {
        ...state,
        detailView: { error }
      };
    }

    case understand.ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG_SUCC: {
      const { detailViewObj } = action.payload;
      let selectedAnalyticsCategories = state.selectedAnalyticsCategories;

      // Set selectedAnalyticsCategories based on the ID of the data to allow routing back after a refresh
      if (detailViewObj && (detailViewObj.id === 'customerTransactions' || detailViewObj.id === 'mobileOrderStatsDetails'
          || detailViewObj.id === 'cateringDetails' || detailViewObj.id === 'topCateringProducts')) {
        selectedAnalyticsCategories = 'meet'
      } else if (detailViewObj && (detailViewObj.id === 'careDigitalRefund' || detailViewObj.id === 'careContactStatus'
          || detailViewObj.id === 'careContact' || detailViewObj.id === 'careStatus' || detailViewObj.id === 'careOfferStat')) {
        selectedAnalyticsCategories = 'care'
      } else if (detailViewObj && (detailViewObj.id === 'redemptionLocationComparison' || detailViewObj.id === 'aggregatedEvents'
          || detailViewObj.id === 'emailDetailsV2')) {
        selectedAnalyticsCategories = 'engage'
      }

      return {
        ...state,
        detailView: {
          ...state.detailView,
          ...detailViewObj,
          ...detailViewObj.body
        },
        selectedAnalyticsCategories
      };
    }

    case understand.ActionTypes.SEARCH_BY: {
      const searchBy = action.payload;
      return {
        ...state,
        searchBy,
        searchTerm: initialState.searchTerm,
        detailView: {
          ...state.detailView,
          page: 0
        }
      };
    }

    case understand.ActionTypes.SEARCH_TERM: {
      const searchTerm = action.payload;
      return {
        ...state,
        searchTerm,
        detailView: {
          ...state.detailView,
          page: 0,
          loading: true
        }
      };
    }

    case understand.ActionTypes.SELECT_ANALYTICS_CATEGORY: {
      const id = action.payload;
      return { ...state, selectedAnalyticsCategories: id };
    }

    case understand.ActionTypes.SELECT_CATEGORY: {
      const id = action.payload;
      let categories = [...state.selectedCategories];
      const index = categories.indexOf(id);
      if (index === -1) {
        categories = [...categories, id];
      } else {
        categories = categories.filter((c) => c !== id);
      }

      return { ...state, selectedCategories: categories };
    }

    case understand.ActionTypes.SELECT_DROPDOWN_FILTER: {
      let {selectedFilter, dropdownTitle, defaultFilter} = action.payload;
      let filters = [...state.selectedFilters];
      let filterObj = {
        filterId: dropdownTitle,
        filterName: selectedFilter,
        selected: true
      }
      let index = findIndex(propEq('filterId', dropdownTitle))(filters);
      if (index != -1){
        filters.splice(index,1)
      }
      if (selectedFilter != defaultFilter){
        filters = [...filters,filterObj]
      }

      return {
        ...state,
        selectedFilters: filters,
        detailView: { ...state.detailView, loading: true }
      };
    }

    case understand.ActionTypes.SELECT_FILTER_CHIP: {
      const filterChip = action.payload;
      let filters = [...state.selectedFilters];

      if (findIndex(propEq('filterId', filterChip.filterId))(filters) === -1) {
        let filter = {
          filterId: filterChip.filterId,
          filterName: filterChip.filterName,
          selected: true
        };
        filters = [...filters, filter];
      } else {
        filters = filters.filter((c) => c.filterId !== filterChip.filterId);
      }

      return {
        ...state,
        selectedFilters: filters,
        detailView: { ...state.detailView, loading: true }
      };
    }

    case understand.ActionTypes.SET_AS_ONLY_CATEGORY: {
      const categoryId = action.payload;

      return { ...state, selectedCategories: [categoryId] };
    }

    case understand.ActionTypes.UNDERSTAND_TILES_ERROR: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    case understand.ActionTypes.UPDATE_DATE_RANGE:
    case understand.ActionTypes.UPDATE_ENGAGE_ANALYTICS_DATE_RANGE:
    case understand.ActionTypes.UPDATE_MEET_ANALYTICS_DATE_RANGE:
    case understand.ActionTypes.UPDATE_RECOVER_ANALYTICS_DATE_RNG: {
      const updatedDates = action.payload;
      let tiles = resetInitialState(values(state.tiles));
      let newStartDate = moment(updatedDates.startDate);
      let oldEndDate = moment(state.dates.endDate);
      let newEndDate = newStartDate.isAfter(oldEndDate) ? newStartDate : updatedDates.endDate;
      let newDates = {
        startDate: newStartDate,
        endDate: newEndDate
      };

      return {
        ...state,
        dates: newDates,
        tiles,
        detailView: {
          ...initialState.detailView
        }
      };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      return {
        ...initialState,
        dates: state.dates
      };
    }

    default: {
      return state;
    }
  }
}

export function tileData(state, action) {
  switch (action.type) {
    case understand.ActionTypes.ERROR_UNDERSTAND_TILE_DATA: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }

    case understand.ActionTypes.FOUND_UNDERSTAND_TILE_DATA: {
      const { data } = action.payload;
      return { ...state, data, loading: false };
    }

    default: {
      return state;
    }
  }
}

export const getCfaOneComparison = (state: State) => state.cfaOneComparison;

export const getDates = (state: State) => state.dates;

export const getDetailViews = (state: State) => state.detailView;

export const getDropDownFilters = (state) => state.detailView.dropdownFilters;

export const getExcludeCemRewards = (state) => state.excludeCemRewards;

export const getMobileComparison = (state: State) => state.mobileComparison;

export const getSearchBy = (state) => state.searchBy;

export const getSearchTerm = (state) => state.searchTerm;

export const getSelectedAnalyticsCategories = (state) => state.selectedAnalyticsCategories;

export const getSelectedCategories = (state) => state.selectedCategories;

export const getSelectedFilters = (state) => state.selectedFilters;

export const getUnderstandError = (state) => state.error;

export const getUnderstandLoading = (state) => state.loading;

export const getUnderstandTiles = (state) => state.tiles;

export const getUseMembershipIconForMobileFlag = (state) => state.useMembershipIconForMobile;
