import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { getErrorPages, State } from '../../reducers/index';
import { PushErrorPageAction } from '../../actions/error';
import { AnalyticsService } from '../../core/analytics';

@Component({
  selector: 'error-message',
  styleUrls: ['./error-message.component.scss'],
  template: `
    <div class="error-message"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="space-around center">
      <mat-icon class="error-message__icon">sentiment_very_dissatisfied</mat-icon>
      <div class="error-message__tagline">Well, this is embarrassing...</div>
      <div class="error-message__error">{{error}}</div>
    </div>
  `
})

export class ErrorMessageComponent implements OnInit {
  @Input() public error: string;
  @Input() public page: string;

  public errorPages$: Observable<any>;

  constructor(private store: Store<State>, private analytics: AnalyticsService) {
    this.errorPages$ = this.store.select(getErrorPages);
  }

  public ngOnInit() {
    this.errorPages$.pipe(take(1)).subscribe((errorPages) => {
      // Make sure that the error is only sent once per user session
      if (errorPages.indexOf(this.page) < 0) {
        this.analytics.sendMParticleEvent(
          `${this.page} error`,
          { 'Error Message': this.error }
        );
        this.store.dispatch(new PushErrorPageAction(this.page));
      }
    });
  }
}
