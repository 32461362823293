<collapsible-card title="{{fullTitle}}"
                  [postTitle]="postTitle"
                  [loading]="loading"
                  [error]="error">
    <ng-container *ngIf="!loading && !error">
        <data-table [frontEndPaging]="true"
                    [isMobile]="isMobile"
                    [columns]="columns"
                    [rows]="rows"
                    [noteProp]="noteProp"
                    [noResultsMessage]="noResultsMessage"
                    (onExternalLinkClick)="externalLinkClicked($event)"
                    (onRowClick)="rowClicked($event)">
        </data-table>
    </ng-container>
</collapsible-card>
