import { Action } from '@ngrx/store';
import { type } from '../util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */
export const ActionTypes = {
  SEARCH:                          type('[Customer] Search'),
  SEARCH_BY:                       type('[Customer] Search BY'),
  CLEAR_SEARCH:                    type('[Customer] Clear Search'),
  DISPLAY_ERROR_TEXT:              type('[Customer] Display Error Text'),
  AUTOCOMPLETE_SEARCH:             type('[Customer] Autocomplete search'),
  AUTOCOMPLETE_FOUND:              type('[Customer] Autocomplete found'),
  AUTOCOMPLETE_ERROR:              type('[Customer] Autocomplete error'),
  CHANGE_PAGE_SIZE:                type('[Customer] Page size'),
  CHANGE_PAGE:                     type('[Customer] Change page'),
  CHANGE_VIEW:                     type('[Customer] Change view'),
  CHANGE_SORT:                     type('[Customer] Change sort'),
  MAKE_INACTIVE:                   type('[Customer] Make inactive'),
  MAKE_INACTIVE_SUCCESS:           type('[Customer] Make inactive success'),
  MAKE_INACTIVE_FAILURE:           type('[Customer] Make inactive failure'),
  MAKE_ACTIVE:                     type('[Customer] Make active'),
  MAKE_ACTIVE_SUCCESS:             type('[Customer] Make active success'),
  MAKE_ACTIVE_FAILURE:             type('[Customer] Make active failure'),
  FIND_CUSTOMER_COMPLETE:          type('[Customer] Find complete'),
  LOAD:                            type('[Customer] Load'),
  CUSTOMER_ERROR:                  type('[Customer] Error'),
  CUSTOMERS_SELECTED_FOR_ENGAGE:   type('[Customer] Customers selected for engage'),
  SELECT_CUSTOMERS:                type('[Customer] Select Customers'),
  CLEAR_SELECTED_CUSTOMERS:        type('[Customer] Clear selected customers'),
  AUTOCOMPLETE_SELECTED_CUSTOMER:  type('[Customer] Autocomplete selected customer'),
  AUTOCOMPLETE_SWITCH_LOCATION:    type('[Customer] Autocomplete switch locations')
};

export class SearchAction implements Action {
  public type = ActionTypes.SEARCH;
  constructor(public payload: string) { }
}

export class SearchByAction implements Action {
  public type = ActionTypes.SEARCH_BY;
  constructor(public payload: string) { }
}

export class ClearSearch implements Action {
  public type = ActionTypes.CLEAR_SEARCH;
  constructor(public payload: any = '') { }
}

export class DisplayErrorText implements Action {
  public type = ActionTypes.DISPLAY_ERROR_TEXT;
  constructor(public payload: any = '') { }
}

export class AutocompleteSearch implements Action {
  public type = ActionTypes.AUTOCOMPLETE_SEARCH;
  constructor(public payload: string) { }
}

export class AutocompleteFound implements Action {
  public type = ActionTypes.AUTOCOMPLETE_FOUND;
  constructor(public payload: any = '') { }
}

export class AutocompleteError implements Action {
  public type = ActionTypes.AUTOCOMPLETE_ERROR;
  constructor(public payload: string) { }
}

export class ChangePageSizeAction implements Action {
  public type = ActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload: any) { }
}

export class ChangePageAction implements Action {
  public type = ActionTypes.CHANGE_PAGE;
  constructor(public payload: any) { }
}

export class MakeInactiveAction implements Action {
  public type = ActionTypes.MAKE_INACTIVE;
  constructor(public payload: string[]) { }
}

export class MakeInactiveSuccessAction implements Action {
  public type = ActionTypes.MAKE_INACTIVE_SUCCESS;
  constructor(public payload: any) { }
}

export class MakeInactiveFailureAction implements Action {
  public type = ActionTypes.MAKE_INACTIVE_FAILURE;
  constructor(public payload: any) { }
}

export class MakeActiveAction implements Action {
  public type = ActionTypes.MAKE_ACTIVE;
  constructor(public payload: string[]) { }
}

export class MakeActiveSuccessAction implements Action {
  public type = ActionTypes.MAKE_ACTIVE_SUCCESS;
  constructor(public payload: any) { }
}

export class MakeActiveFailureAction implements Action {
  public type = ActionTypes.MAKE_ACTIVE_FAILURE;
  constructor(public payload: any) { }
}

export class ChangeViewAction implements Action {
  public type = ActionTypes.CHANGE_VIEW;

  constructor(public payload: string) { }
}

export class ChangeSortAction implements Action {
  public type = ActionTypes.CHANGE_SORT;

  constructor(public payload: any) { }
}

export class FindCustomersCompleteAction implements Action {
  public type = ActionTypes.FIND_CUSTOMER_COMPLETE;

  constructor(public payload: any) { }
}

export class CustomerError implements Action {
  public type = ActionTypes.CUSTOMER_ERROR;

  constructor(public payload: any) { }
}

export class CustomersSelectedForEngage implements Action {
  public type = ActionTypes.CUSTOMERS_SELECTED_FOR_ENGAGE;
  constructor(public payload: any) { }
}

export class SelectCustomers implements Action {
  public type = ActionTypes.SELECT_CUSTOMERS;
  constructor(public payload: any) { }
}

export class ClearSelectedCustomers implements Action {
  public type = ActionTypes.CLEAR_SELECTED_CUSTOMERS;
  constructor(public payload: any) { }
}

export class AutocompleteSelectedCustomer implements Action {
  public type = ActionTypes.AUTOCOMPLETE_SELECTED_CUSTOMER;
  constructor(public payload: any = '') { }
}

export class AutocompleteSwitchLocation implements Action {
  public type = ActionTypes.AUTOCOMPLETE_SWITCH_LOCATION;
  constructor(public payload: any = '') { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = SearchAction
  | SearchByAction
  | ClearSearch
  | DisplayErrorText
  | AutocompleteSearch
  | AutocompleteFound
  | AutocompleteError
  | ChangePageSizeAction
  | ChangePageAction
  | MakeInactiveAction
  | MakeInactiveSuccessAction
  | MakeInactiveFailureAction
  | MakeActiveAction
  | MakeActiveSuccessAction
  | MakeActiveFailureAction
  | ChangeViewAction
  | ChangeSortAction
  | FindCustomersCompleteAction
  | CustomerError
  | CustomersSelectedForEngage
  | SelectCustomers
  | ClearSelectedCustomers
  | AutocompleteSelectedCustomer
  |AutocompleteSwitchLocation;
