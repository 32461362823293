<spot-nav [title]="navTitle"
          [isMobile]="true"
          (back)="goBackWithUnsavedCheck()">
</spot-nav>
<div class="filter-display-for-mobile">
  <ng-container>
    <div *ngIf="((selectedFilters$ | async).length > 0 || (includedCustomers$ | async).length > 0 ||
                (excludedCustomers$ | async).length > 0 || (selectedSavedGroups$ | async).length > 0)
                && !(error$ | async)"
         fxLayout="row"
         fxLayoutAlign="space-between start"
         class="filter-display-for-mobile__show-filter-section">
      <div fxFlex
           class="filter-display-for-mobile__show-filter-section-outer"
           [ngClass]="{'filter-display-for-mobile__divider' : page === engagePage || (meetSavedGroupBeingEdited$ | async) !== null}">
        <div fxLayout="row" (click)="showAppliedFilters()">
          <div fxFlex="100">
            <div class="filter-display-for-mobile__clickable-text filter-display-for-mobile__applied-filter-btn">
              <mat-icon class="filter-display-for-mobile__mat-icon-red">visibility_on</mat-icon>
              Show Applied Filters
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(meetSavedGroupBeingEdited$ | async) !== null" fxFlex="50" class="filter-display-for-mobile__rename-group-btn">
        <div fxLayout="row" (click)="renameSavedGroup()">
          <div fxFlex="100">
            <div class="filter-display-for-mobile__clickable-text">
              <img class="filter-display-for-mobile__rename-group-image"
                   src="https://www.cfacdn.com/img/Spotlight/Icons/Edit_RedIcon.png" alt="">
              Rename This Group
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="page === engagePage"
           fxFlex="50"
           class="filter-display-for-mobile__show-filter-section-outer">
        <div fxLayout="row" (click)="showCustomersList()">
          <div fxFlex="100">
            <div class="filter-display-for-mobile__clickable-text">
              <img src="https://www.cfacdn.com/img/Spotlight/Icons/HeartIcon.svg" alt="">
              Show Customers List
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <filter-list [autocompleteCustomersLoading]="filteredCustomersLoading$ | async"
               [displayCustomerLoading]="displayCustomerLoading$ | async"
               [displaySavedGroupActions]="true"
               [error]="error$ | async"
               [excludedCustomers]="excludedCustomers$ | async"
               [filteredCustomers]="filteredCustomers$ | async"
               [filters]="filters$ | async"
               [filtersEndDate]="filtersEndDate$ | async"
               [filtersStartDate]="filtersStartDate$ | async"
               [filtersWithoutSelectedSubOption]="filtersWithoutSelectedSubOption$ | async"
               [filtersWithoutSelectedToggle]="filtersWithoutSelectedToggle$ | async"
               [includedCustomers]="includedCustomers$ | async"
               [loading]="loading$ | async"
               [mobile]="true"
               [page]="page"
               [savedGroupEditActive]="(meetSavedGroupBeingEdited$ | async) !== null"
               [savedGroupEditDisabled]="(disableSavedGroupEditAndDelete$ | async)"
               [savedGroupEditName]="(meetSavedGroupBeingEdited$ | async) !== null ? (meetSavedGroupBeingEdited$ | async).name : null"
               [savedGroups]="savedGroups$ | async"
               [savedGroupsError]="savedGroupsError$ | async"
               [savedGroupsLoading]="savedGroupsLoading$ | async"
               [selectedEngageCustomers]="filterDisplayCustomerCount$ | async"
               [selectedFilters]="selectedFilters$ | async"
               [selectedSavedGroups]="selectedSavedGroups$ | async"
               [totalCustomers]="totalCustomers$ | async"
               (addSavedGroup)="addSavedGroup($event)"
               (addSelectedFilter)="addSelectedFilter($event)"
               (apply)="apply()"
               (buttonChipClicked)="buttonChipToggled($event)"
               (buttonClicked)="buttonToggled($event)"
               (categoryExpansionToggled)="categoryToggled($event)"
               (customerSearch)="onCustomerSearch($event)"
               (customerSelection)="onCustomerSelection($event)"
               (deleteSavedGroup)="deleteSelectedSavedGroup($event)"
               (editSavedGroup)="editSelectedSavedGroup($event)"
               (goBack)="goBack()"
               (removeSavedGroup)="removeSavedGroup($event)"
               (removeSelectedFilter)="removeSelectedFilter($event)"
               (selectAllCustomers)="selectAllCustomers()"
               (sliderValueUpdated)="sliderValueUpdated($event)"
               (subOptionClicked)="subOptionToggled($event)"
               (twoVariableSliderValueUpdated)="twoVariableSliderValueUpdated($event)"
               (updateFilterDateRangeOption)="updateFilterDateRangeOption($event)"
               (updateFilterDates)="updateFilterDates($event)"
               (updateSavedGroup)="updateSavedGroup()">
  </filter-list>
</div>
