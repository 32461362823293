import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'double-slider',
  templateUrl: './double-slider.component.html',
  styleUrls: ['./double-slider.component.scss']
})
export class DoubleSliderComponent implements OnInit {
  @Input() public filter: any;

  @Output() public twoVariableSliderValueUpdated = new EventEmitter<any>();

  public lowSliderValue = null;
  public highSliderValue = null;
  public prevLowSliderValue = null;
  public prevHighSliderValue = null;

  public ngOnInit() {
    this.lowSliderValue = this.filter.sliderOptions.value;
    this.highSliderValue = this.filter.sliderOptions.highValue;
    this.prevLowSliderValue = this.filter.sliderOptions.value;
    this.prevHighSliderValue = this.filter.sliderOptions.highValue;
  }

  public updateSliderLowValue(value) {
    this.lowSliderValue = value;
  }

  public updateSliderHighValue(value) {
    this.highSliderValue = value;
  }

  public updateTwoVariableSliderValue(filter) {
    // check updated low value with prev low value and also check updated high value with prev high value
    // to prevent unnecessary API call if value not changes
    if (this.lowSliderValue !== this.prevLowSliderValue ||
        this.highSliderValue !== this.prevHighSliderValue) {
      const payload = {
        value: this.lowSliderValue,
        highValue: this.highSliderValue,
        id: filter.id,
        parentId: filter.parentCategory
      };
      this.twoVariableSliderValueUpdated.emit(payload);
    }
  }
}