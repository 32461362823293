<ng-container *ngIf="!(loading$ | async) && !(this.discoverTilesLoading$ | async) && !(error$ | async)">
  <div fxLayout="row" fxLayoutAlign="none center">
    <spot-nav fxFlex="100"
              [actionMenu]="false"
              [hideBackArrow]="!(isMobile$ | async)"
              [isMobile]="isMobile$ | async"
              [isSmallNav]="true"
              [title]="(tile$ | async).title"
              (back)="goToDiscover()">
    </spot-nav>
  </div>

  <app-free-item-in-cart-details [allDaysDisabled]="allDaysBlocked$ | async"
                                 [areDatesWithinSevenDays]="areDatesWithinSevenDays$ | async"
                                 [campaignName]="campaignName$ | async"
                                 [customerTypes]="customerTypes$ | async"
                                 [destinations]="destinations$ | async"
                                 [disabledDays]="disabledDays$ | async"
                                 [endDate]="endDate$ | async"
                                 [endDateRestriction]="endDateRestriction$ | async"
                                 [endTime]="endTime$ | async"
                                 [endTimeEndRestriction]="endTimeEndRestriction$ | async"
                                 [endTimeRestriction]="endTimeRestriction$ | async"
                                 [isMobile]="isMobile$ | async"
                                 [isReview]="false"
                                 [repeatPromotionButtons]="repeatPromotionButtons$ | async"
                                 [repeatThisPromotion]="repeatThisPromotion$ | async"
                                 [rewardCategories]="rewardCategories$ | async"
                                 [rewardList]="rewardList$ | async"
                                 [selectedDestinationId]="selectedDestinationId$ | async"
                                 [selectedDestinationIdsForMultiSelect]="selectedDestinationIdsForMultiSelect$ | async"
                                 [selectedLocation]="selectedLocation$ | async"
                                 [selectedRepeatPromotion]="selectedRepeatPromotion$ | async"
                                 [selectedReward]="selectedReward$ | async"
                                 [selectedTimeOfDay]="selectedTimeOfDay$ | async"
                                 [startDate]="startDate$ | async"
                                 [startTime]="startTime$ | async"
                                 [startTimeEndRestriction]="startTimeEndRestriction$ | async"
                                 [startTimeRestriction]="startTimeRestriction$ | async"
                                 [tile]="tile$ | async"
                                 [timeOfDayOptions]="timeOfDayOptions$ | async"
                                 (freeInCartCampaignNameUpdate)="updateCampaignName($event)"
                                 (freeInCartCustomerTypesUpdate)="promotionCustomerTypesUpdate($event)"
                                 (freeInCartDateUpdate)="updateDate($event)"
                                 (freeInCartRewardUpdate)="updateReward($event)"
                                 (onAnyEngagementChange)="onAnyEngagementChange($event)"
                                 (onRepeatPromotionSelected)="updateRepeatPromotion($event)"
                                 (onSelectedTimeOfDayChange)="onSelectedTimeOfDayChange($event)"
                                 (onSendFreeInCart)="onSendFreeInCart()"
                                 (onStartTimeChanged)="onStartTimeChange($event)"
                                 (goToPreviewPage)="goToPreviewPage()"
                                 (goToDiscover)="goToDiscover()"
                                 (updateMultiSelectDestinations)="updateMultiSelectDestinations($event)">
  </app-free-item-in-cart-details>
</ng-container>
