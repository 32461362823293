import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '../../core/analytics';
import { browserRefreshed } from '../../app.component';

@Component({
  selector: 'automation-info-page',
  templateUrl: './automation-info-page.component.html',
  styleUrls: ['./automation-info-page.component.scss']
})

export class AutomationInfoPageComponent {
  constructor(private router: Router, private analytics: AnalyticsService) {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.router.navigate(['/discover']);
    }
  }

  public goBack() {
    this.router.navigate(['/discover/edit/edit-details']);
  }
}
