import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/location.model';
import { contains } from 'ramda';
import { environment } from "../../environments/environment";

@Injectable()
export class SettingsService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService
  ) {}

  public loadFutureEventSettings() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/futureEvents`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject || []),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public saveUserSettings(defaultLocation, user) {
    let req = { ...user.userSettings, defaultLocation, username: user.userName };
    const url = `/api/user/settings/${user.userName}`;
    return this.http.post(environment.spotlightApiUrl + url, req).pipe(
      map((res: any) => res.responseObject || []),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  public saveEventSettings(originalEvents, updatedEvents) {
    let promiseArray = [];

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/calendarEvent`;

        promiseArray = updatedEvents.map((event) => {
          if (!contains(event, originalEvents)) {
            return  this.http.put(environment.spotlightApiUrl + url, event);
          }
        });

        return forkJoin(promiseArray);
      })
    );
  }
}
