<div *ngIf="!isUpdated && mode !== 'review'">
    <div class="treat-summary__customize-header">
        Reward Selection
    </div>
    <div class="treat-summary__info-text">
        {{infoText}}
    </div>
    <mat-nav-list class="treat-summary__list treat-summary__customize-list">
        <mat-list-item (click)="summaryClicked()">
            <img mat-list-avatar alt="Gift Icon" class="treat-summary__gift-image" src="../../../assets/img/red_gift.png">
            <h4 matLine class="treat-summary__summary-header">Select a Reward Template</h4>
            <button mat-icon-button>
                <img alt="Chevron Icon"
                     class="treat-summary__chevron-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png">
            </button>
        </mat-list-item>
    </mat-nav-list>
</div>
<div *ngIf="!isMultiReward && type !== 'discoverTreat' && (mode === 'review' || isUpdated)">
    <div class="treat-summary__header">
        Reward Selection
    </div>
    <template-summary [templateName]="title"
                      [imageURL]="imageURL"
                      [details]="details"
                      [isMobile]="isMobile"
                      [isDisabled]="isDisabled"
                      (onSummaryClick)="summaryClicked()">
    </template-summary>
</div>
<div *ngIf="isMultiReward && isUpdated">
    <div class="treat-summary__customize-header">
        Reward Selection
    </div>
    <div class="treat-summary__info-text">
        {{infoText}}
    </div>
    <mat-nav-list class="treat-summary__list treat-summary__customize-list">
        <mat-list-item [disableRipple]="isDisabled" (click)="summaryClicked()" class="treat-summary__list-item">
            <img mat-list-avatar alt="Gift Icon" class="treat-summary__gift-image" src="../../../assets/img/red_gift.png">
            <h4 matLine class="treat-summary__selected-header">{{title}}</h4>
            <button mat-icon-button *ngIf="!isDisabled">
                <img alt="Chevron Icon"
                     class="treat-summary__chevron-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png">
            </button>
        </mat-list-item>
    </mat-nav-list>
</div>
