import { Component, Input } from '@angular/core';
import { DiscoverConstants } from '../discover.constants';

@Component({
  selector: 'automation-info',
  templateUrl: './automation-info.component.html',
  styleUrls: ['./automation-info.component.scss']
})
export class AutomationInfoComponent {
  @Input() public disableActiveTreatsToggle: boolean = false;

  public infoSections: any[] = DiscoverConstants.automationInfoSections;
}
