import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnInit {
  public title;
  public displayText;
  public warningText;
  public confirmText;
  public hideCancel: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  public ngOnInit() {
    this.title = this.dialogData.title;
    this.displayText = this.dialogData.displayText;
    this.warningText = this.dialogData.warningText;
    this.confirmText = this.dialogData.confirmText;
    this.hideCancel = this.dialogData.hideCancel;
  }

  public closeDialog(shouldSend: boolean) {
    this.dialogRef.close(shouldSend);
  }
}
