import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'myDate' })
export class MyDatePipe implements PipeTransform {
  public transform(value: any, formatType: string = 'short', inputFormat: string = null): string {
    const date = moment(value, inputFormat);
    switch (formatType) {
      case 'short':
        return date.format('MMMM Do');
      case 'relative':
        return date.fromNow();
      case 'calendar':
        return date.format('MM/DD/YYYY');
      case 'year':
        return date.format('YYYY');
      default:
        return '';
    }
  }
}
