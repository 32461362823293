import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() public note: any;
  @Input() public disableChanges: boolean = false;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public subText: string = null;
  @Input() public deleteEnabled: boolean = true;
  @Input() public readOnly : boolean = false;

  @Output() public deleteNoteClicked = new EventEmitter();
  @Output() public saveNoteClicked = new EventEmitter();
  @Output() public editNoteClicked = new EventEmitter();
  @Output() public updateNoteText = new EventEmitter();
  @Output() public cancelEditClicked = new EventEmitter();

  @ViewChild('textInput') public textInputContainer;

  public ngOnInit() {
    if (this.note.isNewNote) {
      setTimeout(() => {
        this.textInputContainer.nativeElement.focus();
      });
    }
  }

  public updateText(text) {
    this.updateNoteText.emit(text.target.value);
  }

  public editNote() {
    this.editNoteClicked.emit();
  }

  public deleteNote() {
    this.deleteNoteClicked.emit();
  }

  public cancelEdit() {
    this.cancelEditClicked.emit();
  }

  public saveNote() {
    this.saveNoteClicked.emit();
  }
}
