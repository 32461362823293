<!-- Need two separate mat-selects to avoid 'changed after checked' errors -->
<!-- from formControl and disabled input interaction -->
<ng-container *ngIf="validationControl">
  <mat-form-field class="time-picker__container">
    <mat-select #timeSelect
                [ngModel]="time"
                [disabled]="disabled"
                [placeholder]="placeholder"
                [formControl]="validationControl"
                (selectionChange)="timeSelected.emit($event.value)">
      <mat-option *ngFor="let time of displayTimes" [disabled]="checkTimeDisabled(time)" [value]="time">{{time}}</mat-option>
    </mat-select>
    <!-- ngIf is required here to avoid angular mat select validation defect -->
    <mat-error *ngIf="timeSelect.errorState">{{placeholder}} is required</mat-error>
  </mat-form-field>
</ng-container>
<ng-container *ngIf="!validationControl">
  <mat-form-field class="time-picker__container">
    <div *ngIf="!validationControl">
      <mat-select [ngModel]="time"
                  [placeholder]="placeholder"
                  [disabled]="disabled"
                  (selectionChange)="timeSelected.emit($event.value)">
        <mat-option *ngFor="let time of displayTimes" [value]="time">{{time}}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
</ng-container>
