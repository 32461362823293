import * as activeRewards from '../actions/active-rewards';

export interface State {
  activeTreatCustomers: string[];
  error: any;
  includeActiveCustomers: boolean;
  loading: boolean;
}

export const initialState: State = {
  activeTreatCustomers: [],
  error: null,
  includeActiveCustomers: false,
  loading: false,
};

export function reducer(state = initialState, action: activeRewards.Actions): State {
  switch (action.type) {
    case activeRewards.ActionTypes.DIALOG_OPENED: {
      return { ...initialState };
    }

    case activeRewards.ActionTypes.ERROR_ACTIVE_REWARD_CUSTOMERS: {
      const error = action.payload;
      return {
        ...state,
        activeTreatCustomers: [],
        error,
        loading: false
      };
    }

    case activeRewards.ActionTypes.FETCH_ACTIVE_REWARD_CUSTOMERS: {
      return {
        ...state,
        includeActiveCustomers: false,
        loading: true
      };
    }

    case activeRewards.ActionTypes.FOUND_ACTIVE_REWARD_CUSTOMERS: {
      const activeTreatCustomers = action.payload ? action.payload : [];
      return {
        ...state,
        activeTreatCustomers,
        error: null,
        loading: false
      };
    }

    case activeRewards.ActionTypes.TOGGLE_ACTIVE_REWARD_CUSTOMERS: {
      const includeActiveCustomers = action.payload;
      return { ...state, includeActiveCustomers };
    }

    default: {
      return state;
    }
  }
}

export const getActiveRewardCustomers = (state: State) => state.activeTreatCustomers;

export const getError = (state: State) => state.error;

export const getIncludeActiveCustomers = (state: State) => state.includeActiveCustomers;

export const getLoading = (state: State) => state.loading;
