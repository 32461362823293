import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SharedConstants } from '../shared.constants';
import { AppConstants } from '../../app.constants';
import { MeetConstants } from '../../meet/meet.constants';
import { findIndex, propEq } from 'ramda';

@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  animations: [
    trigger('arrowState', [
      state('desc', style({
        transform: 'rotate(-90deg)',
        opacity: 1.0
      })),
      state('asc', style({
        transform: 'rotate(90deg)',
        opacity: 1.0
      })),
      transition('desc => asc', animate('150ms ease-in-out')),
      transition('asc => desc', animate('150ms ease-in-out')),
    ])
  ]
})

export class FilterListComponent {
  @Input() public filters: any[] = [];
  @Input() public mobile: boolean = false;
  @Input() public filtersStartDate: any;
  @Input() public filtersEndDate: any;
  @Input() public filtersWithoutSelectedSubOption: number;
  @Input() public filtersWithoutSelectedToggle: number;
  @Input() public loading: boolean;
  @Input() public error: any;
  @Input() public page: string;
  @Input() public savedGroups: any[] = [];
  @Input() public savedGroupsLoading: boolean;
  @Input() public savedGroupsError: string;
  @Input() public savedGroupEditActive: boolean = false;
  @Input() public savedGroupEditDisabled: boolean = false;
  @Input() public savedGroupEditName: string;
  @Input() public autocompleteCustomersLoading: boolean;
  @Input() public filteredCustomers: any[];
  @Input() public selectedFilters: any[] = [];
  @Input() public selectedSavedGroups: any[] = [];
  @Input() public includedCustomers: any[] = [];
  @Input() public excludedCustomers: any[] = [];
  @Input() public totalCustomers: number = 0;
  @Input() public selectedEngageCustomers: number = 0;
  @Input() public displaySavedGroupActions: boolean;
  @Input() public displayCustomerLoading: boolean = false;

  @Output() public categoryExpansionToggled = new EventEmitter<any>();
  @Output() public addSelectedFilter = new EventEmitter<any>();
  @Output() public removeSelectedFilter = new EventEmitter<any>();
  @Output() public addSavedGroup = new EventEmitter<any>();
  @Output() public removeSavedGroup = new EventEmitter<any>();
  @Output() public buttonClicked = new EventEmitter<any>();
  @Output() public buttonChipClicked = new EventEmitter<any>();
  @Output() public subOptionClicked = new EventEmitter<any>();
  @Output() public customerSelection = new EventEmitter<any>();
  @Output() public customerSearch = new EventEmitter<any>();
  @Output() public editSavedGroup = new EventEmitter<any>();
  @Output() public updateSavedGroup = new EventEmitter<any>();
  @Output() public deleteSavedGroup = new EventEmitter<any>();
  @Output() public updateFilterDateRangeOption = new EventEmitter<any>();
  @Output() public updateFilterDates = new EventEmitter<any>();
  @Output() public sliderValueUpdated = new EventEmitter<any>();
  @Output() public twoVariableSliderValueUpdated = new EventEmitter<any>();
  @Output() public goBack = new EventEmitter<any>();
  @Output() public selectAllCustomers = new EventEmitter<any>();
  @Output() public apply = new EventEmitter<any>();

  public deleteIconUrl = MeetConstants.deleteIcon;
  public editIconUrl = MeetConstants.editIcon;
  public customerSearchPlaceholder = SharedConstants.searchByCustomerNameMessage;
  public noCustomerResultsText = SharedConstants.noCustomersFoundMessage;
  public meetPage = AppConstants.meetPage;
  public engagePage = AppConstants.engagePage;
  public pastDatesOnly = true;
  public sliderTitle;
  public filterOnBetween = SharedConstants.filtersOnBetweenId;

  public getCategoryState(category) {
    if (category.open) {
      return 'asc';
    } else {
      return 'desc';
    }
  }

  public categoryToggled(category) {
    // If category is toggled, reset slider title so that other category's sliders don't show it
    this.sliderTitle = null;
    this.categoryExpansionToggled.emit(category);
  }

  public filterToggled(isChecked, filter) {
    // Reset button toggles to default states
    if (filter && filter.buttonToggles) {
      filter.buttonToggles.forEach((buttonToggle) => {
        buttonToggle.selected = buttonToggle.defaultToggle;
      });
    }

    if (isChecked) {
      this.addSelectedFilter.emit(filter);
    } else {
      this.removeSelectedFilter.emit(filter);
    }
    if (filter && filter.parentCategory === SharedConstants.filtersDateRangeId) {
      this.updateFilterDateRangeOption.emit();
      // The slider will return to the default value when toggled off so reset the sliderTitle variable
      this.sliderTitle = '';
    }
  }

  public buttonToggled(categoryId, filter, valueId) {
    this.buttonClicked.emit({ categoryId, filter, valueId });
    // Update the filters toggle values
    filter.buttonToggles.forEach((buttonToggle) => {
      buttonToggle.selected = buttonToggle.id === valueId;
    });
  }

  public buttonChipToggled(chip, filter, chipSection) {
    this.buttonChipClicked.emit({ chip, filter, chipSection });
  }

  public subOptionToggled(filter, subOption) {
    this.subOptionClicked.emit({ filter, subOption });
    if (filter && filter.subOptions) {
      let index = findIndex(propEq('id', subOption.id))(filter.subOptions);
      filter.subOptions[index].selected = !filter.subOptions[index].selected;
    }
  }

  public savedGroupToggled(isChecked, savedGroup) {
    if (isChecked) {
      this.addSavedGroup.emit(savedGroup);
    } else {
      this.removeSavedGroup.emit(savedGroup);
    }
  }

  public onCustomerSearch(term) {
    this.customerSearch.emit(term);
  }

  public onCustomerSelection(customer, filter) {
    let object = { customer : customer, filter : filter };
    this.customerSelection.emit(object);
  }

  public savedGroupEdit(group) {
    this.editSavedGroup.emit(group);
  }

  public savedGroupDelete(group) {
    this.deleteSavedGroup.emit(group);
  }

  public updateDate(dates) {
    this.updateFilterDates.emit(dates);
  }

  public isApplyDisabled(): boolean {
    return (this.selectedFilters.length < 1 && this.includedCustomers.length < 1 && this.excludedCustomers.length < 1
      && this.selectedSavedGroups.length < 1) || this.filtersWithoutSelectedToggle !== 0 || this.filtersWithoutSelectedSubOption !== 0
      || this.selectedEngageCustomers < 1;
  }
}
