<mat-dialog-content *ngIf="(sendLoading$ | async)"
                    fxLayout="column">
  <div fxLayout="row"
       fxLayoutAlign="start"
       [ngClass]="{'engage-confirmation-dialog__mobile-loader-container': (isMobile$ | async)}">
    <div class="engage-confirmation-dialog__loading-wheel"
         [ngClass]="{'engage-confirmation-dialog__mobile-loading-wheel': (isMobile$ | async)}"
         [cfaLoading]="true">
    </div>
    <div class="engage-confirmation-dialog__loader-text-wrapper"
         [ngClass]="{'engage-confirmation-dialog__mb-loader-text-wrapper': (isMobile$ | async)}">
      <h3 class="engage-confirmation-dialog__loader-text">
        {{sendConfirmationAction$ | async}}
      </h3>
    </div>
  </div>
</mat-dialog-content>
<!-- Show error -->
<mat-dialog-content *ngIf="(sendError$ | async) && !(sendLoading$ | async)"
                    fxLayout="column"
                    class="engage-confirmation-dialog__wrapper"
                    [ngClass]="{'engage-confirmation-dialog__mobile-wrapper': (isMobile$ | async)}">
  <div *ngIf="!(failedPlatforms$ | async) || (failedPlatforms$ | async).length == 0" fxLayout="row" fxLayoutAlign="start">
    <div class="engage-confirmation-dialog__icon-wrapper"
         [ngClass]="{'engage-confirmation-dialog__mobile-icon-wrapper': (isMobile$ | async)}">
      <span *ngIf="(sendError$ | async)">
        <mat-icon class="engage-confirmation-dialog__error-icon"
                  [ngClass]="{'engage-confirmation-dialog__mobile-error-icon': (isMobile$ | async)}">
          warning
        </mat-icon>
      </span>
    </div>
    <div>
      <ng-container *ngIf="sendError$ | async">
        <h3 class="engage-confirmation-dialog__heading-text"
            [ngClass]="{'engage-confirmation-dialog__mobile-heading-text': (isMobile$ | async)}">
          {{(sendConfirmationAction$ | async).headingMessage}}
        </h3>
        <p class="engage-confirmation-dialog__sub-text">
          {{(sendConfirmationAction$ | async).errorSubText}}
        </p>
      </ng-container>
    </div>
  </div>
  <!-- Show Third Party Error -->
  <div *ngIf = "(failedPlatforms$ | async) && (failedPlatforms$ | async).length > 0" fxLayout="row" fxLayoutAlign="start">
    <div fxLayout="column">
      <h3 class="engage-confirmation-dialog__heading-text"
          [ngClass]="{'engage-confirmation-dialog__mobile-heading-text': (isMobile$ | async)}">
        This promotion is only active on {{successfulPlatforms$ | async}}. Do you want to continue?
      </h3>
      <p class="engage-confirmation-dialog__sub-text">
        It will not be available on {{failedPlatforms$ | async}}.
      </p>
    </div>
  </div>
</mat-dialog-content>
<!-- success message -->
<mat-dialog-content *ngIf="!(sendLoading$ | async) && !(sendError$ | async)"
                    fxLayout="column"
                    class="engage-confirmation-dialog__wrapper"
                    [ngClass]="{'engage-confirmation-dialog__mobile-wrapper': (isMobile$ | async)}">
  <div fxLayout="row"
       fxLayoutAlign="start">
    <div class="engage-confirmation-dialog__icon-wrapper"
         [ngClass]="{'engage-confirmation-dialog__mobile-icon-wrapper': (isMobile$ | async)}">
      <span *ngIf="!(areAllDuplicates$ | async) && !(isHoldouts$ | async) && !(sendError$ | async)">
        <mat-icon class="engage-confirmation-dialog__success-icon"
                  [ngClass]="{'engage-confirmation-dialog__mobile-success-icon': (isMobile$ | async)}">
          check_circle_outline
        </mat-icon>
      </span>
      <span *ngIf="(areAllDuplicates$ | async) || (isHoldouts$ | async) || (sendError$ | async)"
            [ngClass]="{'engage-confirmation-dialog__mobile-icon-wrapper': (isMobile$ | async)}">
        <mat-icon class="engage-confirmation-dialog__error-icon"
                  [ngClass]="{'engage-confirmation-dialog__mobile-error-icon': (isMobile$ | async)}">
          warning
        </mat-icon>
      </span>
    </div>
    <div>
      <ng-container *ngIf="!(areAllDuplicates$ | async) && !(isHoldouts$ | async) && !(sendError$ | async)">
        <h3 class="engage-confirmation-dialog__heading-text"
            [ngClass]="{'engage-confirmation-dialog__mobile-heading-text': (isMobile$ | async)}">
          {{(sendConfirmationAction$ | async).headingMessage}}
        </h3>
        <p class="engage-confirmation-dialog__sub-text">
          {{(sendConfirmationAction$ | async).subText}}
        </p>
      </ng-container>
      <ng-container *ngIf="(areAllDuplicates$ | async) && !(sendError$ | async)">
        <h3 class="engage-confirmation-dialog__heading-text"
            [ngClass]="{'engage-confirmation-dialog__mobile-heading-text': (isMobile$ | async)}">
          Please Note
        </h3>
        <p class="engage-confirmation-dialog__sub-text">
          All customers selected in this reward campaign have the same item already available.
          To send duplicate reward(s) to Customer(s) for recovery needs, use either the “Sorry for the Snag” or “We
          Apologize” reward theme.
          Otherwise, try changing the dates or selecting a different reward.
        </p>
      </ng-container>
      <ng-container *ngIf="(isHoldouts$ | async) && !(sendError$ | async)">
        <h3 class="engage-confirmation-dialog__heading-text"
            [ngClass]="{'engage-confirmation-dialog__mobile-heading-text': (isMobile$ | async)}">
          Please Note
        </h3>
        <p class="engage-confirmation-dialog__sub-text">
          All customer(s) selected in this reward campaign are in the National control group and ineligible to receive
          Spotlight Rewards for 30 days.
          For Recovery effort, use Restaurant Contacts on Recover tab.
        </p>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="!(sendLoading$ | async)"
                    fxLayout="column"
                    class="engage-confirmation-dialog__wrapper"
                    [ngClass]="{'engage-confirmation-dialog__mobile-wrapper': (isMobile$ | async)}">
  <div fxLayout="row"
       fxLayoutAlign="space-around start">
    <div fxFlex="100">
      <div mat-dialog-actions
           class="engage-confirmation-dialog__btn-wrapper"
           [ngClass]="{'engage-confirmation-dialog__mobile-btn-wrapper': (isMobile$ | async)}">
        <button *ngIf="page === engagePage && !(areAllDuplicates$ | async) && !(isHoldouts$ | async) && !(sendError$ | async)"
                mat-raised-button
                class="engage-confirmation-dialog__action-btn"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="goToCalendar()">
          View on Calendar
        </button>
        <button *ngIf="page === engagePage && (mode$|async) != editMode"
                mat-raised-button
                class="engage-confirmation-dialog__action-btn"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="backToEngage()">
          Back to Engage
        </button>
        <button *ngIf="page === engagePage && (mode$ |async) == editMode && (sendError$|async)"
                mat-raised-button
                class="engage-confirmation-dialog__action-btn"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="backToFullCalendar()">
          Back to Calendar
        </button>
        <button *ngIf="page === discoverPage && (type$ | async) === thirdPartyPromotionType && (!(failedPlatforms$ | async) || (failedPlatforms$|async).length == 0) && !(sendError$|async)"
                mat-raised-button
                class="engage-confirmation-dialog__cancel-button"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="goToCalendar()">
          Calendar View
        </button>
        <button *ngIf="page === discoverPage && (failedPlatforms$ | async).length == 0"
                mat-raised-button
                class="engage-confirmation-dialog__action-btn"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="backToDiscover()">
          Back to Discover
        </button>
        <button *ngIf="page === discoverPage && (type$ |async) === thirdPartyPromotionType && ((failedPlatforms$ | async) && (failedPlatforms$ | async).length > 0 )"
                mat-raised-button
                class="engage-confirmation-dialog__cancel-button"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="cancelThirdPartyPromotion()">
          Cancel Promotion
        </button>
        <button *ngIf="page === discoverPage && (type$ |async) === thirdPartyPromotionType && ((failedPlatforms$ | async) && (failedPlatforms$ | async).length > 0 )"
                mat-raised-button
                class="engage-confirmation-dialog__action-btn"
                [ngClass]="{'engage-confirmation-dialog__mobile-action-btn': (isMobile$ | async)}"
                (click)="createThirdPartyPromotion()">
          Continue
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
