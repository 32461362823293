import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

// When adding an animation, make sure to add the width, height, and display styling to the host in the component
export const routeTransitionAnimations = trigger('slideUpAnimation', [
  transition('discover => menu, ' +
    'meet => menu, ' +
    'recover => menu, ' +
    'templateSelection => menu, ' +
    'understand => menu, ' +
    'calendar => menu, ' +
    'discover => discoverEdit, ' +
    'discoverEdit => automationInfo, ' +
    'meet => profile, ' +
    'meet => filters, ' +
    'meet => filterDisplayForMobile, ' +
    'meet => savedFilters, ' +
    'meet => editFilters, ' +
    'meet => appliedFilters, '+
    'recover => reportDetails, ' +
    'recover => understandDetail, ' +
    'recover => actionSelection, ' +
    'reportDetails => actionSelection, ' +
    'recoverRespond => recoverCategories, ' +
    'appliedFilters => additionalFiltersCustomer, ' +
    'understandDetail => reportDetails, ' +
    'calendar => engage,'+
    'appliedFilters => showCustomersList,' +
    'showCustomersList => appliedFilters,' +
    'filterDisplayForMobile => appliedFilters,' +
    'filterDisplayForMobile => showCustomersList',
  [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'white'
      })
    ]),
    query(':enter', [style({ zIndex: '100' })]),
    query(':leave', animateChild()),
    group([
      query(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.75s ease-in-out', style({ transform: 'translateY(0%)' }))
      ]),
      query(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('0.75s ease-in-out', style({ transform: 'translateY(0%)' }))
      ]),

      // "animate" inner content so that it persists when there are child routes
      query(':leave *', [
        style({ }),
        animate(1, style({ }))
      ])
    ]),
    query(':enter', animateChild())
  ]
  ),
  transition('menu => discover, ' +
    'menu => meet, ' +
    'menu => recover, ' +
    'menu => templateSelection, ' +
    'menu => understand, ' +
    'menu => calendar, ' +
    'reportDetails => recover, ' +
    'understandDetail => recover, ' +
    'actionSelection => recover, ' +
    'recoverRespond => recover, ' +
    'recoverRespond => reportDetails, ' +
    'actionSelection => reportDetails, ' +
    'actionSelection => recoverRespond, ' +
    'recoverCategories => recoverRespond, ' +
    'discoverEdit => discover, ' +
    'automationInfo => discoverEdit, ' +
    'profile => meet, ' +
    'filters => meet, ' +
    'filterDisplayForMobile => meet, ' +
    'savedFilters => meet, ' +
    'editFilters => meet, ' +
    'appliedFilters => meet, ' +
    'additionalFiltersCustomer => appliedFilters, ' +
    'reportDetails => understandDetail, ' +
    'engage => calendar,' +
    'showCustomersList => engage,' +
    'showCustomersList => filterDisplayForMobile,' +
    'showCustomersList => appliedFilters,'+
    'appliedFilters => filterDisplayForMobile',
  [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'white'
      })
    ]),
    query(':enter', animateChild()),
    query(':leave', [style({ zIndex: '100' })]),
    group([
      query(':enter', [
        style({ transform: 'translateY(0%)' }),
        animate('0.75s ease-in-out', style({ transform: 'translateY(0%)' }))
      ]),
      query(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('0.75s ease-in-out', style({ transform: 'translateY(100%)' }))
      ]),

      // "animate" inner content so that it persists when there are child routes
      query(':leave *', [
        style({ }),
        animate(1, style({ }))
      ])
    ])
  ]
  )
]);
