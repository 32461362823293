import { Component, Input, OnInit } from '@angular/core';
import { max, reduce } from 'ramda';
import { SharedConstants } from '../../../shared.constants';

@Component({
  selector: 'vertical-bar',
  templateUrl: './vertical-bar.component.html',
  styleUrls: ['./vertical-bar.component.scss']
})

export class VerticalBarComponent implements OnInit {
  @Input() public chartData: any = [];
  @Input() public category: string;
  @Input() public listTitleProp: string;
  @Input() public listValueProp: string;
  public maxDataValue: number;
  public barWidth: string;

  public ngOnInit() {
    const values = this.chartData.map((data) => data[this.listValueProp]);
    this.maxDataValue = reduce(max, 0, values);
    this.barWidth = `${(40 / Math.sqrt(this.chartData.length))}%`;
  }

  public getData(chartData, prop) {
    return chartData[prop] || 0;
  }

  public getBarPercentage(chartData, prop) {
    const currentVal = this.getData(chartData, prop);
    let height = (currentVal / this.maxDataValue) * 100 - 2;

    // Smallest height that we allow so that the bar is visible
    if (!height || height < 10) {
      height = 10;
    }
    return `${height}%`;
  }

  public setColor(chartData) {
    if (chartData.colorCode) {
      return chartData.colorCode;
    } else {
      return SharedConstants.categoryColor(this.category);
    }
  }
}
