export class NavMenuConstants {
  public static get navMenuOptions(): any[] {
    return [
      {
        title: 'Change your restaurant',
        isLocationMenu: true,
        options: [
          {
            id: 'location',
            label: 'Location',
            imageURL: '../assets/img/location_marker.png',
            showChangeBtn: true
          }
        ]
      },
      {
        title: 'More Options',
        options: [
          {
            id: 'calendar',
            label: 'Calendar',
            imageURL: '../assets/img/calendar-menu.png'
          }
        ]
      },
      {
        title: 'Account',
        options: [
          {
            id: 'settings',
            label: 'Settings',
            imageURL: '../assets/img/settings-menu.png'
          },
          {
            id: 'notifications',
            label: 'Notifications',
            imageURL: '../assets/img/notifications-menu.png'
          },
          {
            id: 'help',
            label: 'Help',
            imageURL: '../assets/img/help-menu.png'
          },
          {
            id: 'give-feedback',
            label: 'Give feedback',
            imageURL: '../assets/img/give-feedback-menu.png'
          }
        ]
      },
      {
        title: 'Leave Spotlight',
        options: [
          {
            id: 'logout',
            label: 'Log out',
            imageURL: '../assets/img/logout-menu.png'
          }
        ]
      }
    ];
  }

  public static get navMenuOptionsWithoutCalendar(): any[] {
    return [
      {
        title: 'Change your restaurant',
        isLocationMenu: true,
        options: [
          {
            id: 'location',
            label: 'Location',
            imageURL: '../assets/img/location_marker.png',
            showChangeBtn: true
          }
        ]
      },
      {
        title: 'Account',
        options: [
          {
            id: 'settings',
            label: 'Settings',
            imageURL: '../assets/img/settings-menu.png'
          },
          {
            id: 'notifications',
            label: 'Notifications',
            imageURL: '../assets/img/notifications-menu.png'
          },
          {
            id: 'help',
            label: 'Help',
            imageURL: '../assets/img/help-menu.png'
          },
          {
            id: 'give-feedback',
            label: 'Give feedback',
            imageURL: '../assets/img/give-feedback-menu.png'
          }
        ]
      },
      {
        title: 'Leave Spotlight',
        options: [
          {
            id: 'logout',
            label: 'Log out',
            imageURL: '../assets/img/logout-menu.png'
          }
        ]
      }
    ];
  }

  public static get locationSearchTitle(): any {
    return 'Search for a restaurant';
  }
}
