<div class="button-chip-section">
  <div class="button-chip-section__title">{{name}}</div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div *ngFor="let chip of buttonChips" [fxFlex]="chip.width" class="button-chip-section__chip-container">
      <div [ngClass]="{'button-chip-section__chip-mobile' : isMobile, 'button-chip-section__chip' : !isMobile, 'button-chip-section__chip-selected' : chip.selected}"
           (click)="buttonChipToggled.emit(chip)">
        <div fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'button-chip-section__subtext-chip' : chip.subtext}">
          <div class="button-chip-section__text"
               [ngClass]="{'button-chip-section__subtext-alignment' : chip.subtext}">
            {{chip.name}}
          </div>
          <div *ngIf="chip.subtext" class="button-chip-section__subtext">{{chip.subtext}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
