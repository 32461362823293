export class NavbarConstants {
  public static navbarTabs(disableDiscoverTab, disableMeetTab, disableRecoverTab, disableEngageTab, disableCalendarTab): any[] {
    let tabs = [];
    if (!disableDiscoverTab) {
      tabs.push({
        inactiveIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/DiscoverInactive.png',
        activeIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/DiscoverActive.png',
        label: 'Discover',
        url: '/discover',
        active: false,
        displayOnBottom: true
      });
    }
    if (!disableMeetTab) {
      tabs.push({
        inactiveIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/MeetInactive.png',
        activeIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/MeetActive.png',
        label: 'Meet',
        url: '/meet',
        active: false,
        displayOnBottom: true
      });
    }
    if (!disableRecoverTab) {
      tabs.push({
        inactiveIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/RecoverInactive.png',
        activeIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/RecoverActive.png',
        label: 'Recover',
        url: '/care',
        active: false,
        displayOnBottom: true
      });
    }
    if (!disableEngageTab) {
      tabs.push({
        inactiveIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/EngageInactive.png',
        activeIconUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/EngageActive.png',
        label: 'Engage',
        url: '/engage/template-selection',
        active: false,
        displayOnBottom: true
      });
    }
    if (!disableCalendarTab) {
      tabs.push({
        class: 'fa-calendar',
        label: 'Calendar',
        url: '/calendar',
        active: false,
        displayOnBottom: false
      });
    }
    tabs.push({
      class: 'fa-cog',
      label: 'Settings',
      url: '/settings',
      active: false,
      displayOnBottom: false,
      mobileOnly: true
    },
    {
      class: 'fa-bell',
      label: 'Notifications',
      url: '/notifications',
      active: false,
      displayOnBottom: false,
      mobileOnly: true
    });
    return tabs;
  }
}
