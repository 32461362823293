<div class="icon-confirmation-dialog" fxLayout="column" fxLayoutAlign="space-around center">
  <img alt="Confirmation Icon" class="icon-confirmation-dialog__image" [src]="iconUrl"/>
  <div class="icon-confirmation-dialog__title">{{title}}</div>
  <div class="icon-confirmation-dialog__text" [innerHTML]="displayText"></div>
  <div mat-dialog-actions class="icon-confirmation-dialog__button-wrapper">
    <button mat-button
            class="icon-confirmation-dialog__discard-button"
            [ngClass]="{'icon-confirmation-dialog__mobile-button': isMobile}"
            (click)="closeDialog(false)">
      {{cancelText}}
    </button>
    <button mat-button
            class="icon-confirmation-dialog__send-button"
            [ngClass]="{'icon-confirmation-dialog__mobile-button': isMobile}"
            (click)="closeDialog(true)">
      {{confirmText}}
    </button>
  </div>
</div>
