import { clone, filter, find, findIndex, flatten, keys, pluck, propEq, replace  } from 'ramda';
import { MeetConstants } from '../meet/meet.constants';
import { SharedConstants } from '../shared/shared.constants';

export function addExtraFilters(
    allFilters, selectedFilters, includedCustomers, excludedCustomers, selectedOnBetweenId, startDate, endDate, meetSavedGroupBeingEdited
) {
  let formattedSelectedFilters = clone(selectedFilters);

  if (includedCustomers.length > 0 || meetSavedGroupBeingEdited !== null) {
    const categoryIndex = findIndex(propEq('id', MeetConstants.individualCustomersFilterId))(allFilters);
    const cfaIds = pluck('id', includedCustomers);
    const subFilters = allFilters[categoryIndex].subFilters;

    let includeCustomersFilter = find(propEq('id', MeetConstants.includeFilterId))(subFilters);
    includeCustomersFilter.cfaIdList = cfaIds;
    includeCustomersFilter = { ...includeCustomersFilter, customerProfiles: includedCustomers };
    formattedSelectedFilters.push(includeCustomersFilter);
  }

  if (excludedCustomers.length > 0 || meetSavedGroupBeingEdited !== null) {
    const categoryIndex = findIndex(propEq('id', MeetConstants.individualCustomersFilterId))(allFilters);
    const cfaIds = pluck('id', excludedCustomers);
    const subFilters = allFilters[categoryIndex].subFilters;

    let excludeCustomersFilter = find(propEq('id', MeetConstants.excludeFilterId))(subFilters);
    excludeCustomersFilter.cfaIdList = cfaIds;
    excludeCustomersFilter = { ...excludeCustomersFilter, customerProfiles: excludedCustomers };
    formattedSelectedFilters.push(excludeCustomersFilter);
  }

  if (selectedOnBetweenId && selectedOnBetweenId !== SharedConstants.onBetweenDefaultId) {
    const categoryIndex = findIndex(propEq('id', SharedConstants.filtersDateRangeId))(allFilters);
    const subFilters = allFilters[categoryIndex].subFilters;

    let onBetweenFilter = find(propEq('id', SharedConstants.filtersOnBetweenId))(subFilters);
    onBetweenFilter.selectedSliderValue = onBetweenFilter.sliderOptions.value;
    onBetweenFilter.selectedOnBetweenId = selectedOnBetweenId;
    formattedSelectedFilters.push(onBetweenFilter);
  } else if (startDate && endDate) {
    const categoryIndex = findIndex(propEq('id', SharedConstants.filtersDateRangeId))(allFilters);
    const subFilters = allFilters[categoryIndex].subFilters;

    let customDateRangeFilter = find(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(subFilters);
    if (customDateRangeFilter.isSelected) {
      customDateRangeFilter.startDate = startDate;
      customDateRangeFilter.endDate = endDate;
      formattedSelectedFilters.push(customDateRangeFilter);
    }
  }

  return formattedSelectedFilters;
}

export function getAllFormattedSelectedFilters(
    allFilters, selectedFilters, includedCustomers, excludedCustomers, selectedOnBetweenId, startDate, endDate, meetSavedGroupBeingEdited
) {
  let formattedSelectedFilters = addExtraFilters(allFilters, selectedFilters, includedCustomers, excludedCustomers, selectedOnBetweenId,
    startDate, endDate, meetSavedGroupBeingEdited);
  formattedSelectedFilters = getFormattedSelectedFilters(allFilters, formattedSelectedFilters);

  return formattedSelectedFilters;
}

export function getFormattedSelectedFilters(allFilters, selectedFilters) {
  let formattedSelectedFilters = clone(selectedFilters);

  formattedSelectedFilters.map(baseFilter => {
    let selectedCategory = find(propEq('id', baseFilter.parentCategory))(allFilters);
    let subFilters = selectedCategory.subFilters;
    let selectedFilter = find(propEq('id', baseFilter.id))(subFilters);

    // The selected filters in the saved group edit flow won't have buttonToggles, buttonChipSections, or sliderOptions
    // because the BE does not include them. Instead, also check if the applicable selected attribute is on the filter
    if (baseFilter.buttonToggles || baseFilter.selectedButtonToggle) {
      let selectedToggle = find(propEq('selected', true))(selectedFilter.buttonToggles);
      if (selectedToggle !== undefined) {
        baseFilter.selectedButtonToggle = selectedToggle.id;
      }
    }

    if (baseFilter.buttonChipSections || baseFilter.selectedButtonChipSections) {
      baseFilter.selectedButtonChipSections = selectedFilter.buttonChipSections.reduce((result,buttonChipSection) => {
        let selectedChips = filter(chip => chip.selected, buttonChipSection.buttonChips);
        if (selectedChips.length > 0) {
          buttonChipSection.selectedButtonChips = flatten(pluck('id', selectedChips));
          result.push(buttonChipSection);
        }
        return result;
      },[]);
    }

    // Works for both single and double sliders since highValue will just be null, as desired
    if ((baseFilter.sliderOptions || baseFilter.selectedSliderValue) && baseFilter.id !== SharedConstants.filtersOnBetweenId) {
      baseFilter.selectedSliderValue = selectedFilter.sliderOptions.value;
      baseFilter.selectedSliderHighValue = selectedFilter.sliderOptions.highValue;
    }
  });

  return formattedSelectedFilters;
}

export function getMatchingCustomer(customer, recipientsSearchTerm) {
  let customerFullName = '';

  if (customer.fullName) {
    customerFullName = customer.fullName.toUpperCase();
  } else if (customer.firstName && customer.lastName) {
    customerFullName = (customer.firstName + ' ' + customer.lastName).toUpperCase();
  }

  return customerFullName.includes(recipientsSearchTerm.toUpperCase());
}

export function replaceCareHtml(mergeVars, html) {
  const dateFormat = 'M/D/YYYY';
  const endDateDisplay = mergeVars.offerEndDate0 ? mergeVars.offerEndDate0.format(dateFormat) : '';
  const endDateDisplay1 = mergeVars.offerEndDate1 ? mergeVars.offerEndDate1.format(dateFormat) : '';
  const endDateDisplay2 = mergeVars.offerEndDate2 ? mergeVars.offerEndDate2.format(dateFormat) : '';
  const endDateDisplay3 = mergeVars.offerEndDate3 ? mergeVars.offerEndDate3.format(dateFormat) : '';
  const endDateDisplay4 = mergeVars.offerEndDate4 ? mergeVars.offerEndDate4.format(dateFormat) : '';

  let offerNames = [];
  let offerImages = [];
  mergeVars.rewards.map((reward) => {
    // The same reward can have a quantity greater than 1. We want to show each individually
    for (let i = 0; i < reward.count; i++) {
      offerNames.push(reward.offerName);
      offerImages.push(reward.imageURL);
    }
  });

  let mapping = {
    arContent: mergeVars.copy,
    emailCopy: mergeVars.copy,
    firstName: mergeVars.firstName,
    headLine: mergeVars.headline,
    offerImage: offerImages[0] || mergeVars.placeholderImage,
    offerImage1: offerImages[1] ? offerImages[1] : 'style="display:none;"',
    offerImage2: offerImages[2] ? offerImages[2] : 'style="display:none;"',
    offerImage3: offerImages[3] ? offerImages[3] : 'style="display:none;"',
    offerImage4: offerImages[4] ? offerImages[4] : 'style="display:none;"',
    offerName: offerNames[0] || '',
    offerName1: offerNames[1],
    offerName2: offerNames[2],
    offerName3: offerNames[3],
    offerName4: offerNames[4],
    preHeader: mergeVars.preHeader,
    redemptionEndDate: endDateDisplay,
    redemptionEndDate1: endDateDisplay1 ? endDateDisplay1 : endDateDisplay,
    redemptionEndDate2: endDateDisplay2 ? endDateDisplay2 : endDateDisplay,
    redemptionEndDate3: endDateDisplay3 ? endDateDisplay3 : endDateDisplay,
    redemptionEndDate4: endDateDisplay4 ? endDateDisplay4 : endDateDisplay,
    subtitle: mergeVars.preHeader,
    themeUrl: mergeVars.themeUrl
  };

  return replaceAll(mapping, html);
}

export function replaceMGHtml(mergeVars, html) {
  const displayNone = 'style = \"display:none\"';
  const dateFormat = 'M/D/YYYY';
  const dateFormatCountdownTimer = 'YYYY-MM-DD';

  let startDateDisplay = '';
  let endDateDisplay = '';
  let endDateDisplayForCountdownTimer = '';

  if (mergeVars.startDate) {
    startDateDisplay = mergeVars.startDate.format(dateFormat);
  }
  if (mergeVars.endDate) {
    endDateDisplay = mergeVars.endDate.format(dateFormat);
    endDateDisplayForCountdownTimer = mergeVars.endDate.format(dateFormatCountdownTimer);
  }

  let mapping = {
    arContent: mergeVars.careCopy,
    breakfastdisclaimer: mergeVars.breakfastDisclaimerText,
    ctabuttondesktopimage: mergeVars.ctaButtonDesktopImage ? mergeVars.ctaButtonDesktopImage : '',
    ctabuttonlink: mergeVars.showCtaButton ? mergeVars.ctaButtonLink : displayNone,
    ctabuttonmobileimage: mergeVars.ctaButtonMobileImage ? mergeVars.ctaButtonMobileImage : '',
    EmailAddress: '[EMAIL]',
    emailcopy: mergeVars.description || mergeVars.offerDetails,
    emaildetails: mergeVars.validDetails || mergeVars.description,
    emailheadline: mergeVars.subTitle || mergeVars.headline || mergeVars.subject,
    emailtitle: mergeVars.title,
    enddate: endDateDisplay,
    enddatecountdowntimer: endDateDisplayForCountdownTimer,
    endtime: mergeVars.endTime,
    eventenddate: endDateDisplay,
    eventstartdate: startDateDisplay,
    expectedadults: '[Number of RSVP\'d Adults] ',
    expectedkids: '[Number of RSVP\'d Kids]',
    fname: '[First Name]',
    headerimageurl: mergeVars.headerImage || mergeVars.imageUrl2,
    imageurl: mergeVars.imageUrl || mergeVars.offerImage,
    imageurl2: mergeVars.headerImage,
    isbreakfast: mergeVars.breakfast === true ? '' : displayNone,
    islimitedtimeoffer: mergeVars.limitedTimeOffer === true ? '' : displayNone,
    maxcustomers: mergeVars.limitedReservations === true ? '' : 'hidden',
    mobileheaderimageurl: mergeVars.mobileHeaderImage || mergeVars.headerImage || mergeVars.imageUrl2,
    mobileimageurl: mergeVars.mobileImageUrl,
    offerImage: mergeVars.offerImage,
    offername: mergeVars.name,
    recruitmentmoduleenabled: mergeVars.recruitmentModuleEnabled === true ? '' : displayNone,
    rsvprequired: mergeVars.rsvpRequired === true ? '' : 'hidden',
    showcountdowntimer: mergeVars.showCountdownTimer,
    starttime: mergeVars.startTime,
    venue: mergeVars.venue
  };

  return replaceAll(mapping, html);
}

function replaceAll(mapping, html) {
  keys(mapping).map((value) => {
    let mergeRecipientVarReplace = '${Recipient.' + value + '}';
    mergeRecipientVarReplace = mergeRecipientVarReplace.replace(/[${]/g, '\\$&');
    let mergeReplace = '${ContextData.Entry.' + value + '}';
    mergeReplace = mergeReplace.replace(/[${]/g, '\\$&');
    let replaceWith = mapping[value];
    let replaceRegEx = new RegExp(mergeReplace, 'g');
    let replaceRecipient = new RegExp(mergeRecipientVarReplace, 'g');
    html = replace('id="tableWrapper"', '', html);
    html = replace(replaceRegEx, replaceWith, html);
    html = replace(replaceRecipient, replaceWith, html);
  });

  return html;
}
