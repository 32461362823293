import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  EULA_FOUND:           type('[Eula] Eula Found'),
  EULA_ERROR:           type('[Eula] Eula Error'),
  SAVE_EULA_ACCEPT:     type('[Eula] Save Eula Accept'),
};

export class EulaFoundAction implements Action {
    public type = ActionTypes.EULA_FOUND;
    constructor(public payload: any = '') {}
}

export class EulaErrorAction implements Action {
  public type = ActionTypes.EULA_ERROR;
  constructor(public payload: any = '') {}
}

export class SaveEulaAcceptAction implements Action {
  public type = ActionTypes.SAVE_EULA_ACCEPT;
  constructor(public payload: any = '') {}
}

export type Actions
  = EulaFoundAction
  | EulaErrorAction
  | SaveEulaAcceptAction;
