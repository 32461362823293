import { Component, EventEmitter, Input, Output } from '@angular/core';
import { all, isEmpty, isNil, values, without } from 'ramda';

@Component({
  selector: 'stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})

export class StatCardComponent {
  @Input() public tile: any = {};
  @Input() public isExcludeCemRewardsChecked: any = false;
  @Input() public engageAnalyticsShowCheckboxTile: boolean = false;
  @Output() public buttonClicked = new EventEmitter();
  @Output() public excludeCemRewards = new EventEmitter();

  public onClicked() {
    if (this.hasData(this.tile.data) && !this.tile.drillDownDisabled) {
      this.buttonClicked.emit(this.tile);
    }
  }

  // If there is an array in the object and it is empty, show no data
  public hasData(data) {
    if (!data || isEmpty(data) || (data.checkZeroCountData !== undefined && !data.checkZeroCountData)) {
      return false;
    }
    const dataValues = values(data);
    if (without([null], dataValues).length > 0) {
      const isNotData = (dataPiece) => {
        return isEmpty(dataPiece) || isNil(dataPiece) || dataPiece === 0 || dataPiece === 0.0 || dataPiece === '0.0';
      };
      return !all(isNotData)(dataValues);
    }
  }

  public getPageTitle() {
    let title = this.tile.marketGiveawayTile ? this.tile.id : this.tile.title;
    return `Understand Tile: ${title}`;
  }

  public excludeCemRewardsChecked(checked) {
    this.excludeCemRewards.emit(checked);
  }
}
