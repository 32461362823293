import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, debounceTime } from 'rxjs/operators';

import { CareService } from '../care/care.service';
import { CareConstants } from '../care/care.constants';
import * as fromRoot from '../reducers';
import * as care from '../actions/care';
import * as user from '../actions/user';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ServiceNowReportComponent } from '../care/service-now-report/service-now-report.component';

@Injectable()
export class CareEffects {
  @Effect()
  public getReports$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.PAGE_CHANGE,
      care.ActionTypes.PAGE_SIZE_CHANGE,
      care.ActionTypes.SORT_CHANGE,
      care.ActionTypes.SEARCH_TERM,
      care.ActionTypes.CLEAR_SEARCH_TERM,
      care.ActionTypes.SEARCH_BY,
      care.ActionTypes.CARE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getCareReportRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getCareReports(requestObj).pipe(
      map((res) => new care.GetCareReportsSuccess(res)),
      catchError((error) => of(new care.GetCareReportsError(error)))
    ))
  );

  @Effect()
  public getReportDetails$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.GET_CARE_REPORT_DETAILS,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap((id) => this.cs.getReportDetails(id).pipe(
      map((res) => res != null ?
          new care.GetCareReportDetailsSuccess(res) :
          new care.GetCareReportDetailsError("There was an error retrieving care tickets details. Please try again later.")),
      catchError((error) => of(new care.GetCareReportDetailsError(error)))
    ))
  );

  @Effect()
  public getReportActivityHistory$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.SELECT_REPORT,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((report) => this.cs.getReportActivityHistory(report).pipe(
      map((res) => new care.GetActivityHistorySuccess(res)),
      catchError((error) => of(new care.GetActivityHistoryError(error)))
    ))
  );

  @Effect()
  public getCareEmailDetails$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.GET_CARE_EMAIL_DETAILS),
    map((action: any) => action.payload),
    switchMap((id) => this.cs.getCareEmailDetails(id).pipe(
      map((res) => new care.GetCareEmailDetailsSuccess(res)),
      catchError((error) => of(new care.GetCareEmailDetailsError(error)))
    ))
  );

  @Effect()
  public getTemplate$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_TEMPLATE),
    map((action: any) => action.payload),
    filter((payload) => payload.memberType !== CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareTemplateRequest).pipe(take(1))),
    switchMap((request) => this.cs.getCareTemplate(request).pipe(
      map((res) => new care.FetchTemplateSuccess(res)),
      catchError((error) => of(new care.FetchTemplateError(error)))
    ))
  );

  @Effect()
  public getSpotMemberTemplate$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_TEMPLATE),
    map((action: any) => action.payload),
    filter((payload) => payload.memberType === CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getSpotCustomerCareTemplateRequest).pipe(take(1))),
    switchMap((type) => this.cs.getSpotCustomerCareTemplate(type).pipe(
      map((res) => new care.FetchTemplateSuccess(res)),
      catchError((error) => of(new care.FetchTemplateError(error)))
    ))
  );

  @Effect()
  public setIncidentStatus$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.UPDATE_STATUS),
    map((action: any) => action.payload),
    switchMap((request) => this.cs.updateIncident(request).pipe(
      map((res) => new care.UpdateStatusSuccess(res.incidentId)),
      catchError((error) => of(new care.UpdateStatusError(error)))
    ))
  );

  @Effect()
  public sendResponse$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE),
    switchMap(() => this.store.select(fromRoot.getMemberType).pipe(take(1))),
    filter((memberType) => memberType !== CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareRespondPayload).pipe(take(1))),
    switchMap((payload) => this.cs.sendCareResponse(payload).pipe(
      map((incidentId) => {
        return new care.SendResponseSuccess({
          id: incidentId,
          message: CareConstants.sendSuccessMessage
        });
      }),
      catchError((incidentId) => {
        return of(new care.SendResponseError({
          incidentId,
          message: CareConstants.sendErrorMessage
        }));
      })
    ))
  );

  @Effect()
  public sendSpotResponse$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE),
    switchMap(() => this.store.select(fromRoot.getMemberType).pipe(take(1))),
    filter((memberType) => memberType === CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareSpotRespondPayload).pipe(take(1))),
    switchMap((payload) => this.cs.sendSpotCareResponse(payload).pipe(
      map((response) => {
        return new care.SendResponseSuccess({
          ...response,
          message: CareConstants.sendSuccessMessage
        });
      }),
      catchError((response) => {
        return of(new care.SendResponseError({
          ...response,
          message: CareConstants.sendErrorMessage
        }));
      })
    ))
  );

  @Effect()
  public reopenReport$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((report) => this.cs.reopenReport(report).pipe(
      map(() => new care.ReopenReportSuccess(report.id)),
      catchError((error) => of(new care.ReopenReportError(error)))
    ))
  );

  @Effect({ dispatch: false })
  public showCreateToast$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE_SUCCESS),
    map((action: any) => action.payload),
    tap((payload) => {
      const { activeSpotlightCustomer, id, message, memberType, customerFirstName } = payload;

      // This checks if it is a CFA One contact send vs a CARES report, not necessarily if they are in the Spotlight
      if (memberType === CareConstants.spotCustomer) {
        // This checks if they are actually are a Spotlight customer at this location
        if (activeSpotlightCustomer) {
          this.router.navigate(
            ['/customer'],
            { queryParams: { id, previousUrl: '/care' } }
          );
        } else {
          this.router.navigate(
            ['/care'],
            { queryParams: { index: CareConstants.closedChickFilAOneContactsTabIndex, searchTerm: customerFirstName } }
          );
        }
        return this.snackBar.open(message, 'Dismiss', { duration: 5000 });
      } else {
        this.router.navigate(['/care/details'], { queryParams: { incidentId: id } });
        return this.snackBar.open(message, 'Dismiss', { duration: 5000 });
      }
    })
  );

  @Effect({ dispatch: false })
  public showCreateFailToast$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE_ERROR),
    map((action: any) => action.payload),
    tap((payload) => {
      const { message } = payload;
      this.router.navigate(['/care']);
      return this.snackBar.open(
        message,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public showReopenSuccessToast$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT_SUCCESS),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReportFullName).pipe(take(1))),
    tap((name) => {
      return this.snackBar.open(
        `CARES Contact for ${name} successfully reopened and moved to Spotlight Recover tab.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public showReopenErrorToast$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT_ERROR),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReportFullName).pipe(take(1))),
    tap((name) => {
      return this.snackBar.open(
        `CARES Contact for ${name} failed to be reopened.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  // TODO: Dispatching OpenServiceNowReport should trigger an api call to get the report data. Currently that is not set up yet
  @Effect({ dispatch: false })
  public openServiceNowReport$: Observable<any> = this.actions$.pipe(
    ofType(care.ActionTypes.OPEN_SERVICE_NOW_REPORT),
    map(() => {
      const config: MatDialogConfig = { }
      this.dialog.open(ServiceNowReportComponent, config);
    })
  );

  @Effect()
  public findSpotlightContactsDebounce$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_SORT,
      care.ActionTypes.CFA_ONE_CHANGE_VIEW
    ),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getSpotlightContactsRequest).pipe(take(1))),
    switchMap((payload) => this.cs.getCustomersHttp(payload).pipe(
      map((res) => new care.SpotlightContactsSearchComplete((res))),
      catchError((error) => of(new care.SpotlightContactsSearchError(error)))
    ))
  );

  @Effect()
  public eligibleForRecovery$: Observable<Action> = this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_CUSTOMER),
    switchMap(() => this.store.select(fromRoot.getCareSelectedCustomer).pipe(take(1))),
    switchMap((payload) => this.cs.getEligibleForRecovery(payload).pipe(
      map((res) => new care.GetEligibleForRecoverySuccess(res)),
      catchError((error) => of(new care.GetEligibleForRecoveryError(error)))
    ))
  );

  @Effect()
  public getCustomerNotes$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.SELECT_REPORT,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS,
      care.ActionTypes.CUSTOMER_NOTE_ACTION_COMPLETE,
      care.ActionTypes.DELETE_CUSTOMER_NOTE_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((id) => this.cs.getCustomerInformation(id).pipe(
      map((res) => new care.GetCustomerNotesSuccess(res)),
      catchError((error) => of(new care.GetCustomerNotesError(error)))
    ))
  );

  @Effect()
  public createCustomerNote: Observable<any> = this.actions$.pipe(
    ofType(
      care.ActionTypes.CREATE_CUSTOMER_NOTE
    ),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() =>
          this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))
        ),
        switchMap(([action, report]) => {
          const noteData = action.payload;
          return this.cs.createCustomerNote(noteData, report.id).pipe(
            map((res) => new care.CustomerNoteActionComplete({ noteId: res.noteId })),
            catchError((err) => of(new care.CustomerNoteError({ err })))
          );
        })
      )
    )
  );

  @Effect()
  public updateCustomerNote: Observable<any> = this.actions$.pipe(
    ofType(
      care.ActionTypes.UPDATE_CUSTOMER_NOTE
    ),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() =>
          this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))
        ),
        switchMap(([action, report]) => {
          let noteData = action.payload;
          return this.cs.updateCustomerNote(noteData, report.id).pipe(
            map((res) => new care.CustomerNoteActionComplete({ noteId: noteData.noteId })),
            catchError((err) => of(new care.CustomerNoteError({ err, noteId: noteData.noteId })))
          );
        })
      )
    )
  );

  @Effect()
  public deleteCustomerNote: Observable<any> = this.actions$.pipe(
    ofType(
      care.ActionTypes.DELETE_CUSTOMER_NOTE
    ),
    map((action: any) => action.payload),
    switchMap((noteData) => {
      return this.cs.deleteCustomerNote(noteData).pipe(
        map((res) => new care.DeleteCustomerNoteSuccess({ noteId: noteData.noteId })),
        catchError((err) => of(new care.ErrorAction({ err, noteId: noteData.noteId })))
      );
    })
  );

  @Effect()
  public getClosedCareContacts$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_SORT,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH_BY,
      care.ActionTypes.CLOSED_CARE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getClosedCareContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getClosedCareContacts(requestObj).pipe(
      map((res) => new care.GetClosedCareContactsSuccess(res)),
      catchError((error) => of(new care.GetClosedCareContactsError(error)))
    ))
  );

  @Effect()
  public getRecoveredCfaOneContacts$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.LOAD_RECOVERED_CFA_ONE_CONTACTS_DATA
    ),
    map((action: any) => action.payload),
    filter((payload) => payload && this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getRecoveredCfaOneContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getRecoveredCfaOneContacts(requestObj).pipe(
      map((res) => new care.GetRecoveredCfaOneContactsSuccess(res)),
      catchError((error) => of(new care.GetRecoveredCfaOneContactsError(error)))
    ))
  );

  @Effect()
  public getRecoveredCfaOneContactsOnAction$: Observable<Action> = this.actions$.pipe(
    ofType(
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_SORT,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CLEAR_SEARCH_TERM,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH_BY,
      care.ActionTypes.RECOVERED_CFA_ONE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getRecoveredCfaOneContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getRecoveredCfaOneContacts(requestObj).pipe(
      map((res) => new care.GetRecoveredCfaOneContactsSuccess(res)),
      catchError((error) => of(new care.GetRecoveredCfaOneContactsError(error)))
    ))
  );

  @Effect()
  public createActionHistoryNote: Observable<any> = this.actions$.pipe(
    ofType(care.ActionTypes.CREATE_ACTION_HISTORY_NOTE),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
        switchMap(([action]) => {
          let noteData = action.payload;
          return this.cs.createActionHistoryNote(noteData).pipe(
            map((res) => new care.CreateActionHistoryNoteSuccess(res)),
            catchError((err) => of(new care.CreateActionHistoryNoteError({ err })))
          );
        })
      )
    )
  );

  @Effect()
  public updateActionHistoryNote: Observable<any> = this.actions$.pipe(
    ofType(care.ActionTypes.UPDATE_ACTION_HISTORY_NOTE),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
        switchMap(([action]) => {
          const noteData = action.payload;
          return this.cs.updateActionHistoryNote(noteData).pipe(
            map((res) => new care.UpdateActionHistoryNoteSuccess(res)),
            catchError((err) => of(new care.UpdateActionHistoryNoteError({ err, noteId: noteData.noteId })))
          );
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private cs: CareService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<fromRoot.State>
  ) { }
}
