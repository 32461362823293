import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import * as fromRoot from '../../reducers/index';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

// Usage:- To convert dates to locations timezone date.
// Use this pipe whenever needed, to display formatted date in application.
@Pipe({ name: 'locTimeZoneDate' })
export class LocTimeZoneDatePipe implements PipeTransform {

  public selectedLocation$: Observable<any>;
  public locationTimezoneAcronym: string = null;
  public locationTimeZone: string = null;

  constructor(private store: Store<fromRoot.State>) {
    this.selectedLocation$ = store.select(fromRoot.getSelectedLocation);
    this.selectedLocation$.pipe(take(1)).subscribe(location => {
      this.locationTimeZone = location.timeZone;
      this.locationTimezoneAcronym = location.locationTimeZoneForDisplay;
    });
  }

  public transform(value: any, inputFormat: string = null, showTimeZone: boolean = false): string {
    if (value) {
      let date = moment.tz(value, this.locationTimeZone).format(inputFormat);
      if (showTimeZone && this.locationTimezoneAcronym) {
        date = R.concat(date, ' ' + this.locationTimezoneAcronym);
      }
      return date;
    } else {
      return null;
    }
  }
}
