import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() public totalElements: number;
  @Input() public pageSize: number;
  @Input() public currentPage: number;

  @Output() public pageLeft = new EventEmitter<any>();
  @Output() public pageRight = new EventEmitter<any>();

  public getNumberOfPages() {
    return Math.ceil(this.totalElements / this.pageSize);
  }

  public getPageStart() {
    return ((this.currentPage) * this.pageSize) + 1;
  }

  public getPageEnd() {
    if (this.isLastPage()) {
      return this.totalElements;
    } else {
      return (this.getPageStart() + this.pageSize) - 1;
    }
  }

  public isLastPage() {
    let numberOfPages = Math.ceil(this.totalElements / this.pageSize);
    return this.currentPage === numberOfPages - 1 || numberOfPages === 0;
  }
}
