<navbar [showSelectedLocationName]="true" [selectedIndex]="0"></navbar>
<div *ngIf="discoverLoading$ | async"
     class="discover__loading"
     [cfaLoading]="true">
</div>
<div *ngIf="error$ | async">
  <error-message [error]="error$ | async"
                 [page]="'Discover'">
  </error-message>
</div>
<div *ngIf="imageBanner$ | async" class="picture" style="text-align: center" >
  <img src="{{(imageBanner$ | async).bannerText}}" alt="error retrieving image">
</div>
<div class="discover"
     [ngClass]="{'discover__mobile': isMobile$ | async}"
     *ngIf="!(error$ | async) && !(discoverLoading$ | async)"
     fxLayout.xs="column"
     fxLayout="row wrap">
  <banner-card fxFlex.sm="50"
               fxFlex.md="33.3"
               fxFlex="25"
               *ngIf="bannerTile$ | async"
               [tile]="bannerTile$ | async"
               [isMobile]="isExtraSmallScreen$ | async"
               (dismissBanner)="dismissBanner()"
               (bannerBtnClick)="bannerBtnClick($event)">
  </banner-card>
  <error-card fxFlex.sm="50"
              fxFlex.md="33.3"
              fxFlex="25"
              *ngIf="(errorTile$ | async) && (errorTile$ | async).errors && (errorTile$ | async).errors.length > 0"
              [tile]="errorTile$ | async"
              [isMobile]="isExtraSmallScreen$ | async"
              (markResolved)="markResolved($event)"
              (showFailedCampaignDetails)="showFailedCampaignDetails($event)">
  </error-card>
  <div *ngFor="let promotionAndCampaignTileArr of promotionAndCampaignTiles$ | async"
       fxFlex.sm="50"
       fxFlex.md="33.3"
       fxFlex="25">
    <div *ngFor="let tile of promotionAndCampaignTileArr">
      <div *ngIf="!(tile.automated)" [hidden]="error$ | async">
        <discover-card [tile]="tile"
                       [isSendingDisabled]="isSendingDisabled$ | async"
                       [isRewardsSendingDisabled]="isRewardsSendingDisabled$ | async"
                       (sendReward)="send(tile, rewardType)"
                       (sendEmail)="send(tile, emailType)"
                       (sendPromotion)="send(tile, tile.category)"
                       (sendCommunityCare)="send(tile, communityCareType)"
                       (setUpAutomation)="send(tile, automationType)"
                       (editAutomation)="editAutomation(tile)"
                       (stopAutomation)="stopAutomation(tile)"
                       (stopCommunityCareEvent)="stopCommunityCareEvent(tile)"
                       (learnMore)="learnMore(tile.id, tile.externalUrl)">
          <div *ngIf="tile.displayBanner" class="promoBanner">Spotlight Engagement Promotion Eligible</div>
          <campaign [image]="tile.displayImageUrl"
                    [text]="tile.descriptionText"
                    [displayNewIcon]="tile.displayNewIcon">
          </campaign>
        </discover-card>
      </div>
      <div *ngIf="(tile.automated) && (tile.stayInPlaceAfterAutomating)" [hidden]="error$ | async">
        <discover-card [tile]="tile"
                       [isSendingDisabled]="isSendingDisabled$ | async"
                       [isRewardsSendingDisabled]="isRewardsSendingDisabled$ | async"
                       (sendReward)="send(tile, rewardType)"
                       (sendEmail)="send(tile, emailType)"
                       (sendPromotion)="send(tile, tile.category)"
                       (sendCommunityCare)="send(tile, communityCareType)"
                       (setUpAutomation)="send(tile, automationType)"
                       (editAutomation)="editAutomation(tile)"
                       (stopAutomation)="stopAutomation(tile)"
                       (stopCommunityCareEvent)="stopCommunityCareEvent(tile)"
                       (stopPromotion) ="stopPromotion(tile)"
                       (learnMore)="learnMore(tile.id, tile.externalUrl)">
          <div *ngIf="tile.displayBanner" class="promoBanner">Spotlight Engagement Promotion Eligible</div>
          <campaign [image]="tile.displayImageUrl"
                    [text]="tile.descriptionText"
                    [displayNewIcon]="tile.displayNewIcon">
          </campaign>
        </discover-card>
      </div>
    </div>
  </div>

  <div *ngFor="let tile of generalTiles$ | async"
       fxFlex.sm="50"
       fxFlex.md="33.3"
       fxFlex="25"
       [hidden]="error$ | async"
       [fxFlexOrder]="calculateOrder(tile.loading, tile.order)">
    <discover-card [tile]="tile"
                   [isSendingDisabled]="isSendingDisabled$ | async"
                   [isRewardsSendingDisabled]="isRewardsSendingDisabled$ | async"
                   (sendReward)="send(tile, rewardType)"
                   (sendEmail)="send(tile, emailType)"
                   (setUpAutomation)="send(tile, automationType)"
                   (editAutomation)="editAutomation(tile)"
                   (stopAutomation)="stopAutomation(tile)"
                   (stopCommunityCareEvent)="stopCommunityCareEvent(tile)"
                   (learnMore)="learnMore(tile.id, tile.externalUrl)">
      <filters *ngIf="tile.type === 'list'"
               [filters]="tile.filters"
               (filterClick)="goToMeet($event)">
      </filters>
      <div *ngIf="tile.displayBanner" class="promoBanner">Spotlight Engagement Promotion Eligible</div>
      <statistic *ngIf="tile.type === 'stat'"
                 [stat]="tile.count"
                 [percentage]="tile.percentage"
                 [image]="tile.displayImageUrl"
                 [text]="tile.descriptionText"
                 [isInteger]="tile.dataType === 'integer'"
                 [isPercent]="tile.dataType === 'percent'"
                 [isImage]="tile.dataType === 'image'"
                 [displayNewIcon]="tile.displayNewIcon">
      </statistic>
    </discover-card>
  </div>
  <div *ngFor="let automatedTileArr of automatedTiles$ | async"
       fxFlex.sm="50"
       fxFlex.md="33.3"
       fxFlex="25"
       fxFlexOrder="100"
       [hidden]="error$ | async">
    <div *ngFor="let tile of automatedTileArr">
      <discover-card [tile]="tile"
                     [isSendingDisabled]="isSendingDisabled$ | async"
                     [isRewardsSendingDisabled]="isRewardsSendingDisabled$ | async"
                     (sendReward)="send(tile, rewardType)"
                     (sendEmail)="send(tile, emailType)"
                     (setUpAutomation)="send(tile, automationType)"
                     (editAutomation)="editAutomation(tile)"
                     (stopAutomation)="stopAutomation(tile)"
                     (stopCommunityCareEvent)="stopCommunityCareEvent(tile)"
                     (learnMore)="learnMore(tile.id, tile.externalUrl)">
        <filters *ngIf="tile.type === 'list'"
                 [filters]="tile.filters"
                 (filterClick)="goToMeet($event)">
        </filters>
        <div *ngIf="tile.displayBanner" class="promoBanner">Spotlight Engagement Promotion Eligible</div>
        <statistic *ngIf="tile.type === 'stat'"
                   [stat]="tile.count"
                   [percentage]="tile.percentage"
                   [image]="tile.displayImageUrl"
                   [text]="tile.descriptionText"
                   [isInteger]="tile.dataType === 'integer'"
                   [isPercent]="tile.dataType === 'percent'"
                   [isImage]="tile.dataType === 'image'"
                   [displayNewIcon]="tile.displayNewIcon">
        </statistic>
      </discover-card>
    </div>
  </div>
</div>
