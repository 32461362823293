<div class="double-slider">
  <div *ngIf="lowSliderValue === highSliderValue">
    <span class="double-slider__title-primary">{{lowSliderValue}}</span>
    <span *ngIf="lowSliderValue === 1" class="double-slider__title-secondary"> time</span>
    <span *ngIf="lowSliderValue !== 1" class="double-slider__title-secondary"> times</span>
  </div>
  <div *ngIf="lowSliderValue !== highSliderValue">
    <span class="double-slider__title-secondary">Between </span>
    <span class="double-slider__title-primary">{{lowSliderValue}}</span>
    <span class="double-slider__title-secondary"> and </span>
    <span class="double-slider__title-primary">{{highSliderValue}}</span>
    <span class="double-slider__title-secondary"> times</span>
  </div>
  <ngx-slider [(value)]="filter.sliderOptions.value"
              [(highValue)]="filter.sliderOptions.highValue"
              [options]="filter.sliderOptions"
              (valueChange)="updateSliderLowValue($event)"
              (highValueChange)="updateSliderHighValue($event)"
              (userChangeEnd)="updateTwoVariableSliderValue(filter)">
  </ngx-slider>
</div>
