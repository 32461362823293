<div class="footer__cfa-footer"
     [ngClass]="{'footer__cfa-footer-blue' : isBlueBackground}">
  <hr class="footer__dotted-line" *ngIf="!(isMobile$ | async) || (isMobile$ | async) && showFooter">
  <div *ngIf="!(isMobile$ | async)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <p><a (click)="goToFeedback()" class="footer__feedback-text">We want your feedback!</a></p>
      </div>
      <div class="footer__version-num">
        v{{versionNumber$ | async}}
      </div>
      <div>
        <p>Copyright © {{currentYear}} Chick-fil-A, Inc. All rights reserved.</p>
      </div>
    </div>
  </div>
  <div *ngIf="(isMobile$ | async) && showFooter">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <div class="footer__mobile-copyright">
        <p>Copyright © {{currentYear}} Chick-fil-A, Inc.</p>
      </div>
      <div class="footer__mobile-copyright-text">
        <p>All rights reserved.</p>
      </div>
    </div>
  </div>
</div>
