import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  DIALOG_OPENED:                  type('[Active Rewards] Dialog Opened'),
  FETCH_ACTIVE_REWARD_CUSTOMERS:  type('[Active Rewards] Fetch Active Reward Customers'),
  FOUND_ACTIVE_REWARD_CUSTOMERS:  type('[Active Rewards] Found Active Reward Customers'),
  ERROR_ACTIVE_REWARD_CUSTOMERS:  type('[Active Rewards] Error on Active Reward Customers'),
  TOGGLE_ACTIVE_REWARD_CUSTOMERS: type('[Active Rewards] Toggle Active Reward Customers')
};

export class DialogOpened implements Action {
  public type = ActionTypes.DIALOG_OPENED;
  constructor(public payload: any = '') { }
}

export class FetchActiveRewardCustomers implements Action {
  public type = ActionTypes.FETCH_ACTIVE_REWARD_CUSTOMERS;
  constructor(public payload: any) { }
}

export class FoundActiveRewardCustomers implements Action {
  public type = ActionTypes.FOUND_ACTIVE_REWARD_CUSTOMERS;
  constructor(public payload: any = '') { }
}

export class ErrorActiveRewardCustomers implements Action {
  public type = ActionTypes.ERROR_ACTIVE_REWARD_CUSTOMERS;
  constructor(public payload: any = '') { }
}

export class ToggleActiveRewardCustomers implements Action {
  public type = ActionTypes.TOGGLE_ACTIVE_REWARD_CUSTOMERS;
  constructor(public payload: any = '') { }
}

export type Actions
  = DialogOpened
  | FetchActiveRewardCustomers
  | FoundActiveRewardCustomers
  | ErrorActiveRewardCustomers
  | ToggleActiveRewardCustomers;
