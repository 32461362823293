import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { find, propEq } from 'ramda';

@Component({
  selector: 'single-slider',
  templateUrl: './single-slider.component.html',
  styleUrls: ['./single-slider.component.scss']
})
export class SingleSliderComponent implements OnInit {
  public sliderTitle: string = '';
  public sliderPreValue: any;

  @Input() public filter: any;

  @Output() public sliderValueUpdated = new EventEmitter<any>();

  public ngOnInit(): void {
    this.sliderPreValue = this.filter.sliderOptions.value;
  }

  public updateSliderTitle(value, options) {
    let selectedStep = find(propEq('value', value))(options.stepsArray);
    this.sliderTitle = selectedStep.title;
  }

  public updateSliderValue(value, filter) {
    // check prev slider value and selected slider value (to prevent unnecessary API call from being made from the sliders)
    // if both values are different then only emit slider updated event
    if (value !== this.sliderPreValue) {
      const payload = { value, id: filter.id, parentId: filter.parentCategory };
      this.sliderValueUpdated.emit(payload);
    }
  }
}