<div *ngIf="(loading$ | async) || (this.discoverTilesLoading$ | async)"
     class="edit__loading"
     [cfaLoading]="true">
</div>
<ng-container *ngIf="error$ | async">
    <spot-nav [title]="title$ | async"
              [isMobile]="isMobile$ | async"
              (back)="goToDiscover()">
    </spot-nav>
    <error-message [error]="error$ | async"
                   [page]="'Discover Edit'">
    </error-message>
</ng-container>
<div fxLayout="row" *ngIf="!(loading$ | async) && !(this.discoverTilesLoading$ | async)">
    <div fxFlex="60" fxShow.gt-sm fxHide>
        <template-preview [templateHtml]="templateHtml$ | async"
                          [showAppPreview]="(engagementType$ | async) !== emailType && !promotionTypes.includes(engagementType$ | async)"
                          [showEmailPreview]="!promotionTypes.includes(engagementType$ | async)"
                          [showPromotionAppPreview]="(engagementType$ | async) === promotionType "
                          [showThirdPartyPromotion]="(engagementType$ | async) === thirdPartyType "
                          [showCommunityAppPreview]="(engagementType$ | async) === communityEventType"
                          [showFreeItemInCartPreview]="(engagementType$ | async) === freeItemInCart"
                          [isMobile]="isMobile$ | async"
                          (backBtnClicked)="goToDiscover()">
        </template-preview>
    </div>
    <div fxLayout="column" fxFlex.gt-sm="40" fxFlex="100">
        <router-outlet></router-outlet>
    </div>
</div>
