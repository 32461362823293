import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { UserService } from '../core/user';
import * as fromRoot from '../reducers';
import * as customer from '../actions/customer';
import * as engage from '../actions/engage';
import * as user from '../actions/user';
import * as meet from '../actions/meet';
import { ErrorHandlerService } from '../core/error-handler';
import { FindCustomerResponse } from '../models/find-customer-response.model';
import { UserLocation } from '../models/location.model';
import { MeetConstants } from '../meet/meet.constants';
import { AppConstants } from '../app.constants';
import { environment } from 'spotlight-ui/environments/environment';

@Injectable()
export class CustomerEffects {
  @Effect()
  public findCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(
      customer.ActionTypes.CHANGE_VIEW,
      customer.ActionTypes.MAKE_INACTIVE_SUCCESS,
      customer.ActionTypes.MAKE_ACTIVE_SUCCESS,
      meet.ActionTypes.ADD_CUSTOM_DATE_FILTER,
      meet.ActionTypes.ADD_FILTER_FROM_DISCOVER,
      meet.ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER,
      meet.ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER,
      meet.ActionTypes.ADD_SAVED_GROUP,
      meet.ActionTypes.ADD_SELECTED_FILTER,
      meet.ActionTypes.CLEAR_FILTERS,
      meet.ActionTypes.EDIT_SAVED_GROUP,
      meet.ActionTypes.EXIT_SAVED_GROUP_EDIT,
      meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER,
      meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER,
      meet.ActionTypes.REMOVE_SAVED_GROUP,
      meet.ActionTypes.REMOVE_SELECTED_FILTER,
      meet.ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE,
      meet.ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE,
      meet.ActionTypes.UPDATE_SAVED_GROUP_ERROR,
      meet.ActionTypes.UPDATE_SLIDER_VALUE,
      meet.ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE,
      meet.ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE
    ),
    map((action: any) => action.payload),
    filter((payload) => payload.page !== AppConstants.engagePage),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.meetPage)).pipe(take(1))),
    switchMap((state) => {
      return this.getCustomersHttp(state).pipe(
        map((res: FindCustomerResponse) => new customer.FindCustomersCompleteAction(res)),
        catchError((error) => {
          let errorMessage;
          if (error && error instanceof Array) {
            errorMessage = error[0];
          } else {
            errorMessage = error && error.message ? error.message : error;
          }
          return of(new customer.CustomerError(errorMessage))
        })
      );
    })
  );

  @Effect()
  public findCustomersDebounce$: Observable<Action> = this.actions$.pipe(
    ofType(
      customer.ActionTypes.CLEAR_SEARCH,
      customer.ActionTypes.SEARCH
    ),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.meetPage)).pipe(take(1))),
    switchMap((state) => this.getCustomersHttp(state)),
    map((res) => new customer.FindCustomersCompleteAction(res))
  );

  @Effect()
  public updateFilterDates$: Observable<Action> = this.actions$.pipe(
    ofType(meet.ActionTypes.UPDATE_FILTER_DATES),
    map((action: any) => action.payload),
    filter((payload) => payload.page !== AppConstants.engagePage),
    switchMap((payload) => this.store.select(fromRoot.getIfFiltersDateIsNull(payload.page)).pipe(take(1))),
    filter((dateIsNull) => !dateIsNull),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.meetPage)).pipe(take(1))),
    switchMap((state) => {
      return this.getCustomersHttp(state).pipe(
        map((res: FindCustomerResponse) => new customer.FindCustomersCompleteAction(res)),
        catchError((error) => {
          let errorMessage;
          if (error && error instanceof Array) {
            errorMessage = error[0];
          } else {
            errorMessage = error && error.message ? error.message : error;
          }
          return of(new customer.CustomerError(errorMessage))
        })
      );
    })
  );

  @Effect()
  public meetFilterCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(
      customer.ActionTypes.CHANGE_PAGE,
      customer.ActionTypes.CHANGE_PAGE_SIZE,
      customer.ActionTypes.CHANGE_SORT,
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.meetPage)).pipe(take(1))),
    switchMap((state) => {
      return this.getCustomersHttp(state).pipe(
        map((res: FindCustomerResponse) => new customer.FindCustomersCompleteAction(res)),
        catchError((error) => {
          let errorMessage;
          if (error && error instanceof Array) {
            errorMessage = error[0];
          } else {
            errorMessage = error && error.message ? error.message : error;
          }
          return of(new customer.CustomerError(errorMessage))
        })
      );
    })
  );

  @Effect()
  public findAutocompleteCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(customer.ActionTypes.AUTOCOMPLETE_SEARCH),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestForAutocompleteByPage(AppConstants.meetPage)).pipe(take(1))),
    switchMap((state) => this.getCustomersHttp(state).pipe(
      map((res) => new customer.AutocompleteFound((res))),
      catchError((error) => of(new customer.AutocompleteError(error)))
    ))
  );

  @Effect()
  public makeActive$: Observable<Action> = this.actions$.pipe(
    ofType(customer.ActionTypes.MAKE_ACTIVE),
    map((action: any) => action.payload),
    switchMap((customerIds) => this.toggleInactives(customerIds).pipe(
      map(() => new customer.MakeActiveSuccessAction({
        id: customerIds[0], type: 'customerInformation', customerCount: customerIds.length
      })),
      catchError((error) => of(new customer.MakeActiveFailureAction(error)))
    ))
  );

  @Effect({ dispatch: false })
  public makeActiveSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(customer.ActionTypes.MAKE_ACTIVE_SUCCESS),
    tap((object: any) => {
      const customerText = object.payload ? object.payload.customerCount : '';
      return this.openInactiveSnackBar(customerText, 'active');
    })
  );

  @Effect()
  public makeInactive$: Observable<Action> = this.actions$.pipe(
    ofType(customer.ActionTypes.MAKE_INACTIVE),
    map((action: any) => action.payload),
    switchMap((customerIds) => this.toggleInactives(customerIds).pipe(
      map(() => new customer.MakeInactiveSuccessAction({
        id: customerIds[0], type: 'customerInformation', customerCount: customerIds.length
      })),
      catchError((err) => of(new customer.MakeInactiveFailureAction(err)))
    ))
  );

  @Effect({ dispatch: false })
  public makeInactiveSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(customer.ActionTypes.MAKE_INACTIVE_SUCCESS),
    tap((object: any) => {
      const customerText = object.payload ? object.payload.customerCount : '';
      return this.openInactiveSnackBar(customerText, 'inactive');
    })
  );

  @Effect()
  public fetchSelectedCustomersForEngage$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.GET_SELECTED_INDIVIDUALS_DETAILS),
    switchMap(() => this.store.select(fromRoot.shouldFetchIndividualCustomers).pipe(take(1))),
    filter((shouldFetch) => shouldFetch),
    switchMap(() => this.store.select(fromRoot.getSelectedEngageIndividualIds).pipe(take(1))),
    switchMap((customerIds) => this.fetchCustomersByIds(customerIds).pipe(
      map((customers) => new customer.SelectCustomers(customers)),
      catchError((error) => of(new engage.GetSelectedIndividualsDetailsFailure(error)))
    ))
  );

  @Effect()
  public loadAutocompleteCustomers$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.TOGGLE_CATEGORY_EXPANSION),
    map((action: any) => action.payload),
    filter((payload) => payload.category.id === MeetConstants.individualCustomersFilterId && !payload.category.open),
    map(() => new customer.AutocompleteSearch(''))
  );

  constructor(
    private actions$: Actions,
    private ehs: ErrorHandlerService,
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private us: UserService,
    public snackBar: MatSnackBar
  ) { }

  private openInactiveSnackBar(customerLength, activeText) {
    this.snackBar.open(
      `${customerLength} customer(s) have been moved to the ${activeText} customers list`,
      'Dismiss', {
        duration: 5000
      }
    );
  }

  private getCustomersHttp(req) {
     return this.us.getSelectedLocation().pipe(
       mergeMap((location: UserLocation) => {
         const locationNumber = location.locationNumber;
         const baseUrl = '/api/location/';
         const url = baseUrl + locationNumber + '/filters/customers';

         return this.http.post(environment.spotlightApiUrl + url, req).pipe(
           map((res: any) => {
             return res.responseObject || {};
           }),
           catchError((err) => this.ehs.handleError(err))
         );
       })
     );
  }

  private toggleInactives(ids) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location: UserLocation) => this.clearInactiveCache(location)),
      switchMap((location: UserLocation) => {
        const locationNumber = location.locationNumber;
        const url = `/api/location/${locationNumber}/toggleInactive`;
        return this.http.post(environment.spotlightApiUrl + url, ids);
      }),
      map((res: any) => res.responseObject || []),
      catchError((err) => this.ehs.handleError(err))
    );
  }

  private fetchCustomersByIds(ids) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc: UserLocation) => this.fetchLocationCustomersByIds(loc.locationNumber, ids))
    );
  }

  private fetchLocationCustomersByIds(locationNumber, ids) {
    const baseUrl = '/api/location/';
    const url = baseUrl + locationNumber + '/customersById';
    return this.http.post(environment.spotlightApiUrl + url, ids).pipe(
      map((res: any) => {
        return res.responseObject.customerProfiles || {};
      }),
      catchError(this.ehs.handleError)
    );
  }

  private clearInactiveCache(location) {
    const baseUrl = '/api/cache/reset/location';
    const url = baseUrl + '/' + location.locationNumber + '/inactiveCache';
    return this.http.get(environment.spotlightApiUrl + url).pipe(
      map(() => {
        return location;
      }),
      catchError(this.ehs.handleError)
    );
  }
}
