<collapsible-card title="Customer Notes" [loading]="loading">
  <error-message *ngIf="error"
                 [page]="'Note Component'"
                 [error]="noteErrorMessage">
  </error-message>
  <div *ngIf="!error" fxLayout="column" fxLayoutAlign="start stretch">
    <button mat-raised-button
            fxFlexAlign="end"
            class="note-section__create-button"
            [disabled]="disabledChanges"
            (click)="checkForUnsavedChanges(createNote)">
      {{createNoteText}}
    </button>
    <div *ngIf="notes.length === 0" class="note-section__no-results-text">
      {{noteNoResultsMessage}}
    </div>
    <div *ngFor="let note of displayNotes; let i = index"
         class="note-section__note-container"
         [ngClass]="{'note-section__blue-background': i % 2 !== 0, 'note-section__note-top-border': i === 0}">
      <note [note]="note"
            [disableChanges]="disabledChanges"
            [subText]="subText"
            (editNoteClicked)="checkForUnsavedChanges(editNote, note)"
            (updateNoteText)="updateNoteText($event, note)"
            (cancelEditClicked)="checkForUnsavedChanges(cancelEdit, note)"
            (saveNoteClicked)="saveNote(note)"
            (deleteNoteClicked)="deleteNote(note)">
      </note>
    </div>
    <pagination *ngIf="notes.length > pageSize"
                [totalElements]="notes.length"
                [pageSize]="pageSize"
                [currentPage]="currentPage"
                (pageLeft)="pageLeft()"
                (pageRight)="pageRight()">
    </pagination>
  </div>
</collapsible-card>
