import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'additional-customers-filter-chips',
  templateUrl: './additional-customers-filter-chips.component.html',
  styleUrls: ['./additional-customers-filter-chips.component.scss']
})
export class AdditionalCustomersFilterChipsComponent {
  @Input() public customerFilter: any;
  @Input() public isSelectedSavedGroupLarge: any;
  @Input() public isMobile: boolean;
  @Input() public noCustomersAvailableMsg: string;
  @Input() public maxCustomersMessage: string;
  @Input() public startFromIndex: number;
  @Output() public removeClicked = new EventEmitter();
}
