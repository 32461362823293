import * as calendar from '../actions/calendar';
import * as manageAttendees from '../actions/manage-attendees';
import { clone, findIndex, propEq } from 'ramda';

export interface State {
  attendeesLoading: boolean;
  error?: any;
  eventLoading: boolean;
  invitedCustomers: any;
  isUpdated: boolean;
  walkInCustomers: any;
}

export const initialState: State = {
  attendeesLoading: true,
  eventLoading: true,
  invitedCustomers: null,
  isUpdated: false,
  walkInCustomers: {
    inMySpotlight: [],
    notInMySpotlight: []
  }
};

export function reducer(state = initialState, action: manageAttendees.Actions | calendar.Actions): State {
  switch (action.type) {
    case calendar.ActionTypes.FETCH_EVENT_DETAILS: {
      return { ...state, eventLoading: true };
    }

    case calendar.ActionTypes.FETCH_EVENT_DETAILS_FAILURE: {
      const error = action.payload;
      return { ...state, error, eventLoading: false };
    }

    case calendar.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS: {
      return { ...state, eventLoading: false };
    }

    case manageAttendees.ActionTypes.FETCH_ATTENDEE_DATA:
    case manageAttendees.ActionTypes.UPDATE_ATTENDEE_DATA: {
      return { ...state, attendeesLoading: true };
    }

    case manageAttendees.ActionTypes.FETCH_ATTENDEE_DATA_FAILURE:
    case manageAttendees.ActionTypes.UPDATE_ATTENDEE_DATA_FAILURE: {
      const error = action.payload;
      return { ...state, attendeesLoading: false, error };
    }

    case manageAttendees.ActionTypes.FETCH_ATTENDEE_DATA_SUCCESS:
    case manageAttendees.ActionTypes.UPDATE_ATTENDEE_DATA_SUCCESS: {
      const attendeeData = action.payload;
      return { ...state, ...attendeeData, attendeesLoading: false };
    }

    case manageAttendees.ActionTypes.REMOVE_SPOT_WALK_IN_CUSTOMER: {
      const customer = action.payload;

      let walkInCustomers = clone(state.walkInCustomers);
      let walkInSpotCustomers = walkInCustomers.inMySpotlight;
      let index = findIndex(propEq('id', customer.id))(walkInSpotCustomers);
      walkInSpotCustomers.splice(index, 1);

      walkInCustomers.inMySpotlight = walkInSpotCustomers;

      return { ...state, isUpdated: true, walkInCustomers };
    }

    case manageAttendees.ActionTypes.SELECT_WALK_IN_CUSTOMER: {
      let customer = action.payload;

      // Default to 1 adult and no kids
      let formattedCustomer = {
        ...customer,
        adultsAttending: 1,
        kidsAttending: 0,
        name: customer.fullName
      };

      let inMySpotlight = clone(state.walkInCustomers.inMySpotlight);
      inMySpotlight.push(formattedCustomer);

      return {
        ...state,
        isUpdated: true,
        walkInCustomers: { ...state.walkInCustomers, inMySpotlight }
      };
    }

    case manageAttendees.ActionTypes.UPDATE_CUSTOMER_ATTENDEES: {
      let { responseType, customer } = action.payload;

      let invitedCustomers = state.invitedCustomers ? clone(state.invitedCustomers) : {};
      let responseArr = invitedCustomers[responseType] ? invitedCustomers[responseType] : [];
      let index = findIndex(propEq('id', customer.id))(responseArr);

      if (index > -1) {
        responseArr[index] = customer;
      } else {
        responseArr.push(customer);
      }

      invitedCustomers[responseType] = responseArr;

      return { ...state, invitedCustomers, isUpdated: true };
    }

    case manageAttendees.ActionTypes.UPDATE_NON_SPOT_WALK_IN_ADULTS: {
      let adultsAttending = action.payload;

      let notInMySpotlight = clone(state.walkInCustomers.notInMySpotlight);
      notInMySpotlight[0].adultsAttending = adultsAttending;

      return {
        ...state,
        isUpdated: true,
        walkInCustomers: { ...state.walkInCustomers, notInMySpotlight }
      };
    }

    case manageAttendees.ActionTypes.UPDATE_NON_SPOT_WALK_IN_KIDS: {
      let kidsAttending = action.payload;

      let notInMySpotlight = clone(state.walkInCustomers.notInMySpotlight);
      notInMySpotlight[0].kidsAttending = kidsAttending;

      return {
        ...state,
        isUpdated: true,
        walkInCustomers: { ...state.walkInCustomers, notInMySpotlight }
      };
    }

    case manageAttendees.ActionTypes.UPDATE_SPOT_WALK_IN_ATTENDEES: {
      let customer = action.payload;

      let walkInCustomers = clone(state.walkInCustomers);
      let walkInSpotCustomers = walkInCustomers.inMySpotlight;
      let index = findIndex(propEq('id', customer.id))(walkInSpotCustomers);
      walkInSpotCustomers[index] = customer;

      walkInCustomers.inMySpotlight = walkInSpotCustomers;

      return { ...state, isUpdated: true, walkInCustomers };
    }

    default: {
      return state;
    }
  }
}

export const getAttendeesLoading = (state: State) => state.attendeesLoading;

export const getError = (state: State) => state.error;

export const getEventLoading = (state: State) => state.eventLoading;

export const getInvitedCustomers = (state: State) => state.invitedCustomers;

export const getIsUpdated = (state: State) => state.isUpdated;

export const getWalkInCustomers = (state: State) => state.walkInCustomers;
