import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'personal-information',
  templateUrl: './personal-information-card.component.html',
  styleUrls: ['./personal-information-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PersonalInformationComponent {
  @Input() public info: any;
  @Input() public loading: boolean;
  @Input() public error: string;

  public get name() {
    if (this.info && this.info.displayName) {
      return this.info.displayName;
    } else {
      return this.info.name;
    }
  }
}
