<div class="profile-section__header" fxLayout="row">
    <div fxFlex class="profile-section__title">
        {{title}}
    </div>
    <div fxShow.gt-sm fxHide fxFlex="200px">
        <day-range-picker *ngIf="displayDate"
                          [exclude365Days] = "exclude365Days"
                          [defaultSelectedRange]="defaultSelectedRange"
                          (updateDate)="updateDateRange($event)">
        </day-range-picker>
    </div>
</div>
<ng-content></ng-content>
