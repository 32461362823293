import { Component, Input } from '@angular/core';

@Component({
  selector: 'basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss']
})
export class BasicCardComponent {
  @Input() public title: string = '';
  @Input() public subtitle: string;
  @Input() public loading: boolean;
  @Input() public error: string;
}
