import { Component, Input } from '@angular/core';

@Component({
  selector: 'number-display',
  templateUrl: './number-display.component.html',
  styleUrls: ['./number-display.component.scss']
})

export class NumberDisplayComponent {
  @Input() public category: string;
  @Input() public number: number;
  @Input() public title: string;
  @Input() public isPrimary: boolean;
  @Input() public format: string;
}
