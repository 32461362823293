import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment-timezone';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DiscoverConstants } from '../../discover/discover.constants';

export const customFormat = {
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: customFormat },
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() minDate;
  @Input() maxDate;
  @Input() public campaignType: string;
  @Input() public isMobile: boolean = false;
  @Input() public date: moment.Moment;
  @Input() public disabled: boolean = false;
  @Input() public futureDatesOnly: boolean = false;
  @Input() public pastDatesOnly: boolean = false;
  @Input() public maxDaysAllowed: number;
  @Input() public disableSundays: boolean = false;
  // An array of epoch dates that we need to disable
  @Input() public disabledDays: string[];
  @Input() public placeholder: string = 'Choose a Date';
  @Input() public validationControl: FormControl;

  @Output() public dateChanged = new EventEmitter();


  public dayFilter = null;
  public footerText = DiscoverConstants.datePickerFooter;

  public ngOnInit() {
    if (this.futureDatesOnly) {
      this.minDate = this.minDate ? this.minDate : moment();
    }

    if (this.pastDatesOnly) {
      this.maxDate = this.maxDate ? this.maxDate : moment();
    }

    if (this.disableSundays || this.disabledDays) {
      this.dayFilter = (d: moment.Moment): boolean => {
        let dayEnabled = true;
        // Prevent Sunday from being selected
        if (this.disableSundays) {
          dayEnabled = d && d.day() !== 0;
        }
        // Don't check disabledDays if day is already removed from Sunday check
        if (this.disabledDays && dayEnabled) {
          this.disabledDays.forEach((day) => {
            if (dayEnabled) {
              dayEnabled = d && moment(d).format("YYYY-MM-DD") !== day;
            }
          });
        }
        return dayEnabled;
      };
    }
  }

  public onDateChange(date) {
    let dateString = date.value.format('MM-DD-YYYY');
    this.dateChanged.emit(moment(dateString, 'MM-DD-YYYY'));
  }

  public onOpen() {
    // If it is BPP or FIC add a footer to the date picker
    if (this.campaignType === DiscoverConstants.bonusPoint || this.campaignType === DiscoverConstants.freeItemInCartPromotion) {
      const matCalendar = document.getElementsByClassName('mat-calendar')[0];
      const line = document.createElement('hr');
      line.className = 'date-picker__line';
      const footer = document.createElement('p');
      footer.className = 'date-picker__footer';
      const node = document.createTextNode(this.footerText);

      footer.appendChild(node);
      matCalendar.appendChild(line);
      matCalendar.appendChild(footer);
    }
  }
}
