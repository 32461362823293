export class AnalyticsConstants {
  public static get dimensionMap(): any {
    return {
      'devr.spotlight.cfahome.com': {
        env: 'DEV-R',
        logout: 'https://www.cfahome.com/login/logout.do',
        envColor: 'blue'
      },
      'dev.spotlight.cfahome.com': {
        env: 'DEV',
        logout: 'https://www.cfahome.com/login/logout.do',
        envColor: 'yellow'
      },
      'qa-basic.spotlight.cfahome.com': {
        env: 'QA-BASIC',
        logout: '/saml/logout',
        envColor: 'orange'
      },
      'qa.spotlight.cfahome.com': {
        env: 'QA',
        logout: '/saml/logout',
        envColor: 'red'
      },
      'spotlight.cfahome.com': {
        env: 'PROD',
        logout: '/saml/logout',
        envColor: ''
      },
      'cfa-spotlight-plt-devr.elasticbeanstalk.com': {
        // Deprecated
        env: 'DEV-R-PLT',
        logout: 'https://www.cfahome.com/login/logout.do',
        envColor: 'blue'
      },
      'cfa-spotlight-plt-dev.elasticbeanstalk.com': {
        // Deprecated
        env: 'DEV-PLT',
        logout: 'https://www.cfahome.com/login/logout.do',
        envColor: 'yellow'
      },
      'cfa-spotlight-plt-qa.elasticbeanstalk.com': {
        // Deprecated
        env: 'QA-PLT',
        logout: '/saml/logout',
        envColor: 'red'
      }
    };
  }
}
