import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { UserService } from '../../../../core/user';
import { MeetConstants } from '../../../../meet/meet.constants';
import { DataTableConstants } from '../../data-table.constants';
import {
  clone, compose, ifElse, isEmpty, pluck, prop, propIs, reverse, sortBy, toLower, findIndex, propEq
} from 'ramda';
import { Subject } from 'rxjs';

@Component({
  selector: 'mobile-data-table-full',
  templateUrl: './mobile-data-table-full.component.html',
  styleUrls: ['./mobile-data-table-full.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileDataTableFullComponent implements OnInit, OnDestroy {
  @Input() public frontEndPaging: boolean = false;
  @Input() public preventPagination: boolean = false;
  @Input() public rowIdProp: string = 'id';
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public detailViewNoteText: string;
  @Input() public rowId: string;

  @Input()
  set columns(columns: any[]) {
    this._columns = columns;
    const firstColumn = columns ? columns[0] : {};
    if (isEmpty(this.currentSort)) {
      this.currentSort = {
        prop: firstColumn.prop,
        order: 'asc',
      };
    }
  }

  get columns() {
    return this._columns;
  }

  @Input() public detailColumns: any[];
  @Input() public totalElements: number;
  @Input() public numberOfPages: number;
  @Input() public allCustomerIds: any[];
  @Input() public pageSize: number = DataTableConstants.defaultPageSize;
  @Input() public pageSizeOptions: number[];
  @Input() public sort: any;
  @Input() public currentPage: number;
  @Input() public currentSort: any = {};
  @Input() public isLoading: boolean;
  @Input() public noResultsMessage: string;
  @Input() public detailProp: string;
  @Input() public isDetailView: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public disableSelect: boolean = false;
  @Input() public actionableErrorText: string;
  @Input() public disableIndividualRowAttribute: string = '';
  @Input() public useSmallRowSize: boolean = false;
  @Input() public isRowNotInteractable: boolean;




  @Input()
  set rows(rows: any[]) {
    if (rows && rows.length > 0) {
      this._rows = this.setRows(rows);
      if (this.frontEndPaging) {
        this.allCustomerIds = pluck(this.rowIdProp, this.rows.filter((row) => !row[this.disableIndividualRowAttribute]));
        this.currentPage = 0;
        this.allRows = this.sortData(this.currentSort, this._rows);
        if (!this.preventPagination) {
          this.sliceRowsBasedOnCurrentPage();
        } else {
          this._rows = this.allRows;
        }
      }
    } else {
      this._rows = [];
    }
  }

  get rows() { return this._rows; }

  @Input()
  set view(view: any) {
    if (view === MeetConstants.inactivesView || this.previousView === MeetConstants.inactivesView) {
      this.clearSelections();
    }
    this.previousView = view;
  }

  @Input()
  set initiallySelectedCustomers(initialCustomers: any[]) {
    this.selectedItems = clone(initialCustomers);
    this.rows = this.setRows(this.rows);
  }

  @Input() public openRowSubject$: Subject<any>;
  @Input() public navigateToPage$: Subject<number>;

  @Output() public onPageChange = new EventEmitter<number>();
  @Output() public onPageSizeChange = new EventEmitter<number>();
  @Output() public onSortChange = new EventEmitter<any>();
  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onSelection = new EventEmitter<any[]>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onButtonClick = new EventEmitter<any>();
  @Output() public onExternalLinkClick = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onSortedData = new EventEmitter<any>();

  public selectedItems: any[] = [];
  public hasOnClickListeners: boolean;
  public _rows: any[];
  public _columns: any[];
  public previousView;
  public allRows: any[];
  public subscriptionArray = [];

  constructor(public us: UserService, public cd: ChangeDetectorRef) {
    this.subscriptionArray.push(this.us.selectedLocation$.subscribe(() => {
      this.clearSelections();
    }));
  }

  public ngOnInit() {
    this.hasOnClickListeners = this.onRowClick.observers.length > 0;
    if (this.navigateToPage$) {
      this.subscriptionArray.push(this.navigateToPage$.subscribe(page => {
        this.pageChange(page);
        this.cd.detectChanges();
      }));
    }

    if (this.openRowSubject$) {
      this.subscriptionArray.push(this.openRowSubject$.subscribe(row => {
        if (row && this.rowId) {
          let rowDataIndex = findIndex(propEq(this.rowId, row[this.rowId]))(this.rows);
          this.rows[rowDataIndex].open = false;
          this.openDetail(this.rows[rowDataIndex]);
          this.cd.detectChanges();
        }
      }));
    }
  }
  public rowClicked(rowData: any) {
    this.onRowClick.emit(rowData);
  }

  get showFooter(): boolean {
    return this.onPageChange.observers.length > 0 ||
      this.onPageSizeChange.observers.length > 0 ||
      (this.frontEndPaging && !this.preventPagination);
  }

  public pageChange(event) {
    this.currentPage = event;
    if (this.frontEndPaging) {
      this.sliceRowsBasedOnCurrentPage();
    } else {
      this.onPageChange.emit(event);
    }
  }

  public errorTextClicked() {
    this.errorTextClick.emit();
  }

  public sortData(sort, data) {
    let sortedRows = [];
    if (data && sort) {
      const isString = propIs(String, sort.prop);
      const ignoreCase = compose(toLower, prop(sort.prop));
      let sortByProp = prop(sort.prop);
      if (sort.subProp) {
        sortByProp = compose(prop(sort.subProp), prop(sort.prop));
      }
      const sortFn = sortBy(ifElse(
        isString,
        ignoreCase,
        sortByProp
      ));

      sortedRows = sortFn(data);
      if (sort.order === 'desc') {
        sortedRows = reverse(sortedRows);
      }
    }
    this.onSortedData.emit(sortedRows);
    return sortedRows;
  }

  public sliceRowsBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.totalElements) {
      this._rows = this.allRows.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this._rows = this.allRows.slice(nextRowIndex, this.totalElements);
    }
    this._rows = this.setRows(this._rows);
  }

  public clearSelections() {
    this.selectedItems = [];
    this.onSelection.emit(this.selectedItems);
  }

  public setRows(rows) {
    let ids = this.selectedItems;
    return rows.reduce((curr, next) => {
      next = { ...next };
      if (ids.indexOf(next[this.rowIdProp]) > -1) {
        next = { ...next, state: true };
      }
      curr.push(next);
      return curr;
    }, []);
  }

  public getColSize(col) {
    // undefined doesn't work with fxFlex so need to return null
    return col.size ? col.size : null;
  }

  public isDataAvailable() {
    return this.rows
      && this.rows.length
      && this.rows.length > 0;
  }

  public openDetail(rowData) {
    if (rowData) {
      rowData['open'] = !rowData.open;
    }
  }

  public shouldDisableRow(row: any) {
    return row[this.disableIndividualRowAttribute];
  }

  public shouldHideChevron(row: any) {
    return (this.shouldDisableRow(row) || (!this.hasOnClickListeners) || this.isRowNotInteractable) || this.isDetailView;
  }

  public ngOnDestroy() {
    this.subscriptionArray.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
