import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss']
})
export class FilterChipComponent {
  @Input() public title: string = '';
  @Input() public isShowGroup;
  @Input() public isMobile: boolean;

  @Output() public removeClicked = new EventEmitter<any>();
  @Output() public showGroupClicked = new EventEmitter<any>();
}
