import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { browserRefreshed } from 'spotlight-ui/app/app.component';
import { take } from 'rxjs/operators';
import {
  getEngageDisplayCampaignName, getEngagementCampaignName, getEngagementStartAndEndDate, getEngageMode, getEngageType,
  getIsExtraSmallMobile, getOverlappingEngagements, getOverlappingEngagementType, getSelectedEngagement, State
} from 'spotlight-ui/app/reducers';
import { SharedConstants } from '../shared.constants';

@Component({
  selector: 'overlapping-engagements',
  templateUrl: './overlapping-engagements.component.html',
  styleUrls: ['./overlapping-engagements.component.scss']
})
export class OverlappingEngagementsComponent implements OnInit {

  public selectedEngagement$: Observable<any>;
  public campaignName$: Observable<string>;
  public overlappingEngagements$: Observable<any>;
  public type$: Observable<string>;
  public isMobile$: Observable<boolean>;
  public mode$: Observable<string>;
  public selectedOverlappingEngagementType$: Observable<string>;

  constructor(private store: Store<State>, private router: Router) {}

  public ngOnInit() {
    this.selectedOverlappingEngagementType$ = this.store.select(getOverlappingEngagementType);
    if (browserRefreshed) {
      this.router.navigate(['/discover']);
    }
    window.scroll(0, 0);
    // check selected Overlapping engagement type
    this.selectedOverlappingEngagementType$.pipe(take(1)).subscribe((overlappingEngagementType) => {
      if (overlappingEngagementType === SharedConstants.discoverPage) {
        this.isMobile$ = this.store.select(getIsExtraSmallMobile);
        this.overlappingEngagements$ = this.store.select(getOverlappingEngagements);
        this.selectedEngagement$ = this.store.select(getEngagementStartAndEndDate);
        this.campaignName$ = this.store.select(getEngagementCampaignName);
      } else if (overlappingEngagementType === SharedConstants.engagePage) {
        this.selectedEngagement$ = this.store.select(getSelectedEngagement);
        this.campaignName$ = this.store.select(getEngageDisplayCampaignName);
        this.overlappingEngagements$ = this.store.select(getOverlappingEngagements);
        this.type$ = this.store.select(getEngageType);
        this.isMobile$ = this.store.select(getIsExtraSmallMobile);
        this.mode$ = this.store.select(getEngageMode);
      }
    });
  }

  public goBack() {
    this.selectedOverlappingEngagementType$.pipe(take(1)).subscribe((overlappingEngagementType) => {
      if (overlappingEngagementType === SharedConstants.discoverPage) {
        this.router.navigate(['/discover/edit/edit-community-care-detail']);
      } else if (overlappingEngagementType === SharedConstants.engagePage) {
        this.router.navigate(['/engage/engage-details']);
      }
    })
  }
}
