import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppConstants } from '../../app.constants';

@Injectable()
export class ErrorHandlerService {
  public handleError(httpError: HttpErrorResponse | any): Observable<any> {
    let errMsg: string;
    if (httpError instanceof HttpErrorResponse) {
      console.log(httpError);
      if (httpError.error && httpError.error.cfaError) {
        errMsg = httpError.error.cfaError.userMessage ?  httpError.error.cfaError.userMessage : AppConstants.genericApiError ;
      } else {
        if (httpError.error && (httpError.error.message || httpError.error.error)) {
          const err = httpError.error;
          errMsg = `${httpError.status} - ${err.message || ''} ${err.error || ''}`;
        } else {
          if (httpError.status === 0) {
            errMsg = httpError.url.includes("sendOffer") ? AppConstants.rewardTimeoutError : AppConstants.genericTimeoutError;
          } else {
            errMsg = AppConstants.genericApiError;
          }
        }
      }
    } else {
      errMsg = httpError.message ? httpError.message : httpError.toString();
    }
    console.error(errMsg);

    return throwError(errMsg);
  }
}
