import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import * as fromUnderstand from '../actions/understand';
import {
  ErrorUnderstandTileData, FetchUnderstandDetailFailure, FetchUnderstandDetailsConfigError,
  FetchUnderstandDetailsConfigSuccess, FetchUnderstandDetailSuccess, FoundCfaOneComparison, FoundMobileComparison,
  FoundUnderstandTileData, FoundUnderstandTiles, GetCateringTiles, GetCustomerTiles, GetEmailTiles, GetEventTiles, GetRewardTiles,
  GetTrendTiles, UnderstandTilesError, GetRecoverTiles,
} from '../actions/understand';
import * as fromUser from '../actions/user';
import { UserService } from '../core/user/user.service';
import { ErrorHandlerService } from '../core/error-handler/error-handler.service';
import {
  getEmailTiles, getEventTiles, getRewardTiles, getSelectedTabIndex, getTilesWithoutExistingData,
  getUnderstandDetails, State, getCareSelectedTabIndex, getRecoverTiles, getCateringTiles,
  getCustomerTiles, getTilesForMeetPage, getTrendTiles
} from '../reducers';
import { EngageConstants } from '../engage/engage.constants';
import { UnderstandService } from '../understand/understand.service';
import { Tile } from '../models/understand/tile.model';
import { clone } from 'ramda';
import { CareConstants } from '../care/care.constants';
import { environment } from "../../environments/environment";

@Injectable()
export class UnderstandEffects {
  @Effect()
  public fetchCfaOneComparison: Observable<Action> = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.FETCH_COMPARISON),
    map((action: any) => action.payload),
    map((dates) => this.formatDates(dates)),
    switchMap((dates) => this.getCfaOneComparison(dates)),
    map((res) => new FoundCfaOneComparison(res))
  );

  @Effect()
  public fetchMobileComparison: Observable<Action> = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.FETCH_COMPARISON),
    map((action: any) => action.payload),
    map((dates) => this.formatDates(dates)),
    switchMap((dates) => this.getMobileComparison(dates)),
    map((res) => new FoundMobileComparison(res))
  );

  @Effect()
  public getUnderstandDrillDownConfig$: Observable<Action> = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG),
    map((action: any) => action.payload),
    switchMap((params) => this.understandService.getUnderstandDrillDownConfig(params.id).pipe(
      map((res) => new FetchUnderstandDetailsConfigSuccess({
        detailViewObj: res,
        detailViewId: params.detailViewId
      })),
      catchError((error) => of(new FetchUnderstandDetailsConfigError(error)))
    ))
  );

  @Effect()
  public fetchUnderstandDetail = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG_SUCC,
      fromUnderstand.ActionTypes.SELECT_FILTER_CHIP,
      fromUnderstand.ActionTypes.SELECT_DROPDOWN_FILTER,
      fromUnderstand.ActionTypes.SEARCH_TERM,
      fromUnderstand.ActionTypes.CLEAR_SEARCH,
      fromUnderstand.ActionTypes.CHANGE_PAGE,
      fromUnderstand.ActionTypes.CHANGE_PAGE_SIZE,
      fromUnderstand.ActionTypes.CHANGE_SORT
    ),
    map((action: any) => action.payload),
    switchMap((payload) => this.store.select(getUnderstandDetails).pipe(take(1),
      switchMap((config) => this.getUnderstandDetail(config, payload).pipe(
        map((res) => new FetchUnderstandDetailSuccess({ id: config.id, viewData: res })),
        catchError((err) => of(new FetchUnderstandDetailFailure(err)))
      ))
    ))
  );

  @Effect()
  public fetchUnderstandTilesForEngage$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.LOAD_UNDERSTAND_TILES,
      fromUser.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      fromUnderstand.ActionTypes.UPDATE_ENGAGE_ANALYTICS_DATE_RANGE
    ),
    switchMap(() => this.store.select(getSelectedTabIndex).pipe(take(1))),
    filter((tabIndex) => this.router.url.includes('/engage/template-selection') && tabIndex === EngageConstants.engageAnalyticsTabIndex),
    switchMap(() => this.understandService.loadUnderstandTiles().pipe(
      mergeMap((data) => [
        new FoundUnderstandTiles(data),
        new GetRewardTiles(),
        new GetEmailTiles(),
        new GetEventTiles()
      ]),
      catchError((err) => of(new UnderstandTilesError(err)))
    ))
  );

  @Effect()
  public fetchUnderstandTilesForMeet$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.LOAD_UNDERSTAND_TILES,
      fromUser.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      fromUnderstand.ActionTypes.UPDATE_MEET_ANALYTICS_DATE_RANGE
    ),
    switchMap(() => this.store.select(getSelectedTabIndex).pipe(take(1))),
    filter(() => this.router.url.includes('/meet')),
    switchMap(() => this.understandService.loadUnderstandTiles().pipe(
      mergeMap((data) => [
        new FoundUnderstandTiles(data),
        new GetTrendTiles(),
        new GetCustomerTiles(),
        new GetCateringTiles()
      ]),
      catchError((err) => of(new UnderstandTilesError(err)))
    ))
  );

  @Effect({ dispatch: false })
  public selectCategory$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.SELECT_CATEGORY,
      fromUnderstand.ActionTypes.SET_AS_ONLY_CATEGORY,
      fromUnderstand.ActionTypes.UPDATE_DATE_RANGE,
      fromUnderstand.ActionTypes.EXCLUDE_CEM_REWARDS,
    ),
    switchMap(() => this.store.select(getTilesWithoutExistingData).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getRewardTiles$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.GET_REWARD_TILES,
      fromUnderstand.ActionTypes.EXCLUDE_CEM_REWARDS
    ),
    switchMap(() => this.store.select(getSelectedTabIndex).pipe(take(1))),
    filter((tabIndex) => this.router.url.includes('/engage/template-selection') && tabIndex === EngageConstants.engageAnalyticsTabIndex),
    switchMap(() => this.store.select(getRewardTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getEventTiles$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.GET_EVENT_TILES
    ),
    switchMap(() => this.store.select(getEventTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getEmailTiles$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.GET_EMAIL_TILES
    ),
    switchMap(() => this.store.select(getEmailTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect()
  public fetchUnderstandTilesForCare$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.LOAD_UNDERSTAND_TILES,
      fromUser.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      fromUnderstand.ActionTypes.UPDATE_RECOVER_ANALYTICS_DATE_RNG
    ),
    switchMap(() => this.store.select(getCareSelectedTabIndex).pipe(take(1))),
    filter((tabIndex) => this.router.url.includes('/care') && tabIndex === CareConstants.recoverAnalyticsTabIndex),
    switchMap(() => this.understandService.loadUnderstandTiles().pipe(
      mergeMap((data) => [
        new FoundUnderstandTiles(data),
        new GetRecoverTiles()
      ]),
      catchError((err) => of(new UnderstandTilesError(err)))
    ))
  );

  @Effect({ dispatch: false })
  public getRecoverTiles$ = this.actions$.pipe(
    ofType(
      fromUnderstand.ActionTypes.GET_RECOVER_TILES
    ),
    switchMap(() => this.store.select(getRecoverTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public updateMeetAnalyticsDateRange$ = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.UPDATE_MEET_ANALYTICS_DATE_RANGE),
    switchMap(() => this.store.select(getTilesForMeetPage).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getTrendTiles$ = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.GET_TREND_TILES),
    switchMap(() => this.store.select(getTrendTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getCustomerTiles$ = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.GET_CUSTOMER_TILES),
    switchMap(() => this.store.select(getCustomerTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  @Effect({ dispatch: false })
  public getCateringTiles$ = this.actions$.pipe(
    ofType(fromUnderstand.ActionTypes.GET_CATERING_TILES),
    switchMap(() => this.store.select(getCateringTiles).pipe(take(1))),
    mergeMap((tiles) => this.triggerTileCalls(tiles)),
    map((observable) => observable.subscribe())
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private us: UserService,
    private ehs: ErrorHandlerService,
    private router: Router,
    private store: Store<State>,
    private understandService: UnderstandService
  ) {}

  private setUpUrlMap(tiles: Tile[]){
    let urlMap = {}
    tiles.forEach(tile=>{
      let currentArray = urlMap[tile.url] ? urlMap[tile.url] : [];
      currentArray.push(tile);
      urlMap[tile.url]= currentArray;
    })
    return urlMap;
  }
  private triggerTileCalls(tiles: Tile[]) {
    let urlMap = this.setUpUrlMap(tiles);
    let tileObservables = []
    for(let url in urlMap){
      let tiles = urlMap[url];
      let currentTile = tiles[0];
      tileObservables.push(this.makeOneCallForAllTiles(currentTile,tiles));
    }
    return merge(tileObservables);
  }

  private makeOneCallForAllTiles(currentTile,tiles){
    return this.understandService.getUnderstandTileData(currentTile).pipe(
      map((data)=>{
        let tileList =  tiles.map(tile => {
          return this.store.dispatch(new FoundUnderstandTileData({
            id: tile.id,
            data: data
          }))
        })
        return merge(tileList)
      }),
      catchError((err) => {
          return merge(tiles.map(tile => {
            return of(this.store.dispatch(new ErrorUnderstandTileData({id: tile.id, err})))
          }))
        }
      )
    )
  }

  private getUnderstandDetail(config, payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        let url = `/api/location/${locationNumber}${config.url}`;
        if (config.requestProp) {
          url += `?${config.requestProp}=${payload.detailViewId}`;
        } else if (config.url === '/treatRedemptionComparisonList') {
          // Add the CEM check only to the drilldown used for rewards
          url += `?isCemCheck=${config.isCemCheck}`;
        }
        let sortBy = config.sortBy;
        let sortOrder = config.sortOrder;
        if (config.serverPaging && config.defaultSortBy) {
          sortBy = config.sortBy ? config.sortBy : config.defaultSortBy.prop;
          sortOrder = config.sortOrder ? config.sortOrder : config.defaultSortBy.order;
        }

        let onePlus = clone(config.endDate).add(1, 'd');
        let filters = config.selectedFilterChips;
        let searchTerm = config.searchTerm ? config.searchTerm.termName : null;
        const dates = {
          fromDate: config.startDate.format('YYYY-MM-DD'),
          toDate: onePlus.format('YYYY-MM-DD')
        };
        return this.http.post(environment.spotlightApiUrl +
          url,
          {
            ...dates,
            ...config.body,
            page: config.page,
            limit: config.pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
            filters,
            searchBy: config.searchBy,
            searchTerm
          }
        );
      }),
      map((res: any) => {
        return res.responseObject;
      }),
      catchError(this.ehs.handleError)
    );
  }

  private getCfaOneComparison(dates) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/transactions/cfaoneComparisonByLocation`;
        return this.http.post(environment.spotlightApiUrl + url, dates).pipe(
          map((res: any) => {
            return res.responseObject.cfaoneComparisonBatchStats[0] || {};
          }));
      })
    );
  }

  private getMobileComparison(dates) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/transactions/mobileComparisonByLocation`;
        return this.http.post(environment.spotlightApiUrl + url, dates).pipe(
          map((res: any) => {
            return res.responseObject.mobileComparisonBatchStats[0] || {};
          }));
      })
    );
  }

  private formatDates(dates) {
    return {
      fromDate: dates.startDate.format('YYYY-MM-DD'),
      toDate: dates.endDate.format('YYYY-MM-DD')
    };
  }
}
