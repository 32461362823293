import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  FETCH_COMPARISON:                   type('[Understand] Fetch Comparison Stats'),
  FOUND_CFA_COMPARISON:               type('[Understand] Found CFA One Comparison Stats'),
  FOUND_MOBILE_COMPARISON:            type('[Understand] Found Mobile Comparison Stats'),
  FETCH_UNDERSTAND_DETAIL_SUCCESS:    type('[Understand] Fetch understand drilldown data success'),
  FETCH_UNDERSTAND_DETAIL_FAILURE:    type('[Understand] Fetch understand drilldown data failure'),
  CLEAR_UNDERSTAND_DATA:              type('[Understand] Clear understand data'),
  FETCH_UNDERSTAND_TILES:             type('[Understand] Fetch understand tiles'),
  FOUND_UNDERSTAND_TILES:             type('[Understand] Found understand tiles'),
  UNDERSTAND_TILES_ERROR:             type('[Understand] Understand tiles error'),
  SELECT_CATEGORY:                    type('[Understand] Select Category'),
  SET_AS_ONLY_CATEGORY:               type('[Understand] Set as only category'),
  FOUND_UNDERSTAND_TILE_DATA:         type('[Understand] Found Understand Tile Data'),
  ERROR_UNDERSTAND_TILE_DATA:         type('[Understand] Error Understand Tile Data'),
  UPDATE_DATE_RANGE:                  type('[Understand] Update date range'),
  UPDATE_MEET_ANALYTICS_DATE_RANGE:   type('[Understand] Update Meet Analytics Date Range'),
  EXCLUDE_CEM_REWARDS:                type('[Understand] Exclude CEM Rewards Checked'),
  GET_UNDERSTAND_DETAIL_CONFIG:       type('[Understand] Fetch understand drilldown config'),
  GET_UNDERSTAND_DETAIL_CONFIG_SUCC:  type('[Understand] Fetch understand drilldown config success'),
  GET_UNDERSTAND_DETAIL_CONFIG_ERROR: type('[Understand] Fetch understand drilldown config ERROR'),
  SELECT_FILTER_CHIP:                 type('[Understand] Select Filter Chip'),
  SELECT_DROPDOWN_FILTER:             type('[Understand] Select Dropdown Filter'),
  SEARCH_BY:                          type('[Understand] Search By'),
  SEARCH_TERM:                        type('[Understand] Search Term'),
  CLEAR_SEARCH:                       type('[Understand] Clear Search'),
  CHANGE_PAGE:                        type('[Understand] Change Page'),
  CHANGE_PAGE_SIZE:                   type('[Understand] Change Page Size'),
  CHANGE_SORT:                        type('[Understand] Change Sort'),
  LOAD_UNDERSTAND_TILES:              type('[Understand] Load Understand Tiles'),
  UPDATE_ENGAGE_ANALYTICS_DATE_RANGE: type('[Understand] Update Engage Analytics Date Range'),
  GET_REWARD_TILES:                   type('[Understand] Get Rewards Tiles'),
  GET_EVENT_TILES:                    type('[Understand] Get Events Tiles'),
  GET_EMAIL_TILES:                    type('[Understand] Get Email Tiles'),
  UPDATE_RECOVER_ANALYTICS_DATE_RNG:  type('[Understand] Update Recover Analytics Date Range'),
  GET_RECOVER_TILES:                  type('[Understand] Get Recover Tiles'),
  SELECT_ANALYTICS_CATEGORY:          type('[Understand] Select Analytics Category'),
  GET_TREND_TILES:                    type('[Understand] Get Trend Tiles'),
  GET_CUSTOMER_TILES:                 type('[Understand] Get Customer Tiles'),
  GET_CATERING_TILES:                 type('[Understand] Get Catering Tiles')
};

export class FetchComparison implements Action {
  public type = ActionTypes.FETCH_COMPARISON;

  constructor(public payload: any = '') { }
}

export class FoundCfaOneComparison implements Action {
  public type = ActionTypes.FOUND_CFA_COMPARISON;

  constructor(public payload: any) { }
}

export class FoundMobileComparison implements Action {
  public type = ActionTypes.FOUND_MOBILE_COMPARISON;

  constructor(public payload: any) { }
}

export class FetchUnderstandDetailSuccess implements Action {
  public type = ActionTypes.FETCH_UNDERSTAND_DETAIL_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchUnderstandDetailFailure implements Action {
  public type = ActionTypes.FETCH_UNDERSTAND_DETAIL_FAILURE;
  constructor(public payload: string) { }
}

export class ClearUnderstandData implements Action {
  public type = ActionTypes.CLEAR_UNDERSTAND_DATA;
  constructor(public payload: string) { }
}

export class FetchUnderstandTiles implements Action {
  public type = ActionTypes.FETCH_UNDERSTAND_TILES;
  constructor(public payload: any = '') { }
}

export class FoundUnderstandTiles implements Action {
  public type = ActionTypes.FOUND_UNDERSTAND_TILES;
  constructor(public payload: any = '') { }
}

export class UnderstandTilesError implements Action {
  public type = ActionTypes.UNDERSTAND_TILES_ERROR;
  constructor(public payload: any = '') { }
}

export class SelectCategory implements Action {
  public type = ActionTypes.SELECT_CATEGORY;
  constructor(public payload: any = '') { }
}

export class SetAsOnlyCategory implements Action {
  public type = ActionTypes.SET_AS_ONLY_CATEGORY;
  constructor(public payload: any) { }
}

export class SelectFilterChip implements Action {
  public type = ActionTypes.SELECT_FILTER_CHIP;
  constructor(public payload: any = '') { }
}

export class SelectDropdownFilter implements Action {
  public type = ActionTypes.SELECT_DROPDOWN_FILTER;
  constructor(public payload: any = '') { }
}

export class ExcludeCemRewards implements Action {
  public type = ActionTypes.EXCLUDE_CEM_REWARDS;
  constructor(public payload: any = '') { }
}

export class FoundUnderstandTileData implements Action {
  public type = ActionTypes.FOUND_UNDERSTAND_TILE_DATA;
  constructor(public payload: any = '') { }
}

export class ErrorUnderstandTileData implements Action {
  public type = ActionTypes.ERROR_UNDERSTAND_TILE_DATA;
  constructor(public payload: any = '') { }
}

export class UpdateDateRange implements Action {
  public type = ActionTypes.UPDATE_DATE_RANGE;
  constructor(public payload: any = '') { }
}

export class UpdateMeetAnalyticsDateRange implements Action {
  public type = ActionTypes.UPDATE_MEET_ANALYTICS_DATE_RANGE;
  constructor(public payload: any = '') { }
}

export class FetchUnderstandDetailsConfig implements Action {
  public type = ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG;
  constructor(public payload: any = '') { }
}

export class FetchUnderstandDetailsConfigSuccess implements Action {
  public type = ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG_SUCC;
  constructor(public payload: any = '') { }
}

export class FetchUnderstandDetailsConfigError implements Action {
  public type = ActionTypes.GET_UNDERSTAND_DETAIL_CONFIG_ERROR;
  constructor(public payload: any = '') { }
}

export class SearchBy implements Action {
  public type = ActionTypes.SEARCH_BY;
  constructor(public payload: any = '') {}
}

export class SearchTerm implements Action {
  public type = ActionTypes.SEARCH_TERM;
  constructor(public payload: any = '') {}
}

export class ClearSearch implements Action {
  public type = ActionTypes.CLEAR_SEARCH;
  constructor(public payload: any = '') {}
}

export class ChangePage implements Action {
  public type = ActionTypes.CHANGE_PAGE;
  constructor(public payload: number) { }
}

export class ChangePageSize implements Action {
  public type = ActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload: number) { }
}

export class ChangeSort implements Action {
  public type = ActionTypes.CHANGE_SORT;
  constructor(public payload: any) { }
}

export class UpdateEngageAnalyticsDateRange implements Action {
  public type = ActionTypes.UPDATE_ENGAGE_ANALYTICS_DATE_RANGE;
  constructor(public payload: any = '') { }
}
export class GetRewardTiles implements Action {
  public type = ActionTypes.GET_REWARD_TILES;
  constructor(public payload: any = '') { }
}

export class GetEventTiles implements Action {
  public type = ActionTypes.GET_EVENT_TILES;
  constructor(public payload: any = '') { }
}

export class GetEmailTiles implements Action {
  public type = ActionTypes.GET_EMAIL_TILES;
  constructor(public payload: any = '') { }
}
export class UpdateRecoverAnalyticsDateRange implements Action {
  public type = ActionTypes.UPDATE_RECOVER_ANALYTICS_DATE_RNG;
  constructor(public payload: any = '') { }
}

export class GetRecoverTiles implements Action {
  public type = ActionTypes.GET_RECOVER_TILES;
  constructor(public payload: any = '') { }
}

export class LoadUnderstandTiles implements Action {
  public type = ActionTypes.LOAD_UNDERSTAND_TILES;
  constructor(public payload: any = '') { }
}

export class SelectAnalyticsCategory implements Action {
  public type = ActionTypes.SELECT_ANALYTICS_CATEGORY;
  constructor(public payload: any = '') { }
}

export class GetTrendTiles implements Action {
  public type = ActionTypes.GET_TREND_TILES;
  constructor(public payload: any = '') { }
}

export class GetCustomerTiles implements Action {
  public type = ActionTypes.GET_CUSTOMER_TILES;
  constructor(public payload: any = '') { }
}

export class GetCateringTiles implements Action {
  public type = ActionTypes.GET_CATERING_TILES;
  constructor(public payload: any = '') { }
}

export type Actions
  = FetchComparison
  | FoundCfaOneComparison
  | FoundMobileComparison
  | FetchUnderstandDetailSuccess
  | FetchUnderstandDetailFailure
  | ClearUnderstandData
  | FetchUnderstandTiles
  | FoundUnderstandTiles
  | UnderstandTilesError
  | SelectCategory
  | SetAsOnlyCategory
  | FoundUnderstandTileData
  | ErrorUnderstandTileData
  | UpdateDateRange
  | UpdateMeetAnalyticsDateRange
  | ExcludeCemRewards
  | FetchUnderstandDetailsConfig
  | FetchUnderstandDetailsConfigSuccess
  | FetchUnderstandDetailsConfigError
  | SelectFilterChip
  | SearchBy
  | SearchTerm
  | ClearSearch
  | ChangePage
  | ChangePageSize
  | GetTrendTiles
  | GetCustomerTiles
  | GetCateringTiles
  | ChangeSort
  | LoadUnderstandTiles
  | GetRewardTiles
  | GetEventTiles
  | UpdateEngageAnalyticsDateRange
  | GetRecoverTiles
  | UpdateRecoverAnalyticsDateRange
  | SelectAnalyticsCategory
  | SelectDropdownFilter;
