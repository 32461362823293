export class MeetConstants {
  public static get pageSizeOptions(): number[] {
    return [20, 40, 60];
  }

  public static get customerViews(): any {
    return [this.allCustomerView, this.cateringView, this.inactivesView];
  }

  public static get allCustomerView(): string {
    return 'All Customers';
  }

  public static get cateringView(): string {
    return 'Catering Customers';
  }

  public static get inactivesView(): string {
    return 'Inactive Customers';
  }

  public static get feedbackView(): string {
    return 'Customer Feedback';
  }

  public static get cemMobileComment(): string {
    return 'CEM-Mobile';
  }

  public static get cemComment(): string {
    return 'CEM';
  }

  public static get fourDigitMembershipNumberTitle(): string {
    return 'Last 4 Digits of Membership Number';
  }

  public static get fullMembershipNumberTitle(): string {
    return 'Full 14-Digit Membership Number';
  }

  public static get membershipNumberValue(): string {
    return 'membershipNumber';
  }

  public static get customerEmailTitle(): string {
    return 'Full Email Address';
  }

  public static get customerEmailValue(): string {
    return 'customerEmail';
  }

  public static get phoneNumberTitle(): string {
    return '10-Digit Phone Number';
  }

  public static get phoneNumberValue(): string {
    return 'phoneNumber';
  }

  public static get isNewCfaOneLastMonth(): string {
    return 'isNewCfaOneLastMonth';
  }

  public static get newMemberLastMonth(): string {
    return 'new-member-last-month';
  }

  public static get isNewCfaOneThisMonth(): string {
    return 'isNewCfaOneThisMonth';
  }

  public static get newMemberThisMonth(): string {
    return 'new-member-this-month';
  }

  public static get filtersSpecifyMembershipID(): string {
    return 'membership';
  }

  public static get filtersOrderingCategory(): string {
    return 'ordering';
  }

  public static get customerNameSearchBy(): string {
    return 'customerName';
  }

  public static get searchByCategories(): any[] {
    return [{
      defaultCategory: true,
      title: 'Customer Name',
      value: 'customerName'
    }, {
      defaultCategory: false,
      exampleText: 'Example `4047658000`',
      minLength: 10,
      title: this.phoneNumberTitle,
      value: this.phoneNumberValue
    }, {
      defaultCategory: false,
      errorText: 'Please enter a valid email address to continue',
      exampleText: 'Example `cows@cfacorp.com`',
      exampleTextNote: 'Note: Due to privacy restrictions, email is searchable but will not display.',
      title: this.customerEmailTitle,
      value: this.customerEmailValue
    }, {
      defaultCategory: false,
      exampleText: 'Example `1234`',
      maxLength: 4,
      minLength: 4,
      numbersOnly: true,
      title: this.fourDigitMembershipNumberTitle,
      value: this.membershipNumberValue
    }, {
      defaultCategory: false,
      exampleText: 'Example `89001123456789`',
      maxLength: 14,
      minLength: 14,
      numbersOnly: true,
      title: this.fullMembershipNumberTitle,
      value: this.membershipNumberValue
    }];
  }

  public static get cateringCustomersTableColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'lastCateringDate',
      title: 'Last Catering Order Date',
      mobile: 'subtitle',
      type: 'visitDate',
      mobileTitle: 'Last Catering Order Date'
    }, {
      prop: 'lastCateringTotal',
      title: 'Last Catering Order Total',
      type: 'currency'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      sort: false
    }];
  }

  public static get inactivesTableColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'firstName',
      title: 'First Name',
      icon: 'inactive'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'lastTransactionDate',
      title: 'Last Visit Date',
      mobile: 'subtitle',
      type: 'visitDate',
      mobileTitle: 'Last visited '
    }, {
      prop: 'birthDate',
      title: 'Birthday'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      sort: false
    }];
  }

  public static get feedbackTableColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'surveyDate',
      title: 'Submit Date',
      mobile: 'subtitle',
      type: 'DayDateTime'
    }, {
      prop: 'visitDate',
      title: 'Visit Date',
      type: 'DayDateTime'
    }, {
      prop: 'firstName',
      title: 'First Name',
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'source',
      title: 'Source'
    }];
  }

  public static get allCustomersTableColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'firstName',
      title: 'First Name',
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'lastTransactionDate',
      title: 'Last Visit Date',
      mobile: 'subtitle',
      type: 'visitDate',
      mobileTitle: 'Last visited '
    }, {
      prop: 'birthDate',
      title: 'Birthday'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      sort: false
    }];
  }

  public static get currentNoResultsMessage(): string {
    return 'There were no customers found for the requested criteria.';
  }

  public static get pageSize(): number {
    return 5;
  }

  public static get disableSendingFlag(): string {
    return 'disableAllSending';
  }

  public static get searchPlaceHolderForDesktop(): string {
    return 'Search Spotlight';
  }

  public static get disableSavedGroupEditAndDeleteFlag(): string {
    return 'filterRedesignDisableSavedGroupEditAndDelete';
  }

  public static get includeFilterId() {
    return 'include-customers';
  }

  public static get excludeFilterId() {
    return 'exclude-customers';
  }

  public static get individualCustomersFilterId() {
    return 'individual-customers';
  }

  public static get maxIndividualCustomer() {
    return 10;
  }

  public static get analyticsTabIndex(): number {
    return 1;
  }

  public static get analyticsMeetCategory(): string {
    return 'meet';
  }

  public static get analyticsTrendsCategory(): string {
    return 'trends';
  }

  public static get analyticsCustomerCategory(): string {
    return 'customer';
  }

  public static get analyticsCateringCategory(): string {
    return 'catering';
  }

  public static get savedGroupsFilterId(): string {
    return 'saved-groups';
  }

  public static get noCustomersAvailableMsg(): string {
    return 'Oh no! Looks like there are no customers in this saved group.';
  }

  public static get maxSavedGroupCustomersMessage(): string {
    return 'You are viewing a maximum of 50 customers. To view all the customers from this group, please use the main customer table.';
  }

  public static get deleteText(): string {
    return 'Delete';
  }

  public static get cancelDeleteText(): string {
    return 'No, thanks';
  }

  public static get savedGroupDeleteText(): string {
    return 'Are you sure you want to delete this group?';
  }

  public static get editIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/Edit_RedIcon.png';
  }

  public static get deleteIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/Delete_RedIcon.png';
  }

  public static get savedGroupDeleteWarningText(): string {
    return 'This action is permanent and you will not be able to recover data.';
  }

  public static get infoHelpText(): string {
    return ' It appears that your selected filter is also included in the applied saved group filter.'+
      'Please apply a new filter to avoid getting this error.';
  }
}
