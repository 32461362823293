import { Component, Input } from '@angular/core';
import { all, equals, values } from 'ramda';
import { SharedConstants } from '../../../shared.constants';

@Component({
  selector: 'doughnut-chart',
  template: `
    <chart *ngIf="showChart"
           [type]="'doughnut'"
           [width]="230"
           [height]="103"
           [margin]="10"
           [data]="getDoughnutChartData()"
           [options]="getDoughnutChartOptions()">
    </chart>
    <div *ngIf="!showChart"
         class="doughnut-chart__no-data-container"
         fxLayout="column"
         fxLayoutAlign="center center">
        <span class="doughnut-chart__no-data-text">
            {{noDataText}}
        </span>
    </div>
  `,
  styleUrls: ['./doughnut-chart.component.scss']

})

export class DoughnutChartComponent {
  @Input()
  set data(data: any) {
    this._data = data;

    // Don't show chart if all data values are 0
    let allValues = values(this.data);
    this.showChart = !all(equals(0))(allValues);
  }

  get data() {
    return this._data;
  }

  @Input() public labels: string[];
  @Input() public colors: string[];

  public _data;
  public showChart;
  public noDataText = SharedConstants.noDataText;

  public getDoughnutChartData() {
    return {
      datasets: [{
        data: values(this.data),
        backgroundColor: this.colors
      }],
      labels: this.labels,
    };
  }

  public getDoughnutChartOptions() {
    return {
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontSize: 9
        }
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) =>
            this.getLabelFormatted(
              data.labels[tooltipItems.index],
              data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
            )
        }
      }
    };
  }

  public getLabelFormatted(labelName: string, labelValue: number) {
    // Handle decimal point display on UI for Eg: 0.55 will be displayed as 55%
    // and 0.23434 will be displayed as 23.434%
    return `${labelName}: ${Number((labelValue * 100).toPrecision(12))}%`;
  }
}
