import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { contains, pluck } from 'ramda';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, mergeMap, take } from 'rxjs/operators';

import * as meet from '../actions/meet';
import * as customer from '../actions/customer';
import * as user from '../actions/user';
import * as care from '../actions/care';
import { MeetService } from '../meet/meet.service';
import * as fromRoot from '../reducers';
import { MeetConstants } from '../meet/meet.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../app.constants';
import { Router } from '@angular/router';

@Injectable()
export class MeetEffects {
  @Effect()
  public loadFilters$: Observable<any> = this.actions$.pipe(
    ofType(
      meet.ActionTypes.LOAD_FILTERS,
      user.ActionTypes.SELECT_LOCATION,
      customer.ActionTypes.CUSTOMERS_SELECTED_FOR_ENGAGE,
      care.ActionTypes.LOAD_CARES_PAGE
    ),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.areFiltersLoaded)),
    filter(([action, areFiltersLoaded]) => !areFiltersLoaded),
    switchMap(([action, filters]) => this.ms.loadFilters().pipe(
      map((res) => new meet.LoadFiltersSuccess({ filters: res, page: action.page })),
      catchError((error) => of(new meet.LoadFiltersFailure(error)))
    ))
  );

  @Effect()
  public loadSavedGroups$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.TOGGLE_CATEGORY_EXPANSION),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.getAllSavedGroups)),
    filter(([payload, savedGroups]) => {
      return payload.category.id === MeetConstants.savedGroupsFilterId && !payload.category.open && savedGroups.length === 0;
    }),
    switchMap(() => this.ms.loadSavedGroups().pipe(
      map((res) => new meet.LoadSavedGroupsSuccess(res)),
      catchError((error) => of(new meet.LoadSavedGroupsFailure(error)))
    ))
  );

  @Effect()
  public loadSavedGroupsForNewLocation$: Observable<any> = this.actions$.pipe(
    ofType(user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.isSavedGroupsCategoryOpen)),
    filter(([payload, isSavedGroupsOpen]) => isSavedGroupsOpen),
    switchMap(() => this.ms.loadSavedGroups().pipe(
      map((res) => new meet.LoadSavedGroupsSuccess(res)),
      catchError((error) => of(new meet.LoadSavedGroupsFailure(error)))
    ))
  );

  @Effect()
  public getSelectedSavedGroupCustomers$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS),
    map((action: any) => action.payload),
    switchMap((groupId) => this.ms.loadSelectedSavedGroupCustomers(groupId).pipe(
      map((res) => new meet.LoadSelectedSavedGroupCustomersSuccess(res)),
      catchError((error) => of(new meet.LoadSelectedSavedGroupCustomersFailure(error)))
    ))
  );

  @Effect()
  public updateSavedGroup$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.UPDATE_SAVED_GROUP),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.getUpdateSavedGroupPayload).pipe(take(1))),
    switchMap(([name, group]) => this.ms.updateSavedGroup({ ...group, name }).pipe(
      map(() => new meet.UpdateSavedGroupSuccess({ ...group, name })),
      catchError((error) => of(new meet.UpdateSavedGroupError(error)))
    ))
  );

  @Effect()
  public deleteSavedGroupFilter$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.DELETE_SAVED_GROUP),
    map((action: any) => action.payload),
    switchMap((group) => this.ms.deleteSavedGroup(group.id).pipe(
      map((res) => new meet.DeleteSavedGroupSuccess(group)),
      catchError((error) => of(new meet.DeleteSavedGroupError(error)))
    ))
  );

  @Effect()
  public refreshSavedGroupsOnDelete$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.DELETE_SAVED_GROUP_SUCCESS),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.getMeetSelectedSavedGroups)),
    switchMap(([group, selectedSavedGroups]) => this.ms.loadSavedGroups().pipe(
      mergeMap((res) => {
        let actionArray: any[] = [new meet.LoadSavedGroupsSuccess(res)];
        let selectedGroupIds = pluck('id', selectedSavedGroups);

        // Only dispatch RemoveSavedGroup action if the saved filter is currently applied
        if (contains(group.id, selectedGroupIds)) {
          actionArray.push(new meet.RemoveSavedGroup({ savedGroup : group, page: AppConstants.meetPage }));
        }
        return actionArray;
      }),
      catchError((error) => of(new meet.LoadSavedGroupsFailure(error)))
    ))
  );

  @Effect()
  public refreshSavedGroupsOnCreate$: Observable<any> = this.actions$.pipe(
    ofType(
      meet.ActionTypes.CREATE_SAVED_GROUP_SUCCESS,
      meet.ActionTypes.UPDATE_SAVED_GROUP_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap(() => this.ms.loadSavedGroups().pipe(
      map((res) => new meet.LoadSavedGroupsSuccess(res)),
      catchError((error) => of(new meet.LoadSavedGroupsFailure(error)))
    ))
  );

  @Effect()
  public createSavedGroup: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.CREATE_SAVED_GROUP),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.getCreateGroupRequest)),
    switchMap(([groupName, groupPayload]) => this.ms.createSavedGroup({ ...groupPayload, name: groupName }).pipe(
      map((group) => new meet.CreateSavedGroupSuccess(group)),
      catchError((error) => of(new meet.CreateSavedGroupError(error)))
    ))
  );

  @Effect({ dispatch: false })
  public createSavedGroupSuccess$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.CREATE_SAVED_GROUP_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Saved Group has been successfully created.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public createSavedGroupError$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.CREATE_SAVED_GROUP_ERROR),
    tap((error) => {
      this.snackBar.open(
        error.payload,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public deleteSavedGroupSuccess$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.DELETE_SAVED_GROUP_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Saved Group has been successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public updateSavedGroupError$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.UPDATE_SAVED_GROUP_ERROR),
    tap(() => {
      this.snackBar.open(
        `Failed to update saved group, please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public deleteSavedGroupError$: Observable<any> = this.actions$.pipe(
    ofType(meet.ActionTypes.DELETE_SAVED_GROUP_ERROR),
    tap(() => {
      this.snackBar.open(
        `Failed to delete saved group, please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  // When the user removes the final selected filter on mobile navigate them back to the filter selection page
  @Effect({ dispatch: false })
  public goToFilterSelection$: Observable<any> = this.actions$.pipe(
    ofType(
      meet.ActionTypes.REMOVE_SELECTED_FILTER,
      meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER,
      meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER,
      meet.ActionTypes.CLEAR_FILTERS,
      meet.ActionTypes.REMOVE_SAVED_GROUP
    ),
    map((action: any) => action.payload),
    concatLatestFrom((payload) => {
      const page = payload.page;
      return this.store.select(fromRoot.isMobileWithFiltersCount(page));
    }),
    filter(([filter, removedAllFilters]) => removedAllFilters),
    map(([filter, removedAllFilters]) => {
      const page = filter.page;
      const previousRoute = page == AppConstants.meetPage ? '/meet' : '/engage/engage-details';
      const queryParams = { previousRoute, page };
      this.router.navigate(['/filter/filter-display-for-mobile'], { queryParams });
    }),
  );

  constructor(
    private actions$: Actions,
    private ms: MeetService,
    private store: Store<fromRoot.State>,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }
}
