import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'spotlight-ui/app/core/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'service-now-report',
  templateUrl: './service-now-report.component.html',
  styleUrls: ['./service-now-report.component.scss']
})
export class ServiceNowReportComponent {
  public isMobile$: Observable<boolean>;

  constructor(private analytics: AnalyticsService,
              private store: Store<fromRoot.State>,
              private router: Router,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<ServiceNowReportComponent>) {
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
  }

  public closeReport() {
    this.dialogRef.close();
  }
}
