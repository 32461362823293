export class NotificationListConstants {
  public static get notificationTypes(): any[] {
    return [{
      name: 'Event Notifications',
      displayName: 'Engagement Notifications'
    }];
  }

  public static get errorText(): string {
    return 'There was an error accessing notifications. Please try again later.';
  }
}
