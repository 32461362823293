import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
  @Input() displayString
  @Input() isMobile;

  public display = '';
  public length;
  public continuedString = '';
  public readMoreExpanded = false;
  constructor() {

  }

  ngOnInit(): void {
    this.length = this.isMobile ? 11 : 33;
    if(this.displayString && this.length) {
      let splitDisplayString = this.displayString.split(' ');
      splitDisplayString.forEach(subString =>{
        if(this.display.length > this.length){
          this.continuedString = this.continuedString + ' ' + subString;
        }else{
          this.display = this.display + ' ' + subString;
        }
      })
      this.display = this.display.slice(1)
    }
  }

  expandReadMore(event){
    event.stopPropagation();
    this.readMoreExpanded = true;
  }

  collapseReadMore(event){
    event.stopPropagation();
    this.readMoreExpanded = false;
  }

}
