<mat-toolbar class="navbar-footer">
    <div class="navbar-footer__flex-container" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container *ngFor="let page of navLinks; let i = index">
            <span *ngIf="page.displayOnBottom" fxLayout="column" fxLayoutAlign="start center" [routerLink]="page.url">
                <img alt="{{page.active ? 'Active Icon' : 'Inactive Icon'}}"
                     class="navbar-footer__tab-icon" [src]="page.active ? page.activeIconUrl : page.inactiveIconUrl">
                <span class="navbar-footer__tab-label" [ngClass]="{'navbar-footer__tab-label-active': page.active}">
                  {{page.label}}
                </span>
            </span>
        </ng-container>
    </div>
</mat-toolbar>
