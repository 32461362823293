<div class="community-care-confirmation-dialog">
  <div class="community-care-confirmation-dialog__title">Are You Sure?</div>
  <div class="community-care-confirmation-dialog__text">
    Please confirm the organization you have selected to support aligns with
    <a href ="{{givingStrategyUrl}}" target = "_blank" class="community-care-confirmation-dialog__hyperlink"> Chick-fil-A’s giving strategy</a> and meets
    <a href="{{operatingStandardUrl}}" target = "_blank" class="community-care-confirmation-dialog__hyperlink">operating standards</a>.
    Recipient organizations should have community-wide impact and not be social, political or divisive in nature.
    For questions or vetting please contact <a href="mailto:prsupport@chick-fil-a.com" class="community-care-confirmation-dialog__hyperlink">prsupport@chick-fil-a.com</a>.
  </div>

  <div mat-dialog-actions align="end" class="community-care-confirmation-dialog__wrapper">
    <button mat-button (click)="closeDialog(true)" class="community-care-confirmation-dialog__button">
     <img alt="Confirm Button" src="{{confirmationButton}}">
    </button>
    <button mat-button (click)="closeDialog(false)" class="community-care-confirmation-dialog__button">
      <img alt="Go Back Button" src="{{goBackButton}}">
    </button>
  </div>
</div>
