import { ErrorHandler, Injectable } from '@angular/core';
import { AnalyticsService } from '../analytics';

@Injectable()
export class ConsoleErrorHandlerService implements ErrorHandler {
  constructor(private analytics: AnalyticsService) {}

  public handleError(error: Error) {
    console.error(error);
    this.analytics.sendMParticleEvent(
      'Console Error Thrown',
      {
        error: error.message || 'Undefined Error',
        stackTrace: error.stack || 'Undefined Stack'
      }
    );
  }
}
