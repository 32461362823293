import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() public headerText: string;
  @Input() public categories: string[];
  @Input() public selectedCategory: string = '';
  @Input() public isMobile: boolean;

  @Output() public categoryChanged = new EventEmitter();

  public dropdownOpen = false;
  public bottomSheetData = {
    'list': [],
    'title': 'Search by',
    'searchBy': true,
    'isCancel': true,
    'selectedItem': this.selectedCategory
  };

  @HostListener('document:click', ['$event'])
  public clickOutside(event) {
    if (!this.elemRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }
  constructor(private bottomSheet: MatBottomSheet, private elemRef: ElementRef) {}

  public toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.isMobile) {
      this.openMobileCategories();
    }
  }

  public selectCategory(newCategory) {
    this.dropdownOpen = !this.dropdownOpen;

    // Only make a new call if the category has been changed
    if (this.selectedCategory !== newCategory) {
      this.selectedCategory = newCategory;
      this.categoryChanged.emit(newCategory);
    }
  }

  public openMobileCategories() {
    // Convert the category string array into an array of objects for the bottomSheetData.list
    let categoryObjectArray = this.categories.map((category) => {
      return { name: category, value: category }
    });
    this.bottomSheetData.list = categoryObjectArray;

    const bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: { content: this.bottomSheetData }
    });

    bottomSheetRef.afterDismissed().pipe(take(1)).subscribe((dataFromBottomSheet) => {
      this.dropdownOpen = !this.dropdownOpen;

      // Only make a new call if the category has been changed
      if (dataFromBottomSheet && dataFromBottomSheet.value
          && this.selectedCategory !== dataFromBottomSheet.value) {
        this.selectedCategory = dataFromBottomSheet.value;
        this.categoryChanged.emit(dataFromBottomSheet.value);
      }
    });
  }
}
