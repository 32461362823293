import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '../../core/analytics';
import * as fromRoot from '../../reducers';
import { browserRefreshed } from '../../app.component';

@Component({
  selector: 'discover-error',
  templateUrl: './error.component.html'
})
export class DiscoverErrorComponent {
  public title$: Observable<any>;
  public error$: Observable<string>;
  public isMobile$: Observable<boolean>;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private analytics: AnalyticsService,
              private store: Store<fromRoot.State>) {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.title$ = store.select(fromRoot.getDiscoverTileById).pipe(map((tile) => tile.descriptionText.summary));
      this.error$ = store.select(fromRoot.getEngagementError);
    }
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }
}
